<template>
    <FilesNewView parent="student" :files="getFiles()" />
</template>

<script>
import FilesNewView from "@components/Files/FilesNewView.vue"
import { base64ToBytes } from "@helpers"

export default {
    name: "FileComponent",
    components: { FilesNewView },
    props: {
        files: {
            type: String,
            default: ""
        }
    },
    methods: {
        getFiles() {
            const result = new TextDecoder().decode(base64ToBytes(this.files))

            if (result) {
                return JSON.parse(result).map(f => {
                    return {
                        ...f,
                        url: f.url || f.preview_path
                    }
                })
            }

            return []
        }
    }
}
</script>

<style scoped lang="sass">

</style>
