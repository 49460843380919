<template>
    <div
        @click="$emit('click')"
        class="youtube-video-player-preview"
        :style="{
            paddingBottom: padding,
            backgroundImage: `url('${preview}')`
        }"
    >
        <button class="play-button">
            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M19.376 12.4158L8.77735 19.4816C8.54759 19.6348 8.23715 19.5727 8.08397 19.3429C8.02922 19.2608 8 19.1643 8 19.0656V4.93408C8 4.65794 8.22386 4.43408 8.5 4.43408C8.59871 4.43408 8.69522 4.4633 8.77735 4.51806L19.376 11.5838C19.6057 11.737 19.6678 12.0474 19.5146 12.2772C19.478 12.3321 19.4309 12.3792 19.376 12.4158Z"
                ></path>
            </svg>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        padding: {
            type: String,
            default: ""
        },
        preview: {
            type: String,
            default: ""
        }
    },
    name: "YoutubeVideoShadow"
}
</script>


<style lang="sass" scoped>
.youtube-video-player-preview
    padding-bottom: 50%
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    border-radius: 10px
    position: relative
.play-button
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 75px
    height: 75px
    border-radius: 50%
    background-color: #3965ff
    cursor: pointer
    pointer-events: all
    display: flex
    align-items: center
    justify-content: center
    svg
        width: 50px
</style>
