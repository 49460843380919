<template>
    <div class="flat-loader">
        <div
            :style="{
                height: `3px`,
                transition: '.2s',
                background: `#15A06E`,
                width: `${percent}%`
            }"
        ></div>
    </div>
</template>

<script>
export default {
    props: {
        percent: {
            default: 0
        }
    },
    name: "FlatLoader"
}
</script>

<style scoped lang="sass">
.flat-loader
    pointer-events: none
    border-radius: 10px
    height: 3px
    position:  relative
    background-color: #D2DFF3
</style>
