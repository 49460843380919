<template>
    <div class="option-container">
        <component
            v-if="option"
            :is="component"
            :to="option.value ? { name: option.value } : { path: option.path }"
            :href="type === 'href' ? option.path : ''"
            :target="type === 'href' ? '_self' : ''"
            align="center"
            class="options-list-item pointer flex-wrap"
            :class="{
                'options-list-item_selected':
                    (defaultVal && (defaultVal.id === option.id || defaultVal.product_id === option.id)) ||
                    (selectedIds && selectedIds.includes(option.id)),
                disabled: option.$isDisabled,
                'events-none': option.$eventNone || (type === 'hierarhy' && option.course_nodeble_type === 'module'),
                bold: type === 'hierarhy' && option.course_nodeble_type === 'module',
                ['pl-' + option.$paddingLeft]: option.$paddingLeft,
                'flex-al-i-c': type === 'routes'
            }"
            @click.native="select(option, $event)"
        >
            {{ option[keyName] }}

            <p class="options-list-item__description" v-if="option.description_text">
                {{ option.description_text }}
            </p>

            <component
                :class="{ 'rotate-180': option.isRotateIcon }"
                fill="#52565C"
                class="ml-3"
                v-if="option.icon"
                :is="option.icon"
            ></component>
        </component>
        <div class="ml-15" v-if="type === 'hierarhy'">
            <DefaultDropdownOption
                @select="$emit('select', $event)"
                :key="index"
                :option="opt"
                :key-name="keyName"
                :type="type"
                v-for="(opt, index) in option.children"
            />
        </div>
    </div>
</template>

<script>
import FlexContainer from "../Containers/FlexContainer.vue"

export default {
    name: "DefaultDropdownOption",
    components: {
        FlexContainer
    },
    props: {
        option: {
            type: Object,
            required: true
        },
        defaultVal: {
            type: Object,
            default: null
        },
        keyName: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: "default"
        },
        selectedIds: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        component() {
            if (this.type === "href") {
                return "a"
            }
            return this.type === "routes" ? "RouterLink" : "FlexContainer"
        }
    },
    methods: {
        select(opt, event) {
            if (this.type === "hierarhy" && this.option.course_nodeble_type === "module") {
                return
            }

            if (this.type === "routes") {
                return
            }

            this.$emit("select", { opt, event })
        }
    }
}
</script>

<style scoped lang="sass">
$text-color: #151515
$hover-color: #F2F7FF
.px-15
    padding-left: 15px
    padding-right: 15px
.py-4
    padding-top: 4px
    padding-bottom: 4px
.option-container
    &:not(&:last-child)
        margin-bottom: 2px
.selected-item
    color: $text-color !important
    background: $hover-color
    &:hover
        background: $hover-color !important
.options-list-item
    text-align: left
    height: fit-content
    min-height: 40px
    padding: 0 8px
    border-radius: 5px
    font-size: 14px
    color: #323030
    font-family: Inter, sans-serif
    font-weight: 400
    transition: .2s
    &:not(&:last-child)
        margin-bottom: 2px
    &__description
        display: block
        width: 100%
        text-align: left
        color: #151515 !important
        font-size: 11px
    &.bold
        font-weight: 600
        color: #151515
    &:not(:last-child)
        margin-bottom: 2px
    &:hover
        background: rgba($hover-color, 0.7)
    //@extend .selected-item
    &_selected
        @extend .selected-item
        cursor: pointer
.option-container.text-blue
    .options-list-item
        color: inherit
</style>
