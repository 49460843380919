<template>
    <ModalWindow :modalWidth="300" @closeModal="$emit('close'), $emit('clear')">
        <default-title :weight="500" :size="16" :lineHeight="16 * 1.3" textAlign="center" :marginBottom="30">
            {{ t("expert.delete_title") }}<span v-if="type" class="ml-5">{{ type }}</span
            >?
        </default-title>
        <div v-if="itemName" class="modal-data p-15 mb-20">{{ itemName }}</div>
        <div v-if="text" class="modal-text mb-20">{{ text }}</div>
        <div v-if="imgPath" class="modal-img mb-20">
            <img :src="imgPath" alt="Image to delete" />
        </div>
        <div class="modal-buttons">
            <button
                type="button"
                class="btn2 btn2_clear"
                @click="
                    $emit('close')
                    $emit('clear')
                "
            >
                {{ t("expert.no_cancel") }}
            </button>

            <button
                type="button"
                :disabled="loading"
                class="btn2 btn2_danger"
                :class="{ loader: loading }"
                @click="$emit('delete', item)"
            >
                {{ t("expert.yes_delete") }}
            </button>
        </div>
    </ModalWindow>
</template>

<script>
import ModalWindow from "@components/ModalWindow/ModalWindow"
import DefaultTitle from "@components/Typography/DefaultTitle"

export default {
    name: "SimpleDeleteModal",
    components: {
        ModalWindow,
        DefaultTitle
    },
    props: {
        item: {
            type: [Object, Array],
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        type: String,
        text: {
            type: String,
            default: null
        },
        itemName: {
            type: String,
            default: null
        },
        imgPath: {
            type: String,
            default: null
        }
    }
}
</script>

<style lang="sass" scoped>
@import 'resources/sass/vars'

.modal-data
    position: relative
    height: fit-content
    min-height: $modal-content-min-height
    margin: 20px 0 15px !important
    font-weight: 500
    font-size: 13px
    line-height: 150%
    color: #151515
    box-shadow: 2px 2px 10px rgba(128, 158, 191, 0.15)
    border-radius: 5px
    white-space: nowrap
    overflow: hidden
    &::after
        content: ""
        position: absolute
        right: 0
        width: 50px
        height: 100%
        background: linear-gradient(to right, transparent 0%, #fff 80%)
        border-radius: 0 5px 5px 0
.modal-text
    font-weight: 400
    font-size: 14px
    line-height: 130%
    color: $text-accent-color
.modal-buttons
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 10px
.modal-img
    width: 100%
    height: fit-content
    max-height: 200px
    img
        border-radius: 10px
        width: 100%
        height: 56.25%
        object-fit: cover
</style>
