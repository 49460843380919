<template>
    <flex-container align="center" class="creation-page-loader z10">
        <LoaderBlock :greenColor="true" />
    </flex-container>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer"
import LoaderBlock from "@components/Loaders/LoaderBlock"

export default {
    name: "CreationBlockLoader",
    components: {
        LoaderBlock,
        FlexContainer
    }
}
</script>

<style lang="sass" scoped>
.creation-page-loader
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    width: 100%
    background: rgba(255, 255, 255, 0.5)
    border-radius: 10px
</style>
