<template>
    <div class="mt-12 mb-20" v-if="isBlocked() || isClosed()">
        <default-description :line-height="21" :size="14" class="quiz-unavailable-description">
            <span v-html="format(blocked.tooltip_message)" v-if="isBlocked()"></span>
            <span v-html="format(closed.tooltip_message)" v-else-if="isClosed()"></span>
        </default-description>
        <default-divider class="mt-20" color="#F3F3F3"></default-divider>
    </div>
</template>

<script>
import DefaultDescription from "@components/Typography/DefaultDescription.vue"
import DefaultDivider from "@components/Typography/DefaultDivider.vue"
import { DateTime } from "luxon"

export default {
    components: { DefaultDivider, DefaultDescription },
    props: {
        quiz: {
            type: Object,
            default: () => {}
        }
    },
    name: "PracticeQuizAccess",
    methods: {
        format(message) {
            const toSpan = (date, modifier) => {
                return `<span class="${modifier}">${DateTime.fromISO(date)
                    .setLocale(window.app_current_locale)
                    .setZone(window.app_timezone)
                    .toFormat("dd MMMM, yyyy HH:mm")}</span> <span class="gray-light">(${window.app_timezone})</span>`
            }

            return message.replaceAll(
                ":date",
                this.isBlocked() ? toSpan(this.blocked.access_date, "blue") : toSpan(this.closed.access_date, "red")
            )
        },
        isBlocked() {
            return !this.blocked.is_accessible
        },
        isClosed() {
            return !this.closed.is_accessible || this.closed.tooltip_message
        }
    },
    computed: {
        blocked() {
            return this.quiz.blocked_info
        },
        closed() {
            return this.quiz.closed_info
        }
    }
}
</script>

<style scoped lang="sass">

</style>
