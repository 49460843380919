import PracticeQuestionDescription from "@components/Practice/PracticeQuestions/components/PracticeQuestionDescription.vue"
import PracticeQuestionComment from "@components/Practice/PracticeQuestions/components/PracticeQuestionComment.vue"
import { QUESTION_TYPES } from "@components/Practice/PracticeQuestions/_types"
import TimerMixin from "@components/Practice/common/timer/_timer"
import strFilters from "@mixins/strFilters"

export default {
    components: {
        PracticeQuestionDescription,
        PracticeQuestionComment
    },
    props: {
        commentableType: {
            type: String,
            default: "quiz-attempt"
        },
        question: {
            type: Object,
            default: () => ({
                id: 0
            })
        },
        isDisplayComment: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
            default: () => []
        },
        attempt: {
            type: Object,
            default: null
        },
        quiz: {
            type: Object,
            default: null
        },
        viewedUser: {
            type: Object,
            default: null
        },
        results: {
            type: Array,
            default: null
        },
        questionNumber: {
            type: Number,
            default: 0
        },
        courseId: {
            type: [String, Number],
            default: 0
        },
        unitId: {
            type: [String, Number],
            default: 0
        },
        lesson: {
            type: Object,
            default: null
        },
        interactive: {
            type: Boolean,
            default: false
        },
        viewable: {
            type: Boolean,
            default: false
        },
        checkable: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        expertView: {
            type: Boolean,
            default: false
        },
        aggregateInterview: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            didUpdateAction: false,
            QUESTION_TYPES
        }
    },
    beforeDestroy() {
        this.$emit("destroy")
    },
    mixins: [TimerMixin, strFilters],
    mounted() {
        setTimeout(() => {
            this.$forceUpdate()
        })
    },
    methods: {
        isUserFriendlyType() {
            return [
                QUESTION_TYPES.TEXT_MEDIA_ANSWER,
                QUESTION_TYPES.RATING,
                QUESTION_TYPES.REACTION,
                QUESTION_TYPES.NET_PROMOTE,
                QUESTION_TYPES.INTERVIEW,
                QUESTION_TYPES.LIKERT_SCALE,
                QUESTION_TYPES.TEXT_GRAPHIC_ANSWER
            ].includes(this.question.type.slug)
        },
        isUserSelectType() {
            return [
                QUESTION_TYPES.SINGLE_ANSWER,
                QUESTION_TYPES.MULTIPLE_ANSWER,
                QUESTION_TYPES.YES_NO,
                QUESTION_TYPES.FILL_GAPS,
                QUESTION_TYPES.WORD_ORDER,
                QUESTION_TYPES.WORD_FROM_CHARS,
                QUESTION_TYPES.CONFORMITY_ANSWER,
                QUESTION_TYPES.SORTING,
                QUESTION_TYPES.PASTE_WORD,
                QUESTION_TYPES.CHOOSE_FROM_LIST,
                QUESTION_TYPES.MULTI_CHOOSE_FROM_LIST
            ].includes(this.question.type.slug)
        },
        hasCode() {
            return (
                this.question &&
                this.question.answers &&
                this.question.answers.find(a => a.text && a.text.includes("code-component"))
            )
        },
        isNotStarted() {
            if (this.interactive) {
                return false
            }

            return this.question.is_not_started
        },
        isSkipped() {
            if (this.interactive) {
                return false
            }

            return this.question.is_skipped
        },
        isTrueRTL(s) {
            let ltrChars =
                    "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
                    "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
                rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
                rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]")

            return rtlDirCheck.test(s)
        },
        isCorrect() {
            if (this.isUserFriendlyType()) {
                return false
            }
            if (
                this.interactive ||
                !(
                    this.typeShow() === "only_correct_or_not" ||
                    (this.typeShow() === "full_information" && this.isUserSelectType())
                )
            ) {
                return false
            }

            return this.question.attempt_question_result && this.question.attempt_question_result.is_correct
        },
        isIncorrect() {
            if (this.isUserFriendlyType()) {
                return false
            }

            if (this.interactive) {
                return false
            }

            if (
                !(
                    this.typeShow() === "only_correct_or_not" ||
                    (this.typeShow() === "full_information" && this.isUserSelectType())
                )
            ) {
                return
            }

            const isCorrect = this.question.attempt_question_result && this.question.attempt_question_result.is_correct

            return !isCorrect
        },
        isAllAnswersChoice() {
            return this.question.is_answers_combined
        },
        isType(type) {
            return this?.question?.type?.slug === type
        },
        forceUpdateAction() {
            this.didUpdateAction = true
            this.$nextTick(() => {
                this.didUpdateAction = false
            })
        },
        getImageStyle(entry) {
            if (entry.image_width && entry.image_height) {
                if (this.$el) {
                    const containerWidth = this.$el.getBoundingClientRect().width
                    if (containerWidth > entry.image_width) {
                        return {
                            height: `${entry.image_height}px`,
                            width: `${entry.image_width}px`,
                            maxWidth: "100%",
                            "object-fit": "cover"
                        }
                    }
                }
                return {
                    height: `auto`,
                    maxHeight: `${entry.image_height}px`,
                    maxWidth: `${entry.image_width}px`,
                    width: "100%",
                    "object-fit": "cover"
                }
            }
            return {
                maxHeight: "400px"
            }
        },
        isHorizontal(scale) {
            return scale == 2
        },
        getCorrectAnswers() {
            return this.question.answers.filter(answer => answer.is_correct)
        },
        isOneAnswer() {
            return this?.question?.answers?.length === 1
        },
        getCommentable(statement) {
            return statement
        },
        getTextWithCustom(answer) {
            if (!this.interactive) {
                if (answer.type === 1) {
                    return answer.text
                } else {
                    const text_answer = this.results.find(r => r.quiz_answer_id === answer.id)?.text_answer
                    return `<p><i style="color: #3451BE; font-size: 14px;">${this.inlineText(answer.text)}:</i> ${
                        text_answer || ""
                    }</p>`
                }
            }

            if (answer.text === '<p style="text-align: left"></p>') {
                answer.text = ""
            }

            return this.isCustomAnswer(answer)
                ? answer.is_correct
                    ? ""
                    : answer.text_answer || answer.text
                : answer.text
        },
        isAnswered(answer) {
            const result = this.results && this.results.find(r => r.quiz_answer_id === answer.id)

            if (this.viewable && this.question.type.slug === QUESTION_TYPES.WORD_FROM_CHARS) {
                return result
            }

            if (this.interactive || !this.results) {
                return true
            }

            return result
        },
        widget() {
            return {
                expertView: this.expertView,
                typeShow: this.quiz?.result_attempt?.type_show,
                lessonId: this.lessonId || this.lesson?.id || 0,
                questionId: this.question.id,
                question: this.question,
                userId: this.viewedUser?.id || 0,
                attemptId: this.attempt?.id || 0
            }
        },
        typeShow() {
            if (this.question.is_viewed_result) {
                const type = this.quiz.type_show_question_result_attempt.id

                if (type === 2) {
                    return "only_correct_or_not"
                }

                if (type === 3) {
                    return "full_information"
                }
            }

            return this.quiz?.result_attempt?.type_show || "simple_answer"
        },
        inlineText(html) {
            const div = document.createElement("div")
            div.innerHTML = html
            return div.textContent || div.innerText || ""
        },
        isCustomAnswer(answer) {
            return answer.type === 2
        },
        isShowAnswers() {
            if (this.interactive) {
                return true
            }
            return this.typeShow() !== "only_correct_or_not_question"
        },
        disabledByTimer() {
            return this.interactive && this.quiz.attempt && this.isTimeLeft(this.question.left_time_in_seconds)
        },
        questionStyle() {
            if (this.interactive || this.typeShow() !== "only_correct_or_not_question") {
                return {}
            }

            const isCorrect = this.question.attempt_question_result && this.question.attempt_question_result.is_correct

            if (this.isSkipped()) {
                return {}
            }

            if (!isCorrect) {
                return {
                    background: "rgba(221, 65, 65, 0.15)"
                }
            } else {
                return {
                    background: "rgba(48, 197, 143, 0.15)"
                }
            }
        }
    },
    computed: {
        ty() {
            return this.typeShow()
        }
    }
}
