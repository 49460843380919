
<template>
    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.75 10C13.75 9.58579 13.4142 9.25 13 9.25C12.5858 9.25 12.25 9.58579 12.25 10H13.75ZM1.75 10C1.75 9.58579 1.41421 9.25 1 9.25C0.585786 9.25 0.25 9.58579 0.25 10H1.75ZM10 19.75C10.4142 19.75 10.75 19.4142 10.75 19C10.75 18.5858 10.4142 18.25 10 18.25V19.75ZM4 18.25C3.58579 18.25 3.25 18.5858 3.25 19C3.25 19.4142 3.58579 19.75 4 19.75V18.25ZM9.25 4V10H10.75V4H9.25ZM4.75 10V4H3.25V10H4.75ZM7 12.25C5.75736 12.25 4.75 11.2426 4.75 10H3.25C3.25 12.0711 4.92893 13.75 7 13.75V12.25ZM9.25 10C9.25 11.2426 8.24264 12.25 7 12.25V13.75C9.07107 13.75 10.75 12.0711 10.75 10H9.25ZM7 1.75C8.24264 1.75 9.25 2.75736 9.25 4H10.75C10.75 1.92893 9.07107 0.25 7 0.25V1.75ZM7 0.25C4.92893 0.25 3.25 1.92893 3.25 4H4.75C4.75 2.75736 5.75736 1.75 7 1.75V0.25ZM12.25 10C12.25 12.8995 9.8995 15.25 7 15.25V16.75C10.7279 16.75 13.75 13.7279 13.75 10H12.25ZM7 15.25C4.10051 15.25 1.75 12.8995 1.75 10H0.25C0.25 13.7279 3.27208 16.75 7 16.75V15.25ZM6.25 16V19H7.75V16H6.25ZM7 19.75H10V18.25H7V19.75ZM7 18.25H4V19.75H7V18.25Z"
            fill="#3E4755"
        />
    </svg>
</template>
<script>
export default {
    name: "RecordVoiceIcon"
}
</script>