
<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="#B5C1D2" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.33398 5V4C7.7817 4 7.33398 4.44772 7.33398 5H8.33398ZM31.6673 35V36C32.2196 36 32.6673 35.5523 32.6673 35H31.6673ZM8.33398 35H7.33398C7.33398 35.5523 7.7817 36 8.33398 36V35ZM23.334 5L24.0411 4.29289C23.8536 4.10536 23.5992 4 23.334 4V5ZM31.6673 13.3333H32.6673C32.6673 13.0681 32.562 12.8138 32.3744 12.6262L31.6673 13.3333ZM13.334 27.3333C12.7817 27.3333 12.334 27.781 12.334 28.3333C12.334 28.8856 12.7817 29.3333 13.334 29.3333V27.3333ZM26.6673 29.3333C27.2196 29.3333 27.6673 28.8856 27.6673 28.3333C27.6673 27.781 27.2196 27.3333 26.6673 27.3333V29.3333ZM13.334 20.6667C12.7817 20.6667 12.334 21.1144 12.334 21.6667C12.334 22.219 12.7817 22.6667 13.334 22.6667V20.6667ZM23.334 22.6667C23.8863 22.6667 24.334 22.219 24.334 21.6667C24.334 21.1144 23.8863 20.6667 23.334 20.6667V22.6667ZM31.6673 34H8.33398V36H31.6673V34ZM9.33398 35V5H7.33398V35H9.33398ZM8.33398 6H23.334V4H8.33398V6ZM30.6673 13.3333V35H32.6673V13.3333H30.6673ZM22.6269 5.70711L30.9602 14.0404L32.3744 12.6262L24.0411 4.29289L22.6269 5.70711ZM20.6673 5V11.6667H22.6673V5H20.6673ZM25.0007 16H31.6673V14H25.0007V16ZM20.6673 11.6667C20.6673 14.0599 22.6074 16 25.0007 16V14C23.712 14 22.6673 12.9553 22.6673 11.6667H20.6673ZM13.334 29.3333H26.6673V27.3333H13.334V29.3333ZM13.334 22.6667H23.334V20.6667H13.334V22.6667Z"
        />
    </svg>
</template>
<script>
export default {
    name: "FileSimpleIcon"
}
</script>