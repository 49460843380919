
<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 0C8.625 0 7.5 1.125 7.5 2.5V37.5C7.5 38.875 8.625 40 10 40H35C36.375 40 37.5 38.875 37.5 37.5V10L27.5 0H10Z"
            fill="#E2E5E7"
        />
        <path d="M30 10H37.5L27.5 0V7.5C27.5 8.875 28.625 10 30 10Z" fill="#B0B7BD" />
        <path d="M37.5 17.5L30 10H37.5V17.5Z" fill="#CAD1D8" />
        <path
            d="M32.5 32.5C32.5 33.1875 31.9375 33.75 31.25 33.75H3.75C3.0625 33.75 2.5 33.1875 2.5 32.5V20C2.5 19.3125 3.0625 18.75 3.75 18.75H31.25C31.9375 18.75 32.5 19.3125 32.5 20V32.5Z"
            fill="#F15642"
        />
        <path
            d="M7.94922 23.6837C7.94922 23.3537 8.20922 22.9937 8.62797 22.9937H10.9367C12.2367 22.9937 13.4067 23.8637 13.4067 25.5312C13.4067 27.1112 12.2367 27.9912 10.9367 27.9912H9.26797V29.3112C9.26797 29.7512 8.98797 30 8.62797 30C8.29797 30 7.94922 29.7512 7.94922 29.3112V23.6837ZM9.26797 24.2525V26.7425H10.9367C11.6067 26.7425 12.1367 26.1512 12.1367 25.5312C12.1367 24.8325 11.6067 24.2525 10.9367 24.2525H9.26797Z"
            fill="white"
        />
        <path
            d="M15.3638 30C15.0338 30 14.6738 29.82 14.6738 29.3812V23.7037C14.6738 23.345 15.0338 23.0837 15.3638 23.0837H17.6526C22.2201 23.0837 22.1201 30 17.7426 30H15.3638ZM15.9938 24.3037V28.7812H17.6526C20.3513 28.7812 20.4713 24.3037 17.6526 24.3037H15.9938Z"
            fill="white"
        />
        <path
            d="M23.7401 24.3837V25.9725H26.2889C26.6489 25.9725 27.0089 26.3325 27.0089 26.6812C27.0089 27.0112 26.6489 27.2812 26.2889 27.2812H23.7401V29.38C23.7401 29.73 23.4914 29.9987 23.1414 29.9987C22.7014 29.9987 22.4326 29.73 22.4326 29.38V23.7025C22.4326 23.3437 22.7026 23.0825 23.1414 23.0825H26.6501C27.0901 23.0825 27.3501 23.3437 27.3501 23.7025C27.3501 24.0225 27.0901 24.3825 26.6501 24.3825H23.7401V24.3837Z"
            fill="white"
        />
        <path
            d="M31.25 33.75H7.5V35H31.25C31.9375 35 32.5 34.4375 32.5 33.75V32.5C32.5 33.1875 31.9375 33.75 31.25 33.75Z"
            fill="#CAD1D8"
        />
    </svg>
</template>
<script>
export default {
    name: "FilePdfIcon"
}
</script>