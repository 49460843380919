<template>
    <div
        class="practice-question-description"
        :class="{ 'practice-question-description_collapse': allowCollapse && description && overflowComputed }"
    >
        <template v-if="allowCollapse && description">
            <ExtendedRenderHtml
                :data-overflow-render="uuid"
                v-if="!overflowComputed"
                @click.native="$emit('click')"
                :html="text"
            />
            <template v-else>
                <custom-collapse :min="120" :is-open="isOpen">
                    <ExtendedRenderHtml v-if="!didUpdate" @click.native="$emit('click')" :html="text" />
                </custom-collapse>
                <CollapseHandler
                    @update:isOpen="toggle"
                    :title="t(isOpen ? 'create-course.collapse_question' : 'create-course.expand_question')"
                    :is-open="!isOpen"
                />
            </template>
        </template>
        <ExtendedRenderHtml v-else-if="description && !didUpdate" @click.native="$emit('click')" :html="text" />
    </div>
</template>

<script>
import CollapseHandler from "@components/Collapse/CollapseHandler.vue"
import CustomCollapse from "@components/Collapse/CustomCollapse.vue"
import ImageEnlargable from "@mixins/image-enlargable"
import ExtendedRenderHtml from "@components/ExtendedRender/ExtendedRenderHtml.vue"
import IsOpen from "@mixins/is-open"
import renderMathInElement from "katex/contrib/auto-render"
import { v4 } from "uuid"
import "katex/dist/katex.min.css"

export default {
    components: { CollapseHandler, CustomCollapse, ExtendedRenderHtml },
    props: {
        description: {
            type: String,
            default: ""
        },
        allowCollapse: {
            type: Boolean,
            default: false
        },
        question: {
            type: Object,
            default: null
        },
        number: {
            type: Number,
            default: 0
        }
    },
    mixins: [ImageEnlargable, IsOpen],
    name: "PracticeQuestionDescription",
    data() {
        return {
            text: "",
            didUpdate: false,
            overflowComputed: false,
            uuid: v4()
        }
    },
    created() {
        this.isOpen = false
        if (!this.description) {
            return
        }
        this.text = this.createTextView()
        this.$forceUpdate()
    },
    mounted() {
        this.initImages()
    },
    methods: {
        checkOverflow() {
            this.overflowComputed = false

            this.$nextTick(() => {
                const el = document.querySelector(`[data-overflow-render='${this.uuid}']`)

                if (el) {
                    const { height } = el.getBoundingClientRect()

                    if (height > 120) {
                        this.overflowComputed = true
                    }
                }
            })
        },
        createTextView() {
            let description = this.description

            if (this.number) {
                const num = document.createElement("span")

                num.innerHTML = `${this.number}. `

                num.classList.add("number")
                num.classList.add("show")

                const text = document.createElement("span")

                text.innerHTML = description

                const first = text.firstChild

                if (first && first.tagName === "P") {
                    const html = first.innerHTML
                    first.innerHTML = `${num.outerHTML}${html}`
                } else if (text.firstChild) {
                    text.insertBefore(num, text.firstChild)
                }

                description = text.outerHTML
            } else {
                const text = document.createElement("div")
                text.innerHTML = description

                description = text.outerHTML
            }

            this.$nextTick(() => {
                this.checkOverflow()
            })

            const div = document.createElement("pre")
            div.className = "text-view"

            div.innerHTML = description

            renderMathInElement(div, {
                // customised options
                // • auto-render specific keys, e.g.:
                delimiters: [{ left: "{{", right: "}}", display: false }],
                // • rendering keys, e.g.:
                throwOnError: false
            })

            return div.outerHTML
        }
    },
    watch: {
        description() {
            this.text = this.createTextView()

            this.didUpdate = true
            this.$nextTick(() => {
                this.didUpdate = false
                this.$forceUpdate()
            })
        }
    }
}
</script>

<style scoped lang="sass">
.practice-question-description
    width: 100%
    padding-right: 12px
    &.text-center
        padding-right: 0
    &.text-center
        &::v-deep
            p
                text-align: center !important
    .collapse-content
        width: 100%
    &_collapse
        .collapse-content
            position: relative
            &:after
                content: ''
                height: 50px
                background: linear-gradient(to top, #fff, transparent)
                position: absolute
                transition: .1s
                pointer-events: none
                bottom: 0
                width: 100%
                display: block
            &.open
                &::after
                    opacity: 0
    .collapse-handler
        margin: 10px auto 0
        justify-content: center
        min-width: 200px
        @container (height: < 165)
            display: none
.ql-container
    height: max-content
    .ql-editor
        padding: 0
</style>
