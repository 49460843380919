<template>
    <a :href="link" v-if="native" class="back-button">
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5 5L0.500001 5M0.500001 5L5.08824 9M0.500001 5L5.08824 1"
                stroke="#52565C"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
        <span class="back-button__text" v-if="!text">{{ t("expert.go_back") }}</span>
        <span class="back-button__text" v-else v-html="text"></span>
        <template v-if="$slots.extra">
            <span class="mx-3 inline-block">{{ t("expert._to") }}</span>
            <span class="text-[#151515]"><slot name="extra"></slot></span>
        </template>
    </a>
    <router-link v-else-if="link !== 'prev'" :to="link" class="back-button" :class="[additionalClass]">
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5 5L0.500001 5M0.500001 5L5.08824 9M0.500001 5L5.08824 1"
                stroke="#52565C"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
        <span class="back-button__text" v-if="!text">{{ t("expert.go_back") }}</span>
        <span class="back-button__text" v-else v-html="text"></span>
        <template v-if="$slots.extra">
            <span class="mx-3 inline-block">{{ t("expert._to") }}</span>
            <span class="text-[#151515]"><slot name="extra"></slot></span>
        </template>
    </router-link>
    <button @click.stop.prevent="back" v-else class="back-button" :class="[additionalClass]">
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5 5L0.500001 5M0.500001 5L5.08824 9M0.500001 5L5.08824 1"
                stroke="#52565C"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
        <span class="back-button__text" v-if="!text">{{ t("expert.go_back") }}</span>
        <span class="back-button__text" v-else v-html="text"></span>
        <template v-if="$slots.extra">
            <span class="mx-3 inline-block">{{ t("expert._to") }}</span>
            <span class="text-[#151515]"><slot name="extra"></slot></span>
        </template>
    </button>
</template>

<script>
export default {
    name: "BackButton",
    props: {
        link: {
            type: [String, Object],
            required: false
        },
        text: {
            type: String
        },
        additionalClass: {
            type: String
        },
        native: {
            type: Boolean,
            default: false
        },
        isRouteName: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        back() {
            if (this._events.click) {
                this.$emit("click")
            } else {
                this.$router.go(-1)
            }
        }
    }
}
</script>
<style lang="sass" scoped>
@import "resources/sass/vars"
.back-button
    display: flex
    align-items: center
    width: fit-content
    height: max-content
    padding: 6px
    font-family: Inter, sans-serif
    color: #323030
    font-size: 13px
    font-weight: 400
    & > *
        pointer-events: none
    &__text
        margin-left: 11px
</style>
