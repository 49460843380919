import axios from "~axios"
import ProvidedExpertRoutes from "../../modules/Expert/data/provided-expert-routes"

export default {
    namespaced: true,
    state: {
        currentUser: {
            name: "",
            avatar: "",
            courseCount: 0,
            cabinets: [],
            default_cabinet: {},
            subscriptions: 0,
            products: 0
        },
        courses: [],
        cabinet_link: null,
        cabinets: [],
        // defaultCabinet: {},
        //get course viewers for experts
        // courseViewers: [],
        coursesLoad: false,
        // breadCrLoader: false,
        isProd: true,
        isOpenOnProd: true,
        cabinetAccesses: null,
        activeAlerts: [],
        menu: {}
    },
    getters: {
        currentUser(state) {
            return state.currentUser
        },
        isAnimalAvatar(state) {
            return (
                state.currentUser && state.currentUser.avatar_url && state.currentUser.avatar_url.includes("/animals")
            )
        },
        courses(state) {
            return state.courses
        },
        cabinets(state) {
            return state.cabinets
        },
        activeCabinet(state) {
            return state.cabinets.find(c => c.id === window.active_cabinet_id)
        },
        coursesLoad(state) {
            return state.coursesLoad
        },
        cabinetAccesses(state) {
            return state.cabinetAccesses
        },
        activeAlerts(state) {
            return state.activeAlerts
        },
        expertMenu(state) {
            return state.menu
        },
        cabinetLink(state) {
            return state.cabinet_link
        }
        // defaultCabinet(state) {
        //     return state.defaultCabinet
        // }
    },
    mutations: {
        setProductionMode(state, newValue) {
            state.isProd = newValue
        },
        SET_CURRENT_USER(state, newValue) {
            state.currentUser = newValue
        },
        SET_COURSES(state, newValue) {
            state.courses = newValue
        },
        TOGGLE_LOADING(state, newValue) {
            state.coursesLoad = newValue
        },
        SET_CABINETS(state, newValue) {
            state.cabinets = newValue
        },
        CHANGE_CURRENT_CABINET(state, personalSettings) {
            state.cabinets.forEach(cabinet => {
                if (cabinet.is_current && cabinet["personal_settings"]) {
                    cabinet["personal_settings"] = personalSettings
                }
            })
        },
        SET_CABINET_ACCESSES(state, newValue) {
            state.cabinetAccesses = newValue
        },
        SET_ALERTS(state, payload) {
            state.activeAlerts = payload
        },
        SET_MENU(state, payload) {
            state.menu = payload
        },
        SET_CABINET_LINK(state, payload) {
            state.cabinet_link = payload
        }
        // SET_DEFAULT_CABINET(state, newValue) {
        //     state.defaultCabinet = newValue
        // }
    },
    actions: {
        async fetchIsProductionMode({ commit }) {
            if (window.hasOwnProperty("is_prod")) {
                const isProd = window.is_prod
                commit("setProductionMode", isProd)
                return isProd
            }
            await axios.get("/is_prod").then(response => {
                let value = response.data.status
                commit("setProductionMode", value)
                if (!sessionStorage.getItem("isProd")) sessionStorage.setItem("isProd", value)
            })
        },
        fetchUser({ commit }) {
            if (window.hasOwnProperty("user")) {
                const user = window.user
                commit("SET_CURRENT_USER", user)
                return user
            }
            return axios.get("/user").then(response => {
                const user = response.data.data
                commit("SET_CURRENT_USER", user)
                return user
            })
        },
        async fetchCourse({ commit }, payload = {}) {
            commit("TOGGLE_LOADING", true)
            try {
                const response = await axios.get("/user/products", { params: payload })
                const courses = response.data.data
                commit("SET_COURSES", courses)
                commit("SET_CABINET_LINK", response.data.cabinet_link)
                return response.data
            } catch (e) {
                throw Error(e)
            } finally {
                commit("TOGGLE_LOADING", false)
            }
            // return axios.get("/courses/user").then((response) => {
            //     const courses = response.data.data
            //     commit("SET_COURSES", courses)
            //     return courses
            // })
        },

        fetchCabinets({ commit }) {
            if (window.hasOwnProperty("cabinets")) {
                const cabinets = window.cabinets
                commit("SET_CABINETS", cabinets)
                return cabinets
            }
            return axios.get("/cabinets").then(response => {
                const cabinets = response.data.data
                commit("SET_CABINETS", cabinets)
                return cabinets
            })
        },

        fetchExpertMenu({ commit }) {
            return new Promise(resolve => {
                commit("SET_MENU", ProvidedExpertRoutes)
                resolve()
            })
        },

        fetchCabinetAccesses({ commit }) {
            return axios.get("/cabinet/users/permissions").then(response => {
                let cabinetAccesses = response.data.data

                if (!Array.isArray(cabinetAccesses)) {
                    let data = []
                    for (let type in cabinetAccesses) {
                        data.push(cabinetAccesses[type])
                    }

                    cabinetAccesses = data
                }

                commit("SET_CABINET_ACCESSES", cabinetAccesses)
                return cabinetAccesses
            })
        },
        fetchActiveAlerts({ commit }) {
            return axios
                .get("/alerts")
                .then(res => commit("SET_ALERTS", res.data.data))
                .catch(e => console.log("fetching alerts error:", e))
        }
        // fetchDefaultCabinet({commit, state, rootState}) {
        //     return axios.get('/cabinets').then((response) => {
        //         const defaultCabinet = response.data.data.find(cabinet => cabinet['is_active']);
        //         commit('SET_DEFAULT_CABINET', defaultCabinet);
        //         return defaultCabinet
        //     })
        // }
    }
}
