export default function (workerUrl) {
    const js = `import ${JSON.stringify(new URL(workerUrl, import.meta.url))}`
    const blob = new Blob([js], { type: "application/javascript" })
    function WorkaroundWorker(options) {
        const objURL = URL.createObjectURL(blob)
        return new Worker(objURL, { type: "module", name: options?.name })
    }

    return WorkaroundWorker
}
