import { reflectKeys } from "@helpers"
import { LESSON_PREFIX } from "./lesson-prefix"
//import sortby from "lodash.sortby"

export const {
    SET_COURSES,
    CLEAR_PROGRAMS,
    SET_PROGRAM,
    SET_LESSON,
    SET_COURSE,
    SET_LESSON_LOADER,
    SET_LESSON_ENTRY,
    LESSON_ERROR,
    SET_ACTIVE_SECTION,
    UPDATE_SECTION,
    ADD_SECTION,
    DELETE_SECTION,
    SET_SECTION,
    SET_LESSON_DTO,
    SET_LESSON_SECTIONS_POSITIONS
} = reflectKeys(
    [
        "SET_COURSES",
        "CLEAR_PROGRAMS",
        "SET_PROGRAM",
        "SET_LESSON",
        "SET_COURSE",
        "SET_LESSON_LOADER",
        "SET_LESSON_ENTRY",
        "LESSON_ERROR",
        "SET_ACTIVE_SECTION",
        "UPDATE_SECTION",
        "ADD_SECTION",
        "DELETE_SECTION",
        "SET_SECTION",
        "SET_LESSON_DTO",
        "SET_LESSON_SECTIONS_POSITIONS"
    ],
    LESSON_PREFIX
)

export default {
    [SET_COURSE](state, payload) {
        state.course = payload
    },
    [SET_SECTION](state, payload) {
        state.section = payload
    },
    [SET_LESSON_DTO](state, payload) {
        state.lessonDto = payload
    },
    [SET_LESSON_SECTIONS_POSITIONS](state, payload) {
        if (!state.lesson) {
            return
        }
        state.lesson.sections = state.lesson.sections.map(section => {
            if (payload[section.id]) {
                section.order = payload[section.id]
            }
            return section
        })

        //state.lesson.sections = sortby(state.lesson.sections, section => section.order)
    },
    [DELETE_SECTION](state, section) {
        if (section === state.activeSectionIndex) {
            if (state.lesson.sections[section - 1]) {
                state.activeSectionIndex = section - 1
            } else {
                state.activeSectionIndex = 0
            }
        }

        state.lesson.sections.splice(section, 1)
    },
    [UPDATE_SECTION](state, section) {
        if (!state.lesson) {
            return
        }
        state.lesson.sections = state.lesson.sections.map((s, index) => {
            if (index === state.activeSectionIndex) {
                return section
            }

            return s
        })
    },
    [ADD_SECTION](state, section) {
        state.lesson.sections.push(section)
        state.activeSectionIndex = state.lesson.sections.length - 1
    },
    [SET_COURSES](state, payload) {
        state.courses = payload
    },
    [SET_ACTIVE_SECTION](state, payload) {
        if (state.lesson.sections[payload]) {
            state.activeSectionIndex = payload
        } else if (state.lesson.sections[payload - 1]) {
            state.activeSectionIndex = payload - 1
        } else {
            state.activeSectionIndex = 0
        }
    },
    [SET_LESSON_ENTRY](state, payload) {
        state.entry = payload
    },
    [SET_LESSON](state, payload) {
        state.lesson = payload
        state.loaders = {
            ...state.loaders,
            all: false
        }
    },
    [SET_LESSON_LOADER](state, { loader, value }) {
        state.loaders = {
            ...state.loaders,
            [loader]: value
        }
    },
    [LESSON_ERROR](state, payload) {
        state.error = payload
    },
    [SET_PROGRAM](state, { id, program }) {
        state.programs = {
            ...state.programs,
            [id]: program
        }
    },
    [CLEAR_PROGRAMS](state) {
        state.programs = {}
    }
}
