<template>
    <div class="practice-blue-alert">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "PracticeBlueAlert"
}
</script>

<style scoped lang="sass">
.practice-blue-alert
    padding: 10px 14px
    border-radius: 7px
    background: #F6F9FF
    color: #3965FF
    font-size: 16px
    font-family: Inter, sans-serif
    font-weight: 400
    line-height: 21px
    width: max-content
    max-width: 700px
    &.green
        border: none !important
        width: max-content
        background: #F2FFFA !important
        color: #15A06E !important
    &.red
        border: none !important
        width: max-content
        background: #FFF6F6 !important
        color: #DD4141 !important
    &.yellow
        border: none !important
        width: max-content
        background: #FFFBF6 !important
        color: #F1AD27 !important
    strong
        font-weight: 600
        color: #3965FF
    @media (max-width: 640px)
        padding: 10px 5px
</style>
