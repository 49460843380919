
<template>
    <svg v-if="!bold" width="10" height="10" viewBox="0 0 10 10" fill="#DD4141" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.86401 2.19708C9.1569 1.90418 9.1569 1.42931 8.86401 1.13642C8.57112 0.843525 8.09624 0.843525 7.80335 1.13642L8.86401 2.19708ZM1.13666 7.8031C0.843769 8.096 0.843769 8.57087 1.13666 8.86376C1.42956 9.15666 1.90443 9.15666 2.19732 8.86376L1.13666 7.8031ZM2.19734 1.13642C1.90445 0.843525 1.42958 0.843525 1.13668 1.13642C0.84379 1.42931 0.84379 1.90418 1.13668 2.19708L2.19734 1.13642ZM7.80337 8.86376C8.09626 9.15666 8.57114 9.15666 8.86403 8.86376C9.15692 8.57087 9.15692 8.096 8.86403 7.8031L7.80337 8.86376ZM7.80335 1.13642L1.13666 7.8031L2.19732 8.86376L8.86401 2.19708L7.80335 1.13642ZM1.13668 2.19708L7.80337 8.86376L8.86403 7.8031L2.19734 1.13642L1.13668 2.19708Z"
        />
    </svg>
    <svg v-else width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.77752 7.64422C1.51085 7.64422 1.33312 7.55534 1.15534 7.37756C0.799783 7.02201 0.799783 6.48865 1.15534 6.13309L6.13309 1.15534C6.48865 0.799783 7.02201 0.799783 7.37756 1.15534C7.73312 1.51089 7.73312 2.04419 7.37756 2.39975L2.39975 7.37756C2.22197 7.55534 2.04418 7.64422 1.77752 7.64422Z"
            fill="#DD4141"
        />
        <path
            d="M6.75533 7.64422C6.48866 7.64422 6.31087 7.55534 6.13309 7.37756L1.15534 2.39975C0.799783 2.04419 0.799783 1.51089 1.15534 1.15534C1.51089 0.799783 2.04419 0.799783 2.39975 1.15534L7.37756 6.13309C7.73312 6.48865 7.73312 7.02201 7.37756 7.37756C7.19978 7.55534 7.02199 7.64422 6.75533 7.64422Z"
            fill="#DD4141"
        />
    </svg>
</template>
<script>
export default {
    props: {
        bold: {
            type: Boolean,
            default: false
        }
    },
    name: "CloseIcon"
}
</script>
