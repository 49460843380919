<template>
    <flex-container class="question-info">
        <flex-container align="center">
            <AttemptResultExpertWidget
                v-if="expertView && attemptId"
                :expert-view="expertView"
                :type-show="quiz.result_attempt.type_show"
                :lesson-id="lessonId"
                :question-id="questionId"
                :attempt-id="attemptId"
                :question="question"
                :user-id="userId"
            />
            <AttemptResultStudentWidget
                :attempt-id="quiz && quiz.attempt && quiz.attempt.id"
                v-else-if="!expertView && question && question && question.has_comments"
                :commentable-id="question.id"
                :comments-count="question.comments_count"
            />

            <span
                class="number"
                :class="{ show: question && !question.description && $screenWidth <= 500 }"
                v-if="hasNumeration"
            >
                {{ number }}.</span
            >

            <em class="required-notifier" style="color: #dd4141" v-if="question && question.is_required"> *</em>
        </flex-container>

        <PracticeQuestionDescription
            v-if="question && question.description"
            :number="$screenWidth <= 500 && hasNumeration ? number : 0"
            :description="question.description"
            :question="question"
            :allow-collapse="question.is_collapse_enabled"
        />
    </flex-container>
</template>

<script>
import FlexContainer from "../../../Containers/FlexContainer"
import AttemptResultExpertWidget from "../../common/result/AttemptResultExpertWidget"
import AttemptResultStudentWidget from "../../common/result/AttemptResultStudentWidget"
import PracticeQuestionDescription from "@components/Practice/PracticeQuestions/components/PracticeQuestionDescription.vue"

export default {
    props: {
        expertView: {
            type: Boolean,
            default: false
        },
        userId: {
            type: [Number, String],
            default: 0
        },
        lessonId: {
            type: [Number, String],
            default: 0
        },
        attemptId: {
            type: [Number, String],
            default: 0
        },
        questionId: {
            type: [Number, String],
            default: 0
        },
        question: {
            type: Object,
            default: () => {}
        },
        quiz: {
            type: Object,
            default: () => {}
        },
        number: {
            type: [Number, String],
            default: ""
        },
        name: {
            type: [Number, String],
            default: ""
        }
    },
    components: {
        PracticeQuestionDescription,
        AttemptResultStudentWidget,
        AttemptResultExpertWidget,
        FlexContainer
    },
    name: "PracticeQuestionInfo",
    computed: {
        hasNumeration() {
            const settings = this.quiz.settings || this.quiz
            return (
                this.number &&
                settings &&
                settings.questions_numeration_type &&
                settings.questions_numeration_type.id === 1
            )
        }
    }
}
</script>

<style lang="sass">
.question-info
    padding-bottom: 10px
    .required-notifier
        position: absolute
        top: 0
        right: 0
        font-size: 40px
        font-style: normal
    .number
        margin-right: 7px
        font-family: Ubuntu, serif
        font-size: 16px
        line-height: 1.5
        color: #B5C1D2
        white-space: nowrap
        @media (max-width: 500px)
            display: none
        &.show
            display: inline
            @media (min-width: 500px)
                display: none
</style>
