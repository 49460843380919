<template>
    <div
        v-if="
            isNotStarted &&
            !isNotStarted() &&
            (isCorrect || isIncorrect || isSkipped) &&
            (isCorrect() || isIncorrect() || isSkipped())
        "
        class="correct-status-alert mt-20"
        :class="{
            correct: isCorrect && isCorrect(),
            incorrect: isIncorrect && isIncorrect(),
            skipped: isSkipped && isSkipped()
        }"
    >
        <p class="correct-status-title">
            <template v-if="isSkipped && isSkipped()"> {{ t("create-course.answer_skipped") }} </template>
            <template v-else-if="isCorrect && isCorrect()"> {{ t("create-course.answer_correct") }} </template>
            <template v-else-if="isIncorrect && isIncorrect()"> {{ t("create-course.answer_incorrect") }} </template>
        </p>

        <div
            v-if="correctAnswers && correctAnswers.length && !(isSkipped && isSkipped())"
            class="correct-status-description"
        >
            <template v-if="isFillGaps">
                <b>{{ t("quiz.correct_answer") }}</b
                >: <span v-html="getGapsText()"></span>
            </template>
            <!--            <template v-else-if="correctAnswers.length === 1">
                <flex-container align="center">
                    <b class="mr-8">{{ t("quiz.correct_answer") }}:</b>
                    <template v-for="(answer, index) in correctAnswers">
                        <ExtendedRenderHtml
                            :key="index"
                            v-if="answer.text || typeof answer === 'string'"
                            :html="createTextView(answer.text) || answer"
                        />
                    </template>
                </flex-container>
            </template>-->
            <template v-else-if="correctAnswers.length">
                <b>{{ t("quiz.correct_answer") }}</b
                >:
                <ul>
                    <li :key="index" v-for="(answer, index) in correctAnswers">
                        <ExtendedRenderHtml
                            v-if="answer.text || typeof answer === 'string'"
                            :html="createTextView(answer.text || answer)"
                        />
                    </li>
                </ul>
            </template>
        </div>
    </div>
</template>

<script>
import ExtendedRenderHtml from "@components/ExtendedRender/ExtendedRenderHtml.vue"

export default {
    components: { ExtendedRenderHtml },
    props: {
        isCorrect: {
            type: Function,
            default: () => {}
        },
        isFillGaps: {
            type: Boolean,
            default: false
        },
        correctAnswers: {
            type: [Array, Boolean],
            default: null
        },
        isIncorrect: {
            type: Function,
            default: () => {}
        },
        isNotStarted: {
            type: Function,
            default: () => {}
        },
        isSkipped: {
            type: Function,
            default: () => {}
        }
    },
    name: "PracticeCorrectStatusAlert",
    methods: {
        createTextView(text) {
            if (!text) {
                return ""
            }

            return `<div class="text-view">${text}</div>`
        },
        getGapsText() {
            return this.t("create-course.fill_gaps_show_correct_answer", {
                ":icon":
                    '<svg width="24" height="24" viewBox="0 0 24 24" stroke="#3965FF" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 8.02941H15.5M8.5 11.5588H12.875M5 4.5H19V15.0882H12L7.625 19.5V15.0882H5V4.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>'
            })
        }
    }
}
</script>

<style scoped lang="sass">
.correct-status-alert
    padding: 12px 15px
    border-radius: 6px
    .correct-status-description
        font-family: Inter, sans-serif
        font-size: 14px
        font-weight: 400
        color: #323030
        margin-top: 4px
        line-height: 160%
        span
            display: inline-flex
            &::v-deep
                svg
                    width: 20px
                    margin: 0 2px
        b
            font-weight: 500
            white-space: nowrap
        ul
            margin-top: 4px
            margin-left: 20px
            list-style-type: disc
    .correct-status-title
        font-family: Inter, sans-serif
        font-size: 15px
        line-height: 160%
    &.incorrect
        background-color: #FFF6F6
        .correct-status-title
            color:  #DD4141
    &.correct
        background-color: #14a06e1a
        .correct-status-title
            color: #14a06e
    &.skipped
        background-color: #3865ff17
        .correct-status-title
            color:  #3865ff
</style>
