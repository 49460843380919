
<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30.8332 37.5H9.1665C7.78567 37.5 6.6665 36.3808 6.6665 35V5C6.6665 3.61917 7.78567 2.5 9.1665 2.5H24.9998L33.3332 10.8333V35C33.3332 36.3808 32.214 37.5 30.8332 37.5Z"
            fill="#FF7439"
        />
        <path d="M33.3333 10.8333H25V2.5L33.3333 10.8333Z" fill="#BBDEFB" />
        <path d="M25 10.8333L33.3333 19.1667V10.8333H25Z" fill="#c7501e" />
        <path
            d="M12.5 19.1667H27.5V20.8333H12.5V19.1667ZM12.5 22.5H27.5V24.1667H12.5V22.5ZM12.5 25.8333H27.5V27.5H12.5V25.8333ZM12.5 29.1667H20.8333V30.8333H12.5V29.1667Z"
            fill="#E3F2FD"
        />
    </svg>
</template>
<script>
export default {
    name: "FilePptIcon"
}
</script>
