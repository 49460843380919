import Vue from "vue"
import CustomNotification from "./CustomNotification"

const NotificationConstructor = Vue.extend(CustomNotification)

let instance

export default {
    name: "notify",
    exec(parameters) {
        if (parameters.fullWidth) {
            if (parameters.position) {
                parameters.position = parameters.position.replace("right", "left")
            }
        }

        instance = new NotificationConstructor({
            data: parameters
        })
        instance.vm = instance.$mount()
        parameters.click ? instance.vm.$on("click", parameters.click) : null
        const body = document.body
        body.insertBefore(instance.vm.$el, body.firstChild)
    }
}
