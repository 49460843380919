<template>
    <flex-container
        class="custom-switcher-container"
        :inline="inline"
        :direction="reverse ? 'row-reverse' : 'row'"
        :justify="
            reverse && !justify ? 'flex-end' : (reverse && justify) || (!reverse && justify) ? justify : 'flex-start'
        "
        align="center"
        :class="{
            'always-active': alwaysActive,
            'with-negative-accent': withNegativeAccent && !value,
            'pointer-events-none': loading
        }"
    >
        <div
            v-if="Object.keys($slots).length > 1"
            :style="textStyles"
            class="custom-switcher__text"
            :class="{
                'mr-5': !reverse,
                'text-nowrap': textNowrap,
                'custom-switcher__text_bold': boldText,
                pointer: changeOnTextClick
            }"
            @click="handleClickOnTitle"
        >
            <slot name="additional"></slot>
        </div>

        <div
            class="cursor-pointer"
            @click="change"
            :class="{
                'custom-switcher': !check && !loading,
                'custom-switcher_active': value && !check,
                'custom-check': check,
                'custom-check_active': value && check,
                'mr-20': check && !reverse,
                'cursor-not-allowed events-none': disabled,
                'ml-10': reverse
            }"
        >
            <LoaderIcon v-if="loading" />
            <CheckIcon v-else-if="check" :no-color="true" />
            <span v-else class="custom-switcher-indicator"></span>
        </div>

        <div
            v-if="text || Object.keys($slots).length"
            :style="textStyles"
            class="custom-switcher__text"
            :class="{
                'ml-8': !reverse,
                'text-nowrap': textNowrap,
                'custom-switcher__text_bold': boldText,
                pointer: changeOnTextClick
            }"
            @click="handleClickOnTitle"
        >
            {{ text }}
            <slot></slot>
        </div>
        <slot name="tooltip"></slot>
    </flex-container>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer"
import LoaderIcon from "@components/Loaders/LoaderIcon.vue"
import CheckIcon from "@icons/CheckIcon"

export default {
    components: {
        LoaderIcon,
        CheckIcon,
        FlexContainer
    },
    props: {
        value: {
            type: [Boolean, Number],
            default: false,
            required: false
        },
        text: {
            type: String,
            default: "",
            required: false
        },
        disabled: {
            type: [Boolean, Number],
            default: false,
            required: false
        },
        reverse: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        textNowrap: {
            type: Boolean,
            default: true
        },
        justify: String,
        check: {
            type: Boolean,
            default: false
        },
        boldText: {
            type: Boolean,
            default: false
        },
        textML: Number,
        alwaysActive: {
            type: Boolean,
            default: false
        },
        withNegativeAccent: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        changeOnTextClick: {
            type: Boolean,
            default: true
        }
    },
    model: {
        prop: "value",
        event: "update"
    },
    name: "CustomSwitcher",
    computed: {
        textStyles() {
            return {
                marginLeft: this.textML && `${this.textML}px`
            }
        }
    },
    methods: {
        change() {
            this.$emit("update", !this.value)
        },
        handleClickOnTitle(event) {
            if (event && event.target) {
                if (event.target.closest(".wrapper-alert")) {
                    return
                }
            }

            if (this.changeOnTextClick) {
                this.change()
            }
        }
    }
}
</script>

<style scoped lang="sass">
@import 'resources/sass/vars'

.custom-switcher
    .text-nowrap
        white-space: nowrap

    width: 28px
    min-width: 28px
    height: 17px
    border-radius: 30px
    border: 1px solid #C3D1E3
    transition: $transitionTime
    position: relative
    input
        display: none
    &__text
        font-size: 14px
        font-weight: 400
        font-family: Inter, sans-serif
        line-height: 130%
        color: hsl(0, 0%, 8%)
        &_bold
            font-weight: 500
    .custom-switcher-indicator
        position: absolute
        min-width: 11px
        height: 11px
        background: #C3D1E3
        border-radius: 50%
        top: 2px
        left: 2px
        transition: .2s
    &_active
        border-color: $success-color2
        .custom-switcher-indicator
            background: $success-color2
            transform: translateX(11px)

.custom-check
    transition: $transitionTime
    fill: $accent-color6
    &_active
        fill: $success-color2

.bold-text
    font-weight: 700
    font-size: 14px
    line-height: 130%
    color: $text-accent-color

.always-active
    .custom-switcher
        border: 1px solid $success-color2
        .custom-switcher-indicator
            background: $success-color2
.with-negative-accent
    .custom-switcher
        border: 1px solid $danger-color
        .custom-switcher-indicator
            background: $danger-color
</style>
