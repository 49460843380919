export default [
    {
        key: "product",
        icon: "lms",
        children: [
            {
                key: "course",
                link: "/expert/courses",
                icon: "course",
                additional: {
                    key: "create_course",
                    link: "/expert/courses?new=true"
                }
            },
            {
                key: "marathon",
                link: "/expert/marathons",
                icon: "marathon",
                additional: {
                    key: "create_marathon",
                    link: "/expert/marathons?new=true"
                }
            },
            {
                key: "closed_groups",
                link: "/expert/closed-groups",
                icon: "private",
                additional: {
                    key: "create_closed_groups",
                    link: "/expert/closed-group/detail/new/settings/edit"
                }
            },
            {
                key: "consultations",
                disabled: true,
                icon: "consultations"
            },
            {
                key: "guides",
                disabled: true,
                icon: "guides"
            },
            {
                key: "divider"
            },
            {
                key: "practices",
                icon: "practices",
                link: "/expert/practices",
                keyTranslate: "app-meta.title.practices",
                additional: {
                    key: "create_task",
                    link: "/expert/quiz/list?new=true"
                }
            }
        ]
    },
    {
        key: "practices",
        icon: "practices",
        keyTranslate: "app-meta.title.practices",
        link: "/expert/practices"
    },
    {
        key: "divider"
    },
    {
        key: "webinar_auto_webinar",
        icon: "broadcasting",
        link: "/expert/webinars-calendar"
    },
    {
        key: "test_constructor",
        icon: "quizzes",
        link: "/expert/quiz/list"
    },
    {
        key: "crm",
        icon: "crm",
        children: [
            {
                key: "contacts",
                icon: "contacts",
                link: "/expert/crm/contacts"
            },
            {
                key: "contact-lists",
                icon: "contacts-list",
                link: "/expert/crm/contact-lists"
            },
            {
                key: "contacts",
                keyTranslate: "app-meta.title.settings",
                icon: "gears",
                link: "/expert/crm/contacts?view=settings"
            },
            {
                key: "divider"
            },
            {
                key: "orders",
                icon: "orders",
                link: "/expert/crm/orders"
            },

            {
                key: "deals",
                icon: "deals",
                link: "/expert/crm",
                disabled: true
            },
            {
                key: "tasks",
                icon: "certificates",
                link: "/expert/crm/tasks",
                disabled: true
            }
        ]
    },
    {
        key: "site",
        icon: "site",
        children: [
            {
                key: "site_constructor",
                icon: "site",
                disabled: true
            },
            {
                key: "cta",
                icon: "widget",
                keyTranslate: "settings.cta.title",
                link: "/expert/settings/cta"
            },
            {
                key: "domain",
                icon: "domain",
                link: "/expert/settings/domain"
            }
        ]
    },
    {
        key: "chat_bot",
        icon: "bot",
        disabled: true
    },
    {
        key: "mailing",
        icon: "email",
        children: [
            {
                key: "mailing",
                icon: "email",
                link: "/expert/mailing"
            },
            {
                key: "letter_builder",
                icon: "letter-builder",
                link: "/expert/mailing/templates/create"
            },
            {
                key: "letters_templates",
                icon: "certificates",
                link: "/expert/mailing/templates"
            },
            {
                key: "automations",
                icon: "automation",
                link: "/expert/automation/list"
            }
        ]
    },
    {
        key: "automation",
        icon: "automation",
        link: "/expert/automation/list"
    },
    {
        key: "cloud",
        icon: "storage",
        link: "/expert/storage"
    },
    {
        key: "divider"
    },
    {
        key: "payments",
        icon: "payments",
        children: [
            {
                key: "offers",
                icon: "certificates",
                link: "/expert/payments/offers"
            },
            {
                key: "coupons",
                icon: "tag",
                link: "/expert/payments/coupons"
            },
            {
                key: "affiliates",
                icon: "map",
                link: "/expert/affiliates"
            }
        ]
    },
    {
        key: "analytics",
        icon: "analytics",
        children: [
            {
                key: "analytics-insight",
                icon: "contacts-list",
                link: "/expert/analytics-insight",
                disabled: true
            },
            {
                key: "analytics-reports",
                icon: "flag",
                keyTranslate: "app-meta.title.payment",
                link: "/expert/reports"
            },
            {
                key: "analytics-transactions",
                icon: "coin",
                link: "/expert/analytics-transactions"
            },
            {
                key: "analytics-webinars",
                keyTranslate: "streams.webinars",
                icon: "broadcasting",
                link: "/expert/analytics-webinars"
            }
        ]
    },
    {
        key: "divider"
    },
    {
        key: "certificates",
        icon: "certificates",
        keyTranslate: "certificates.certificates",
        link: "/expert/certificate/list"
    },
    {
        key: "integrations",
        icon: "integrations",
        link: "/expert/integrations/all"
    },
    {
        key: "api",
        title: "API",
        icon: "api",
        link: "/expert/api"
    }

    /* {
        key: "payments",
        children: [
            {
                key: "offers",
                link: "/expert/payments/offers"
            },
            {
                key: "coupons",
                link: "/expert/payments/coupons"
            },
            {
                key: "affiliates",
                link: "/expert/affiliates"
            }
        ]
    },*/
    /*  {
        key: "analytics",
        children: [
            {
                key: "analytics-insight",
                link: "/expert/analytics-insight",
                disabled: true
            },
            {
                key: "analytics-reports",
                link: "/expert/analytics-reports",
                disabled: true
            },
            {
                key: "analytics-transactions",
                divided: true,
                link: "/expert/analytics-transactions"
            }
        ]
    },*/
]
