<template>
    <modal-container block centred :top="120" :padding="[20]" :min-width="440" :max-width="440">
        <default-title
            v-html="t('quiz.finish_modal')"
            text-color="#323030"
            :size="16"
            :weight="600"
            :line-height="25"
            font-family="Inter, sans-serif"
        >
        </default-title>

        <default-title
            v-html="t('quiz.finish_modal_descr')"
            class="mb-10 mt-10"
            :size="16"
            :weight="400"
            :text-color="'#52565C'"
            :line-height="25"
            font-family="Inter, sans-serif"
        >
        </default-title>

        <flex-container class="mt-30">
            <DefaultButton
                @click="$emit('cancel')"
                background="cancel"
                class="mr-10 inter"
                full-width
                :height="45"
                :text="t('expert.cancel')"
            />
            <DefaultButton
                class="inter"
                @click="$emit('save')"
                background="red"
                full-width
                :height="45"
                :text="t('expert.yes').capitalize()"
            />
        </flex-container>
    </modal-container>
</template>

<script>
import DefaultButton from "@components/Buttons/DefaultButton.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import ModalContainer from "@expert-components/Modals/ModalContainer.vue"

export default {
    name: "PracticeFinishModal",
    components: { DefaultButton, DefaultTitle, FlexContainer, ModalContainer }
}
</script>

<style lang="scss" scoped>

</style>
