<template>
    <div :style="questionStyle()" :class="{ 'practice-question_with_code': hasCode() }" class="practice-question">
        <PracticeQuestionInfo
            v-bind="widget()"
            :expert-view="expertView"
            :number="questionNumber"
            :name="question.name"
            :question="question"
            :quiz="quiz"
        />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />

        <div
            v-if="isShowAnswers()"
            class="practice-question__answers promote-blocks-type promote-blocks-type_public"
            :class="{ 'practice-question__answers_disabled': disabledByTimer(), 'pt-10': !interactive }"
        >
            <PracticeQuestionDescription
                class="mb-10"
                :class="$screenWidth > 840 ? 'text-center' : 'mb-15'"
                v-if="!isHorizontal(question.likert_scale_type) || $screenWidth <= 840"
                :description="question.grade_text_min"
            />

            <div
                class="promote-blocks-container mb-20"
                :class="{ 'promote-blocks-container_vertical': !isHorizontal(question.likert_scale_type) }"
            >
                <div
                    @click="setCorrect(answer)"
                    :class="{
                        'pointer-events-none': readonly,
                        'promote-score-block_active': getIsActive(answer)
                    }"
                    :style="{
                        backgroundColor: isAnswered(answer) ? '' : '#e3e3e3 !important'
                    }"
                    class="promote-score-block cursor-pointer"
                    v-for="(answer, index) in question.answers"
                    :key="index"
                >
                    <PracticeQuestionDescription class="text-center" :description="answer.text" />
                    <default-description text-color="#fff">
                        <PracticeQuestionDescription
                            class="text-center mt-2"
                            v-if="expertView"
                            :description="getAggregated(answer)"
                        />
                    </default-description>
                </div>
                <PracticeQuestionDescription
                    class="mt-10 text-center"
                    v-if="!isHorizontal(question.likert_scale_type)"
                    :description="question.grade_text_max"
                />
            </div>
            <flex-container v-if="isHorizontal(question.likert_scale_type)" justify="space-between" class="mt-10">
                <PracticeQuestionDescription
                    :description="$screenWidth > 840 ? question.grade_text_min : question.grade_text_max"
                />
                <PracticeQuestionDescription
                    v-if="$screenWidth > 840"
                    class="text-right"
                    :description="question.grade_text_max"
                />
            </flex-container>
        </div>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />

        <PracticeQuestionComment v-if="isDisplayComment" :question="question" />

        <PracticeActionsContainer
            class="mt-25"
            v-if="interactive"
            :question="question"
            :quiz="quiz"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import PracticeCorrectStatusAlert from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import DefaultDescription from "@components/Typography/DefaultDescription.vue"
import FlexContainer from "../../Containers/FlexContainer"
import PracticeMixin from "./_mixin"
import PracticeActionsContainer from "./components/PracticeActionsContainer.vue"
import PracticeQuestionDefaultOptions from "./components/PracticeQuestionDefaultOptions"
import PracticeQuestionInfo from "./components/PracticeQuestionInfo"

export default {
    props: {
        question: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        PracticeCorrectStatusAlert,
        DefaultDescription,
        PracticeQuestionInfo,
        PracticeActionsContainer,
        PracticeQuestionDefaultOptions,
        FlexContainer
    },
    name: "PracticeNetPromoteAnswer",
    mixins: [PracticeMixin],
    methods: {
        getIsActive(answer) {
            if (!this.interactive) {
                if (this.typeShow() === "simple_answer" || this.typeShow() === "only_correct_or_not_question") {
                    return !!this.isAnswered(answer)
                }
            }
            return !!answer.is_correct
        },
        getAggregated(answer) {
            const aggregated = this.aggregateInterview.find(item => item.quiz_answer_id === answer.id)

            if (aggregated) {
                return `${aggregated.count} (${aggregated.percent}%)`
            }

            return "0 (0%)"
        },
        setCorrect(answ) {
            if (this.disabledByTimer() || !this.interactive) {
                if (!this.checkable) {
                    return
                } else {
                    this.$emit("callout")
                }
            }

            this.$nextTick(() => {
                for (const answer of this.question.answers) {
                    const issuedAnswer = this.question.answers.find(a => a.id === answer.id)
                    issuedAnswer.is_correct = false
                }

                const issuedAnswer = this.question.answers.find(a => a.id === answ.id)
                issuedAnswer.is_correct = true

                this.$forceUpdate()
                this.$emit("callout")
            })
        }
    }
}
</script>
<style lang="sass" scoped>
.promote-score-block
    .text-center
        padding-right: 0
</style>
