<template>
    <div
        v-click-outside="hide"
        class="custom-calendar__head flex justify-between items-center"
        :class="{ disabled: disableMonthChange }"
    >
        <button type="button" class="custom-calendar__head_lt-month" @click="$emit('prev')">
            <ArrowRightIcon class="-scale-100" />
        </button>
        <button type="button" @click="handleClick" class="custom-calendar__head_curr-month text-center relative">
            {{ textDate() }}

            <ArrowDownIcon
                :style="{ transform: isOpen ? 'rotate(180deg)' : 'none' }"
                v-if="allowYearSelect"
                class="ml-5"
            />
            <template v-if="isOpen">
                <div ref="scroll" class="custom-scroll options-list">
                    <div :key="`${index}-${option.id}`" v-for="(option, index) in years">
                        <DefaultDropdownOption
                            :key-name="'name'"
                            :default-val="{ id: currYear }"
                            :option="option"
                            :type="'default'"
                            @select="openedOption = option"
                        />
                        <div class="months" :key="`mon-${index}-${option.id}`" v-if="openedOption === option">
                            <div
                                @click="selectDate(option.name, monIndex)"
                                class="month"
                                :key="month"
                                v-for="(month, monIndex) in months"
                            >
                                {{ translate(month).substring(0, 3) }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </button>
        <button type="button" class="custom-calendar__head_gt-month" @click="$emit('next')">
            <ArrowRightIcon />
        </button>
    </div>
</template>

<script>
import ArrowRightIcon from "../../icons/ArrowRightIcon"
import ArrowDownIcon from "../../icons/ArrowDownIcon.vue"
import IsOpen from "../../mixins/is-open"
import DefaultDropdownOption from "../../components/Forms/DefaultDropdownOption.vue"
import LocalTranslator from "../../mixins/local-translator"

export default {
    name: "CustomCalendarHead",
    props: {
        allowYearSelect: {
            type: Boolean,
            default: false
        },
        currYear: {
            type: Number,
            required: true
        },
        currMonth: {
            type: Number,
            required: true
        },
        firstDate: {
            type: Date,
            required: false
        },
        endDate: {
            type: Date,
            required: false
        },
        disableMonthChange: {
            type: Boolean,
            default: false
        }
    },
    components: {
        DefaultDropdownOption,
        ArrowDownIcon,
        ArrowRightIcon
    },
    mixins: [IsOpen, LocalTranslator],
    data() {
        return {
            localKeys: "course",
            months: [
                "course.months.0",
                "course.months.1",
                "course.months.2",
                "course.months.3",
                "course.months.4",
                "course.months.5",
                "course.months.6",
                "course.months.7",
                "course.months.8",
                "course.months.9",
                "course.months.10",
                "course.months.11"
            ],
            years: [],
            currentYear: new Date().getFullYear(),
            openedOption: null
        }
    },
    created() {
        if (this.allowYearSelect) {
            this.years = new Array(151)
                .fill(0)
                .map((_, index) => ({ name: this.currentYear + 50 - index, id: this.currentYear + 50 - index }))
        }
    },
    methods: {
        selectDate(year, month) {
            month = month + 1
            let date = `${year}-${month < 10 ? `0${month}` : month}-01 00:00:00`.replace(" ", "T") + "Z"

            this.$emit("select", date)

            setTimeout(() => {
                this.isOpen = false
            })
        },
        handleClick() {
            if (this.allowYearSelect && !this.isOpen) {
                this.open()
                setTimeout(() => {
                    const selector = this.$el.querySelector(".options-list-item_selected")

                    if (selector && this.$refs.scroll) {
                        this.$refs.scroll.scrollTop = selector.offsetTop - 10
                    }
                })
            }
        },
        textDate() {
            if ("currMonth" in this && "currYear" in this) {
                return `${this.translate(this.months[this.currMonth])}  ${this.currYear}`
            }
            if (this.firstDate && this.endDate && this.firstDate.valueOf() !== this.endDate.valueOf()) {
                const startYear = this.firstDate.getFullYear()
                const startMonth = this.firstDate.getMonth()
                const endYear = this.endDate.getFullYear()
                const endMonth = this.endDate.getMonth()
                let text = `${this.translate(this.months[startMonth])}  ${startYear}`
                if (startMonth !== endMonth || startYear !== endYear) {
                    text += ` - ${this.translate(this.months[endMonth])}  ${endYear}`
                }
                return text
            }
            return `${this.translate(this.months[this.currMonth])}  ${this.currYear}`
        }
    }
}
</script>

<style scoped lang="sass">
.custom-calendar__head
    padding: 10px
    &_lt-month, &_gt-month
        width: 24px
        height: 24px
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        border-radius: 4px
        transition: 250ms
        &:hover
            box-shadow: 0 2px 5px rgba(128, 158, 191, 0.25)
        @media (max-width: 640px)
            width: 34px
            height: 34px
    &_curr-month
        display: flex
        align-items: center
        justify-content: center
        font-weight: 600
        font-size: 14px
        line-height: 17px
        color: #151515
        text-transform: capitalize
        font-family: 'Lato', serif
.months
    margin-bottom: 10px
    display: grid
    gap: 10px
    grid-template-columns: 1fr 1fr 1fr 1fr
.month
    height: 30px
    display: flex
    align-items: center
    justify-content: center
    text-transform: lowercase
    &:hover
        background: rgba(#F2F7FF, 1)
.options-list
    max-height: 200px !important
    width: 340px
    position: absolute
    padding: 10px
    top: -10px
    z-index: 555
    background: #fff
    box-shadow: 0 10px 40px rgba(213, 221, 230, 0.6)
    border-radius: 10px
    font-size: 14px
    transform: translateY(10px)
    overflow: auto
    &_left
        right: 0
.custom-scroll
    overflow: auto
</style>
