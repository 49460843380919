
<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        :fill="noColor ? null : '#3E4755'"
        class="move-icon"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.7999 20.0995L13.286 19.5533C13.2804 19.5585 13.275 19.5638 13.2696 19.5692L13.7999 20.0995ZM21.8995 12L22.4298 12.5303C22.7227 12.2374 22.7227 11.7625 22.4298 11.4696L21.8995 12ZM18.2193 14.6195C17.9264 14.9124 17.9264 15.3872 18.2193 15.6801C18.5122 15.973 18.9871 15.973 19.28 15.6801L18.2193 14.6195ZM19.505 8.54478C19.2121 8.25188 18.7372 8.25188 18.4443 8.54478C18.1514 8.83767 18.1514 9.31254 18.4443 9.60544L19.505 8.54478ZM11.1 11.25C10.6858 11.25 10.35 11.5857 10.35 12C10.35 12.4142 10.6858 12.75 11.1 12.75V11.25ZM15.0695 6.23061C15.3624 6.5235 15.8373 6.5235 16.1301 6.23061C16.423 5.93772 16.423 5.46284 16.1301 5.16995L15.0695 6.23061ZM16.0139 19.0462C16.3156 18.7624 16.3301 18.2877 16.0462 17.986C15.7624 17.6844 15.2877 17.6699 14.9861 17.9537L16.0139 19.0462ZM10.7304 19.5692L4.43074 13.2695L3.37008 14.3302L9.66976 20.6299L10.7304 19.5692ZM4.43074 10.7304L10.7304 4.4307L9.66976 3.37004L3.37008 9.66972L4.43074 10.7304ZM4.43074 13.2695C3.72958 12.5684 3.72958 11.4315 4.43074 10.7304L3.37008 9.66972C2.08313 10.9567 2.08313 13.0432 3.37008 14.3302L4.43074 13.2695ZM13.2696 19.5692C12.5684 20.2704 11.4316 20.2704 10.7304 19.5692L9.66976 20.6299C10.9567 21.9168 13.0433 21.9168 14.3302 20.6299L13.2696 19.5692ZM14.3302 3.37004C13.0433 2.08309 10.9567 2.08309 9.66976 3.37004L10.7304 4.4307C11.4316 3.72953 12.5684 3.72953 13.2696 4.4307L14.3302 3.37004ZM21.3692 11.4696L18.2193 14.6195L19.28 15.6801L22.4298 12.5303L21.3692 11.4696ZM22.4298 11.4696L19.505 8.54478L18.4443 9.60544L21.3692 12.5303L22.4298 11.4696ZM21.8995 11.25L11.1 11.25V12.75L21.8995 12.75L21.8995 11.25ZM16.1301 5.16995L14.3302 3.37004L13.2696 4.4307L15.0695 6.23061L16.1301 5.16995ZM14.3138 20.6458L16.0139 19.0462L14.9861 17.9537L13.286 19.5533L14.3138 20.6458Z"
        />
    </svg>
</template>
<script>
export default {
    name: "MoveIcon",
    props: {
        noColor: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="sass" scoped>
.move-icon
    cursor: pointer
    transition: .2s
    &:hover
        fill: #3965FF
</style>
