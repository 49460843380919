<template>
    <div class="attempt-result" :class="`status_${attempt && attempt.status_id}`">
        <LoaderBlock v-if="loading" :green-color="true" />
        <div v-else>
            <default-title
                :class="$screenWidth > 480 ? 'ml-15 mb-5' : 'mb-20'"
                :size="16"
                :weight="500"
                :line-height="21"
            >
                {{ t("course.detail.questions") }}:
                <span class="text-green"> {{ attempt.count_questions_correct || 0 }} </span>/
                <span class="text-red"> {{ attempt.count_questions_incorrect || 0 }} </span>/
                {{ attempt.count_questions || 0 }}
            </default-title>
            <component
                v-for="(question, index) in questions"
                :class="{ 'mt-10': index !== 0 }"
                :question="question"
                :lesson="lesson"
                :results="getQuestionResults(question)"
                :question-number="questions.indexOf(question) + 1"
                :quiz="quiz"
                :viewed-user="user"
                :expert-view="expertView"
                :is-display-comment="question.comment && question.comment_type_id !== 2"
                :course-id="courseId"
                :attempt="attempt"
                :aggregate-interview="aggregateInterview"
                :interactive="false"
                :key="question.id"
                :is="getQuestionComponentBySlug(question.type.slug)"
            ></component>
        </div>
    </div>
</template>

<script>
import LoaderBlock from "../Loaders/LoaderBlock"
import axios from "@libs/axios"
import DefaultTitle from "../Typography/DefaultTitle"
import QuestionsMap from "./PracticeQuestions/_map"

export default {
    props: {
        quiz: {
            type: Object,
            default: null
        },
        readyResults: {
            type: Object,
            default: null
        },
        lesson: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        attemptId: {
            type: Number,
            default: 0
        },
        courseId: {
            type: Number,
            default: 0
        },
        expertView: {
            type: Boolean,
            default: false
        }
    },
    name: "AttemptResult",
    components: { DefaultTitle, LoaderBlock },
    data() {
        return {
            loading: false,
            questions: [],
            results: [],
            attempt: null,
            aggregateInterview: null
        }
    },
    created() {
        this.getAttemptResult()
    },
    methods: {
        getQuestionResults(question) {
            return this.results.filter(r => r.quiz_question_id === question.id)
        },
        getQuestionComponentBySlug(slug) {
            return QuestionsMap[slug]
        },
        async getAttemptResult() {
            const config = {
                params: {}
            }

            if (this.expertView) {
                config.params.is_expert = 1
            }

            if (this.readyResults && this.readyResults.results) {
                const { questions, results, attempt, aggregate_interview } = this.readyResults

                this.questions = questions
                this.results = results
                this.attempt = attempt
                this.aggregateInterview = aggregate_interview
                return
            }

            this.loading = true

            try {
                const { data } = await axios.get(
                    `/lessons/${this.lesson.id}/quizzes/${this.quiz.id}/attempt/${this.attemptId}`,
                    config
                )
                const { questions, results, attempt, aggregate_interview } = data.data

                this.questions = questions
                this.results = results
                this.attempt = attempt
                this.aggregateInterview = aggregate_interview
            } catch (e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped lang="sass">
.loader-block
    max-width: 100%
    height: auto
.attempt-result
    &::v-deep
        .practice-question
            box-shadow: 0 4px 10px rgb(128 158 191 / 15%)
            border-radius: 10px
            padding: 15px
            position: relative

            @media (max-width: 480px)
                padding: 5px
                box-shadow: none
        .practice-radio, .practice-check
            margin-right: 10px
        .practice-question__answers
            margin-top: 0
        .practice-radio, .practice-check, .practice-question__text
            pointer-events: none
</style>
