
<template>
    <svg class="move-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="8" y="6" width="2" height="2" rx="1" fill="#98A2B0" />
        <rect x="14" y="6" width="2" height="2" rx="1" fill="#98A2B0" />
        <rect x="8" y="11" width="2" height="2" rx="1" fill="#98A2B0" />
        <rect x="14" y="11" width="2" height="2" rx="1" fill="#98A2B0" />
        <rect x="8" y="16" width="2" height="2" rx="1" fill="#98A2B0" />
        <rect x="14" y="16" width="2" height="2" rx="1" fill="#98A2B0" />
    </svg>
</template>
<script>
export default {
    name: "Move1Icon"
}
</script>
