<template>
    <label :class="{ 'button-view': buttonView }" class="attach-handler">
        <AttachIcon />

        <default-title v-if="buttonView" class="ml-15" :weight="500" :size="15" :text-color="'#3965FF'">
            {{ t("course.practice.attach_file") }}
        </default-title>
        <input
            class="attach-handler__input"
            type="file"
            hidden
            name="file"
            :multiple="multiple"
            ref="file"
            accept="*.*"
            @change="handleFileUpload"
        />
    </label>
</template>

<script>
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import AttachIcon from "@icons/AttachIcon.vue"

import { createFileTask } from "@components/Files/FileTasks.vue"

export default {
    props: {
        appModel: {
            type: String,
            default: "comment"
        },
        buttonView: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: true
        }
    },
    name: "UploadFile",
    components: { DefaultTitle, AttachIcon },
    data() {
        return {
            file: null,
            fileLoading: false,
            title: ""
        }
    },
    created() {
        this.title = document.title
    },
    methods: {
        async handleFileUpload(e) {
            createFileTask(e.target.files)
                .then(result => {
                    for (const resultElement of result) {
                        if (resultElement.status === "fulfilled") {
                            this.$emit("save", resultElement.value)
                        }
                    }
                })
                .finally(() => {
                    this.$refs.file.value = null
                })
        }
    }
}
</script>

<style scoped lang="sass">
.attach-handler
    cursor: pointer
    width: 30px
    height: 30px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 4px
    background-color: #fff
    transition: .2s
    z-index: 2
    @media screen and (max-width: 640px)
        width: 24px
        height: 24px
    &:hover
        background-color: #F2F7FF
    &.button-view
        width: 100%
        &::v-deep
            path
                fill: #3965FF
</style>
