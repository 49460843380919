<template>
    <div class="file-upload" :class="additionalClasses">
        <input
            class="file-upload__input"
            type="file"
            hidden
            name="file"
            ref="file"
            :id="uploadInputId + index"
            :accept="accepted"
            @change="handleFileUpload()"
        />

        <label v-if="!isCustomLabelEnable" :for="uploadInputId + index" class="file-upload__label">
            <span>{{ loadFileHere }}</span>
            <img src="@images/portal/video-icon.svg" alt="icon" />
            <img src="@images/portal/paper.svg" alt="icon" />
        </label>
        <FilesNewView
            :short-file="shortFile"
            :files="files"
            :parent="parent"
            update-preview
            @openRemoveModal="removeFile($event)"
        />
        <div class="file-upload__list">
            <!-- *** Show loader before the first choosing of file for uploading -->
            <!-- *** and the end of uploading to storage (before starting checking if a path is generated) -->
            <LoaderBlock v-if="fileLoading || propLoading" :green-color="true" />

            <FormMsg
                v-if="uploadingErrors.showInvalidUserHash"
                :status="'error'"
                :msgText="uploadingErrors.textInvalidUserHash"
            />

            <!-- Error for video with size bigger than maximum -->
            <FormMsg v-if="uploadingErrors.showVideo" :status="'error'" :msgText="uploadingErrors.videoAudio" />

            <!-- Error for other files with size bigger than maximum -->
            <FormMsg v-if="uploadingErrors.showOther" :status="'error'" :msgText="uploadingErrors.other" />
            <!-- Error for other files with size bigger than maximum -->
            <FormMsg v-if="uploadingErrors.invalidFile" :status="'error'" :msgText="invalidFileExtension" />
        </div>

        <ModalWindow v-if="removeModal && parent !== 'expert'">
            <p class="delete-lesson__title">{{ textTitle }}</p>

            <div class="delete-lesson__lessons-wrapper">
                <div id="deleteModuleModal" style="overflow: hidden" class="delete-lesson__lessons-container">
                    <div class="delete-lesson__info">
                        <div class="delete-lesson__info-content">
                            <p class="delete-lesson__video-title">{{ removeModal.original_name }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="delete-lesson__buttons">
                <button @click="closeRemoveModal" class="delete-lesson__btn btn2 btn2_clear btn2_w-100" type="button">
                    {{ textNoDelete }}
                </button>

                <button
                    @click="removeFile(removeModal)"
                    class="delete-lesson__btn btn2 btn2_danger btn2_w-100"
                    type="button"
                >
                    {{ textYesDelete }}
                </button>
            </div>
        </ModalWindow>
    </div>
</template>

<script>
import axios from "~axios"
import ModalWindow from "./ModalWindow/ModalWindow"
import EventBus from "~event-bus"
import FormMsg from "@components/Forms/FormMsg"
import LoaderBlock from "./Loaders/LoaderBlock"
import FilesNewView from "@components/Files/FilesNewView"
import dataURItoBlob from "../utils/Helpers/dataURItoBlob"

export default {
    name: "FilesUploader",
    components: { FilesNewView, ModalWindow, FormMsg, LoaderBlock },
    props: {
        index: {
            type: [Number, String],
            default: ""
        },
        isCustomLabelEnable: {
            type: Boolean,
            default: false
        },
        uploadInputId: {
            type: String,
            default: "file"
        },
        shortFile: {
            type: Boolean,
            default: false
        },
        filesList: {
            type: Array,
            default: () => []
        },
        accepted: {
            type: String,
            default: "*.*"
        },
        userComments: {
            type: Boolean,
            default: false
        },
        additionalClasses: String,
        setCommentButtonDisabled: Function,
        parent: {
            type: String,
            default: "expert"
        },
        user: {
            default: null
        },
        filesEvent: {
            type: Array,
            default: () => []
        },
        appModel: {
            type: String,
            default: ""
        },
        cabinet_id: {
            type: [Number, String],
            default: ""
        },
        videoFileMaxSize: {
            type: Number,
            default: 6442450944
        },
        otherFilesMaxSize: {
            type: Number,
            default: 1073741824
        },
        propLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            file: "",
            files: [],
            uploadPercentage: 0,
            isProgressVisible: false,
            isDocPreviewVisible: true,
            removeModal: false,
            loadFileHere: null,
            invalidFileExtension: null,
            textTitle: null,
            textYesDelete: null,
            textNoDelete: null,
            langsKeys: null,
            uploadingErrors: {
                videoAudio: null,
                showInvalidUserHash: false,
                textInvalidUserHash: null,
                showVideo: false,
                invalidFile: false,
                other: null,
                showOther: false
            },
            fileLoading: false
        }
    },
    watch: {
        filesEvent(files) {
            if (files.length) {
                files.forEach(e => this.handleFileUpload(e))
                this.$emit("clearFilesEvent")
            }
        },
        filesList() {
            this.files = this.filesList
        }
    },
    methods: {
        downloadWithChoosingPath(file) {
            let endPoint = `/api/v1/files/${file.uuid}/download`

            const newWindow = window.open(endPoint, "_blank")
            newWindow.document.title = file.original_name
        },
        handleFileUpload(event) {
            this.uploadingErrors.invalidFile = false
            this.file = event ? event : this.$el.querySelector(".file-upload__input").files[0]
            this.file.toJSON = () => {
                return {
                    lastModified: this.file.lastModified,
                    lastModifiedDate: this.file.lastModifiedDate,
                    name: this.file.name,
                    size: this.file.size,
                    type: this.file.type
                }
            }

            this.$refs.file.value = null

            if (!this.file) return

            // Show error while file doesn't fit size condition
            if (/video\/./.test(this.file.type) && this.file.size > this.videoFileMaxSize) {
                return this.showUploadingError("video", 2000)
            } else if (!/video\/./.test(this.file.type) && this.file.size > this.otherFilesMaxSize) {
                return this.showUploadingError("file", 2000)
            }
            const ext = new RegExp(this.file.name.split(".").pop().toLowerCase())
            if (this.accepted !== "*.*" && !ext.test(this.accepted)) {
                this.uploadingErrors.invalidFile = true
                return
            }

            this.fileLoading = true
            let audioFile = null
            if (this.file.type.includes("audio")) {
                let audioBlob = null
                const reader = new FileReader()
                reader.addEventListener("load", () => {
                    audioBlob = dataURItoBlob(reader.result)
                    const audioUrl = URL.createObjectURL(audioBlob)
                    const audio = new Audio(audioUrl)
                    audioFile = {
                        audioBlob,
                        audioUrl,
                        audio,
                        file: this.file,
                        id: new Date().getTime()
                    }
                })
                reader.readAsDataURL(this.file)
            }

            let formData = new FormData()
            let convertMedia = "0"

            formData.append("file", this.file)
            formData.append("user_id", this.user.id)
            formData.append("user_id_hash", this.user.id_hash || window.id_hash)
            formData.append("convert_media", convertMedia)

            if (this.cabinet_id || window.current_cabinet_id) {
                formData.append("cabinet_id", this.cabinet_id || window.current_cabinet_id)
            }
            // let i = this.files.push(data)
            // i -= 1
            //
            // if (this.file.type.match('image') || this.file.type.match('video')) {
            //     this.$nextTick(() => {
            //         this.previewFile(this.file, i)
            //     })
            // }

            this.$emit("setCommentButtonDisabled", true)

            let params = {
                model: this.appModel
            }

            axios
                .post(window.file_storage_url, formData, { params })
                .then(response => {
                    let data = response.data.data

                    if (data.mime_type.match("image") || data.mime_type.match("video")) {
                        data.isDocPreviewVisible = false
                    } else {
                        data.isDocPreviewVisible = true
                    }
                    if (audioFile) {
                        this.files.push({ ...data, ...(audioFile && { url: audioFile.audioUrl }) })
                    } else {
                        this.files.push(data)
                    }

                    this.emitFile()
                    this.$emit("setCommentButtonDisabled", false)

                    this.checkUploadFile(data, false)
                })
                .catch(error => {
                    console.log(error.message)
                    console.log(error)
                    if (error?.response?.data?.message) {
                        this.uploadingErrors.showOther = true
                        this.uploadingErrors.other = error?.response?.data?.message
                    }

                    const data = error?.response?.data
                    this.uploadingErrors.showInvalidUserHash = data && data.errors && data.errors.user_id_hash
                })
                .finally(() => {
                    this.fileLoading = false
                })
        },
        checkUploadFile(file, value) {
            file.isFileLoading = true

            if (!value) {
                file.checkUploadFileTimer = setTimeout(() => {
                    axios
                        .get(`/files/${file.uuid}`)
                        .then(response => {
                            let data = response.data.data

                            if (data.process_status === "completed" || data.url) {
                                clearTimeout(file.checkUploadFileTimer)

                                file.url = data.url
                                file.isFileLoading = false

                                this.emitFile()
                                this.$emit("setCommentButtonDisabled", false)
                            } else {
                                this.checkUploadFile(file, false)
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            file.isFileLoadingError = true
                        })
                }, 5000)
            }
        },
        // previewFile(file, i) {
        //     let name = 'preview' + i
        //     const previewBox = this.$refs[name][0]
        //     let img
        //
        //     var fileReader = new FileReader()
        //
        //     if (file.type.match('image')) {
        //         fileReader.onload = function () {
        //             img = document.createElement('img')
        //             img.src = fileReader.result
        //             previewBox.appendChild(img)
        //         }
        //         fileReader.readAsDataURL(file)
        //     } else {
        //         fileReader.onload = function () {
        //             var blob = new Blob([fileReader.result], {
        //                 type: file.type
        //             })
        //             var url = URL.createObjectURL(blob)
        //             var video = document.createElement('video')
        //             var timeupdate = function () {
        //                 if (snapImage()) {
        //                     video.removeEventListener('timeupdate', timeupdate)
        //                     video.pause()
        //                 }
        //             }
        //             video.addEventListener('loadeddata', function () {
        //                 if (snapImage()) {
        //                     video.removeEventListener('timeupdate', timeupdate)
        //                 }
        //             })
        //             var snapImage = function () {
        //                 var canvas = document.createElement('canvas')
        //                 canvas.width = video.videoWidth
        //                 canvas.height = video.videoHeight
        //                 canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
        //                 var image = canvas.toDataURL()
        //                 var success = image.length > 100000
        //                 if (success) {
        //                     img = document.createElement('img')
        //                     img.src = image
        //                     previewBox.appendChild(img)
        //                     URL.revokeObjectURL(url)
        //                 }
        //                 return success
        //             }
        //             video.addEventListener('timeupdate', timeupdate)
        //             video.preload = 'metadata'
        //             video.src = url
        //             video.muted = true
        //             video.playsInline = true
        //             video.play()
        //         }
        //         fileReader.readAsArrayBuffer(file)
        //     }
        // },
        emitFile() {
            this.$emit("getFile", this.files)
            // console.log(this.files)
        },
        openRemoveModal(file, i) {
            if (this.parent === "lesson") {
                this.$emit("openRemoveFileModal", "file", file, file.id, i)
            } else {
                this.removeModal = file
                this.$emit("openRemoveFileModal", file)
            }
        },
        closeRemoveModal() {
            this.removeModal = false
        },
        removeFile(file) {
            this.$refs.file.value = ""

            this.files = this.files.filter(item => item.id !== (typeof file === "number" ? file : file.id))
            this.removeModal = false

            this.emitFile()
        },
        // cancelAxios(file) {
        //     file.cancelAxios()
        // },
        // setEntryFilesUpd() {
        //     if (this.filesList.length) {
        //         this.files = []
        //         this.filesList.forEach((file) => {
        //             file.progressBar = '100%'
        //             if (file.mime_type.match('image') || file.mime_type.match('video')) {
        //                 file.prev = file.public_path
        //             } else {
        //                 file.isDocPreviewVisible = true
        //             }
        //             this.files.push(file)
        //         })
        //     } else {
        //         this.files = []
        //     }
        // }
        showUploadingError(type, time) {
            switch (type) {
                case "video":
                    this.uploadingErrors.showVideo = true
                    return setTimeout(() => (this.uploadingErrors.showVideo = false), time)
                default:
                    this.uploadingErrors.showOther = true
                    return setTimeout(() => (this.uploadingErrors.showOther = false), time)
            }
        }
    },
    created() {
        if (this.filesList.length) {
            this.files = []

            this.filesList.forEach(file => {
                // if (file.mime_type.match('image') || file.mime_type.match('video')) {
                //     file.prev = file.public_path
                // }

                this.files.push(file)
            })
        }

        // Setting all lang keys needed for files uploading component on both sides (expert, student)
        if (["publicLesson", "student"].includes(this.parent) && window.langs) {
            this.langsKeys = window.langs
            this.loadFileHere = this.langsKeys["create-course"]["create-course.load_file_here"]
            this.textTitle = this.langsKeys["create-course"]["create-course.to_delete_lesson_file"]
            this.textNoDelete = this.langsKeys["create-course"]["create-course.no_cancel"]
            this.textYesDelete = this.langsKeys["create-course"]["create-course.yes_delete"]
            this.uploadingErrors.videoAudio = `${this.langsKeys["create-course"]["create-course.max_uploading_lesson_file"]} 6000 mB`
            this.uploadingErrors.other = `${this.langsKeys["create-course"]["create-course.max_uploading_lesson_file"]} 1000 mB`
            this.invalidFileExtension = `${this.langsKeys["create-course"]["invalid_file_extension"]}`
            this.uploadingErrors.textInvalidUserHash = `${this.langsKeys["create-course"]["create-course.invalid_user_hash_id"]}`
        } else {
            this.loadFileHere = this.t("create-course.load_file_here")
            this.textTitle = this.t("create-course.to_delete_lesson_file")
            this.textNoDelete = this.t("create-course.no_cancel")
            this.textYesDelete = this.t("create-course.yes_delete")
            this.uploadingErrors.videoAudio = `${this.t("create-course.max_uploading_lesson_file")} 6000 mB`
            this.uploadingErrors.other = `${this.t("create-course.max_uploading_lesson_file")} 1000 mB`
            this.invalidFileExtension = this.t("create-course.invalid_file_extension")
            this.uploadingErrors.textInvalidUserHash = this.t("create-course.create-course.invalid_user_hash_id")
        }

        EventBus.$on("removeFile", file => {
            this.removeFile(file)
        })
    }
}
</script>




