import BugIcon from "@icons/BugIcon.vue"
import Vue from "vue"
import VueScreenSize from "vue-screen-size"
import notifier from "@components/Notifications/notifier"
import Vuex from "vuex"
import { lessonModules } from "@lesson/shared/state"
import mixins from "~mixins"
import Lesson from "@lesson/Lesson.vue"
import { PRELOAD_UNITS_DATA } from "@lesson/shared/state/lesson-actions"

import createSentry from "@services/create-sentry"

import "@components/Practice/PracticeQuestions/components/global.js"
import "~taiwind"

createSentry(Vue, null)

Object.defineProperty(String.prototype, "capitalize", {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1)
    },
    enumerable: false,
    configurable: true
})

function createApp() {
    // eslint-disable-next-line no-undef
    if (typeof lesson_dto === "undefined") {
        return
    }

    Vue.use(VueScreenSize)

    Vue.prototype.$notify = notifier.exec

    Vue.mixin({
        data() {
            return {
                scrollY: 0
            }
        },
        computed: {
            $scrollY() {
                return this.scrollY
            },
            $screenWidth() {
                return this.$vssWidth
            },
            $screenHeight() {
                return this.$vssHeight
            }
        },
        created() {
            this.scrollY = window.scrollY
            window.addEventListener("scroll", () => {
                this.scrollY = window.scrollY
            })
        },
        methods: {
            $hasAttr(attr) {
                if (this.$attrs.hasOwnProperty(attr)) {
                    if (this.$attrs[attr] === "") {
                        return attr
                    } else if (typeof this.$attrs[attr] === "boolean") {
                        if (!this.$attrs[attr]) {
                            return ""
                        } else {
                            return attr
                        }
                    }
                }
                return ""
            }
        }
    })

    let token = localStorage.getItem("token")

    if (!token || token !== window.token) {
        token = window.token
    }

    const root = document.querySelector("#root")

    if (root) {
        const store = new Vuex.Store({
            modules: lessonModules
        })

        Vue.mixin(mixins)

        // eslint-disable-next-line no-undef
        const lessonDto = lesson_dto

        if (!lessonDto.is_comments_available) {
            const commentsUnit = lessonDto.section.info_units.find(unit => unit.type_id === 6)
            if (commentsUnit) {
                lessonDto.section.info_units.splice(lessonDto.section.info_units.indexOf(commentsUnit), 1)
            }
        }

        if (lessonDto.section && lessonDto.lesson) {
            lessonDto.lesson.section_id = lessonDto.section.id
        }

        Promise.all([
            store.dispatch("user/fetchUser"),
            store.dispatch(PRELOAD_UNITS_DATA, lessonDto.section.info_units),
            store.dispatch("user/fetchCabinets"),
            store.dispatch("user/fetchIsProductionMode"),
            store.dispatch("langs/fetchLangs", "expert,create-course,course,quiz,common,contact")
        ])
            // eslint-disable-next-line no-unused-vars
            .then(([user, units]) => {
                lessonDto.section.info_units = units
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                new Vue({
                    el: `#root`,
                    store,
                    render: h =>
                        h(Lesson, {
                            props: {
                                lessonDto: lessonDto,
                                lesson: lessonDto.lesson,
                                section: lessonDto.section,
                                course: lessonDto.course
                            }
                        })
                })
            })
    }
}

try {
    createApp()
} catch (e) {
    notifier.exec({
        position: "top-right",
        color: "danger",
        title: "Something went wrong",
        text: e?.response?.data?.message || e?.response?.data?.error || e,
        icon: BugIcon
    })
}
