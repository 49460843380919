<template>
    <div class="practice-comments" :class="{ 'practice-comments_hidden': !displayComments }" v-if="comments.length">
        <button class="practice-comments-btn" @click="toggleComments">
            <flex-container>
                <default-title :font-weight="400" large>
                    {{ t("course.course_card.comments") }} ({{ comments.length }})
                </default-title>
                <ArrowDownIcon :class="{ 'rotate-180': displayComments }" class="ml-5 mt-3" />
            </flex-container>
        </button>
        <div v-if="displayComments">
            <div class="mt-10"></div>
            <template v-if="displayComments">
                <div ref="wrapper" class="comments-wrapper custom-scroll">
                    <div
                        class="practice-comment"
                        :ref="'comment_' + comment.id"
                        :key="comment.id"
                        v-for="comment in comments"
                    >
                        <flex-container class="practice-comment-head" justify="space-between" align="flex-start">
                            <FlatUser :user="comment.user"/>
                            <div class="created_at">
                                <div v-if="comment.commentable.finished_at" class="mb-1">
                                    {{ t("quiz.version_attempt_at") }}
                                    {{ comment.commentable.finished_at | formatDateWithTimeNoSeconds2 }}
                                </div>
                                <span :title="comment.created_at | formatDateWithTimeNoSeconds2">
                                    {{ comment.created_at_human }}
                                </span>
                            </div>
                        </flex-container>

                        <div class="text-view" v-html="comment.text_formatted.clean"></div>

                        <FilesNewView class="mt-10" v-if="comment.files && comment.files.length"
                                      :files="comment.files"/>
                    </div>
                </div>
            </template>

            <template v-if="attempt && quiz.attempt_comment_type && quiz.attempt_comment_type.id === 1">
                <DefaultInput
                    use-focus
                    v-model="text"
                    v-if="isOpen"
                    :readonly="loading"
                    :placeholder="t('quiz.answer')"
                    class="mt-15"
                    is-area
                />
                <button
                    @click="handleClick"
                    v-if="isDisplayComments"
                    class="comment-answer-btn"
                    :class="{
                        'comment-answer-btn_open': isOpen,
                        disabled: !text && isOpen,
                        'pointer-events-none': loading
                    }"
                >
                    <SpinLoader :width="24" color="#fff" v-if="loading" />
                    <template v-else>
                        {{ t(isOpen ? "create-course.send" : "common.answer") }}
                    </template>
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import { getPersonalSettings, updatePersonalSettings } from "@common-store/personal-settings"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultInput from "@components/Forms/DefaultInput.vue"
import SpinLoader from "@components/Loaders/SpinLoader.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import FlatUser from "@components/User/FlatUser.vue"
import { handleError } from "@helpers"
import ArrowDownIcon from "@icons/ArrowDownIcon.vue"
import moment from "moment"
import IsOpen from "@mixins/is-open"

import "moment/dist/locale/ru"
import "moment/dist/locale/uk"
import "moment/dist/locale/it"
import "moment/dist/locale/es"
import "moment/dist/locale/de"
import "moment/dist/locale/pl"
import FilesNewView from "@components/Files/FilesNewView.vue"
import axios from "~axios"

moment.locale(window.app_current_locale)

export default {
    components: {
        ArrowDownIcon,
        DefaultTitle,
        FlatUser,
        FlexContainer,
        SpinLoader,
        DefaultInput,
        FilesNewView
    },
    mixins: [IsOpen],
    props: {
        quiz: {
            type: Object,
            default: () => {}
        },
        attempt: {
            type: Object,
            default: () => {}
        },
        isPublic: {
            default: true
        },
        isDisplayComments: {
            default: true
        }
    },
    filters: {
        formatDateWithTimeNoSeconds2(value) {
            return moment(value).format("DD.MM.YYYY, HH:mm")
        }
    },
    name: "PracticeComment",
    computed: {
        comments() {
            if (this.quiz.comment_versioning_type && this.quiz.comment_versioning_type.slug === 'Disabled') {
                return this.quiz.attempt_comments.filter(comment => comment.commentable.id === this.attempt.id)
            }

            return this.quiz.attempt_comments
        }
    },
    data() {
        return {
            text: "",
            loading: false,
            displayComments: this.isDisplayComments
        }
    },
    mounted() {
        if (this.isDisplayComments) {
            this.displayComments = getPersonalSettings("display_quiz_comments")
        }

        let uri = window.location.search
        let params = new URLSearchParams(uri)

        if (
            params.get("commentable_type") === "quiz-attempt" &&
            this.attempt &&
            params.get("commentable_id") == this.attempt.id
        ) {
            if (this.$route) {
                let query = Object.assign({}, this.$route.query)
                delete query.commentable_type
                delete query.commentable_id

                this.$router.replace({ query })
            } else {
                const url = new URL(window.location)

                url.searchParams.delete("commentable_type")
                url.searchParams.delete("commentable_id")
                window.history.replaceState({}, "", url)
            }

            if (!this.displayComments) {
                this.toggleComments()
            }

            this.$nextTick(() => {
                let params = new URLSearchParams(window.location.search)

                let commentId = params.get("comment_id")

                if (commentId && this.$refs[`comment_${commentId}`].length) {
                    this.$refs[`comment_${commentId}`][0].scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });

                    if (this.$route) {
                        let query = Object.assign({}, this.$route.query)
                        delete query.comment_id

                        this.$router.replace({query})
                    } else {
                        const url = new URL(window.location);

                        url.searchParams.delete('comment_id');

                        window.history.replaceState({}, '', url);
                    }
                }
            })
        } else {
            if (this.$refs.wrapper) {
                this.$refs.wrapper.scrollTop = this.$refs.wrapper.scrollHeight
            }
        }
    },
    methods: {
        toggleComments() {
            this.displayComments = !this.displayComments
            updatePersonalSettings("display_quiz_comments", this.displayComments)
        },
        handleClick() {
            if (!this.isOpen) {
                this.open()
            } else {
                this.answerComment()
            }
        },
        async answerComment() {
            this.loading = true

            try {
                const result = await axios.post(`${this.isPublic ? `/public` : ""}/comments`, {
                    commentable_type: "quiz-attempt",
                    commentable_id: this?.attempt?.id,
                    text: this.text
                })

                this.quiz.attempt_comments.push(result.data.data)

                this.$nextTick(() => {
                    if (this.$refs.wrapper) {
                        this.$refs.wrapper.scrollTop = this.$refs.wrapper.scrollHeight
                    }
                })
            } catch (e) {
                handleError(e)
            } finally {
                this.loading = false
                this.text = ""
                this.hide()
            }
        }
    }
}
</script>
<style lang="sass" scoped>
.practice-comment-head
    @media (max-width: 640px)
        flex-direction: column !important
.practice-comments-btn
    margin: 0
    transition: .2s
    display: block
    padding: 8px 15px
    border-radius: 7px
    &:hover
        background-color: #ececec

.created_at
    font-size: 10px
    text-align: right
    color: #919499
    @media (max-width: 640px)
        text-align: left
        margin-top: 10px
        margin-bottom: 10px
.comment-answer-btn
    padding: 6px 10px
    border-radius: 7px
    width: 100px
    margin-top: 15px
    font-size: 15px
    line-height: 160%
    background-color: #3965ff
    color: #fff
    margin-left: auto
    display: block
    &:hover, &:active
        filter: brightness(90%)
.practice-comment
    font-size: 13px !important
    font-family: Inter, sans-serif
    line-height: 15px
    margin: 0 5px 10px 5px
    background-color: #fff
    border-radius: 10px
    padding: 10px 10px 12px
    word-break: break-word

    .text-view
        margin-top: 2px
        font-size: 13px !important
    b
        font-weight: 500
    i
        color: #52565C
.practice-comments
    background-color: #f7f7f7
    border-radius: 10px
    max-width: 700px
    margin-top: 15px
    padding: 10px 14px
    color: #151515
    transition: .2s
    @media (max-width: 640px)
        padding: 10px 5px
    &_hidden
        width: max-content
        padding: 0
        .practice-comments-btn
            margin: 0
            transition: .2s

.comments-wrapper
    max-height: 400px
    overflow-y: auto
</style>
