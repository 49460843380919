import { mapGetters } from "vuex"
import axios from "~axios"
import { t } from "@helpers"

export default {
    methods: {
        togglePaswordType(property) {
            property.type === "password" ? (property.type = "text") : (property.type = "password")
        },
        redirectToDomainPath(path) {
            let parts = location.hostname.split(".")
            let domain = parts.slice(-2).join(".")
            let url = location.protocol + "//" + domain + "/" + path
            window.location.href = url
        },
        addAudioAqulizersPreviews(previews) {
            let needAddPreviews = true
            previews.forEach(item => {
                if (item.preview_index === 1 || item.preview_index === 2) {
                    needAddPreviews = false
                }
            })
            if (needAddPreviews) {
                let audioEqulizerStriped = {
                    preview_index: 1,
                    url: "/build/img/striped-prev.jpg"
                }
                previews.push(audioEqulizerStriped)
                let audioEqulizerCurved = {
                    preview_index: 2,
                    url: "/build/img/curved-prev.jpg"
                }
                previews.push(audioEqulizerCurved)
            }
        },
        setPlayerDuration(time = 0, original) {
            let sec_num = parseInt(time, 10)
            let hours = Math.floor(sec_num / 3600)
            let minutes = Math.floor((sec_num - hours * 3600) / 60)
            let seconds = sec_num - hours * 3600 - minutes * 60

            if (original) {
                return hours + ":" + minutes + ":" + seconds
            }

            if (hours < 10) {
                hours = "0" + hours
            }
            if (minutes < 10) {
                minutes = "0" + minutes
            }

            if (seconds < 0) {
                seconds += 60
                minutes -= 1
                if (minutes < 0) {
                    minutes += 60
                    hours -= 1
                }
            } else if (seconds < 10) {
                seconds = "0" + seconds
            }

            if (hours > 0) {
                hours = hours + ":"
            } else {
                hours = ""
            }

            return hours + minutes + ":" + seconds
        },
        dateToTimestamp(date) {
            if (!date) return null
            let datum = Date.parse(date)
            return datum / 1000
        },
        dateToString(value) {
            let date = new Date(value * 1000)
            let Year = date.getFullYear()
            let Month = date.getMonth() + 1
            if (Month < 10) Month = "0" + Month
            let Day = date.getDate()
            if (Day < 10) Day = "0" + Day
            let formattedTime = Year + "-" + Month + "-" + Day
            return formattedTime
        },
        dateToTime(value) {
            let date = new Date(value * 1000)
            let Hours = date.getHours()
            let Minutes = date.getMinutes()
            if (Hours < 10) Hours = "0" + Hours
            if (Minutes < 10) Minutes = "0" + Minutes
            let formattedTime = Hours + ":" + Minutes
            return formattedTime
        },
        countdownTimer(el, datetime, message, hideEl) {
            // Set the date we're counting down to
            let countDownDate = new Date(datetime).getTime()

            // Update the count down every 1 second
            let x = setInterval(function () {
                // Destroy counter
                if (document.getElementById(el)) {
                    // Get today's date and time
                    let now = new Date().getTime()

                    // Find the distance between now and the count down date
                    let distance = countDownDate - now

                    let hours = "00"
                    let minutes = "00"
                    let seconds = "00"

                    if (distance > 0) {
                        // Time calculations for days, hours, minutes and seconds
                        // var days = Math.floor(distance / (1000 * 60 * 60 * 24))
                        hours = Math.floor((distance / (1000 * 60 * 60 * 24)) * 24)
                        minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                        seconds = Math.floor((distance % (1000 * 60)) / 1000)

                        if (hours < 10) {
                            hours = "0" + hours
                        }
                        if (minutes < 10) {
                            minutes = "0" + minutes
                        }
                        if (seconds < 10) {
                            seconds = "0" + seconds
                        }
                    }

                    // Display the result in the element with id="demo"
                    document.getElementById(
                        el
                    ).innerHTML = `<span class="orange">${hours}</span>:<span>${minutes}</span>:<span>${seconds}</span>`

                    // If the count down is finished, write some text
                    if (distance < 0) {
                        clearInterval(x)

                        if (message) {
                            document.getElementById(el).innerHTML = `<span class="orange">${message}</span>`
                        }

                        if (hideEl) {
                            let el = document.getElementsByClassName(hideEl)
                            if (el) {
                                el[0].remove()
                            }
                        }
                    }
                } else {
                    clearInterval(x)
                }
            }, 1000)
        },
        t(key, parameters) {
            return t(key, this.langs, parameters)
        },
        // Delete useless get parameters in url after redirecting from somewhere
        clearUrl(url) {
            if (this.$route.query.token) {
                if (this.$route.params.id) {
                    this.$router.push(`/${this.$route.params.id}${url}`)
                } else {
                    this.$router.push(url)
                }
            }
        },
        getDefaultCabinet() {
            this.cabinets.forEach(item => {
                if (item.is_current) {
                    this.defaultCabinet = item
                }
            })
        },
        updateDefaultCabinet() {
            if (this.cabinets.find(cabinet => cabinet.id === 0)) return
            if (this.defaultCabinet) {
                this.cabinets.push({
                    name: this.user.name,
                    id: 0,
                    user_cabinet: true
                })
            }
        },
        num_decline(number, titles, showNumber = true, loweCase = false) {
            if (titles instanceof String) {
                titles = titles.split(",")
            }

            /*if (!number) {
                return titles
            }*/

            // когда указан 1 элемент
            if (!titles[1]) {
                titles[1] = titles[0]
            }

            // когда указано 2 элемента
            if (!titles[2]) {
                titles[2] = titles[1]
            }

            if (loweCase) {
                for (const [index, title] of titles.entries()) {
                    titles[index] = title.toLowerCase()
                }
            }

            const cases = [2, 0, 1, 1, 1, 2]

            const intNum = Math.abs(parseInt(number))

            const titleIndex = intNum % 100 > 4 && intNum % 100 < 20 ? 2 : cases[Math.min(intNum % 10, 5)]

            return `${showNumber ? `${number} ` : ""} ${titles[titleIndex]}`
        },

        deleteMediaFiles(idsArray) {
            let data = {
                files: idsArray,
                user_id: this.user.id,
                user_id_hash: this.user.id_hash
            }
            let storageUrl = window.file_storage_url.replace("upload", "")
            axios.delete(`${storageUrl}delete-files/`, { params: data }).then(() => {
                // console.log(response)
            })
        },
        getLivePassedTime(duration, liveStart, trimEnding) {
            const time = this.setPlayerDuration(duration, true).split(":")
            const liveDate = new Date(liveStart)
            const liveTrimEnding = trimEnding / 60
            const currHour = liveDate.getHours()
            const currMin = liveDate.getMinutes()
            const currSec = liveDate.getSeconds()
            liveDate.setHours(currHour + +time[0])
            liveDate.setSeconds(currSec + +time[2])
            liveDate.setMinutes(currMin + +time[1] - liveTrimEnding)
            return liveDate
        },
        bulkAccessOnlyTypesCheck(module, types, moduleId = null) {
            const accesses = this.cabinetAccesses
            let allTypes = [`${module}_read`, `${module}_create`, `${module}_edit`, `${module}_delete`]

            let typesToCheck = []
            types.forEach(type => {
                if (moduleId) typesToCheck.push(`${module}_${type}.${moduleId}`)
                else typesToCheck.push(`${module}_${type}`)
            })

            allTypes = allTypes.filter(type => typesToCheck.indexOf(type) === -1)

            let checkCount = 0
            let otherCount = 0

            typesToCheck.forEach(type => {
                if (accesses.indexOf(type) !== -1) checkCount++
            })

            allTypes.forEach(type => {
                if (accesses.indexOf(type) !== -1) otherCount++
            })

            return typesToCheck.length === checkCount && otherCount === 0
        },
        bulkAccessTypesCheck(module, types, moduleId = null) {
            if (!this.cabinetAccesses) return
            const accesses = this.cabinetAccesses

            let typesToCheck = []
            types.forEach(type => {
                if (moduleId) typesToCheck.push(`${module}_${type}.${moduleId}`)
                else typesToCheck.push(`${module}_${type}`)
            })

            let checkCount = 0

            typesToCheck.forEach(type => {
                if (accesses.includes(type)) {
                    checkCount++
                }
            })

            return checkCount === typesToCheck.length
        },
        personalizeAccessCheck(module, only = true) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["personalize"])
                : this.bulkAccessTypesCheck(module, ["personalize"])
        },
        analyticPersonalizeAccessCheck(module, only = true, moduleId = null) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["read", "analytic"], moduleId)
                : this.bulkAccessTypesCheck(module, ["read", "analytic"], moduleId)
        },
        exportPersonalizeAccessCheck(module, only = true, moduleId = null) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["export"], moduleId)
                : this.bulkAccessTypesCheck(module, ["export"], moduleId)
        },
        editPersonalizeAccessCheck(module, only = true, moduleId = null) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["edit"], moduleId)
                : this.bulkAccessTypesCheck(module, ["edit"], moduleId)
        },
        createPersonalizeAccessCheck(module, only = true, moduleId = null) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["create"], moduleId)
                : this.bulkAccessTypesCheck(module, ["create"], moduleId)
        },

        deletePersonalizeAccessCheck(module, only = true, moduleId = null) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["delete"], moduleId)
                : this.bulkAccessTypesCheck(module, ["delete"], moduleId)
        },
        curatorPersonalizeAccessCheck(module, only = true, moduleId = null) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["curator"], moduleId)
                : this.bulkAccessTypesCheck(module, ["curator"], moduleId)
        },
        readAccessCheck(module, only = true) {
            return only ? this.bulkAccessOnlyTypesCheck(module, ["read"]) : this.bulkAccessTypesCheck(module, ["read"])
        },
        analyticAccessCheck(module, only = true) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["analytic"])
                : this.bulkAccessTypesCheck(module, ["analytic"])
        },
        exportAccessCheck(module, only = true) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["export"])
                : this.bulkAccessTypesCheck(module, ["export"])
        },
        listAccessCheck(module, only = true) {
            return only ? this.bulkAccessOnlyTypesCheck(module, ["list"]) : this.bulkAccessTypesCheck(module, ["list"])
        },
        objectAccessCheck(module) {
            return {
                read: this.bulkAccessTypesCheck(module, ["read"]),
                edit: this.bulkAccessTypesCheck(module, ["edit"]),
                create: this.bulkAccessTypesCheck(module, ["create"]),
                delete: this.bulkAccessTypesCheck(module, ["delete"])
            }
        },
        curatorAccessCheck(module, only = true) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["read", "curator"])
                : this.bulkAccessTypesCheck(module, ["read", "curator"])
        },
        createAccessCheck(module, only = true) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["create"])
                : this.bulkAccessTypesCheck(module, ["create"])
        },
        editAccessCheck(module, only = true) {
            return only ? this.bulkAccessOnlyTypesCheck(module, ["edit"]) : this.bulkAccessTypesCheck(module, ["edit"])
        },
        crudAccessCheck(module, only = true) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["read", "crud"])
                : this.bulkAccessTypesCheck(module, ["read", "crud"])
        },
        deactivateAccessCheck(module, only = true) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["deactivate"])
                : this.bulkAccessTypesCheck(module, ["deactivate"])
        },
        rootAccessCheck(module, only = true) {
            return only ? this.bulkAccessOnlyTypesCheck(module, ["root"]) : this.bulkAccessTypesCheck(module, ["root"])
        },
        storeAccessCheck(module, only = true) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["store"])
                : this.bulkAccessTypesCheck(module, ["store"])
        },
        allAccessCheck(module, only = true) {
            return only ? this.bulkAccessOnlyTypesCheck(module, ["all"]) : this.bulkAccessTypesCheck(module, ["all"])
        },
        deleteAccessCheck(module, only = true) {
            return only
                ? this.bulkAccessOnlyTypesCheck(module, ["delete"])
                : this.bulkAccessTypesCheck(module, ["delete"])
        },
        createEditAccessCheck(module) {
            return this.bulkAccessOnlyTypesCheck(module, ["read", "create", "edit"])
        },
        createDeleteAccessCheck(module) {
            return this.bulkAccessOnlyTypesCheck(module, ["read", "create", "delete"])
        },
        editDeleteAccessCheck(module) {
            return this.bulkAccessOnlyTypesCheck(module, ["read", "edit", "delete"])
        },
        accessWithoutDeletionCheck(module) {
            return (
                this.readAccessCheck(module) ||
                this.createAccessCheck(module) ||
                this.editAccessCheck(module) ||
                this.createEditAccessCheck(module)
            )
        },
        accessWithDeletionCheck(module) {
            return (
                this.deleteAccessCheck(module) ||
                this.createDeleteAccessCheck(module) ||
                this.editDeleteAccessCheck(module)
            )
        },
        allAccessesCheck(module) {
            return this.bulkAccessOnlyTypesCheck(module, ["read", "create", "edit", "delete"])
        },

        checkPath(path) {
            let pathToCheck = this.$route.path.split("/")
            if (pathToCheck.includes(path)) {
                return true
            }
        },
        showFullSizePage() {
            return this.checkPath("site-builder") === true
        },
        handleDropdownGap(dropdownBlockId, blockUnderDropdownId, type) {
            const dropdownBlock = document.querySelector(`#${dropdownBlockId} .multiselect__content-wrapper`)
            const blockUnderDropdown = document.getElementById(`${blockUnderDropdownId}`)

            switch (type) {
                case "open":
                    setTimeout(() => {
                        blockUnderDropdown.style.height = `${dropdownBlock.offsetHeight}px`
                    }, 0)
                    break
                case "close":
                    blockUnderDropdown.style.height = `0px`
                    break
                default:
            }
        },
        disableInputNumberScroll() {
            document.addEventListener("wheel", () => {
                if (document.activeElement.type === "number") {
                    document.activeElement.blur()
                }
            })
        },
        createPageTitle(path) {
            console.log(path.split("/"))
        },
        setSupportChatUrl() {
            switch (this.appCurrentLocale) {
                case "en":
                    return "https://tawk.to/chat/6408f7ae4247f20fefe4c888/1gr1gf0e9"
                case "uk":
                    return "https://tawk.to/chat/6408f7ae4247f20fefe4c888/1grdq4fdn"
                default:
                    return "https://tawk.to/chat/6408f7ae4247f20fefe4c888/1grdqn8ph"
            }
        },
        convertSize(size) {
            if (!size) return "-"

            let newSize

            if (size < 10000)
                newSize = (size / 1024).toFixed(2) + " " + this.t("expert.media_library.kb")
            if (size > 10000)
                newSize = (size / 1024 / 1024).toFixed(2) + " " + this.t("expert.media_library.mb")
            if (size > 1000000)
                newSize = (size / 1024 / 1024).toFixed(1) + " " + this.t("expert.media_library.mb")
            if (size > 1000000000)
                newSize = (size / 1024 / 1024 / 1024).toFixed(1) + " " + this.t("expert.media_library.gb")

            return newSize
        },
        formatBytes(bytes, decimals = 0) {
            if (bytes === 0) return "0 Bytes"

            const k = 1024

            const dm = decimals < 0 ? 0 : decimals
            const sizes = ["Bytes", "кB", "mB", "gB"]

            const i = Math.floor(Math.log(bytes) / Math.log(k))

            return Math.round(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) + " " + sizes[i]
        },
        // Scroll navigation
        setActive(index) {
            setTimeout(() => this.scrollIt(index, 300), 100)
        },
        scrollIt(idElem, duration = 300, callback) {
            // Change index on mobile/tablet viewports
            if (this.$screenWidth <= this.desktopWidth && idElem > 4) idElem++
            // Handle saving buttons block height
            const gapHeight = 30
            const creationButtonsHeight = Math.ceil(document.querySelector("#creationButtons").clientHeight) - gapHeight
            // Handle rest logic
            const start = window.pageYOffset
            const startTime = "now" in window.performance ? performance.now() : new Date().getTime()
            const destination = document.getElementById(`step-${idElem}`)
            const documentHeight = Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            )
            const windowHeight =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.getElementsByTagName("body")[0].clientHeight
            const destinationOffset = typeof destination === "number" ? destination : destination.offsetTop
            const destinationOffsetToScroll =
                Math.round(
                    documentHeight - destinationOffset < windowHeight
                        ? documentHeight - windowHeight
                        : destinationOffset
                ) - creationButtonsHeight

            // if ("requestAnimationFrame" in window === false) {
            window.scroll(0, destinationOffsetToScroll)
            if (callback) {
                callback()
            }
            return
            // }

            function scroll() {
                const now = "now" in window.performance ? performance.now() : new Date().getTime()
                const time = Math.min(1, (now - startTime) / duration)
                window.scroll(0, Math.ceil(time * (destinationOffsetToScroll - start) + start))
                if (destinationOffsetToScroll - Math.round(window.pageYOffset) < 30) {
                    if (callback) {
                        callback()
                    }
                    window.scroll(0, destinationOffsetToScroll)
                    return
                }

                requestAnimationFrame(scroll)
            }

            // eslint-disable-next-line no-unreachable
            scroll()
        },
        // Errors handling
        detectStepError(step) {
            return this.errors.some(error => error.step === step)
        }
    },
    computed: {
        autoLoginHash() {
            return this.currentUser?.auto_login_hash || ""
        },
        termsUrl() {
            return `${location.protocol}//${window.app_domain}/terms-and-conditions`
        },
        cookiesUrl() {
            return `${location.protocol}//${window.app_domain}/cookies-policy`
        },
        privacyUrl() {
            return `${location.protocol}//${window.app_domain}/privacy-policy`
        },
        knowledgeUrl() {
            return `${location.protocol}//${window.app_domain}/help-center`
        },
        isPartner() {
            return this.currentUser?.is_partner || false
        },
        partnersUrl() {
            return (
                window.app_partner_url_no_locale +
                "/dashboard" +
                (window.is_custom_domain ? "?hfa=" + this.autoLoginHash : "")
            )
        },
        newsUrl() {
            switch (window.app_current_locale) {
                case "ru":
                    return "https://t.me/kwiganews_ru"
                case "uk":
                    return "https://t.me/kwiganews"
                default:
                    return "https://www.facebook.com/kwiga.en"
            }
        },
        callUrl() {
            switch (window.app_current_locale) {
                case "ru":
                    return "https://calendly.com/kwiga_all/45min"
                case "uk":
                    return "https://calendly.com/kwiga_all/45min"
                default:
                    return "https://calendly.com/kwiga_all/en"
            }
        },
        ...mapGetters({
            currentUser: "user/currentUser",
            langs: "langs/getLangs",
            cabinetAccesses: "user/cabinetAccesses"
        })
    }
}
