<template>
    <modal-container block centred :min-width="440" :padding="[20]" :top="240" :max-height="290">
        <default-title
            text-color="#323030"
            class="mb-20"
            :size="16"
            :weight="600"
            :line-height="25"
            font-family="Inter, sans-serif"
        >
            {{ t("quiz.file_saving") }}
        </default-title>

        <default-title
            :size="16"
            :weight="400"
            :text-color="'#52565C'"
            :line-height="25"
            font-family="Inter, sans-serif"
        >
            {{ t("expert.media_library.file_name") }}: <b style="color: #323030">{{ name }}</b>
        </default-title>

        <flex-container
            class="mt-20"
            v-if="percent !== 100"
            align="center"
            style="min-height: 40px"
            justify="space-between"
        >
            <div class="progress-display">
                <div
                    class="progress-display progress-display"
                    :style="{ width: `${percent}%`, backgroundColor: '#3965FF' }"
                ></div>
            </div>

            <default-title
                text-color="#3965FF"
                class="ml-30"
                :size="16"
                :weight="600"
                :line-height="25"
                font-family="Inter, sans-serif"
            >
                {{ percent }}%
            </default-title>
        </flex-container>
        <div class="mt-20" v-else>
            <SpinLoader />
        </div>
    </modal-container>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer.vue"
import SpinLoader from "@components/Loaders/SpinLoader.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import ModalContainer from "@expert-components/Modals/ModalContainer.vue"

export default {
    name: "RecordingVideoSavingModal",
    components: { SpinLoader, FlexContainer, DefaultTitle, ModalContainer },
    data() {
        return {
            name: "test.mp4",
            percent: 0
        }
    },
    methods: {
        setName(name) {
            this.name = name
        },
        setProgress(progress) {
            this.percent = progress
        }
    }
}
</script>

<style scoped lang="sass">
.progress-display
    width: 100%
    background-color: #F3F3F3
    border-radius: 100px
    height: 5px
    transition: .2s
</style>
