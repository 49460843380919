export default dataURI => {
    const byteString = atob(dataURI.split(",")[1], "base64")

    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]

    const ab = new ArrayBuffer(byteString.length)

    const ia = new Uint8Array(ab)

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }

    var blob = new Blob([ab], { type: mimeString })
    return blob
}
