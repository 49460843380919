
<template>
    <svg width="7" height="12" viewBox="0 0 7 12" fill="#3E4755" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.46967 10.4697C0.176777 10.7626 0.176777 11.2374 0.46967 11.5303C0.762563 11.8232 1.23744 11.8232 1.53033 11.5303L0.46967 10.4697ZM6 6L6.53033 6.53033C6.67098 6.38968 6.75 6.19891 6.75 6C6.75 5.80109 6.67098 5.61032 6.53033 5.46967L6 6ZM1.53033 0.46967C1.23744 0.176777 0.762564 0.176777 0.469671 0.46967C0.176778 0.762563 0.176778 1.23744 0.469671 1.53033L1.53033 0.46967ZM1.53033 11.5303L6.53033 6.53033L5.46967 5.46967L0.46967 10.4697L1.53033 11.5303ZM6.53033 5.46967L1.53033 0.46967L0.469671 1.53033L5.46967 6.53033L6.53033 5.46967Z"
        />
    </svg>
</template>
<script>
export default {
    name: "ArrowRightIcon"
}
</script>