<template>
    <div class="modal-part-buttons">
        <button
            type="button"
            class="btn2 btn2_clear overflow-hidden"
            :class="{ disabled: loader }"
            @click="$emit('cancel')"
        >
            {{ showCancelText }}
        </button>
        <button
            type="button"
            :disabled="loader || disabled"
            class="btn2 overflow-hidden"
            :class="[{ loader, disabled }, `btn2_${negative ? 'danger' : 'success'}`, `btn2_${blue ? 'blue' : ''}`]"
            @click="$emit('confirm')"
        >
            {{ showConfirmText }}
        </button>
    </div>
</template>

<script>
export default {
    name: "ModalButtons",
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        loader: {
            type: Boolean,
            default: false
        },
        cancelText: {
            type: String,
            default: null
        },
        confirmText: {
            type: String,
            default: null
        },
        negative: {
            type: Boolean,
            default: false
        },
        blue: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showCancelText() {
            return this.cancelText ? this.cancelText : this.t("expert.cancel")
        },
        showConfirmText() {
            return this.confirmText ? this.confirmText : this.t("expert.save")
        }
    }
}
</script>

<style lang="sass" scoped>
@import 'resources/sass/vars'

.modal-part-buttons
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 10px
    transition: $transitionTime
    @media (max-width: 360px)
        grid-template-columns: 1fr
</style>
