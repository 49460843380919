export const REANSWER_TYPES = {
    NONE: 1,
    VIEW_READONLY: 2,
    VIEW_EDIT: 3
}

export const ANSWER_TYPES = {
    DEFAULT: 1,
    CUSTOM: 2
}

export const QUESTION_DISPLAY_TYPES = {
    SEPARATELY: 1,
    ALL: 2
}
