<template>
    <p
        ref="input"
        @input="
            $emit('update', {
                text_answer: $event.target.innerText,
                answer_group_id: getAnswerGroupId()
            })
        "
        class="fill-gaps-input"
        :class="modifier"
        :placeholder="t('quiz.input_answer')"
        :contenteditable="!!interactive && !readonly"
    ></p>
</template>

<script>
export default {
    props: {
        cases: {
            type: Array,
            default: () => []
        },
        interactive: {
            type: Boolean,
            default: true
        },
        viewable: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        typeShow: {
            type: String,
            default: ""
        },
        result: {
            type: Object,
            default: null
        }
    },
    name: "PracticeFillGapsInput",
    data() {
        return {
            modifier: ""
        }
    },
    methods: {
        getAnswerGroupId() {
            if (this.cases[0]) {
                return this.cases[0].quiz_answer_group_id
            }
            return ""
        }
    },
    mounted() {
        if (!this.interactive || this.viewable) {
            this.$refs.input.innerHTML = this.result?.text_answer || ""
            if (
                this.typeShow !== "simple_answer" &&
                this.typeShow !== "only_correct_or_not_question" &&
                this.result &&
                !this.viewable
            ) {
                this.modifier = this.result.is_correct ? "green" : "red"
            }
        }

        if (this.viewable) {
            this.$emit("update", {
                text_answer: this.result?.text_answer || "",
                answer_group_id: this.getAnswerGroupId()
            })
        }
    }
}
</script>

<style scoped lang="sass">
.fill-gaps-input
    border-bottom: 1px solid #98A2B0
    display: inline-block
    cursor: text
    margin-left: 4px
    margin-right: 4px
    padding-bottom: 5px
    margin-bottom: 4px

    &:focus, &:not(&:empty)
        border-color: #3965FF

    &:focus-visible
        outline: none

    &.red
        color: #DD4141
        border-color: #DD4141 !important

    &.green
        color: #15A06E
        border-color: #15A06E !important
[placeholder]:empty::before
    content: attr(placeholder)
    background-color: transparent
    color: #B5C1D2
</style>
