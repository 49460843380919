<template>
    <div
        @mouseenter="hovered = true"
        @mouseleave="hovered = false"
        v-click-outside="hideModal"
        class="simple-menu"
        :class="{ open: isModalVisible }"
    >
        <div
            v-if="!listed"
            :style="{ transform: rotated ? 'rotate(90deg)' : 'none' }"
            @click="toggleModal"
            class="simple-menu__handler"
            :data-uuid="uuid"
            :class="{
                'simple-menu__handler_shadow': shadow || (shadowOnHover && hovered),
                'simple-menu__handler_shadow-hover': shadowOnHover
            }"
        >
            <DotsMenuIcon :width="handlerWidth" class="pointer-events-none" />
        </div>
        <transition name="fade-up">
            <div
                @click="isModalVisible = false"
                v-if="isModalVisible || listed"
                :class="{ 'simple-menu__modal': !listed, 'flex items-center': listed }"
            >
                <slot></slot>
                <div
                    @click.prevent.stop="handleClick(action, $event)"
                    class="simple-menu-item show-tooltip-on-hover"
                    :class="{ 'simple-menu-item_red': action === 'delete', 'ml-15': listed }"
                    :key="index"
                    v-for="(action, index) in list"
                >
                    <template v-if="withIcons">
                        <CopyIcon v-if="action === 'copy'" />
                        <MoveIcon v-if="action === 'move'" />
                        <DeleteIcon hovered v-if="action === 'delete'" />
                        <ImageIcon hovered v-if="action === 'preview'" />
                        <EditIcon v-if="action === 'edit'" />
                    </template>

                    <template v-if="!listed">
                        <template v-if="action === 'preview'">
                            {{ t("builder.change_preview") }}
                        </template>
                        <template v-else>
                            {{ t(`expert.${action === "copy" ? "copy_to" : action}`) }}
                        </template>
                    </template>

                    <TooltipOnHover
                        :to-top="-20"
                        :triangle-right="action === 'delete'"
                        :to-horizontal="action === 'delete' ? -5 : 0"
                        v-else
                    >
                        {{ t(`expert.${action === "copy" ? "copy_to" : action}`) }}
                    </TooltipOnHover>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import TooltipOnHover from "@components/Tooltips/TooltipOnHover.vue"
import CopyIcon from "@icons/CopyIcon.vue"
import DeleteIcon from "@icons/DeleteIcon.vue"
import DotsMenuIcon from "@icons/DotsMenuIcon"
import EditIcon from "@icons/EditIcon.vue"
import ImageIcon from "@icons/ImageIcon.vue"
import MoveIcon from "@icons/MoveIcon.vue"
import ClickOutside from "vue-click-outside"
import { v4 } from "uuid"
import { EventBus } from "~events"

export default {
    props: {
        rotated: {
            type: Boolean,
            default: false
        },
        listed: {
            type: Boolean,
            default: false
        },
        handlerWidth: {
            type: Number,
            default: 24
        },
        shadow: {
            type: Boolean,
            default: false
        },
        withIcons: {
            type: Boolean,
            default: true
        },
        shadowOnHover: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: () => []
        }
    },
    components: {
        ImageIcon,
        CopyIcon,
        TooltipOnHover,
        EditIcon,
        DeleteIcon,
        MoveIcon,
        DotsMenuIcon
    },
    directives: {
        ClickOutside
    },
    name: "ExpertSimpleMenu",
    data() {
        return {
            hovered: false,
            isModalVisible: false,
            uuid: v4()
        }
    },
    created() {
        EventBus.$on("hide-menu", uuid => {
            if (uuid !== this.uuid) {
                this.isModalVisible = false
            }
        })
    },
    methods: {
        handleClick(action) {
            this.$emit("handle", action)
            this.isModalVisible = false
        },
        toggleModal(event) {
            if (event && event.target && event.target.dataset.uuid !== this.uuid) {
                this.isModalVisible = !this.isModalVisible
                event.preventDefault()
                event.stopPropagation()
                return
            }

            if (!this.isModalVisible) {
                EventBus.$emit("hide-menu", this.uuid)
                event.preventDefault()
                event.stopPropagation()
            }

            this.isModalVisible = !this.isModalVisible

            if (this.isModalVisible) {
                this.$emit("open")
            }
        },
        hideModal() {
            if (this.isModalVisible) {
                this.$emit("hide")
                this.isModalVisible = false
                this.$forceUpdate()
            }
        }
    }
}
</script>

<style lang="sass" >
.simple-menu
    position: relative

    &__handler
        cursor: pointer
        &:hover
            .dots-menu-icon
                fill: #3965FF !important

        &_shadow-hover, &_shadow
            border-radius: 4px
            width: 24px
            height: 24px
            display: flex
            align-items: center
            justify-content: center
            transition: .2s
            svg
                width: 19px
        &_shadow
            background-color: #fff
            box-shadow: 0px 2px 5px 0px #809EBF40
            &:hover
                svg
                    fill: #3965FF

    &__modal
        position: absolute
        right: 0
        width: 200px
        box-shadow: 0px 4px 10px rgba(128, 158, 191, 0.15)
        border-radius: 10px
        padding: 10px
        background: #fff
        z-index: 5555
        top: 0
        .simple-menu-divider
            height: 1px
            width: 100%
            background: #F3F3F3
            margin-top: 10px
            margin-bottom: 10px
        .simple-menu-item
            height: 39px
            padding-left: 10px
            display: flex
            width: 100%
            align-items: center
            font-size: 13px
            line-height: 17px
            color: #52565C
            font-weight: 400
            border-radius: 5px
            cursor: pointer
            transition: .2s

            &:hover
                background: #F2F7FF
                color: #151515
            svg
                margin-right: 8px
                width: 18px
                height: 18px

            &:not(&:last-child)
                margin-bottom: 2px

            &_red
                color: #DD4141

                svg
                    fill: #DD4141

                &:hover
                    background: #FFF6F6
                    color: #DD4141
</style>>

