
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#151515" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 6L8.38587 5.35688C8.15417 5.21786 7.86561 5.21422 7.63048 5.34735C7.39534 5.48048 7.25 5.7298 7.25 6H8ZM8 18H7.25C7.25 18.2702 7.39534 18.5195 7.63048 18.6526C7.86561 18.7858 8.15417 18.7821 8.38587 18.6431L8 18ZM18 12L18.3859 12.6431C18.6118 12.5076 18.75 12.2634 18.75 12C18.75 11.7366 18.6118 11.4924 18.3859 11.3569L18 12ZM8.75 18V6H7.25V18H8.75ZM17.6141 11.3569L7.61413 17.3569L8.38587 18.6431L18.3859 12.6431L17.6141 11.3569ZM7.61413 6.64312L17.6141 12.6431L18.3859 11.3569L8.38587 5.35688L7.61413 6.64312Z"
        />
    </svg>
</template>
<script>
export default {
    name: "PlayIcon"
}
</script>
