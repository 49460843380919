
<template>
    <svg viewBox="0 0 25 14" fill="#3E4755" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.9569 13.9131C7.28299 13.9131 7.50046 13.8044 7.71786 13.587C8.15264 13.1522 8.15264 12.5001 7.71786 12.0653L2.60906 6.95653L7.71786 1.84786C8.15264 1.41308 8.15264 0.760873 7.71786 0.326089C7.28307 -0.108696 6.63087 -0.108696 6.19608 0.326089L0.326461 6.19571C-0.108324 6.6305 -0.108324 7.2827 0.326461 7.71748L6.19608 13.587C6.41348 13.8044 6.63082 13.9131 6.9569 13.9131Z"
        />
        <path
            d="M23.913 8.04331C24.5652 8.04331 25 7.60852 25 6.95635C25 6.30417 24.5652 5.86938 23.913 5.86938H2.71728C2.0651 5.86938 1.63031 6.30417 1.63031 6.95635C1.63031 7.60852 2.0651 8.04331 2.71728 8.04331H23.913Z"
        />
    </svg>
</template>
<script>
export default {
    name: "ArrowIcon"
}
</script>