<template>
    <modal-container class="access-modal" block centred :top="120" :padding="[20]" :min-width="440" :max-width="440">
        <ProgressSteps class="mb-30" :steps="2" :current="step" />

        <default-title text-color="#323030" :size="16" :weight="600" :line-height="25" font-family="Inter, sans-serif">
            {{ t("quiz.camera_and_micro") }}
        </default-title>

        <template v-if="step === 1">
            <default-title
                class="mb-10 mt-10"
                :size="16"
                :weight="400"
                :text-color="'#52565C'"
                :line-height="25"
                font-family="Inter, sans-serif"
            >
                {{ t("quiz.camera_and_micro_access") }}
            </default-title>
        </template>
        <template v-if="step === 2">
            <DefaultDropdown :label="t('quiz.camera')" class="mt-20" :cases="cameras" v-model="camera" />
            <DefaultDropdown :label="t('quiz.microphone')" class="mt-20" :cases="microphones" v-model="microphone" />

            <flex-container class="mt-30">
                <DefaultButton
                    @click="$emit('cancel')"
                    background="cancel"
                    class="mr-10 inter"
                    full-width
                    :height="45"
                    :text="t('expert.cancel')"
                />
                <DefaultButton
                    class="inter"
                    @click="
                        $emit('handle', {
                            video: {
                                deviceId: camera.deviceId
                            },
                            audio: {
                                deviceId: microphone.deviceId
                            }
                        })
                    "
                    background="green"
                    full-width
                    :height="45"
                    :text="t('expert.next')"
                />
            </flex-container>
        </template>
    </modal-container>
</template>

<script>
import DefaultButton from "@components/Buttons/DefaultButton.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultDropdown from "@components/Forms/DefaultDropdown.vue"
import ProgressSteps from "@components/Progress/ProgressSteps.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import ModalContainer from "@expert-components/Modals/ModalContainer.vue"

export default {
    name: "RecordingVideoAccessModal",
    components: { DefaultButton, FlexContainer, DefaultDropdown, DefaultTitle, ProgressSteps, ModalContainer },
    data() {
        return {
            step: 1,
            devices: [],
            microphone: null,
            camera: null
        }
    },
    methods: {
        goNextStep() {
            this.step = 2
            navigator.mediaDevices.enumerateDevices().then(devices => {
                this.devices = devices
                this.$nextTick(() => {
                    if (this.cameras[0]) {
                        this.camera = this.cameras[0]
                    }
                    if (this.microphones[0]) {
                        this.microphone = this.microphones[0]
                    }
                })
            })
        }
    },
    computed: {
        cameras() {
            return this.devices.filter(device => device.kind === "videoinput")
        },
        microphones() {
            return this.devices.filter(device => device.kind === "audioinput")
        }
    }
}
</script>

<style scoped lang="sass">
.access-modal
    &::v-deep
        .default-dropdown__current
            min-height: 45px
            padding-top: 8px
            padding-bottom: 8px
</style>
