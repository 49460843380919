<template>
    <div class="spin-loader">
        <svg class="circular" viewBox="25 25 50 50">
            <circle
                class="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                :stroke-width="strokeWidth"
                :stroke-miterlimit="milterLimit"
            />
        </svg>
        <component key="style" is="style">
            {{ spinStyle }}
        </component>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: 40
        },
        strokeWidth: {
            type: Number,
            default: 4
        },
        milterLimit: {
            type: Number,
            default: 10
        },
        color: {
            type: String,
            default: "#3965ff"
        }
    },
    name: "SpinLoader",
    computed: {
        /*$green: #3965ff;
        $blue: #3965ff;
        $red: #3965ff;
        $yellow: #3965ff;
        $white: #eee;*/
        spinStyle() {
            return `
            .spin-loader {
                position: relative;
                margin: 0 auto;
                width: ${this.width}px;
            }
            @keyframes color {
                100%,
                0% {
                    stroke: ${this.color};
                }
                40% {
                    stroke: ${this.color};
                }
                66% {
                    stroke: ${this.color};
                }
                80%,
                90% {
                    stroke: ${this.color};
                }
            }
            `
        }
    }
}
</script>
<style lang="sass">
.spin-loader
    &.mt-100
        margin-top: 100px
    &:before
         content: ""
         display: block
         padding-top: 100%
    .circular
        animation: rotate 2s linear infinite
        height: 100%
        transform-origin: center center
        width: 100%
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        margin: auto

    .path
        stroke-dasharray: 1, 200
        stroke-dashoffset: 0
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite
        stroke-linecap: round

    @keyframes rotate
        100%
            transform: rotate(360deg)

    @keyframes dash
        0%
            stroke-dasharray: 1, 200
            stroke-dashoffset: 0
        50%
            stroke-dasharray: 89, 200
            stroke-dashoffset: -35px
        100%
            stroke-dasharray: 89, 200
            stroke-dashoffset: -124px
</style>
