<template>
    <transition name="notifier" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div
            v-if="active"
            ref="notifier"
            :style="stylex"
            :class="[`kw-notifier-${position}`, `kw-notifier-${color}`, { 'kw-notifier-active': active }]"
            class="custom-notification"
            @click="handleClick"
        >
            <div class="content-notifier">
                <div class="con-text-notifier">
                    <h3 v-html="title" />
                    <p v-html="text" />
                    <slot />
                </div>
                <div class="kw-icon-notifier" v-if="icon !== null">
                    <component fill="#fff" :is="icon"></component>
                </div>
            </div>
            <span :style="fillingStyle" class="filling" />
        </div>
    </transition>
</template>

<script>
export default {
    name: "CustomNotification",
    data() {
        return {
            fullWidth: false,
            icon: null,
            color: "primary",
            colorText: "rgb(255, 255, 255)",
            active: true,
            text: null,
            title: null,
            position: "bottom-right",
            time: 3000,
            cords: {
                top: null,
                left: null,
                right: null,
                bottom: null
            },
            width: 0,
            fixed: false
        }
    },
    computed: {
        fillingStyle() {
            return {
                left: this.cords.left ? "-100px" : null,
                right: this.cords.right ? "-100px" : null,
                background: this.color,
                height: `${this.width * 2}px`,
                width: `${this.width * 2}px`
            }
        },
        stylex() {
            return {
                ...this.cords,
                color: this.colorText,
                width: this.fullWidth ? `calc(100% - 14px)` : ``,
                maxWidth: this.fullWidth ? "none" : `350px`
            }
        }
    },
    created() {
        setTimeout(() => {
            this.moverNotification()
        }, 0)
        this.changeCords()
    },
    mounted() {
        setTimeout(() => {
            this.width = this.$refs.notifier.clientWidth
        }, 0)

        if (!this.fixed) {
            setTimeout(() => {
                this.close()
            }, this.time)
        }
    },
    methods: {
        handleClick() {
            this.active = false
            this.click ? this.click() : null
        },
        beforeEnter(el) {
            el.style.opacity = 0
        },
        enter(el, done) {
            el.style.opacity = 1
            done()
        },
        leave(el, done) {
            el.style.opacity = 0
            let transform = el.style.transform
            if (this.cords.left === "50%" || this.fullWidth) {
                transform += ` translateY(${this.cords.top ? "-" : ""}100%)`
            } else {
                transform += ` translateX(${this.cords.left ? "-" : ""}100%)`
            }
            el.style.transform = transform
            setTimeout(() => {
                done()
                this.moverNotification()
            }, 150)
        },
        close() {
            this.active = false
        },
        changeCords() {
            let positions = this.position.split("-")
            let search = text => {
                return positions.indexOf(text) !== -1
            }
            if (search("top")) {
                this.cords.top = "0px"
            }
            if (search("bottom")) {
                this.cords.bottom = "0px"
            }
            if (search("right")) {
                this.cords.right = "0px"
            }
            if (search("left")) {
                this.cords.left = "0px"
            }

            if (search("center")) {
                this.cords.left = "50%"
            }
        },
        moverNotification() {
            let notification = document.querySelectorAll(".kw-notifier-" + this.position)
            for (let i = 0; i < notification.length; i++) {
                let hx = 10
                for (let i2 = 0; i2 < i; i2++) {
                    hx += notification[i2].clientHeight + 6
                }
                if (this.position.search("center") === -1) {
                    if (this.position.search("top") !== -1) {
                        notification[i].style.transform = `translateY(${hx}px)`
                    } else if (this.position.search("bottom") !== -1) {
                        notification[i].style.transform = `translateY(-${hx}px)`
                    }
                }

                if (this.position.search("top") !== -1 && this.position.search("center") !== -1) {
                    notification[i].style.transform = `translate(-50%,${hx}px)`
                    notification[i].style.zIndex = 10000 - i
                }
                if (this.position.search("bottom") !== -1 && this.position.search("center") !== -1) {
                    notification[i].style.transform = `translate(-50%,-${hx}px)`
                    notification[i].style.zIndex = 10000 - i
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@use "sass:string";
@use "sass:list";
@use "sass:map";

$colors: (
    primary: rgb(31, 116, 255),
    secondary: rgb(242, 97, 34),
    danger: rgb(255, 71, 87),
    success: rgb(48, 197, 143),
    warning: rgb(255, 186, 0),
    dark: rgb(30, 30, 30),
    light: rgb(245, 245, 245)
);

@each $color, $value in $colors {
    .kw-notifier-#{$color} {
        background: $value;
        .filling {
            background: $value;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }
}

.custom-notification {
    position: fixed;
    z-index: 9999999999999;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
    max-width: 350px;
    box-shadow: 0 4px 10px rgba(128, 158, 191, 0.25);
    min-width: 200px;
    cursor: pointer;
    &:active {
        opacity: 0.8;
    }
    .content-notifier {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .con-text-notifier {
        width: 100%;
    }
    .kw-icon-notifier {
        position: relative;
        z-index: 100;
        display: block;
        padding: 5px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 6px;
        font-size: 20px;
        animation: openicon 0.4s ease;
    }
    h3,
    p {
        z-index: 100;
        position: relative;
        animation: open 0.4s ease;
    }
    h3 {
        font-size: 15px;
        padding: 7px 7px 0;
        font-family: "Ubuntu", serif;
    }
    p {
        font-size: 13px;
        padding: 7px 10px 7px 7px;
    }
    &.kw-notifier-active {
        h3,
        p {
            opacity: 1;
        }
    }
    .filling {
        display: block;
        position: absolute;
        transition: all 0.45s ease;
        z-index: 1;
        transform: translate(0, -50%);
    }
}
@keyframes open {
    0% {
        opacity: 0;
        transform: translate(0px);
    }
    100% {
        transform: translate(0px);
        opacity: 1;
    }
}
@keyframes openicon {
    0% {
        opacity: 0;
        transform: scale(0.4);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
