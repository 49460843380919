<template>
    <div class="practice-radio">
        <div :class="{ 'practice-radio-input_active': active }" class="practice-radio-input">
            <span></span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        active: {
            type: [Boolean, Number],
            default: false
        }
    },
    name: "PracticeRadio"
}
</script>

<style scoped lang="sass">
.practice-radio
    width: 20px
    height: 20px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    margin-right: 14px
    margin-top: 2px
    &.with-red
        .practice-radio-input
            border-color: #DD4141
            span
                background: #DD4141
    &.with-black
        .practice-radio-input_active
            border-color: #3E4755 !important
            span
                background: #3E4755 !important
.practice-radio-input
    width: 17px
    height: 17px
    position: relative
    border-radius: 50%
    border: 1px solid #DDDDDD
    display: grid
    place-items: center
    transition: .1s
    span
        transition: .1s
        display: block
        width: 10px
        height: 10px
        border-radius: 50%
        background: #DDDDDD
    &_active
        border-color: #15A06E !important
        span
            background: #15A06E !important
.with-blue
    .practice-radio-input
        &_active
            border-color: #3965FF !important
            span
                background: #3965FF !important
</style>
