<template>
    <div class="loader-block" :class="[{ 'loader-block_no-p': noPadding }, additionalClass]">
        <LoaderV2Icon v-if="greenColor && v2" />
        <i v-else-if="greenColor" style="display: inline-flex">
            <svg alt="loader-green" width="34" height="34" viewBox="0 0 34 34" fill="none">
                <path
                    d="M17 34C7.62619 34 0 26.3737 0 16.9999C0 16.0466 0.635514 15.4111 1.58879 15.4111C2.54206 15.4111 3.17757 16.0466 3.17757 16.9999C3.17757 24.6261 9.37385 30.8224 17 30.8224C24.6262 30.8224 30.8224 24.6261 30.8224 16.9999C30.8224 9.37374 24.6262 3.17757 17 3.17757C16.0468 3.17757 15.4112 2.54205 15.4112 1.58878C15.4112 0.635513 16.0468 0 17 0C26.3739 0 34 7.62608 34 16.9999C34 26.5326 26.3739 34 17 34Z"
                    fill="#30C58F"
                />
            </svg>
        </i>
        <i v-else-if="whiteColor" style="display: inline-flex">
            <svg alt="loader-white" width="34" height="34" viewBox="0 0 34 34" fill="none">
                <path
                    d="M17 34C7.62619 34 0 26.3737 0 16.9999C0 16.0466 0.635514 15.4111 1.58879 15.4111C2.54206 15.4111 3.17757 16.0466 3.17757 16.9999C3.17757 24.6261 9.37385 30.8224 17 30.8224C24.6262 30.8224 30.8224 24.6261 30.8224 16.9999C30.8224 9.37374 24.6262 3.17757 17 3.17757C16.0468 3.17757 15.4112 2.54205 15.4112 1.58878C15.4112 0.635513 16.0468 0 17 0C26.3739 0 34 7.62608 34 16.9999C34 26.5326 26.3739 34 17 34Z"
                    fill="#fff"
                />
            </svg>
        </i>
        <i v-else style="display: inline-flex">
            <svg alt="loader" width="26" height="26" viewBox="0 0 26 26" fill="none">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C16.2497 26 19.2209 24.8076 21.5 22.8364L19.5 20.5996C17.752 22.0961 15.4815 23 13 23C7.47715 23 3 18.5228 3 13C3 7.47715 7.47715 3 13 3C18.5228 3 23 7.47715 23 13H26Z"
                />
            </svg>
        </i>
    </div>
</template>

<script>
import LoaderV2Icon from "@icons/LoaderV2Icon"

export default {
    name: "LoaderBlock",
    components: {
        LoaderV2Icon
    },
    props: {
        greenColor: {
            type: Boolean,
            default: false
        },
        whiteColor: {
            type: Boolean,
            default: false
        },
        noPadding: {
            type: Boolean,
            default: false
        },
        v2: {
            type: Boolean,
            default: false
        },
        additionalClass: String
    }
}
</script>
<style lang="sass">
.loader-full-container
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    height: 100%
    background-color: #ffffffba
    z-index: 2
</style>
