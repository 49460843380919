
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21 7H21.75C21.75 6.75113 21.6266 6.51847 21.4205 6.37895C21.2144 6.23943 20.9525 6.21122 20.7215 6.30364L21 7ZM21 17L20.7215 17.6964C20.9525 17.7888 21.2144 17.7606 21.4205 17.6211C21.6266 17.4815 21.75 17.2489 21.75 17H21ZM15.7215 8.30364C15.3369 8.45748 15.1498 8.89396 15.3036 9.27854C15.4575 9.66313 15.894 9.85019 16.2785 9.69636L15.7215 8.30364ZM16.2785 14.3036C15.894 14.1498 15.4575 14.3369 15.3036 14.7215C15.1498 15.106 15.3369 15.5425 15.7215 15.6964L16.2785 14.3036ZM4 6.25H15V4.75H4V6.25ZM15.25 6.5V17.5H16.75V6.5H15.25ZM15 17.75H4V19.25H15V17.75ZM3.75 17.5V6.5H2.25V17.5H3.75ZM4 17.75C3.86193 17.75 3.75 17.6381 3.75 17.5H2.25C2.25 18.4665 3.0335 19.25 4 19.25V17.75ZM15.25 17.5C15.25 17.6381 15.1381 17.75 15 17.75V19.25C15.9665 19.25 16.75 18.4665 16.75 17.5H15.25ZM15 6.25C15.1381 6.25 15.25 6.36193 15.25 6.5H16.75C16.75 5.5335 15.9665 4.75 15 4.75V6.25ZM4 4.75C3.0335 4.75 2.25 5.5335 2.25 6.5H3.75C3.75 6.36193 3.86193 6.25 4 6.25V4.75ZM20.25 7V17H21.75V7H20.25ZM20.7215 6.30364L15.7215 8.30364L16.2785 9.69636L21.2785 7.69636L20.7215 6.30364ZM21.2785 16.3036L16.2785 14.3036L15.7215 15.6964L20.7215 17.6964L21.2785 16.3036Z"
            fill="white"
        />
    </svg>
</template>
<script>
export default {
    name: "CameraIcon"
}
</script>