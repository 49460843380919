<template>
    <div class="recording-video" :class="{ 'recording-video_full': buttonView }">
        <button
            @click="
                handleRecord({
                    video: true,
                    audio: true
                })
            "
            :class="{ 'button-view': buttonView }"
            class="recording-video-handler"
        >
            <CameraIcon />

            <default-title class="ml-15" :weight="500" :size="15" :text-color="'#3965FF'" v-if="buttonView">
                {{ t("quiz.record_video") }}
            </default-title>
        </button>

        <transition name="fade">
            <modal-overlay black v-show="isDisplayedModal">
                <RecordingVideoAccessModal
                    @cancel="
                        modals.access = false
                        $forceUpdate()
                    "
                    @handle="handleRecord($event)"
                    ref="access"
                    v-if="modals.access"
                />

                <RecordingVideoIsUseModal
                    @handle="handleRecord($event)"
                    @cancel="modals.isUse = false"
                    v-else-if="modals.isUse"
                />

                <RecordingVideoNotFoundModal
                    @handle="handleRecord($event)"
                    @cancel="modals.notFound = false"
                    v-else-if="modals.notFound"
                />

                <RecordingVideoDeniedModal
                    @handle="handleRecord($event)"
                    @cancel="modals.denied = false"
                    v-else-if="modals.denied"
                />

                <transition name="fade-up">
                    <modal-overlay black v-if="modals.saving">
                        <RecordingVideoSavingModal ref="saving" />
                    </modal-overlay>
                </transition>

                <div class="video-modal" v-show="modals.loading">
                    <div class="relative">
                        <video width="100%" height="auto" autoplay muted></video>
                    </div>
                </div>

                <div class="video-modal" v-show="modals.recording">
                    <div class="relative">
                        <div v-if="time" class="video-timer">
                            {{ formatTimer(time) }}
                        </div>

                        <video playsinline ref="preview" width="100%" height="auto" autoplay muted></video>

                        <button
                            v-if="time && time > 1000"
                            class="stop-video-handler"
                            :class="{ 'pointer-events-none': modals.saving }"
                            @click="stopRecord"
                        ></button>
                    </div>
                </div>
            </modal-overlay>
        </transition>
    </div>
</template>

<script>
import RecordingVideoIsUseModal from "@components/Recording/components/RecordingVideoIsUseModal.vue"
import RecordingVideoNotFoundModal from "@components/Recording/components/RecordingVideoNotFoundModal.vue"
import RecordingVideoAccessModal from "@components/Recording/components/RecordingVideoAccessModal.vue"
import RecordingVideoDeniedModal from "@components/Recording/components/RecordingVideoDeniedModal.vue"
import RecordingVideoSavingModal from "@components/Recording/components/RecordingVideoSavingModal.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import ModalOverlay from "@expert-components/Modals/ModalOverlay.vue"
import CameraIcon from "@icons/CameraIcon.vue"
import { v4 } from "uuid"
import axios from "~axios"

export default {
    props: {
        buttonView: {
            type: Boolean,
            default: false
        }
    },
    name: "RecordingVideo",
    components: {
        DefaultTitle,
        CameraIcon,
        RecordingVideoIsUseModal,
        RecordingVideoSavingModal,
        ModalOverlay,
        RecordingVideoNotFoundModal,
        RecordingVideoDeniedModal,
        RecordingVideoAccessModal
    },
    data() {
        return {
            modals: {
                access: false,
                denied: false,
                recording: false,
                notFound: false,
                isUse: false,
                saving: false,
                loading: false
            },
            recorder: null,
            stream: null,
            interval: null,
            time: 0
        }
    },
    methods: {
        async handleRecord(
            constraints = {
                video: true,
                audio: true
            }
        ) {
            this.modals.loading = true

            if (await this.checkConstrains()) {
                return
            }

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(this.handleVideoStream)
                .then(stream => this.startRecording(stream))
                .then(this.handleChunks)
                .catch(this.handleError)
        },
        async checkConstrains() {
            let result = null

            try {
                result = await navigator.permissions.query({ name: "camera" })
            } catch (e) {
                result = null
            }

            if (!result) {
                /*this.$notify({
                    position: "top-right",
                    color: "danger",
                    time: 5000,
                    title: this.t("expert.something_went_wrong"),
                    text: "return",
                    icon: BugIcon
                })*/
                return false
            }

            navigator.mediaDevices
                .getUserMedia({
                    video: true,
                    audio: true
                })
                .then(() => {
                    if (this.$refs.access) {
                        this.$refs.access.goNextStep()
                    }
                    return new Promise((resolve, reject) => reject("withAccess"))
                })
                .catch(this.handleConstrainsError)

            if (result.state === "prompt") {
                this.modals.loading = false
                this.modals.access = true
                return true
            } else if (result.state === "denied") {
                this.modals.loading = false
                this.modals.denied = true
            }

            return false
        },
        handleVideoStream(stream) {
            this.modals.loading = false

            const preview = this.$refs.preview

            this.hideModals()
            this.modals.recording = true

            preview.srcObject = stream
            preview.captureStream = preview.captureStream || preview.mozCaptureStream

            return new Promise(resolve => {
                preview.onplaying = () => resolve(stream)
            })
        },
        startRecording(stream) {
            let recorder = new MediaRecorder(stream)

            if (this.interval) {
                clearInterval(this.interval)
                this.time = 0
            }

            this.time += 1000

            this.interval = setInterval(() => {
                this.time += 1000
            }, 1000)

            this.stream = stream
            this.recorder = recorder

            let data = []

            recorder.addEventListener("dataavailable", event => {
                data.push(event.data)
            })

            recorder.start()

            let stopped = new Promise((resolve, reject) => {
                recorder.addEventListener("stop", resolve)
                recorder.addEventListener("error", event => reject(event.name))
            })

            return Promise.all([stopped]).then(() => data)
        },
        handleChunks(recordedChunks) {
            this.$refs.preview.pause()
            this.modals.saving = true

            if (this.interval) {
                clearInterval(this.interval)
                this.time = 0
            }

            this.$nextTick(() => {
                let recordedBlob = new Blob(recordedChunks, { type: "video/mp4" })

                const url = window.file_storage_url
                const name = `${v4().slice(0, 12)}.mp4`

                const formData = new FormData()

                formData.append("file", recordedBlob, name)

                if (window.user) {
                    const { id, id_hash } = window.user
                    formData.append("user_id", id)
                    formData.append("user_id_hash", id_hash)
                }

                if (window.current_cabinet_id) {
                    formData.append("cabinet_id", window.current_cabinet_id)
                }

                this.$refs.saving.setName(name)

                axios
                    .post(url, formData, {
                        onUploadProgress: progressEvent => {
                            const percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            this.$refs.saving.setProgress(percent)
                        },
                        params: { model: "comment" }
                    })
                    .then(response => {
                        const file = response.data.data
                        let checkStatus = setInterval(() => {
                            const { uuid } = file
                            axios.get(`/files/${uuid}`).then(response => {
                                let data = response.data.data
                                if (data.process_status === "completed" && !data.url.includes("fileservice")) {
                                    file.url = data.url

                                    clearInterval(checkStatus)

                                    this.hideModals()
                                    this.$emit("save", file)
                                }
                            })
                        }, 2000)
                    })
                    .catch(() => {
                        /*this.$notify({
                            position: "top-right",
                            color: "danger",
                            time: 5000,
                            title: this.t("expert.something_went_wrong"),
                            text: e,
                            icon: BugIcon
                        })*/
                        this.hideModals()
                    })
                    .finally(() => {
                        if (this.stream) {
                            this.stream.getTracks().forEach(track => track.stop())
                        }
                    })
            })
        },
        handleError(error) {
            this.modals.access = false
            this.modals.loading = false
            this.modals.denied = false

            /*this.$notify({
                position: "top-right",
                color: "danger",
                time: 5000,
                title: this.t("expert.something_went_wrong"),
                text: error,
                icon: BugIcon
            })*/

            if (error.name === "NotFoundError") {
                this.modals.notFound = true
            } else if (error.name === "NotReadableError") {
                this.modals.isUse = true
            } else {
                this.modals.denied = true
            }
        },
        handleConstrainsError(error) {
            if (error !== "withAccess") {
                this.modals.access = false
            }

            this.modals.denied = false

            if (error) {
                if (error.name === "NotFoundError") {
                    this.modals.notFound = true
                } else if (error.name === "NotReadableError") {
                    this.modals.isUse = true
                } else if (error !== "withAccess") {
                    this.modals.denied = true
                }
            }
        },
        wait(delayInMS) {
            return new Promise(resolve => setTimeout(resolve, delayInMS))
        },
        stop(stream) {
            if (this.recorder) {
                this.recorder.stop()
            } else {
                stream.getTracks().forEach(track => track.stop())
            }
        },
        stopRecord() {
            this.stop(this.$refs.preview.srcObject)
        },
        hideModals() {
            this.modals.isUse =
                this.modals.loading =
                this.modals.saving =
                this.modals.notFound =
                this.modals.access =
                this.modals.recording =
                this.modals.denied =
                    false
        },
        formatTimer(t) {
            let time = new Date(t).toISOString().slice(14, 19)

            if (time[0] === "0") {
                time = time.slice(1, 5)
            }

            return time
        }
    },
    beforeDestroy() {
        if (this.interval) {
            clearInterval(this.interval)
            this.time = 0
        }
    },
    computed: {
        isDisplayedModal() {
            return (
                this.modals.loading ||
                this.modals.access ||
                this.modals.denied ||
                this.modals.recording ||
                this.modals.notFound ||
                this.modals.saving ||
                this.modals.isUse
            )
        }
    },
    watch: {
        isDisplayedModal(val) {
            if (val) {
                document.body.classList.add("body-overlay")
            } else {
                document.body.classList.remove("body-overlay")
            }
        }
    }
}
</script>

<style scoped lang="sass">
.video-modal
    position: fixed
    top: 120px
    border-radius: 10px
    padding: 20px
    background-color: #fff
    max-width: 1000px
    min-width: 440px
    transform: translateX(-50%)
    left: 50%
    video
        position: relative
        z-index: 1
        transform-style: preserve-3d
        background: url(@images/portal/Rolling-1.4s-198px.gif) no-repeat center
        background-size: 55px

    @media (max-width: 460px)
        width: 100%
        min-width: 0
        padding: 10px !important
        max-width: calc(100vw - 20px) !important
.recording-video
    max-width: 460px
    &::v-deep
        @media (max-width: 460px)
            .modal-container
                min-width: calc(100vw - 20px) !important
                padding: 10px !important
                max-width: calc(100vw - 20px) !important
.recording-video-handler
    width: 30px
    height: 30px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 4px
    background-color: #fff
    transition: .2s
    z-index: 2
    @media screen and (max-width: 640px)
        width: 24px
        height: 24px
    &:hover
        background-color: #F2F7FF
    &::v-deep
        path
            fill: #3E4755
    &.button-view
        width: 100%
        &::v-deep
            path
                fill: #3965FF
.video-timer
    top: 35px
    align-items: center
    justify-content: center
    position: absolute
    transform: translateX(-50%)
    left: 50%
    background-color: #DD4141B2
    padding: 0 10px
    border-radius: 4px
    font-size: 16px
    font-weight: 600
    color: #fff
    line-height: 25px
    font-family: Inter, sans-serif
    z-index: 2
    @media (max-width: 480px)
        top: 10px
.stop-video-handler
    width: 64px
    height: 40px
    background-color: #FFFFFF
    border-radius: 100px
    opacity: .8
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    bottom: 62px
    z-index: 2
    transition: .2s
    transform: translateX(-50%)
    left: 50%
    @media (max-width: 480px)
        bottom: 10px
    &:hover
        opacity: 1
    &::before
        content: ''
        width: 12px
        height: 12px
        background-color: #DD4141
</style>
