
<template>
    <svg
        :class="$hasAttr('hovered')"
        class="icon icon-fill"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.75 6C18.75 5.58579 18.4142 5.25 18 5.25C17.5858 5.25 17.25 5.58579 17.25 6H18.75ZM6.75 6C6.75 5.58579 6.41421 5.25 6 5.25C5.58579 5.25 5.25 5.58579 5.25 6H6.75ZM14.25 6C14.25 6.41421 14.5858 6.75 15 6.75C15.4142 6.75 15.75 6.41421 15.75 6H14.25ZM8.25 6C8.25 6.41421 8.58579 6.75 9 6.75C9.41421 6.75 9.75 6.41421 9.75 6H8.25ZM4 5.25C3.58579 5.25 3.25 5.58579 3.25 6C3.25 6.41421 3.58579 6.75 4 6.75V5.25ZM20 6.75C20.4142 6.75 20.75 6.41421 20.75 6C20.75 5.58579 20.4142 5.25 20 5.25V6.75ZM17.25 6V18H18.75V6H17.25ZM16 19.25H8V20.75H16V19.25ZM6.75 18V6H5.25V18H6.75ZM8 19.25C7.30964 19.25 6.75 18.6904 6.75 18H5.25C5.25 19.5188 6.48122 20.75 8 20.75V19.25ZM17.25 18C17.25 18.6904 16.6904 19.25 16 19.25V20.75C17.5188 20.75 18.75 19.5188 18.75 18H17.25ZM15.75 6V5H14.25V6H15.75ZM13 2.25H11V3.75H13V2.25ZM8.25 5V6H9.75V5H8.25ZM11 2.25C9.48122 2.25 8.25 3.48122 8.25 5H9.75C9.75 4.30964 10.3096 3.75 11 3.75V2.25ZM15.75 5C15.75 3.48122 14.5188 2.25 13 2.25V3.75C13.6904 3.75 14.25 4.30964 14.25 5H15.75ZM4 6.75H20V5.25H4V6.75Z"
        />
    </svg>
</template>
<script>
export default {
    name: "DeleteIcon"
}
</script>
<style lang="sass" scoped>
.hovered
    cursor: pointer
    transition: .2s
    &:hover
        fill: #DD4141
.hovered-red
    fill: #EB6565
</style>
