<template>
    <div
        v-if="langsKeys"
        :style="{ opacity: shouldDisplay ? 1 : 0 }"
        :class="typedClass('lesson-links')"
        class="lesson-links full-width"
    >
        <flex-container v-if="links.find(link => !link.isHidden)" :justify="justify" class="relative full-width">
            <a
                v-for="(target, index) in links"
                :key="index"
                :href="target.isDisabled ? 'javascript:void(0)' : target.link || 'javascript:void(0)'"
                class="lesson-link"
                :class="{
                    'show-tooltip-on-hover': target.tooltip,
                    'show-button-tooltip': isTypeOne(),
                    'lesson-link_hidden': target.isHidden,
                    [typedClass('lesson-link')]: true,
                    [typedClass('lesson-link') + '_disabled']: target.isDisabled
                }"
            >
                <template v-if="isTypeOne()">
                    <ArrowRightIcon class="arrow-icon absolute" :class="{ prev: !index }" />
                    <span v-if="$screenWidth > viewports.sMobile">
                        {{ target.title }}
                    </span>
                    <div v-if="target.tooltip" class="button-tooltip" v-html="target.tooltip"></div>
                </template>

                <template v-else>
                    <ArrowRightLargeIcon class="arrow-icon" :class="{ prev: !!index }" />
                    <flex-container
                        class="lesson-link-content"
                        justify="flex-end"
                        :direction="!index ? 'row' : 'row-reverse'"
                    >
                        <div>
                            <p class="lesson-link-title">
                                {{ target.title }}
                            </p>
                            <p v-if="target.desription" class="lesson-link-description">
                                {{ target.desription }}
                            </p>
                        </div>
                        <flex-container
                            v-if="target.image || target.tooltip"
                            :direction="!!index ? 'row' : 'row-reverse'"
                        >
                            <img class="lesson-link-image" v-if="target.image" :src="target.image" alt="" />
                            <div class="relative mr-10 ml-10">
                                <LockIcon v-if="target.tooltip" width="20" height="20" class="lock-icon" />
                                <TooltipOnHover>
                                    <div v-html="target.tooltip"></div>
                                </TooltipOnHover>
                            </div>
                        </flex-container>
                    </flex-container>
                </template>
            </a>
        </flex-container>
    </div>
</template>

<script>
import TooltipOnHover from "@components/Tooltips/TooltipOnHover.vue"
import ArrowRightLargeIcon from "@icons/ArrowRightLargeIcon.vue"
import LockIcon from "@icons/LockIcon.vue"
import KeyTranslator from "@mixins/keyTranslator"
import ArrowRightIcon from "@icons/ArrowRightIcon.vue"
import formatDate from "@mixins/formatDate"
import tooltipOwn from "@mixins/tooltipOwn"
import { EventBus } from "~events"

export default {
    name: "LessonNavButtons",
    components: {
        TooltipOnHover,
        LockIcon,
        ArrowRightLargeIcon,
        ArrowRightIcon,
        FlexContainer: () => import("@components/Containers/FlexContainer.vue")
    },
    mixins: [KeyTranslator, formatDate, tooltipOwn],
    props: {
        l: {
            type: Object,
            required: false
        },
        lesson: {
            type: Object,
            required: false
        },
        type: {
            type: Number,
            required: false,
            default: 1
        },
        paginateInfo: {
            type: Object,
            required: false
        }
    },
    data: () => ({
        langsKeys: null,
        prevLesson: null,
        noPrevLesson: false,
        nextLesson: null,
        noNextLesson: false,

        prevSection: null,
        noPrevSection: false,
        nextSection: null,
        noNextSection: false,

        shouldDisplay: true,

        viewports: {
            tablet: 1300,
            mobile: 640,
            sMobile: 570
        },

        links: []
    }),
    created() {
        if (!this.paginateInfo && window.paginate_info) {
            this.paginateInfo = window.paginate_info
        }

        if (!this.lesson && window.paginate_lesson) {
            this.lesson = window.paginate_lesson
        }
        this.getLangKeys()
    },
    async mounted() {
        EventBus.$on("set:paginate", paginateInfo => {
            this.setPaginate(paginateInfo)
        })

        setTimeout(() => {
            if (this.$el && this.$el.closest && this.$el.closest(`#navButtons`) && !this.hasOverflow()) {
                this.shouldDisplay = false
            }
        }, 0)
    },
    computed: {
        justify() {
            return this.$screenWidth > this.viewports.tablet
                ? "center"
                : this.$screenWidth < this.viewports.sMobile
                ? "space-between"
                : "center"
        },
        prevLessonTooltip() {
            return this.handleTooltip(this.prevLesson)
        },
        nextLessonTooltip() {
            return this.handleTooltip(this.nextLesson)
        },
        prevSectionTooltip() {
            return null
            //return this.handleTooltip(this.prevSection)
        },
        nextSectionTooltip() {
            return null
            //return this.handleTooltip(this.nextSection)
        }
    },
    methods: {
        hasOverflow() {
            const el = document.querySelector(".course__section.course__shadow")

            if (!el) {
                return true
            } else {
                return el.getBoundingClientRect().height + 200 > window.innerHeight
            }
        },
        setPaginate(paginateInfo) {
            if (!paginateInfo) {
                return
            }

            paginateInfo.previous_lesson ? (this.prevLesson = paginateInfo.previous_lesson) : (this.noPrevLesson = true)

            paginateInfo.next_lesson ? (this.nextLesson = paginateInfo.next_lesson) : (this.noNextLesson = true)

            paginateInfo.previous_section
                ? (this.prevSection = paginateInfo.previous_section)
                : (this.noPrevSection = true)

            paginateInfo.next_section ? (this.nextSection = paginateInfo.next_section) : (this.noNextSection = true)

            this.setLinks()

            this.$forceUpdate()
        },
        isTypeOne() {
            return this.type === 1
        },
        typedClass(base) {
            return `${base}_type_${this.type}`
        },
        setLinks() {
            const links = []

            if (this.prevSection) {
                const section = {
                    ...this.prevSection,
                    isDisabled: this.prevSectionTooltip,
                    isHidden: !this.prevSection && !this.isTypeOne(),
                    title: this.langsKeys
                        ? this.t(
                              `create-course.prev_section${this.$screenWidth < this.viewports.mobile ? "_short" : ""}`
                          )
                        : "",
                    tooltip: this.prevSectionTooltip
                }

                if (!this.isTypeOne() && this.prevSection) {
                    section.image = this.prevSection?.preview?.url
                    section.title = this.prevSection.title
                    section.desription = this.prevSection.sub_title
                }

                links.push(section)
            } else {
                const lesson = {
                    ...this.prevLesson,
                    isHidden: !this.prevLesson && !this.isTypeOne(),
                    isDisabled: this.prevLessonTooltip,
                    title: this.langsKeys
                        ? this.t(
                              `create-course.prev_lesson${this.$screenWidth < this.viewports.mobile ? "_short" : ""}`
                          )
                        : "",
                    tooltip: this.prevLessonTooltip
                }

                if (!this.isTypeOne() && this.prevLesson) {
                    lesson.image = this.prevLesson?.preview?.url
                    lesson.title = this.prevLesson.title
                    lesson.desription = this.prevLesson.sub_title
                }

                links.push(lesson)
            }

            if (this.nextSection) {
                const section = {
                    ...this.nextSection,
                    isHidden: !this.nextSection && !this.isTypeOne(),
                    isDisabled: this.nextSectionTooltip,
                    title: this.langsKeys
                        ? this.t(
                              `create-course.next_section${this.$screenWidth < this.viewports.mobile ? "_short" : ""}`
                          )
                        : "",
                    tooltip: this.nextSectionTooltip
                }

                if (!this.isTypeOne() && this.nextSection) {
                    section.image = this.nextSection?.preview?.url
                    section.title = this.nextSection.title
                    section.desription = this.nextSection.sub_title
                }

                links.push(section)
            } else {
                const lesson = {
                    ...this.nextLesson,
                    isHidden: !this.nextLesson && !this.isTypeOne(),
                    isDisabled: this.nextLessonTooltip,
                    title: this.langsKeys
                        ? this.t(
                              `create-course.next_lesson${this.$screenWidth < this.viewports.mobile ? "_short" : ""}`
                          )
                        : "",
                    tooltip: this.nextLessonTooltip
                }

                if (!this.isTypeOne() && this.nextLesson) {
                    lesson.image = this.nextLesson?.preview?.url
                    lesson.title = this.nextLesson.title
                    lesson.desription = this.nextLesson.sub_title
                }

                links.push(lesson)
            }

            this.links = links
        },
        getLangKeys() {
            if (this.l) {
                this.langsKeys = this.l
                this.setPaginate(this.paginateInfo)
                this.$forceUpdate()

                return
            }
            if (window.langs) {
                this.langsKeys = window.langs
                this.setPaginate(this.paginateInfo)
                this.$forceUpdate()
                return
            }

            let timer = null
            timer = setInterval(() => {
                if (window.langs) {
                    this.langsKeys = window.langs
                    this.setPaginate(this.paginateInfo)
                    this.$forceUpdate()
                    clearInterval(timer)
                }
            }, 1000)
        },
        handleTooltip(lesson) {
            const createDetails = info => {
                if (info.link) {
                    return `${info.checkpoint_name} <a href="${info.link}">${this.t("expert.card.move_1")} →</a>`
                }
                return info.checkpoint_name
            }

            if ((lesson && !lesson.closed_info.is_accessible) || (lesson && !lesson.blocked_info.is_accessible)) {
                if (lesson.blocked_info.access_date && lesson.blocked_info.checkpoint_name) {
                    return this.t("create-course.access_open_on_date_after_practice")
                        .replace(":date", this.formatDateWithTzName(lesson.blocked_info.access_date))
                        .replace(":practice", createDetails(lesson.blocked_info))
                } else if (lesson.closed_info.access_date) {
                    return this.t("create-course.access_closed_after_date").replace(
                        ":date",
                        this.formatDateWithTzName(lesson.closed_info.access_date)
                    )
                } else if (lesson.blocked_info.access_date) {
                    return this.t("create-course.access_open_after_date").replace(
                        ":date",
                        this.formatDateWithTzName(lesson.blocked_info.access_date)
                    )
                } else if (lesson.blocked_info.checkpoint_name) {
                    return this.t("create-course.access_open_after_practice").replace(
                        ":practice",
                        createDetails(lesson.blocked_info)
                    )
                }
            } else {
                return null
            }
        }
    }
}
</script>

<style lang="sass" scoped>
@import "../../../../sass/vars"

$svgButtonPadding: 20px

.show-tooltip-on-hover
    &:hover
        .tooltip-on-hover
            pointer-events: all

.tooltip-on-hover
    font-family: Inter, sans-serif
    &::v-deep
        a
            font-weight: 500
            text-underline-offset: 3px
            color: #3965FF

.lock-icon
    transition: .2s
    cursor: pointer
    &:hover
        fill: #DD4141

.lesson-link-content
    max-width: 100%
    flex: 1
    & > div
        max-width: calc(100% - 60px)

.mr-10
    margin-right: 10px

.lesson-links
    @media (min-width: 1300px)
        max-width: calc(100vw - 410px)
    &_type
        &_1
            margin-left: 40px
            @media (max-width: 1367px)
                margin: 0 auto 20px

    .lesson-link
        &_hidden
            opacity: 0
            pointer-events: none
        &_type
            &_1
                position: relative
                display: flex
                align-items: center
                justify-content: center
                width: 250px
                height: 46px
                padding: 0 20px
                color: $accent-color5
                background: #fff
                border: 1px solid $accent-color5
                border-radius: 8px
                box-shadow: $default-shadow
                text-decoration: none
                transition: $transitionTime
                .arrow-icon
                    right: $svgButtonPadding
                    transition: $transitionTime
                    fill: $accent-color5
                &:last-child
                    margin-left: 10px
                @media (max-width: 570px)
                    width: 52px
                    height: 52px
                    span
                        display: none
                    &:last-child
                        margin-left: 20px
                &:hover
                    color: #fff
                    background: $accent-color5
                    .arrow-icon
                        fill: #fff
                &_disabled
                    color: $accent-color6
                    border-color: $accent-color6
                    .arrow-icon
                        fill: $accent-color6
                    &:hover
                        color: $accent-color6
                        background: #fff
                        border-color: $accent-color6
                        cursor: default
                        .arrow-icon
                            fill: $accent-color6
            &_2
                display: flex
                align-items: center
                justify-content: flex-start
                width: 50%
                height: 80px
                padding: 0 70px
                font-family: Inter, sans-serif
                background: #F4F8FD
                text-decoration: none
                transition: $transitionTime
                line-height: 130%
                white-space: nowrap
                text-align: left

                .arrow-icon
                    min-width: 40px
                    transition: $transitionTime
                    margin-right: 20px
                    @media (max-width: 961px)
                        margin-right: 20px
                    @media (max-width: 521px)
                        min-width: 24px
                        width: 24px
                        margin-right: 10px
                    @media (max-width: 480px)
                        min-width: 40px
                        width: 40px
                        margin: 0 auto !important
                .lesson-link-image
                    height: 20px
                    width: auto
                    margin-left: 10px
                    border-radius: 4px
                    @media (max-width: 480px)
                        display: none
                .lesson-link-description
                    color: #98A2B0
                    font-size: 12px
                    font-weight: 500
                .lesson-link-title
                    color: #323030
                    font-size: 14px
                    font-weight: 500
                .lesson-link-title, .lesson-link-description
                    text-overflow: ellipsis
                    max-width: 100%
                    overflow: hidden
                    @media (max-width: 480px)
                        display: none
                &:first-child
                    text-align: right
                    justify-content: flex-end
                    border-right: 1px solid #fff
                    .lesson-link-title, .lesson-link-description
                        direction: ltr
                &:last-child
                    .lesson-link-image
                        margin-left: 0
                        margin-right: 10px
                    .arrow-icon
                        margin-right: 0
                        margin-left: 20px
                        order: 2
                        @media (max-width: 961px)
                            margin-left: 20px
                        @media (max-width: 521px)
                            margin-left: 10px
                @media (max-width: 1301px)
                    padding: 0 20px
                    &:first-child
                        padding-left: 70px
                        padding-right: 10px
                    &:last-child
                        padding-right: 70px
                        padding-left: 10px
                @media (max-width: 480px)
                    height: 40px

                &:hover
                    background: #E8F1FF
                    .arrow-icon
                        stroke: $accent-color5
                &_disabled
                    background-color: #F3F3F3
                    .arrow-icon, .lesson-link-image, .lesson-link-description, .lesson-link-title
                        opacity: .4
                    &:hover
                        .arrow-icon
                            stroke: $accent-color6
                        background-color: #F3F3F3
                        cursor: default
    .prev
        left: $svgButtonPadding
        transform: rotate(180deg)

    .show-button-tooltip
        position: relative
        &:hover
            .button-tooltip
                opacity: 1
    .button-tooltip
        position: absolute
        top: -10px
        transform: translateY(-100%)
        background-color: #fff
        white-space: nowrap
        border-radius: 8px
        font-size: 12px
        line-height: 130%
        color: $text-accent-color
        padding: 6px 14px
        box-shadow: 0 2px 15px rgba(128, 158, 191, 0.25)
        z-index: 1
        pointer-events: none
        opacity: 0
        transition: $transitionTime
        margin-left: 0 !important
        &:after
            content: ""
            position: absolute
            top: 100%
            left: 50%
            transform: translateX(-50%)
            border: 7px solid transparent
            border-top-color: #fff
</style>

<style lang="sass">
.lesson-loader
    padding: 0 !important
    height: 46px !important
</style>
