/* Copyright 2013 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

function stringToUTF8String(str) {
    return decodeURIComponent(escape(str))
}

function _isValidProtocol(url) {
    switch (url?.protocol) {
        case "http:":
        case "https:":
        case "ftp:":
        case "mailto:":
        case "tel:":
            return true
        default:
            return false
    }
}

function createValidAbsoluteUrl(url, baseUrl = null, options = null) {
    if (!url) {
        return null
    }
    try {
        if (options && typeof url === "string") {
            // Let URLs beginning with "www." default to using the "http://" protocol.
            if (options.addDefaultProtocol && url.startsWith("www.")) {
                const dots = url.match(/\./g)
                // Avoid accidentally matching a *relative* URL pointing to a file named
                // e.g. "www.pdf" or similar.
                if (dots?.length >= 2) {
                    url = `http://${url}`
                }
            }

            // According to ISO 32000-1:2008, section 12.6.4.7, URIs should be encoded
            // in 7-bit ASCII. Some bad PDFs use UTF-8 encoding; see bug 1122280.
            if (options.tryConvertEncoding) {
                try {
                    url = stringToUTF8String(url)
                    // eslint-disable-next-line no-empty
                } catch {}
            }
        }

        const absoluteUrl = baseUrl ? new URL(url, baseUrl) : new URL(url)
        if (_isValidProtocol(absoluteUrl)) {
            return absoluteUrl
        }
    } catch {
        /* `new URL()` will throw on incorrect data. */
    }
    return null
}

/** @typedef {import("./interfaces").IDownloadManager} IDownloadManager */

function download(blobUrl, filename) {
    const a = document.createElement("a")
    if (!a.click) {
        throw new Error('DownloadManager: "a.click()" is not supported.')
    }
    a.href = blobUrl
    a.target = "_parent"
    // Use a.download if available. This increases the likelihood that
    // the file is downloaded instead of opened by another PDF plugin.
    if ("download" in a) {
        a.download = filename
    }
    // <a> must be in the document for recent Firefox versions,
    // otherwise .click() is ignored.
    document.body.append(a)
    a.click()
    a.remove()
}

/**
 * @implements {IDownloadManager}
 */
class DownloadManager {
    #openBlobUrls = new WeakMap()

    downloadData(data, filename, contentType) {
        const blobUrl = URL.createObjectURL(new Blob([data], { type: contentType }))
        download(blobUrl, filename)
    }

    download(data, url, filename) {
        let blobUrl
        if (data) {
            blobUrl = URL.createObjectURL(new Blob([data], { type: "application/pdf" }))
        } else {
            if (!createValidAbsoluteUrl(url, "http://example.com")) {
                console.error(`download - not a valid URL: ${url}`)
                return
            }
            blobUrl = url + "#pdfjs.action=download"
        }
        download(blobUrl, filename)
    }
}

export { DownloadManager }
