<script>
import CodeComponent from "./renders/CodeComponent.vue"
import RenderHtml from "./RenderHtml.vue"
import FileComponent from "./renders/FileComponent.vue"
import HtmlComponent from "./renders/HtmlComponent.vue"
import TooltipComponent from "./renders/TooltipComponent.vue"

export default {
    components: {
        HtmlComponent,
        FileComponent,
        CodeComponent,
        TooltipComponent
    },
    ...RenderHtml
}
</script>
