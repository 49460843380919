<template>
    <div v-if="getAttemptsCases().length" class="prev-comments mt-15 mb-10">
        <!--        <practice-blue-alert>-->
        <!--            <DefaultDropdown-->
        <!--                ref="dropdown"-->
        <!--                :cases="getAttemptsCases()"-->
        <!--                scroll-active-->
        <!--                @update="setAttempt($event)"-->
        <!--                :active-case="attemptComment"-->
        <!--                class="inline-block versions-dropdown"-->
        <!--            >-->
        <!--                <template #handler>-->
        <!--                    <flex-container>-->
        <!--                        <a-->
        <!--                            style="text-underline-offset: 3px"-->
        <!--                            class="text-blue cursor-pointer"-->
        <!--                            @click="$refs.dropdown.show()"-->
        <!--                        >-->
        <!--                            <template v-if="!attemptComment"> {{ t("quiz.prev_comments_handler") }} → </template>-->
        <!--                            <template v-else>-->
        <!--                                {{ t("quiz.prev_comments_comment") }} {{ format(attemptComment.finished_at) }}-->
        <!--                            </template>-->
        <!--                        </a>-->
        <!--                        <CloseButtonSm v-if="attemptComment" @close="attemptComment = null" class="ml-10" />-->
        <!--                    </flex-container>-->
        <!--                </template>-->
        <!--            </DefaultDropdown>-->
        <!--        </practice-blue-alert>-->

        <div class="mt-10" v-if="quiz.attempt_comments && quiz.attempt_comments.length">
            <practice-comment :quiz="quiz"></practice-comment>
        </div>
    </div>
</template>

<script>
import CloseButtonSm from "@components/Buttons/CloseButtonSm.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultDropdown from "@components/Forms/DefaultDropdown.vue"
import PracticeBlueAlert from "@components/Practice/common/alerts/PracticeBlueAlert.vue"
import PracticeComment from "@components/Practice/common/typography/PracticeComment.vue"
import moment from "moment-timezone"

export default {
    props: {
        quiz: {
            type: Object,
            default: null
        }
    },
    name: "PracticeQuizPrevComments",
    components: { FlexContainer, CloseButtonSm, PracticeComment, PracticeBlueAlert, DefaultDropdown },
    data() {
        return {
            attemptComment: null
        }
    },
    methods: {
        setAttempt(attempt) {
            this.attemptComment = attempt
        },
        format(date) {
            return moment(date).format("DD MMMM, YYYY HH:mm")
        },
        getAttemptsCases() {
            const attempts = [...this.quiz.attempts ?? []]
                .filter(a => a.finished_at)
                .filter(a => a.comment || (a.comment_files && a.comment_files.length))
                .map(a => ({
                    id: a.id,
                    name: a.finished_at ? moment(a.finished_at).format("DD MMMM, YYYY HH:mm") : "-",
                    comment: a.comment,
                    finished_at: a.finished_at,
                    comment_files: a.comment_files,
                    checking: a.checking,
                    checked_at: a.checked_at
                }))

            return attempts
        }
    }
}
</script>

<style lang="sass" scoped>
.prev-comments
    .comment-wrapper
        border: 1px solid #D6E351
        background-color: #FEFFF4
        margin-top: 10px
        padding: 10px
    &::v-deep
        .default-dropdown__modal
            top: 30px
</style>
