<template>
    <shadow-container
        class="hide-border-right-radius"
        :class="{ 'mt-20': item.info_unitable_type === 'course' && index === 0 }"
    >
        <h4 v-if="item.title" class="course__content_summary__title bold">
            {{ item.title }}
        </h4>
        <div v-if="item.description_formatted">
            <ExtendedRenderHtml :html="createTextView()" />
        </div>

        <files-new-view
            v-if="item.files && item.files.length"
            :files="item.files"
            style="margin-top: 10px"
        ></files-new-view>
    </shadow-container>
</template>

<script>
import FilesNewView from "@components/Files/FilesNewView"
import ImageEnlargable from "@mixins/image-enlargable"
import { ExternalLoader } from "@helpers"
import ShadowContainer from "@components/Containers/ShadowContainer.vue"
import ExtendedRenderHtml from "@components/ExtendedRender/ExtendedRenderHtml.vue"
import renderMathInElement from "katex/contrib/auto-render"
import "katex/dist/katex.min.css"

export default {
    name: "CoursePublicUnitsText",
    components: { ExtendedRenderHtml, ShadowContainer, FilesNewView },
    mixins: [ImageEnlargable],
    props: {
        index: {
            type: Number,
            required: true
        },
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        createTextView() {
            const div = document.createElement("pre")
            div.className = "text-view"

            const hasTable = this.item.description_formatted && this.item.description_formatted.includes("table")

            div.innerHTML = this.item.description_formatted

            if (hasTable) {
                div.classList.add("with-table")
            }

            renderMathInElement(div, {
                // customised options
                // • auto-render specific keys, e.g.:
                delimiters: [{ left: "{{", right: "}}", display: false }],
                // • rendering keys, e.g.:
                throwOnError: false
            })

            return div.outerHTML
        },
        htmlDecode(input) {
            if (input.includes("iframe")) {
                return input
            }
            let doc = new DOMParser().parseFromString(input, "text/html")
            return doc.documentElement.textContent
        }
    },
    mounted() {
        ExternalLoader.STYLE(["/build/css/quill.snow.css"])

        this.initImages()
    }
}
</script>
