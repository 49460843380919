
<template>
    <svg class="copy-icon" width="20" height="20" viewBox="0 0 20 20" fill="#3E4755" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.5 11.25C15.0858 11.25 14.75 11.5858 14.75 12C14.75 12.4142 15.0858 12.75 15.5 12.75V11.25ZM7.25 4.5C7.25 4.91421 7.58579 5.25 8 5.25C8.41421 5.25 8.75 4.91421 8.75 4.5H7.25ZM3 8.75H10V7.25H3V8.75ZM11.25 10V17H12.75V10H11.25ZM10 18.25H3V19.75H10V18.25ZM1.75 17V10H0.25V17H1.75ZM3 18.25C2.30964 18.25 1.75 17.6904 1.75 17H0.25C0.25 18.5188 1.48122 19.75 3 19.75V18.25ZM11.25 17C11.25 17.6904 10.6904 18.25 10 18.25V19.75C11.5188 19.75 12.75 18.5188 12.75 17H11.25ZM10 8.75C10.6904 8.75 11.25 9.30964 11.25 10H12.75C12.75 8.48122 11.5188 7.25 10 7.25V8.75ZM3 7.25C1.48122 7.25 0.25 8.48122 0.25 10H1.75C1.75 9.30964 2.30964 8.75 3 8.75V7.25ZM10 1.75H17V0.25H10V1.75ZM18.25 3V10H19.75V3H18.25ZM17 11.25H15.5V12.75H17V11.25ZM8.75 4.5V3H7.25V4.5H8.75ZM18.25 10C18.25 10.6904 17.6904 11.25 17 11.25V12.75C18.5188 12.75 19.75 11.5188 19.75 10H18.25ZM17 1.75C17.6904 1.75 18.25 2.30964 18.25 3H19.75C19.75 1.48122 18.5188 0.25 17 0.25V1.75ZM10 0.25C8.48122 0.25 7.25 1.48122 7.25 3H8.75C8.75 2.30964 9.30964 1.75 10 1.75V0.25Z"
        />
    </svg>
</template>
<script>
export default {
    name: "CopyIcon"
}
</script>
<style lang="sass" scoped>
.copy-icon
    cursor: pointer
    transition: .2s
    &:hover
        fill: #3965FF
</style>
