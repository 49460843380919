<template>
    <svg width="40" height="40" viewBox="0 0 40 40" stroke="#98A2B0" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.668 19.9999L28.3346 19.9999M11.668 19.9999L18.3346 13.3333M11.668 19.9999L18.3346 26.6666"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: "ArrowRightLargeIcon"
}
</script>

<style scoped lang="sass">

</style>
