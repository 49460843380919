import moment from "moment-timezone"

import "moment/dist/locale/ru"
import "moment/dist/locale/uk"
import "moment/dist/locale/it"
import "moment/dist/locale/es"
import "moment/dist/locale/de"
import "moment/dist/locale/pl"

moment.locale(window.app_current_locale)

export default {
    data: () => ({
        currentDateTime: new Date()
    }),
    filters: {
        formatdate(value) {
            return moment(value).format("MMMM DD, YYYY")
        },
        formatDateWithMonth(value) {
            return moment(value).format("MMMM YYYY").capitalize()
        },
        formatMonth(value) {
            return moment(value).format("DD MMMM")
        },
        dateByFormat(value, format = "MMMM DD, YYYY") {
            return moment(value).format(format)
        },
        formatDateWithTime(value) {
            return value ? moment(value).format("MMMM DD, YYYY HH:mm:ss") : "—"
        },
        formatDateDdMmYyyyWithHourWithTz(date, tz) {
            if (!date) {
                return "∞"
            }

            if (tz) {
                if (moment.tz(tz).format("Z") !== moment.tz(moment.tz.guess()).format("Z")) {
                    return moment(date).tz(tz).format("DD MMMM YYYY, HH:mm") + ` (${moment.tz(tz).format("Z")} ${tz})`
                } else {
                    return moment(date).tz(tz).format("DD MMMM YYYY, HH:mm")
                }
            } else {
                return moment(date).format("DD MMMM YYYY, HH:mm")
            }
        },
        formatDateWithTimeNoSeconds(value) {
            return moment(value).format("MMMM DD, YYYY HH:mm")
        },
        formatDateWithTimeNoSeconds2(value) {
            return moment(value).format("DD MMMM YYYY, HH:mm") || ""
        },
        formatDateDdMmYyyy(value) {
            return moment(value).format("DD/MM/YYYY")
        },
        formatDateDdMmYyyyWithHour(value) {
            if (value === null) {
                return "—"
            }

            return moment(value).format("DD/MM/YYYY HH:mm")
        },
        formatDurationDate(value) {
            let dateOne = value.start
            let dateTwo = value.end

            let diff = moment(dateOne).diff(moment(dateTwo))
            let dur = moment.duration(diff).humanize()

            return dur
        },
        formatDateFromNow(value) {
            return `${moment(value).fromNow()}`
        }
    },
    methods: {
        formatDateDdMmmmYyyyWithHourWithTz(date, tz) {
            if (tz) {
                if (moment.tz(tz).format("Z") !== moment.tz(moment.tz.guess()).format("Z")) {
                    return moment(date).tz(tz).format("DD MMMM YYYY, HH:mm") + ` (${moment.tz(tz).format("Z")} ${tz})`
                } else {
                    return moment(date).tz(tz).format("DD MMMM YYYY, HH:mm")
                }
            } else {
                return moment(date).format("DD MMMM YYYY, HH:mm")
            }
        },
        formatdate(value, format = "MMMM DD, YYYY") {
            return moment(value).format(format)
        },
        formatDateUTC(value, format = "MMMM DD, YYYY") {
            return moment(value).utc().format(format)
        },
        formatDateDDMMYYYY(value) {
            return moment(value).format("DD MMMM YYYY")
        },
        formatDateDdMmYYYY(value) {
            return moment(value).format("DD.MM.YYYY")
        },
        formatDateWithTime(value) {
            return value ? moment(value).format("MMMM DD, YYYY HH:mm:ss") : "—"
        },
        formatDateWithTimeNoSeconds(value, timezone) {
            if (!value) return "—"
            return timezone
                ? moment(value).utc().format("DD MMMM, YYYY HH:mm")
                : moment(value).format("DD MMMM, YYYY HH:mm")
        },
        formatDateWithTimeNoSeconds2(value, utc = false) {
            return utc ? moment(value).utc().format("DD MMMM YYYY HH:mm") : moment(value).format("DD MMMM YYYY HH:mm")
        },
        formatDateNoSeconds2(value) {
            return moment(value).format("DD MMMM, YYYY")
        },
        formatDateWithTzName(val) {
            const timezoneName = moment.tz.guess()
            const tzOffset = new Date(val)
                .toString()
                .split(" ")
                .find(str => str.includes("GMT"))
                .replace("GMT", "")

            return `${this.formatDateWithTimeNoSeconds2(val)} (${tzOffset} ${timezoneName})`
        },
        formatDateDdMmYyyy(value) {
            return moment(value).format("DD/MM/YYYY")
        },
        formatDateDdMmYyyyWithHour(value) {
            return moment(value).format("DD/MM/YYYY HH:mm")
        },
        formatDateDdMmYyyyWithHourServer(value) {
            return moment(value).format("YYYY-MM-DDTHH:mm")
        },
        formatTimeNoSeconds(value) {
            return moment(value).format("HH:mm")
        },
        formatDurationDate(value) {
            let dateOne = value.start
            let dateTwo = value.end

            let diff = moment(dateOne).diff(moment(dateTwo))
            let dur = moment.duration(diff).humanize()

            return dur
        },
        formatDateFromNow(value) {
            return moment(value).fromNow()
        },
        toTimestamp(strDate) {
            let datum = Date.parse(strDate)
            return datum / 1000
        },
        dateToString(value, utc = false) {
            let date = new Date(value * 1000)
            let Year = utc ? date.getUTCFullYear() : date.getFullYear()
            let Month = utc ? date.getUTCMonth() + 1 : date.getMonth() + 1
            if (Month < 10) Month = "0" + Month
            let Day = utc ? date.getUTCDate() : date.getDate()
            if (Day < 10) Day = "0" + Day
            let formattedTime = Year + "-" + Month + "-" + Day
            return formattedTime
        },
        dateToTime(value, utc = false) {
            let date = new Date(value * 1000)
            let Hours = utc ? date.getUTCHours() : date.getHours()
            let Minutes = utc ? date.getUTCMinutes() : date.getMinutes()
            if (Hours < 10) Hours = "0" + Hours
            if (Minutes < 10) Minutes = "0" + Minutes
            let formattedTime = Hours + ":" + Minutes
            return formattedTime
        },
        showDateWithTz(date, timezone, format = "DD MMMM, YYYY HH:mm") {
            let dateToShow = date
            if (!date.includes("Z")) {
                // Edit date to UTC format if got with timezone
                dateToShow = moment(dateToShow).utcOffset(0, true).format()
            }
            return moment(dateToShow).tz(timezone.name).format(format)
        },
        createDateWithTz(date, time, timezone) {
            return (
                moment(new Date(`${date}T${time}`))
                    // .utcOffset(0, true)
                    .tz(timezone.originalName ? timezone.originalName : timezone.name)
                    .format()
            )
        },
        createDateWithoutTz(date, time) {
            return moment(new Date(`${date}T${time}`))
                .utcOffset(0, true)
                .format()
        },
        currentDateToString(value) {
            return moment(value).utcOffset(0, false).format("YYYY-MM-DD")
        },
        currentDateToStringWithTime(value) {
            return moment(value).format("YYYY-MM-DD HH:mm:ss")
        },
        currentDateToTime(value) {
            return moment(value).utcOffset(0, false).format("HH:mm")
        },
        formatDateWithTimeNoSecondsV2(value) {
            return moment(value).format("DD MMMM YYYY, HH:mm:ss")
        },
        parseDaysAndMonthType(count, isMonth = false) {
            const textsDay = ["day", "day_couple", "days"],
                textsMonth = ["month", "month_couple", "months"],
                cases = [2, 0, 1, 1, 1, 2]
            let textsGeneral = isMonth ? textsMonth : textsDay
            const endTransKey = textsGeneral[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]]
            return "dates." + endTransKey
        }
    }
}
