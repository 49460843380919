<template>
    <svg width="34" height="34" viewBox="0 0 34 34" fill="#30C58F">
        <path
            d="M17 34C7.62619 34 0 26.3737 0 16.9999C0 16.0466 0.635514 15.4111 1.58879 15.4111C2.54206 15.4111 3.17757 16.0466 3.17757 16.9999C3.17757 24.6261 9.37385 30.8224 17 30.8224C24.6262 30.8224 30.8224 24.6261 30.8224 16.9999C30.8224 9.37374 24.6262 3.17757 17 3.17757C16.0468 3.17757 15.4112 2.54205 15.4112 1.58878C15.4112 0.635513 16.0468 0 17 0C26.3739 0 34 7.62608 34 16.9999C34 26.5326 26.3739 34 17 34Z"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "LoaderGreenIcon"
}
</script>

<style scoped lang="sass">

</style>
