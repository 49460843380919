<template>
    <div :style="containerStyle" class="wrapper-alert">
        <svg
            v-if="!withoutClose"
            @click="$emit('close')"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="alert-close"
        >
            <path
                class="pointer-events-none"
                d="M13.8636 7.19699C14.1565 6.90409 14.1565 6.42922 13.8636 6.13633C13.5707 5.84343 13.0959 5.84343 12.803 6.13633L13.8636 7.19699ZM6.1363 12.803C5.8434 13.0959 5.8434 13.5708 6.1363 13.8637C6.42919 14.1566 6.90406 14.1566 7.19696 13.8637L6.1363 12.803ZM7.19698 6.13633C6.90408 5.84343 6.42921 5.84343 6.13632 6.13633C5.84342 6.42922 5.84342 6.90409 6.13632 7.19699L7.19698 6.13633ZM12.803 13.8637C13.0959 14.1566 13.5708 14.1566 13.8637 13.8637C14.1566 13.5708 14.1566 13.0959 13.8637 12.803L12.803 13.8637ZM12.803 6.13633L6.1363 12.803L7.19696 13.8637L13.8636 7.19699L12.803 6.13633ZM6.13632 7.19699L12.803 13.8637L13.8637 12.803L7.19698 6.13633L6.13632 7.19699Z"
                fill="#3E4755"
            />
        </svg>
        <slot></slot>
    </div>
</template>

<script>
//
export default {
    props: {
        withoutClose: {
            type: Boolean,
            default: false
        },
        padding: {
            type: Array,
            default: () => []
        }
    },
    name: "WrapperAlert",
    computed: {
        containerStyle() {
            const style = {}

            if (this.padding.length) {
                style.padding = this.padding.map(n => `${n}px`).join(" ")
            }

            return style
        }
    }
}
</script>

<style scoped lang="sass">
.wrapper-alert
    background-color: #fff
    box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
    border-radius: 10px
    padding: 15px 24px
    z-index: 55555
    position: absolute
    top: -3px
    left: 0
    width: 400px
    white-space: normal
    &.left-auto
        left: auto
    &_hoverable
        top: auto !important
        bottom: calc(100% + 3px)
        left: -20px
        padding: 6px 14px
        &:before
            position: absolute
            bottom: -9px
            left: 20px
            transform: rotate(180deg)
            height: 13px
            width: 21px
            filter: drop-shadow(0 4px 10px rgba(128, 158, 191, 0.15))
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='13' width='21'%3E%3Cpolygon fill='%23fff' points='0,13 10,0 21,13' class='triangle' /%3E%3C/svg%3E")
    svg
        right: 10px
        top: 10px
        position: absolute
        cursor: pointer
        transition: .3s
        border-radius: 4px
        &:hover
            box-shadow: 0px 2px 5px rgba(128, 158, 191, 0.25)
    @media (max-width: 480px)
        width: 300px
</style>
