
<template>
    <svg
        class="plus-icon"
        :style="{ marginRight: marginRight && `${marginRight}px` }"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        :stroke="fill"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M12 7V17M7 12L17 12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>
<script>
export default {
    props: {
        fill: {
            type: String,
            default: "#151515"
        },
        marginRight: Number
    },
    name: "PlusIcon"
}
</script>
