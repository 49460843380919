<template>
    <div class="course__lesson_practice">
        <LoaderBlock class="mt-25" green-color v-if="!langsKeys" />
        <template v-else>
            <div class="course__lesson_practice-short__name">{{ t("create-course.practice") }}:</div>
            <div class="course__lesson_practice__item">
                <p
                    v-for="quiz in items"
                    :key="quiz.id"
                    class="course__content_summary__discr course__lesson_practice__item_list"
                >
                    <!-- Quiz types icons -->
                    <span
                        style="position: relative; top: 6px"
                        class="course__lesson_practice__item_list__icon-type"
                        v-html="getIcon(quiz)"
                    ></span>

                    <svg
                        v-if="quiz.timer"
                        width="14"
                        height="18"
                        viewBox="0 0 14 18"
                        fill="none"
                        style="margin-right: 7px; margin-bottom: -3px"
                    >
                        <path
                            d="M5.33333 0.75C4.91912 0.75 4.58333 1.08579 4.58333 1.5C4.58333 1.91421 4.91912 2.25 5.33333 2.25V0.75ZM8.66667 2.25C9.08088 2.25 9.41667 1.91421 9.41667 1.5C9.41667 1.08579 9.08088 0.75 8.66667 0.75V2.25ZM7 10.25H6.25C6.25 10.4489 6.32902 10.6397 6.46967 10.7803L7 10.25ZM7.75 7.33333C7.75 6.91912 7.41421 6.58333 7 6.58333C6.58579 6.58333 6.25 6.91912 6.25 7.33333H7.75ZM8.13634 12.447C8.42923 12.7399 8.9041 12.7399 9.197 12.447C9.48989 12.1541 9.48989 11.6792 9.197 11.3863L8.13634 12.447ZM12.5 10.25C12.5 13.2876 10.0376 15.75 7 15.75V17.25C10.866 17.25 14 14.116 14 10.25H12.5ZM7 15.75C3.96243 15.75 1.5 13.2876 1.5 10.25H0C0 14.116 3.13401 17.25 7 17.25V15.75ZM1.5 10.25C1.5 7.21243 3.96243 4.75 7 4.75V3.25C3.13401 3.25 0 6.38401 0 10.25H1.5ZM7 4.75C10.0376 4.75 12.5 7.21243 12.5 10.25H14C14 6.38401 10.866 3.25 7 3.25V4.75ZM5.33333 2.25H8.66667V0.75H5.33333V2.25ZM7.75 10.25V7.33333H6.25V10.25H7.75ZM6.46967 10.7803L8.13634 12.447L9.197 11.3863L7.53033 9.71967L6.46967 10.7803Z"
                            fill="#DD4141"
                        />
                    </svg>

                    <!-- Quiz link -->
                    <a
                        class="course__link-hover"
                        :href="`/courses/${lesson.course.slug}/${lesson.slug}/quizzes/${quiz.id}`"
                        :target="target"
                        >{{ quiz.name }}</a
                    >
                    <!-- Quiz result -->
                    <span
                        v-if="quiz.attempt"
                        class="status-quiz"
                        :class="{
                            'text-green': quiz.attempt && quiz.attempt.status_id === 1,
                            'text-red': quiz.attempt && quiz.attempt.status_id === 2,
                            'text-orange':
                                (quiz.attempt && quiz.attempt.status_id === 3) ||
                                (quiz.attempt && quiz.attempt.status_id === 6),
                            'text-gray': quiz.attempt && quiz.attempt.status_id === 5
                        }"
                    >
                        <template v-if="quiz.attempt.status_id === 6">
                            {{ t("course.check") }}
                        </template>
                        <template v-if="quiz.attempt.status_id === 1">
                            {{ t("course.passed") }}
                        </template>
                        <template v-else-if="quiz.attempt.status_id === 2">
                            {{ t("course.failed") }}
                        </template>
                        <template v-else-if="quiz.attempt.status_id === 3">
                            {{ t("course.need_rework") }}
                        </template>
                        <template v-if="quiz.attempt.status_id === 5">
                            {{ t("course.in_process") }}
                        </template>
                    </span>
                </p>
            </div>
        </template>
    </div>
</template>

<script>
import { QuizQuestionTypeAdapter } from "../Practice/PracticeQuestions/_types"
import KeyTranslator from "@mixins/keyTranslator"
import LoaderBlock from "@components/Loaders/LoaderBlock"

export default {
    name: "CoursePracticeLists",
    components: { LoaderBlock },
    mixins: [KeyTranslator],
    props: {
        courseAccess: {
            type: Object
        },
        langsKeys: {
            type: Object
        },
        lesson: {
            type: Object
        },
        items: {
            type: Array
        },
        currentLessonId: {
            type: Number,
            required: true
        },
        currentLessonLink: {
            type: String
        },
        target: {
            default: "_self",
            type: String
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        translate(key) {
            if (this.t && this.$store) {
                return this.t(key)
            }
            return key
        },
        displayTimerIcon(item) {
            // console.log(item)
            return item.timer || (item.options && item.options.timer_duration) || !!item.questions.find(q => q.timer)
        },
        getIcon(item) {
            const adapter = item.type
                ? QuizQuestionTypeAdapter[item.type]
                : QuizQuestionTypeAdapter[item.questions_type]

            if (adapter && adapter.icon) {
                return adapter.icon
            }
            if (adapter && adapter.icons) {
                return adapter.icons[0]
            }
            return QuizQuestionTypeAdapter["default"].icon
        },

        createNodeLink(link) {
            return (this.courseAccess && !this.courseAccess.is_course_subscribed) || !this.courseAccess
                ? "javascript:void(0)"
                : link
        }
    }
}
</script>

<style lang="sass">
.course__lesson_practice__item_list__icon-type
    svg
        width: 24px
        height: 24px
</style>
<style scoped lang="sass">
//.course__lesson_practice__item_list
//    margin-top: 14px
//.course__lesson_practice__item_list__icon-type
//    left: 16px
//    top: -3px
.course__lesson_practice
    min-height: 145px
.course__lesson_practice__item_list
    margin-top: 0
.status-quiz
    display: flex
    align-items: center
    font-weight: 500
    font-size: 13px
    line-height: 130%
    margin-top: 5px
    margin-left: 34px
.text-orange
    color: #E9AF1B
</style>
