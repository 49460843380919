<template>
    <div class="input-with-search full-width">
        <flex-container align="center" class="input-with-search__container relative">
            <label :for="uuid" class="input-with-search__label pointer">
                <LoupeIcon />
            </label>
            <input
                type="text"
                v-model="query"
                :id="uuid"
                :placeholder="placeholderToShow"
                class="input-with-search__input"
                :class="{ 'length-error': withError }"
            />
            <transition name="fade">
                <button v-if="query" type="button" class="delete-button absolute" @click="resetQuery">
                    <CloseIcon />
                </button>
            </transition>
        </flex-container>
        <transition name="fade">
            <FormMsg v-if="withError" class="absolute search-error-msg" :status="'error'" :msgText="errorTextToShow" />
        </transition>
    </div>
</template>

<script>
import FormMsg from "@components/Forms/FormMsg.vue"
import { v4 } from "uuid"
import LoupeIcon from "@icons/LoupeIcon.vue"
import CloseIcon from "@icons/CloseIcon.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"

export default {
    name: "SearchInput",
    components: {
        FlexContainer,
        CloseIcon,
        LoupeIcon,
        FormMsg
    },
    props: {
        value: {
            type: [String, Number],
            default: null
        },
        errorText: {
            type: String,
            default: null
        },
        error: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: null
        },
        minSearchLength: {
            type: Number,
            default: 3
        }
    },
    model: {
        prop: "value",
        event: "input"
    },
    data: () => ({
        uuid: null,
        lengthError: false,
        searchTimer: 0,
        query: null
    }),
    created() {
        this.uuid = v4()
    },
    computed: {
        withError() {
            return !!this.error || this.lengthError
        },
        errorTextToShow() {
            return (
                this.errorText ||
                this.t("expert.not_less_than_error").replace(":field", this.t("expert.search")).replace(":qty", 3)
            )
        },
        placeholderToShow() {
            return this.placeholder || this.t("expert.search")
        }
    },
    watch: {
        value(value) {
            return (this.query = value)
        },
        query(value, oldValue) {
            if (!value && !oldValue) return

            this.lengthError = null
            clearTimeout(this.searchTimer)

            if (!value) {
                this.resetQuery()
                return this.updateData()
            }

            this.searchTimer = setTimeout(() => {
                if (value.length && value.length < this.minSearchLength) this.lengthError = true
                this.input(value)
            }, 800)
        }
    },
    methods: {
        input(value) {
            return this.$emit("input", value)
        },
        resetQuery() {
            this.$emit("searchQuery:clear")
        },
        updateData() {
            this.$emit("data:update")
        }
    }
}
</script>

<style lang="sass" scoped>
@import 'resources/sass/vars'

.input-with-search
    &__label
        display: flex
        justify-content: center
        align-items: center
        margin-right: 20px
    &__input
        width: 100%
        padding: 5px 20px 5px 10px
        font-family: $mainFont
        font-size: 12px
        color: $text-color
        background: transparent
        box-shadow: none
        border-bottom: 1px solid $border-color5
    .delete-button
        right: 5px
        svg
            fill: $svg-color
            transition: $transitionTime
        &:hover
            svg
                fill: $danger-color
    .length-error
        border-bottom: 1px solid $danger-color

.search-error-msg
    transform: translate(50px, -3px)
</style>
