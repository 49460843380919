<template>
    <div class="practice-question">
        <PracticeQuestionInfo :question="question" :quiz="quiz" :number="questionNumber" :name="question.name" />

        <PracticeQuestionDefaultOptions
            v-if="interactive && attempt"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />

        <FilesNewView v-if="question.files && question.files.length" :files="question.files" />

        <PracticeQuestionComment v-if="question.comment && quiz.attempt" :question="question" />

        <div v-if="question.is_answer_enabled" class="mt-10"></div>

        <template v-if="interactive">
            <div
                v-if="question.is_answer_enabled && question.is_public_answer_enabled"
                class="course__lesson_assigned-answer__tabs"
            >
                <div
                    class="course__lesson_assigned-answer__tab"
                    :class="{ active: tabActive === 1 }"
                    @click="tabActive = 1"
                >
                    {{ t("course.my_answer") }}
                </div>
                <div
                    class="course__lesson_assigned-answer__tab"
                    :class="{ active: tabActive === 2 }"
                    @click="tabActive = 2"
                >
                    {{ t("course.answers_from_other_members") }}
                </div>
            </div>
            <div v-if="question.is_answer_enabled">
                <div v-show="tabActive === 1" class="course__lesson_assigned-answer__comments">
                    <CommentsPublic
                        @update:commentable="updateCommentable($event)"
                        key="comments"
                        ref="comments"
                        parent="student"
                        :commentable_type="commentableType"
                        :displayTitle="false"
                        :user="user"
                        :langsProp="langs"
                        :comments-enable="true"
                        :lesson-id="lesson.id"
                        :root-attempt-id="quiz.attempt ? quiz.attempt.root_id : null"
                        :quiz-id="quiz.id"
                        :unit-id="unitId"
                        :question-id="question.id"
                        :auth="true"
                        :hide-actions="true"
                        :course-id="courseId"
                        :is-private-selection="true"
                        :allow-answer="isAllowAnswer()"
                        :allow-upload-file="!!question.can_upload"
                        :allow-text-answer="!!question.is_comment_enabled"
                        :allow-public-answer="!!question.is_public_answer_enabled"
                        :allow-audio-response="!!question.is_audio_comment_enabled"
                        :allow-video-response="!!question.is_video_comment_enabled"
                    />
                </div>
                <div v-if="tabActive === 2" class="course__lesson_assigned-answer__comments-members">
                    <CommentsPublic
                        :displayTitle="false"
                        :user="user"
                        :langsProp="langs"
                        :commentable_type="commentableType"
                        :comments-enable="true"
                        :lesson-id="lesson.id"
                        :is-public="true"
                        :hide-actions="true"
                        :quiz-id="quiz.id"
                        :unit-id="unitId"
                        :question-id="question.id"
                        :root-attempt-id="quiz.attempt ? quiz.attempt.root_id : null"
                        :auth="true"
                        :course-id="courseId"
                        :is-other="true"
                        :allow-answer="!!question.is_answer_enabled"
                        :allow-upload-file="!!question.can_upload"
                        :allow-text-answer="!!question.is_comment_enabled"
                        :allow-public-answer="true"
                        :allow-audio-response="!!question.is_audio_comment_enabled"
                        :allow-video-response="!!question.is_video_comment_enabled"
                        :to-answer-comment-id="quiz.attempt && quiz.attempt.id"
                        key="comments"
                        parent="student"
                    />
                </div>
            </div>
        </template>
        <template v-else>
            <CommentsPublic
                ref="comments"
                :parent="expertView ? 'expert' : 'student'"
                :displayTitle="false"
                :user="user"
                :langsProp="langs"
                :commentable_type="commentableType"
                :comments-enable="true"
                :unit-id="unitId"
                :lesson-id="lesson.id"
                :root-attempt-id="quiz.attempt ? quiz.attempt.root_id : attempt.root_id"
                :quiz-id="quiz.id"
                :question-id="question.id"
                :auth="true"
                :hide-actions="!expertView"
                :course-id="courseId"
                :allow-bulk-actions="!!expertView"
                :advanced-panel="expertView"
                :is-private-selection="!expertView"
                :allow-answer="expertView ? true : !!question.is_answer_enabled"
                :allow-upload-file="expertView ? true : !!question.can_upload"
                :allow-text-answer="expertView ? true : !!question.is_comment_enabled"
                :allow-public-answer="expertView ? true : !!question.is_public_answer_enabled"
                :allow-audio-response="expertView ? true : !!question.is_audio_comment_enabled"
                :allow-video-response="expertView ? true : !!question.is_video_comment_enabled"
                @update:commentable="updateCommentable($event)"
                :viewed-user="viewedUser"
                key="comments"
            />
        </template>

        <!--PracticeActionsContainer
            @click="next()"
            :quiz="quiz"
            :question="question"
            v-if="quiz.type !== 'text_graphic_answer' && interactive"
        />-->
    </div>
</template>

<script>
import LessonRepository from "@lesson/shared/repository/lesson-repository"
import CommentsPublic from "../../Comments/CommentsPublic"
import PracticeQuestionDefaultOptions from "./components/PracticeQuestionDefaultOptions"
import FilesNewView from "../../Files/FilesNewView"
import PracticeRepository from "../shared/repository/practice-repository"
import PracticeMixin from "./_mixin"
import PracticeQuestionInfo from "./components/PracticeQuestionInfo"
import axios from "@libs/axios"

export default {
    components: {
        PracticeQuestionInfo,
        FilesNewView,
        PracticeQuestionDefaultOptions,
        CommentsPublic
    },
    mixins: [PracticeMixin],
    name: "PracticeTextGraphicAnswer",
    data() {
        return {
            tabActive: 1,
            didUpdate: false,
            savedAttempt: null
        }
    },
    methods: {
        isAllowAnswer() {
            if (!this.quiz.attempt) {
                return true
            }
            if (
                this.question.hasOwnProperty("is_allowed_comment_after_check") &&
                !this.question.is_allowed_comment_after_check
            ) {
                return (
                    this.quiz.attempt.status_id !== 1 &&
                    this.quiz.attempt.status_id !== 2 &&
                    !!this.question.is_answer_enabled
                )
            }
            return !!this.question.is_answer_enabled
        },
        setAttempt(attempt) {
            this.$set(this.quiz, "attempt", attempt)
        },
        async updateCommentable(commentable) {
            if (commentable) {
                if (this.quiz?.attempt) {
                    this.$set(this.quiz.attempt, "results", commentable.results)
                }
                this.$set(this.question, "is_required", false)
                this.savedAttempt = commentable

                if (this.quiz.type === "text_graphic_answer") {
                    if (!this.question.comment) {
                        try {
                            const { data } = await axios.get(
                                `/quiz-attempts/${this.attempt.id}/quiz-questions/${this.question.id}`
                            )
                            this.question.comment = data.data.comment

                            if (this.lesson?.section_id) {
                                setTimeout(async () => {
                                    await LessonRepository.replaceContents({ section: this.lesson.section_id })
                                }, 300)
                            }
                        } catch (e) {
                            console.log(e)
                        }
                    }

                    this.$set(this.quiz, "attempt", this.savedAttempt.latest_attempt ?? this.savedAttempt)
                    this.$emit("forseRefresh")
                }

                this.$forceUpdate()
            }
        },
        async next() {
            if (this.$refs.comments && this.$refs.comments.$refs.create) {
                const create = this.$refs.comments.$refs.create
                if (!create.cantBeSubmitted()) {
                    await create.submit()
                    await new Promise(resolve => setTimeout(resolve, 0))
                }
            }

            if (!this.savedAttempt) {
                await this.updateComments(null)
            }
            const index = this.quiz.questions.indexOf(this.question)
            if (index !== this.quiz.questions.length - 1) {
                this.$emit("set", this.quiz.questions[index + 1])
                this.$emit("forseRefresh")
            } else {
                this.$emit("attempt", this.savedAttempt)
                this.$emit("forseRefresh")
            }
        },
        async updateComments() {
            const result = await PracticeRepository.answers({
                lesson_id: this.lesson.id,
                attempt_id: this.quiz.attempt.id,
                unit_id: this.unitId,
                payload: {
                    quiz_question_id: this.question.id,
                    answer_ids: [],
                    comment_id: null
                }
            })

            this.savedAttempt = result.data
        },
        getAnswerCommentId() {
            if (this.quiz.attempt && this.quiz.attempt.results && this.quiz.attempt.results.length) {
                const answer = this.quiz.attempt.results[this.quiz.questions.indexOf(this.question)]
                if (answer) {
                    return answer.comment_id
                } else {
                    return null
                }
            } else {
                return null
            }
        }
    },
    computed: {
        langs() {
            return this.$store.getters["langs/getLangs"]
        },
        user() {
            return this.$store.getters["user/currentUser"]
        }
    },
    watch: {
        tabActive() {
            this.didUpdate = true
            this.$nextTick(() => {
                this.didUpdate = false
            })
        }
    }
}
</script>


