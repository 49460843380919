export default function t(key, langs, parameters) {
    let value, group, groupKey

    if (langs.hasOwnProperty(key)) {
        return langs[key]
    }

    for (group in langs) {
        for (groupKey in langs[group]) {
            if (groupKey === key) value = langs[group][groupKey]
        }
    }

    if (typeof value === "string" && typeof parameters === "object" && parameters !== null) {
        for (const [paramKey, paramValue] of Object.entries(parameters)) {
            if (paramValue) {
                if (value.includes(`:${paramKey}`)) {
                    value = value.replace(`:${paramKey}`, paramValue)
                } else {
                    value = value.replace(`${paramKey}`, paramValue)
                }
            }
        }
    }

    return value ? value : key
}
