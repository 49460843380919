<template>
    <p class="practice-description">
        <slot></slot>
    </p>
</template>

<script>
export default {
    name: "PracticeDescription"
}
</script>

<style scoped lang="sass">
.practice-description
    font-family: 'Ubuntu', serif
    font-size: 16px
    font-weight: 400
    line-height: 21px
    color: #B5C1D2
</style>
