export default class Bus {
    static #subscribers = new Map()

    static #savedEvents = new Map()

    static $emit(event, payload) {
        if (Bus.#subscribers.has(event)) {
            Bus.#subscribers.get(event).forEach(callback => callback.call(null, payload))
        } else {
            Bus.#savedEvents.set(event, payload)
        }
    }

    static $was(event) {
        return Bus.#savedEvents.get(event)
    }
    static $on(event, callback) {
        if (Bus.#subscribers.has(event)) {
            Bus.#subscribers.get(event).push(callback)
        } else {
            Bus.#subscribers.set(event, [callback])
        }
        return function () {
            Bus.#subscribers.set(
                event,
                Bus.#subscribers.get(event).filter(existingCallback => callback !== existingCallback)
            )
        }
    }
}
