import cloneDeep from "lodash.clonedeep"
import { reflectKeys } from "@helpers"
import { LESSON_PREFIX } from "./lesson-prefix"
import LessonRepository from "../repository/lesson-repository"
import {
    LESSON_ERROR,
    SET_ACTIVE_SECTION,
    SET_COURSES,
    SET_LESSON,
    SET_LESSON_LOADER,
    SET_PROGRAM,
    UPDATE_SECTION
} from "./lesson-mutations"
import { COURSE_PROGRAM, LESSON, SECTION } from "./lesson-getters"
import SectionRepository from "../repository/section-repository"
import axios from "~axios"
export const {
    PREPARE_LESSON_PAYLOAD,
    COPY_LESSON_CONTENT,
    GET_COURSES,
    SAVE_LESSON,
    UPDATE_LESSON_PROP,
    LOAD_LESSON,
    CREATE_LESSON,
    GET_PROGRAM,
    HANDLE_LESSON,
    UPDATE_SECTION_PROP,
    ADD_SECTION,
    DELETE_SECTION,
    PRELOAD_UNITS_DATA
} = reflectKeys(
    [
        "PREPARE_LESSON_PAYLOAD",
        "COPY_LESSON_CONTENT",
        "GET_COURSES",
        "SAVE_LESSON",
        "UPDATE_LESSON_PROP",
        "LOAD_LESSON",
        "CREATE_LESSON",
        "GET_PROGRAM",
        "HANDLE_LESSON",
        "UPDATE_SECTION_PROP",
        "ADD_SECTION",
        "DELETE_SECTION",
        "PRELOAD_UNITS_DATA"
    ],
    LESSON_PREFIX
)

export default {
    async [COPY_LESSON_CONTENT](context, { copy }) {
        const current = context.getters[LESSON]

        const { course_nodeble_id, id } = copy

        context.commit(SET_LESSON_LOADER, {
            loader: "settings",
            value: true
        })

        /**
         * Create lesson copy
         */
        const result = await LessonRepository.copy({
            lesson: current.course_nodeble_id || current.id,
            from: course_nodeble_id || id
        }).catch(e => {
            context.commit(LESSON_ERROR, e)
        })

        if (result) {
            /**
             * Set new lesson settings and content
             */

            context.commit(SET_LESSON_LOADER, {
                loader: "settings",
                value: false
            })

            await context.dispatch(HANDLE_LESSON, {
                lesson: await context.dispatch(PREPARE_LESSON_PAYLOAD, { payload: result.data, forSave: false })
            })
        }
    },

    [DELETE_SECTION](context, { id, index }) {
        SectionRepository.delete({
            section: id
        }).catch(e => {
            context.commit(LESSON_ERROR, e)
        })

        context.commit(DELETE_SECTION, index)
    },

    async [ADD_SECTION](context, { name }) {
        const lesson = context.getters[LESSON]

        context.commit(SET_LESSON_LOADER, {
            loader: "section",
            value: true
        })

        const result = await SectionRepository.create({
            lesson: lesson.id,
            name
        })
            .catch(e => {
                context.commit(LESSON_ERROR, e)
            })
            .finally(() => {
                context.commit(SET_LESSON_LOADER, {
                    loader: "section",
                    value: false
                })
            })

        context.commit(ADD_SECTION, result.data)
    },

    async [SAVE_LESSON](context, { loader = "save" }) {
        const lesson = context.getters[LESSON]

        context.commit(SET_LESSON_LOADER, {
            loader,
            value: true
        })

        const payload = await context.dispatch(PREPARE_LESSON_PAYLOAD, { payload: cloneDeep(lesson), forSave: true })

        const result = await LessonRepository.update({
            course: lesson.course_id,
            lesson: lesson.id,
            payload: payload
        })
            .catch(e => {
                context.commit(LESSON_ERROR, e)
                context.commit(SET_LESSON, lesson)
            })
            .finally(() => {
                context.commit(SET_LESSON_LOADER, {
                    loader,
                    value: false
                })
            })

        if (result) {
            return result.data
        }
    },

    async [GET_COURSES](context) {
        context.commit(SET_LESSON_LOADER, {
            loader: "courses",
            value: true
        })

        const result = await LessonRepository.courses()
            .catch(e => {
                context.commit(LESSON_ERROR, e)
            })
            .finally(() => {
                context.commit(SET_LESSON_LOADER, {
                    loader: "courses",
                    value: false
                })
            })

        if (result) {
            context.commit(SET_COURSES, result.data)
            return result.data
        }
    },

    async [PREPARE_LESSON_PAYLOAD](context, { payload, forSave = false }) {
        if (forSave) {
            if (payload.info_units) {
                for (const infoUnit of payload.info_units) {
                    if (
                        infoUnit.files &&
                        infoUnit.files.length &&
                        infoUnit.files.every(quiz => typeof quiz === "object")
                    ) {
                        infoUnit.files = infoUnit.files.map(unit => unit.id)
                    }
                }
            }

            if (payload.quizzes) {
                payload.quiz_ids = payload.quizzes.filter(quiz => !!quiz).map(quiz => quiz.id)
            }
            if (payload.accessible_after_ids && payload.accessible_after_ids.every(quiz => typeof quiz === "object")) {
                payload.accessible_after_ids = payload.accessible_after_ids.map(quiz => quiz.id).filter(quiz => !!quiz)
            }

            if (payload.lesson_previews) {
                payload.preview_ids = [...payload.lesson_previews]
                    .map(preview => preview.id)
                    .filter(preview => !!preview)

                payload.lesson_previews = payload.lesson_previews
                    .map(preview => preview.id)
                    .filter(preview => !!preview)
            }

            if (payload.video_previews) {
                if (payload.preview_ids) {
                    payload.preview_ids = [
                        ...payload.preview_ids,
                        ...payload.video_previews.map(preview => preview.id).filter(preview => !!preview)
                    ]
                } else {
                    payload.preview_ids = [...payload.video_previews]
                        .map(preview => preview.id)
                        .filter(preview => !!preview)
                }

                payload.video_previews = payload.video_previews.map(preview => preview.id).filter(preview => !!preview)
            }

            if (payload.image && !payload.preview_id) {
                payload.preview_id = payload.image.id
            }

            if (payload.accessible_timezone) {
                payload.accessible_timezone_id = payload.accessible_timezone.id
            }
        } else {
            if (payload.accessible_after_ids) {
                payload.accessible_after_ids = payload.accessible_after_ids
                    .map(el => payload.quizzes.find(quiz => quiz.id === el))
                    .filter(quiz => !!quiz)
            }

            if (payload.image && !payload.lesson_previews.length) {
                payload.image = null
                payload.is_preview_added = false
            }
        }

        return payload
    },

    async [UPDATE_LESSON_PROP](context, prop) {
        const lesson = cloneDeep(context.getters[LESSON])

        context.commit(SET_LESSON, {
            ...lesson,
            ...prop
        })

        if (prop.__without_save) {
            return
        }

        prop = await context.dispatch(PREPARE_LESSON_PAYLOAD, { payload: prop, forSave: true })

        const result = await LessonRepository.update({
            course: lesson.course_id,
            lesson: lesson.id,
            payload: prop
        }).catch(e => {
            context.commit(LESSON_ERROR, e)
            context.commit(SET_LESSON, lesson)

            return e
        })

        if (result) {
            return result.data
        }
    },

    async [GET_PROGRAM](context, { course, clear = false }) {
        const cache = context.getters[COURSE_PROGRAM](course)

        if (context.getters[COURSE_PROGRAM][course] && !clear) {
            return cache
        } else {
            context.commit(SET_LESSON_LOADER, {
                loader: "program",
                value: true
            })

            const result = await LessonRepository.program({ course })
                .catch(e => {
                    context.commit(LESSON_ERROR, e)
                })
                .finally(() => {
                    context.commit(SET_LESSON_LOADER, {
                        loader: "program",
                        value: false
                    })
                })

            if (result) {
                context.commit(SET_PROGRAM, {
                    id: course,
                    program: result.data
                })
            }
        }
    },

    async [UPDATE_SECTION_PROP](context, prop) {
        const section = context.getters[SECTION]

        context.commit(UPDATE_SECTION, {
            ...section,
            ...prop
        })

        if (prop.__without_save) {
            return
        }

        prop = await context.dispatch(PREPARE_LESSON_PAYLOAD, { payload: prop, forSave: true })

        const result = await SectionRepository.update({
            section: section.id,
            payload: prop
        }).catch(e => {
            context.commit(LESSON_ERROR, e)
            context.commit(SET_LESSON, section)

            return e
        })

        if (result) {
            return result.data
        }
    },

    async [LOAD_LESSON](context, { lesson, course, section = 0 }) {
        if (section && typeof section === "string") {
            section = parseInt(section)
        }

        context.commit(SET_LESSON_LOADER, {
            loader: "all",
            value: true
        })

        const result = await LessonRepository.index({ lesson, course }).catch(e => {
            context.commit(LESSON_ERROR, e)
        })

        if (result) {
            const payload = result.data

            await context.dispatch(HANDLE_LESSON, {
                lesson: await context.dispatch(PREPARE_LESSON_PAYLOAD, { payload, forSave: false })
            })

            context.commit(SET_ACTIVE_SECTION, section ?? 0)
        }
    },

    [HANDLE_LESSON](context, { lesson, is_copy = false }) {
        if (is_copy) {
            lesson.sections.forEach(section => {
                delete section.id
                section.copied = true

                section.info_units.forEach(unit => {
                    delete unit.id
                    unit.copied = true
                })
            })
        }

        context.commit(SET_LESSON, lesson)

        return lesson
    },
    async [PRELOAD_UNITS_DATA](context, units) {
        const u = []

        for (const unit of units) {
            let url = ""

            if (unit.type_id === 6) {
                url = `/public/comments/info_units/${unit.id}`
            }

            if (url) {
                const response = await axios.get(url).catch()
                if (response) {
                    unit.preloadedData = response.data
                }
            }

            u.push(unit)
        }

        return u
    },
    async [CREATE_LESSON](context, { course, title }) {
        context.commit(SET_LESSON_LOADER, {
            loader: "create",
            value: true
        })

        const result = await LessonRepository.create({ course, title })
            .catch(e => {
                context.commit(LESSON_ERROR, e)
            })
            .finally(() => {
                context.commit(SET_LESSON_LOADER, {
                    loader: "create",
                    value: false
                })
            })

        if (result) {
            return result.data
        }
    }
}
