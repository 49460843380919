export default function (text) {
    return new Promise((resolve, reject) => {
        if (!document) {
            reject(new Error("document is undefined"))
        } else {
            try {
                const dummy = document.createElement("textarea")
                document.body.appendChild(dummy)
                dummy.value = text
                dummy.select()
                document.execCommand("copy")
                document.body.removeChild(dummy)
                resolve()
            } catch (e) {
                reject(e)
            }
        }
    })
}
