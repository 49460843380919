<template>
    <div
        class="videojs-player"
        v-if="reseted"
        :class="{
            'videojs-player__small': displayMobileVersion || smallControlsLocal,
            'is-live': isLive,
            'videojs-player__audio': isAudioHls,
            'videojs-player__vertical': orientation === 'vertical'
        }"
        :id="componentId"
        tabindex="-1"
    >
        <red-alert v-if="adblockEnabled">
            <strong> Attention! </strong> Video is blocked, try to disable
            <strong style="display: inline">Adblock</strong> and reload the page
        </red-alert>
        <video ref="video" class="video-js" controls :poster="preview">
            <p class="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a web browser that
                <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
            </p>
        </video>
    </div>
</template>

<script>
import videojs from "video.js"
import "video.js/dist/video-js.min.css"
import "videojs-youtube"
import "videojs-contrib-quality-levels"
import "./plugins/hlsjs"
import HlsQualitySelector from "./plugins/hlsQualitySelector"
import VjsSeeking from "./plugins/vjsSeeking"
import mixins from "@mixins"
import { EventBus, EventsList } from "~events"
import RedAlert from "@components/Alerts/RedAlert.vue"
import axios from "~axios"

const DEFAULT_EVENTS = [
    "ready",
    "loadeddata",
    "canplay",
    "canplaythrough",
    "play",
    "pause",
    "firstplay",
    "waiting",
    "playing",
    "fullscreenchange",
    "timeupdate",
    "ended",
    "error"
    // more events per tab "Elements" => "Events Listeners" in browser
]
const keyActiveId = "player-active-id"
export default {
    name: "VideojsPlayer",
    components: { RedAlert },
    mixins: [mixins],
    props: {
        uuid: {
            type: String,
            required: false,
            default: ""
        },
        videoType: {
            type: Object,
            required: true
        },
        videoLink: {
            type: String,
            required: true
        },
        isAudioHls: {
            type: Boolean,
            required: false
        },
        preview: {
            type: String,
            default: null
        },
        crossOrigin: {
            type: String,
            default: null
        },
        duration: {
            type: Number,
            required: false,
            default: 0
        },
        seekTo: {
            type: Number,
            required: false,
            default: 0
        },
        displayMobileVersion: {
            type: Boolean,
            required: false,
            default: false
        },
        isLive: {
            type: Boolean,
            required: false,
            default: false
        },
        playsinline: {
            type: Boolean,
            required: false,
            default: true
        },
        liveStartTime: {
            type: String,
            default: null,
            required: false
        },
        liveTrimEnding: {
            type: Number,
            default: 0,
            required: false
        },
        infoUnitId: {
            type: [Number, String],
            required: false
        },
        name: {
            type: String,
            required: false
        },
        orientation: {
            type: String,
            required: false,
            default: "horizontal"
        }
    },
    data() {
        return {
            componentId: "vjs-player-",
            reseted: true,
            timerScroll: null,
            isFisrtStart: false,
            smallControlsLocal: false,
            isReadySettings: false,
            player: null,
            adblockEnabled: false,
            playerOptions: {
                inactivityTimeout: 0,
                language: "ru-RU",
                playbackRates: this.isLive ? [] : [2, 1.75, 1.5, 1.25, 1, 0.75, 0.5],
                autoplay: this.isLive || false,
                muted: this.isLive || false,
                liveui: this.isLive,
                loop: false,
                fluid: true,
                preload: "none", // => metadata
                techOrder: ["html5", "youtube"],
                controlBar: {
                    children: {
                        playToggle: this.isLive === undefined ? true : !this.isLive,
                        volumePanel: {
                            // inline: false
                        },
                        currentTimeDisplay: false,
                        timeDivider: true,
                        liveDisplay: this.isLive,
                        durationDisplay: true,
                        // remainingTimeDisplay: false,
                        progressControl: {
                            seekBar: true
                        },
                        spacer: true,
                        seekToLive: this.isLive,
                        playbackRateMenuButton: !this.isLive && !this.isAudioHls,
                        qualitySelector: true,
                        fullscreenToggle: true
                    }
                },
                sources: [],
                html5: {}
            },
            configLiveWebinar: {
                ended: false,
                startTime: 0
            },
            isHlsSupported: true,
            errorLogged: false
        }
    },
    async created() {
        this.componentId += this._uid
        if (this.isLive) this.checkLiveTime()

        this.checkHlsSupports()

        await this.setSources()

        this.$nextTick(() => {
            this.initPlayer()
            //this.keyPressSpace()
        })

        EventBus.$on(EventsList.VIDEO_SET_SEEK, ({ unitId, seekTo }) => {
            if (this.infoUnitId === unitId) {
                if (this.player) {
                    this.player.currentTime(seekTo)
                } else {
                    console.info("[seek to]: Videojs player no init")
                }
            }
        })
    },
    mounted() {
        window.addEventListener("resize", this.onScroll)
        if (this.configLiveWebinar.ended) {
            document.addEventListener("DOMContentLoaded", () => {
                this.lessonLiveEnded()
            })
        }
        this.onScroll()
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onScroll)
        if (this.player) {
            this.dispose()
            if (this.toggleActiveIdComponent(true) === this.componentId) {
                localStorage.removeItem(keyActiveId)
            }
        }
    },
    methods: {
        parseUrlSegment(xhr, url) {
            if (window.qualityCacheId) {
                const newUrl = url + "?timestamp=" + window.qualityCacheId
                xhr.open("GET", newUrl, true)
            }
        },
        checkHlsSupports() {
            const params = new URL(document.location).searchParams

            this.isHlsSupported = /*Hls.isSupported() &&*/ !params.get("no_hls")

            if (this.isHlsSupported) {
                let overrideNative = true
                // !videojs.browser.IS_SAFARI
                // || !Hls.isSupported()
                // || window.kwg_settings?.hls_override_native
                // || window.location.search.indexOf("override_native") !== -1

                this.playerOptions.html5 = {
                    // docs => https://github.com/video-dev/hls.js/blob/master/docs/API.md
                    nativeAudioTracks: false,
                    nativeVideoTracks: false,
                    hls: {
                        overrideNative: overrideNative,
                        debug: (import.meta.env.DEV && false) || window.location.search.indexOf("debug") !== -1,
                        autoStartLoad: false, //videojs.getAllPlayers().length < 2,
                        forceKeyFrameOnDiscontinuity: true,
                        // (seconds) Если буфер < этот фрагмент значения будет загружен
                        // https://github.com/video-dev/hls.js/blob/master/docs/API.md#maxbufferlength
                        maxBufferLength: 180, // 30 sec
                        // https://github.com/video-dev/hls.js/blob/master/docs/API.md#maxmaxbufferlength
                        maxMaxBufferLength: 600, // 30 sec
                        // `maxBufferSize`(bytes) - размер буфера по умолчанию hls.js составляет 60мб,
                        // поэтому, если файл списка дочерних элементов меньше 60мб,
                        // он загрузит все файлы, я установил 10мб меньше размер буфера для экономии пропускной способности
                        // https://github.com/video-dev/hls.js/blob/master/docs/API.md#maxbuffersize
                        maxBufferSize: 30 * 1000 * 1000, // => 30mb
                        // (seconds) - Величина смещения потока при остановке
                        // currentTime += (nb - подтолкнуть повторить -1) * nudgeOffset
                        // https://github.com/video-dev/hls.js/blob/master/docs/API.md#nudgeoffset
                        nudgeOffset: 0.1,
                        // Количество подталкиваний до BUFFER_STALLED_ERROR
                        // https://github.com/video-dev/hls.js/blob/master/docs/API.md#nudgemaxretry
                        nudgeMaxRetry: 3,
                        enableWorker: true,
                        lowLatencyMode: true,
                        // maxFragLookUpTolerance: 0,
                        // https://github.com/video-dev/hls.js/blob/master/docs/API.md#backbufferlength
                        backBufferLength: 60,
                        maxBufferHole: 1.5,
                        xhrSetup: this.parseUrlSegment,
                        startLevel: -1
                    },
                    vhs: {
                        overrideNative: overrideNative
                    }
                }
            } else {
                this.playerOptions.html5 = {
                    nativeAudioTracks: true,
                    nativeVideoTracks: true
                }
            }
        },
        keyPressSpace() {
            document.addEventListener("keypress", event => {
                const tagName = document.activeElement.tagName.toLowerCase()
                const ignoreTags = ["textarea", "input"]
                const ignoreAttrs = ["contenteditable"]
                let needIgnore = ignoreTags.includes(tagName)
                // check input focus and textarea focus
                if (!needIgnore) {
                    ignoreAttrs.forEach(attrName => {
                        if (document.activeElement.hasAttribute(attrName)) {
                            needIgnore = true
                            return false
                        }
                    })
                }
                if (needIgnore) {
                    return false
                }
                // must be played if the player is full screen or the player is active
                if (this.player?.isFullscreen() || this.toggleActiveIdComponent(true) === this.componentId) {
                    event.target.blur()
                    event.preventDefault()
                    this.toggleButtonPlay()
                    return false
                }
                // if unknown active player
                else if (videojs.getAllPlayers().length > 1) {
                    return false
                }
                // if there is one player on the current page
                const keyCode = event.keyCode || event.which

                if (keyCode === 32) {
                    event.target.blur()
                    event.preventDefault()
                    this.toggleButtonPlay()
                }
            })
        },
        checkLiveTime() {
            const now = new Date()
            const livePassedTime = this.getLivePassedTime(this.duration, this.liveStartTime, this.liveTrimEnding)
            if (now > livePassedTime) {
                this.configLiveWebinar.ended = true
            } else {
                const liveDate = new Date(this.liveStartTime)
                liveDate.setSeconds(0)
                const seconds = (now.getTime() - liveDate.getTime()) / 1000
                this.configLiveWebinar.startTime = seconds
                this.configLiveWebinar.ended = false
            }
        },
        onScroll() {
            clearTimeout(this.timerScroll)
            this.timerScroll = setTimeout(() => {
                this.smallControlsLocal = window.innerWidth < 992
            }, 600)
        },
        dispose(callback) {
            if (this.player && this.player.dispose) {
                if (this.player.techName_ !== "Flash") {
                    this.player.pause && this.player.pause()
                }
                this.player.dispose()
                this.player = null
                this.$nextTick(() => {
                    this.reseted = false
                    this.$nextTick(() => {
                        this.reseted = true
                        this.$nextTick(() => {
                            callback && callback()
                        })
                    })
                })
            }
        },
        setPlaysinline() {
            if (this.playsinline) {
                this.$refs.video.setAttribute("playsinline", this.playsinline)
                this.$refs.video.setAttribute("webkit-playsinline", this.playsinline)
                this.$refs.video.setAttribute("x5-playsinline", this.playsinline)
                this.$refs.video.setAttribute("x5-video-player-type", "h5")
                this.$refs.video.setAttribute("x5-video-player-fullscreen", false)
            }
        },
        initPlayer() {
            // ios fullscreen
            this.setPlaysinline()

            // cross origin
            if (this.crossOrigin) {
                this.$refs.video.crossOrigin = this.crossOrigin
                this.$refs.video.setAttribute("crossOrigin", this.crossOrigin)
            }
            let options = this.playerOptions
            if (this.isLive) {
                delete options.controlBar.children.durationDisplay
                delete options.controlBar.children.timeDivider
            }
            this.player = videojs(this.$refs.video, this.playerOptions)

            if (this.seekTo) {
                setTimeout(() => {
                    this.player.currentTime(this.seekTo)
                    this.player.play()
                })
            }

            // set emits and check methods in current component
            DEFAULT_EVENTS.forEach(event => {
                if (event === "timeupdate") this.player.on(event, e => this.emitPlayerState(event, this.player, e))
                else this.player.on(event, e => this.emitPlayerState(event, this.player, e))
            })
        },
        error() {
            const player = this.player
            const playerError = player.error()

            this.logError(playerError)

            if (playerError && playerError.hlsError) {
                if (playerError.hlsError.details === "fragLoadError") {
                    const seconds = this.player.currentTime()
                    this.$emit("rerender", seconds)
                }

                if (playerError.hlsError.details === "manifestLoadError") {
                    fetch(
                        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
                        // "https://www3.doubleclick.net",
                        // "https://static.ads-twitter.com/uwt.js"
                        { method: "HEAD", mode: "no-cors", cache: "no-store" }
                    )
                        .then(() => {})
                        .catch(() => {
                            this.adblockEnabled = true
                        })
                }
            }
        },
        logError(playerError) {
            const skipError = [
                /*'bufferStalledError', 'bufferAppendError', 'fragLoadError'*/
            ].includes(playerError?.hlsError?.details)

            if (!this.errorLogged && !skipError && playerError?.hlsError?.fatal !== false) {
                try {
                    let errorContext = {}

                    try {
                        JSON.stringify(playerError)
                        errorContext = playerError
                    } catch (err) {
                        console.warn(err)
                        errorContext = {
                            code: playerError?.code,
                            message: playerError?.message,
                            hlsError: {
                                details: playerError?.hlsError?.details,
                                event: playerError?.hlsError?.event,
                                fatal: playerError?.hlsError?.fatal,
                                type: playerError?.hlsError?.type,
                                error: "Error can not be stringify"
                            }
                        }
                    }

                    axios.post(`/player-logs`, {
                        data: {
                            error: errorContext,
                            location: window.location.href,
                            sources: this.playerOptions.sources
                        }
                    })
                } catch (err) {
                    console.error(err)
                }

                this.errorLogged = true
            }
        },
        setTouchEvent() {
            this.player.on("touchstart", e => {
                if (e.target.nodeName === "VIDEO") {
                    if (this.player.userActive() === false) {
                        this.player.pause()
                    } else {
                        this.player.userActive(true)
                        this.player.play()
                    }
                }
            })
        },
        toggleActiveIdComponent(get = false) {
            if (get) return localStorage.getItem(keyActiveId)
            localStorage.setItem(keyActiveId, this.componentId)
        },
        stoppingOtherPlayers($currentPlayer) {
            const players = videojs.getAllPlayers()
            if (players.length < 2) return
            players.forEach(player => {
                if (!player.paused()) {
                    player.pause()
                }
                if (player.el_ === $currentPlayer) {
                    setTimeout(() => player.play().then(() => player.pause()), 0)
                }
            })
        },
        fullscreenchange(p, e) {
            let inactivityTimeout = null
            if (this.player.isFullscreen()) {
                this.player.on("mousemove", () => {
                    if (inactivityTimeout !== null) {
                        clearTimeout(inactivityTimeout)
                    }

                    inactivityTimeout = setTimeout(() => {
                        document.querySelector(`#${this.componentId} .vjs-control-bar`).classList.add("vjs-fade-out")
                    }, 1000)
                    document.querySelector(`#${this.componentId} .vjs-control-bar`).classList.remove("vjs-fade-out")
                })

                this.stoppingOtherPlayers(e.target)
            } else {
                document.querySelector(`#${this.componentId} .vjs-control-bar`).classList.remove("vjs-fade-out")
            }
            this.toggleActiveIdComponent()
        },
        lessonLiveEnded() {},
        toggleButtonPlay() {
            if (!this.player) return
            if (this.player.paused()) {
                this.player.play()
            } else {
                this.player.pause()
            }
        },
        settingsLiveVideo() {
            const componentPlayer = this.player
            let disabledPause = true
            if (this.configLiveWebinar.startTime > 0) componentPlayer.currentTime(this.configLiveWebinar.startTime)
            componentPlayer.play()

            componentPlayer.on("ended", () => {
                this.lessonLiveEnded()
            })
            if (this.liveTrimEnding) {
                componentPlayer.on("timeupdate", () => {
                    if (componentPlayer.currentTime() > +this.duration - +this.liveTrimEnding - 1) {
                        disabledPause = false
                        componentPlayer.muted()
                        componentPlayer.pause()
                        this.lessonLiveEnded()
                        this.configLiveWebinar.ended = true
                    }
                })
            }

            componentPlayer.on("pause", customEvent => {
                if (disabledPause) {
                    customEvent.isPropagationStopped()
                    customEvent.preventDefault()
                    customEvent.stopImmediatePropagation()
                    customEvent.stopPropagation()
                    componentPlayer.play()
                }
            })
        },
        emitPlayerState(event, value, e) {
            // emit to parent
            this.$emit(event, value)
            // if current component has [event]
            if (this[event]) this[event](value, e)
        },
        ready(player) {
            if (this.isLive && !this.configLiveWebinar.ended) this.settingsLiveVideo(player)
            // set touch event
            this.setTouchEvent(player)
            // set Quality selecter
            if (this.videoType.name === "application/x-mpegURL" && this.isHlsSupported) {
                new HlsQualitySelector(this.player).setup()
            }

            new VjsSeeking(this.player).init()
        },
        firstplay() {
            this.isFisrtStart = true
            this.$emit("start")
        },
        play() {
            this.toggleActiveIdComponent()
        },
        async setSources() {
            if (this.isHlsSupported) {
                this.playerOptions.sources = [
                    {
                        src: this.videoLink,
                        type: this.videoType.name
                    }
                ]
            } else {
                const result = await axios.get(`/files/${this.uuid}/mp4`)

                this.playerOptions.sources = [
                    {
                        src: result.data.data.url,
                        type: "video/mp4"
                    }
                ]
            }
        }
    }
}
</script>

<style lang="sass">
.vjs-fullscreen.vjs-user-inactive
    cursor: none

.vjs-fade-out
    opacity: 0 !important
    transition: .3s !important
    &:hover
        opacity: 1 !important

.videojs-player
    .red-alert
        position: absolute
        top: 10px
        left: 10px
        right: 10px
        z-index: 55
    &__vertical
        .video-js
            padding-top: 56.25% !important
    &__audio
        max-width: 480px
        width: 100%
        max-height: 80px
        height: 80px
        border-radius: 10px
        box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
        .vjs-progress-control
            width: calc(100% - 12px) !important
            margin-left: 6px !important
        .vjs-control-bar
            background: #fff !important
            border-radius: 10px
        .vjs-fullscreen-control, .vjs-playback-rate, .vjs-custom-quality-selector, .vjs-custom-seeking
            display: none !important
        .vjs-time-control, .vjs-icon-placeholder:before
            color: rgb(74, 84, 100) !important
            font-size: 1.2rem
        .vjs-play-progress
            background: #3965FF !important
        .vjs-play-progress::before
            color: #3965FF !important
        .vjs-volume-level
            background-color: #000 !important
        .vjs-slider
            background: rgb(115 133 159 / 23%) !important
        .vjs-load-progress div
            background: rgb(115 133 159 / 37%) !important
        .vjs-fluid
            background: #fff !important
            padding-top: 80px !important
            border-radius: 10px
        .vjs-big-play-button
            background:  #3965FF !important
            height: 44px !important
            width: 44px !important
            font-size: 2rem !important
            line-height: 44px !important
            .vjs-icon-placeholder:before
                color: #fff !important
                font-size: 1.5rem !important
</style>
