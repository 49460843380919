<template>
    <div class="lesson-header-container">
        <LessonProgramHandler v-if="$screenWidth < 1300" />

        <LessonNavButtons
            class="mb-20"
            v-if="course.navigation_type_id && course.navigation_type_id === 2"
            :l="langs"
            :type="course.navigation_type_id"
            :lesson="lesson"
            :paginate-info="lessonDto.paginate_info"
        />
        <div v-else-if="$screenWidth < 1300" class="mb-20"></div>

        <div class="shadow-container lesson-header hide-border-right-radius">
            <div
                v-if="!course.navigation_type_id || course.navigation_type_id === 1"
                class="flex relative"
                :class="{ 'full-width': $screenWidth > 570 }"
            >
                <LessonNavButtons
                    :l="langs"
                    :type="course.navigation_type_id"
                    :lesson="lesson"
                    :paginate-info="lessonDto.paginate_info"
                />
            </div>
            <div class="lesson-header-content">
                <default-title
                    class="lesson-header-title"
                    :size="20"
                    :line-height="32"
                    :weight="600"
                    font-family="Inter, sans-serif"
                >
                    {{ title }}
                    <a v-if="editLink" target="_blank" :href="editLink">
                        <EditIcon class="mt-2" width="22" height="22" fill="#000" />
                    </a>
                </default-title>

                <default-description
                    :size="14"
                    :weight="400"
                    v-if="(lesson.model_node && lesson.model_node.parent) || !lessonDto.has_only_one_section"
                    class="mt-5 black"
                >
                    <template v-if="!lessonDto.has_only_one_section">
                        <a :href="lesson.link" class="lesson-header-link"> {{ lesson.title }} </a>
                    </template>
                    <template v-else-if="lesson.model_node && lesson.model_node.parent">
                        <a :href="lesson.model_node.parent.course_nodeble.link" class="lesson-header-link">
                            {{ lesson.model_node.parent.course_nodeble.title }}
                        </a>
                    </template>
                </default-description>
            </div>
        </div>
    </div>
</template>

<script>
import LessonProgramHandler from "@lesson/components/LessonProgramHandler.vue"
import { COURSE, LESSON, LESSON_DTO, SECTION } from "@lesson/shared/state/lesson-getters"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import LessonNavButtons from "@lesson/components/LessonNavButtons.vue"
import DefaultDescription from "@components/Typography/DefaultDescription.vue"
import EditIcon from "@icons/EditIcon.vue"

export default {
    name: "LessonHeader",
    components: { LessonProgramHandler, EditIcon, DefaultDescription, LessonNavButtons, DefaultTitle },
    props: {
        editLink: {
            type: String,
            default: "course"
        }
    },
    computed: {
        lesson() {
            return this.$store.getters[LESSON]
        },
        section() {
            return this.$store.getters[SECTION]
        },
        langs() {
            return this.$store.getters["langs/getLangs"]
        },
        lessonDto() {
            return this.$store.getters[LESSON_DTO]
        },
        course() {
            return this.$store.getters[COURSE]
        },
        title() {
            return this.lessonDto.has_only_one_section ? this.lesson.title : this.section.title
        }
    }
}
</script>

<style scoped lang="sass">
.lesson-header-title
    display: flex
    align-items: center
    a
        height: 25px
        margin-left: 6px
.lesson-header
    display: flex
    align-items: center
    justify-content: space-between
    min-height: 86px
    flex-direction: row-reverse
    @media screen and ( max-width: 1367px )
        flex-direction: column
        align-items: flex-start
.lesson-header-content
    width: 100%
.lesson-header-link
    color: #3965ff !important
    text-decoration: none !important
    font-family: Ubuntu, serif !important
</style>
