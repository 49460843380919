<template>
    <div
        class="create-popup create-course"
        :class="[
            {
                'fixed-position': !insideBlock && !isAbsolute,
                'modal-inside': insideBlock,
                'absolute-position': isAbsolute,
                animation: withAnimation
            },
            leftPosition ? `left-${leftPosition}` : '',
            rightPosition ? `right-${rightPosition}` : ''
        ]"
    >
        <div :class="{ wrapper: !floatedInsideComponent && !fullWidth, 'wrapper-full': fullWidth }">
            <div
                :class="{
                    'filter-overlay': !insideBlock,
                    'filter-overlay__full-page': overflowFullPage,
                    ['filter-overlay__' + overflowColor]: overflowColor
                }"
                @click="closeOnOutsideClick"
            ></div>

            <div
                class="orders__new-order-form"
                :class="[
                    {
                        'create-popup__slides': !floatedInsideComponent,
                        'no-top-padding': noTopPadding,
                        'inline-block': isInlineBlock,
                        'orders__new-order-form_new': isNew
                    },
                    verticalPositions || ''
                ]"
                :style="{ width: insideBlock ? '100%' : 'auto' }"
                @click.self="closeOnOutsideClick"
            >
                <div
                    :style="containerStyle"
                    :class="[
                        {
                            'create-course-slide_fit-content': isFittedToContent,
                            'slide-inside': insideBlock,
                            'modal-style-v2': styleVersion === 2
                        },
                        additionalOuterClass
                    ]"
                >
                    <div
                        class="create-course-slide__inner"
                        :class="[
                            {
                                shadow: insideBlock,
                                'modal-style-v2__inner': styleVersion === 2,
                                relative: showCloseBtn || showCloseBtnV2,
                                'create-course-slide__inner_no-padding': noInnerPadding
                            },
                            additionalClass
                        ]"
                    >
                        <close-button-sm
                            v-if="showCloseBtn"
                            additionalClass="absolute close-right-top"
                            :dangerAccent="closeBtnDanger"
                            :hoverDanger="closeBtnHoverDanger"
                            @close="$emit('closeModal')"
                        />
                        <BigCloseIcon
                            v-if="showCloseBtnV2"
                            class="absolute close-right-top close-button-v2"
                            @click.native="$emit('closeModal')"
                        />
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CloseButtonSm from "@components/Buttons/CloseButtonSm"
import BigCloseIcon from "@icons/BigCloseIcon"

export default {
    name: "ModalWindow",
    components: {
        CloseButtonSm,
        BigCloseIcon
    },
    props: {
        additionalClass: {
            type: [String, Array],
            default: ""
        },
        noInnerPadding: {
            type: Boolean,
            default: false
        },
        isFittedToContent: {
            type: Boolean,
            default: false
        },
        additionalOuterClass: {
            type: String,
            default: ""
        },
        modalWidth: {
            type: [Number, String],
            default: 440
        },
        modalMaxWidth: {
            type: [Number, String],
            default: null
        },
        insideBlock: {
            type: Boolean,
            default: false
        },
        parent: String,
        styleVersion: Number,
        overflowFullPage: {
            type: Boolean,
            default: false
        },
        overflowColor: {
            type: String,
            default: ""
        },
        floatedInsideComponent: {
            type: Boolean,
            default: false
        },
        closeBtnDanger: {
            type: Boolean,
            default: false
        },
        closeBtnHoverDanger: {
            type: Boolean,
            default: false
        },
        showCloseBtn: {
            type: Boolean,
            default: false
        },
        showCloseBtnV2: {
            type: Boolean,
            default: false
        },
        noTopPadding: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        isAbsolute: {
            type: Boolean,
            default: false
        },
        withAnimation: {
            type: Boolean,
            default: true
        },
        isInlineBlock: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        leftPosition: {
            type: Number
        },
        rightPosition: {
            type: Number
        },
        verticalPositions: {
            type: String,
            default: null
        },
        restrictOutsideClickClose: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        containerStyle() {
            let width = null
            if (this.fullWidth) {
                width = "100%"
            } else if (typeof this.modalWidth === "number") {
                width = `${this.modalWidth}px`
            } else {
                width = this.modalWidth
            }

            return {
                width: width,
                maxWidth: this.modalMaxWidth && this.modalMaxWidth
            }
        }
    },
    methods: {
        closeOnOutsideClick() {
            if (!this.restrictOutsideClickClose) this.$emit("closeModal")
        }
    }
}
</script>

<style lang="sass" scoped>
.orders__new-order-form
    &.vertical-center
        top: 0
        transform: translateY(-50%)
.absolute-position
    position: absolute
.wrapper-full
    max-width: 100vw
    padding: 0 38px
.modal-inside,
.slide-inside
    position: absolute
    left: 0
    width: 100%
.modal-inside
    top: -1px
.slide-inside
    right: 0

.shadow
    box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)

.create-course-slide
    &__inner
        padding: 30px 20px
        &_no-padding
            padding: 0
.close-button-v2
    cursor: pointer
    stroke: #3E4755
    &:hover
        stroke: #DD4141
.modal-style-v2
    position: relative
    max-width: 440px
    @media (max-width: 480px)
        width: 100%
    &__inner
        padding: 30px 24px 24px !important
        @media (max-width: 480px)
            padding: 20px 10px 20px !important
</style>

<style lang="sass">
@import 'resources/sass/vars'

.no-top-padding
    padding-top: 0 !important

.modal-buttons
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 10px
    transition: $transitionTime
.modal-data
    height: auto !important
.animation
    animation: modalFadeUp 0.1s

@keyframes modalFadeUp
    0%
        opacity: 0
        transform: translateY(-10px)
    100%
        opacity: 1
        transform: translateY(0px)
</style>
