<template>
    <div class="file-preview">
        <div class="file-preview-container">
            <div ref="content" class="file-preview-content" :class="file.extension">
                <template v-if="mount">
                    <iframe
                        v-if="
                            file.extension === 'doc' ||
                            file.extension === 'docx' ||
                            file.extension === 'ppt' ||
                            file.extension === 'pptx'
                        "
                        :src="`https://view.officeapps.live.com/op/embed.aspx?src=${file.url}`"
                        width="100%"
                        :height="`${getContentHeight()}px`"
                        frameBorder="0"
                    ></iframe>

                    <template v-else-if="file.extension === 'pdf'">
                        <PdfViewer
                            :allow-download="file.allow_doc_download"
                            v-if="isViewerEnabled()"
                            :path="file.url"
                        />
                        <blue-alert v-else>
                            <default-title large>
                                {{ t("expert.pdf_preview_not_available") }} {{ getPlatform().description }}
                            </default-title>
                            <default-title
                                class="mt-10"
                                v-html="t('expert.try_download_file_by_link', { ':href': file.url })"
                                large
                            >
                            </default-title>
                        </blue-alert>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import BlueAlert from "@components/Alerts/BlueAlert.vue"
import PdfViewer from "@components/Files/PdfViewer.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import platform from "platform"

export default {
    components: { DefaultTitle, BlueAlert, PdfViewer },
    props: {
        file: {
            type: Object,
            default: () => {}
        }
    },
    name: "FilePreview",
    data() {
        return {
            mount: false
        }
    },
    mounted() {
        this.mount = true
    },
    methods: {
        getPlatform() {
            return platform
        },
        isViewerEnabled() {
            if (!platform) {
                return true
            }

            if (platform.name === "Safari" && platform.version <= "16.4") {
                return false
            }
            if (platform.name === "Chrome" && platform.version <= "103") {
                return false
            }

            return true
        },
        getContentHeight() {
            //this?.$el?.querySelector(".file-preview-content")?.getBoundingClientRect()?.height - 40 || 500
            return window.innerHeight
        }
    }
}
</script>

<style lang="sass" scoped>
.file-preview
    &::v-deep
        .pdfobject-container
            height: 500px
    .file-preview-header
        height: 70px
        display: flex
        align-items: center
        justify-content: space-between
        padding: 0 34px 0 24px
    .file-preview-container
        height: 100%
        width: 100%
        flex: 1
        display: flex
        flex-direction: column
    .file-preview-content
        &.doc, &.docx
            flex: 1
            overflow: auto
</style>

