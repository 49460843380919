<template>
    <div class="html-renderer" v-html="html"></div>
</template>

<script>
export default {
    props: {
        value: {
            default: ""
        }
    },
    name: "HtmlComponent",
    computed: {
        html() {
            return this.value.replace(/(\r\n|\n|\r)/gm, "")
        }
    }
}
</script>

<style scoped lang="sass">

</style>
