<template>
    <div class="alert-bad-connection" v-if="connectionBad || connectionNo">
        <div v-if="connectionBad" class="alert-bad-connection__item course__shadow">
            {{ t("course.connection_bad") }}..
        </div>
        <div v-if="connectionNo" class="alert-bad-connection__item course__shadow">
            {{ t("course.connection_no") }}
        </div>
    </div>
</template>

<script>
import KeyTranslator from "@mixins/keyTranslator"

export default {
    name: "AlertBadConnection",
    mixins: [KeyTranslator],
    props: {
        langsProp: {
            type: Object
        }
    },
    data() {
        return {
            langsKeys: null,
            connectionBad: false,
            connectionNo: false,
            displayConnectionBad: true,
            displayConnectionNo: true
        }
    },
    methods: {
        detectConnectInternet() {
            window.addEventListener("online", () => {
                this.connectionNo = false
            })

            window.addEventListener("offline", () => {
                this.connectionNo = true
            })
            const connectionInfo =
                navigator.connection || navigator.mozConnection || navigator.webkitConnection || navigator.msConnection
            if (connectionInfo) {
                const check2gConnect = connectName => {
                    this.connectionBad = ["slow-2g", "2g"].includes(connectName)
                }
                connectionInfo.onchange = event => {
                    if (event.target) check2gConnect(event.target.effectiveType)
                }
                if (connectionInfo?.effectiveType) {
                    check2gConnect(connectionInfo.effectiveType)
                }
            }
        },
        getLangs() {
            let timerInterval = null
            timerInterval = setInterval(() => {
                if (window.langs) {
                    clearInterval(timerInterval)
                    this.langsKeys = window.langs
                }
            }, 1000)
        }
    },
    mounted() {
        if (this.langsProp || window.langs) {
            this.langsKeys = this.langsProp || window.langs
        } else {
            this.getLangs()
        }
        this.detectConnectInternet()
    }
}
</script>

<style scoped lang="sass">
.alert-bad-connection
    position: fixed
    top: 40px
    right: 30px
    &__item
        background-color: rgba(255, 116, 57, 0.7)
        padding: 10px 14px
        z-index: 100
        font-size: 14px
        color: #fff
</style>
