<template>
    <div>
        <div class="custom-calendar__week grid grid-cols-7 gap-y-15 px-16 pt-10 pb-15">
            <div
                class="custom-calendar__week_day custom-calendar__days_day"
                v-for="week in weeks"
                :key="'week-day-' + week"
            >
                <span>{{ translate(week) }}</span>
            </div>
        </div>

        <div class="custom-calendar__days grid gap-y-15">
            <div
                class="custom-calendar__days_row grid grid-cols-7 px-16"
                v-for="(row, idx) in rows"
                :key="'custom-calendar__days_row-' + idx"
            >
                <div
                    class="custom-calendar__days_day cursor-pointer"
                    v-for="(date, index) in row"
                    :key="index"
                    :class="{
                        disabled: disabledDay(date) || (disableMonthChange && date.getMonth() !== currMonth),
                        'custom-calendar__days_blank': date.getMonth() !== currMonth,
                        'custom-calendar__days_between': isBetween(date),
                        'custom-calendar__days_first': isFirstSelect(date),
                        'custom-calendar__days_end': isEndSelect(date),
                        'custom-calendar__days_first-double-click':
                            allowDoubleClick && isFirstSelect(date) && !samePeriodDates,
                        'custom-calendar__days_end-double-click':
                            allowDoubleClick && isEndSelect(date) && !samePeriodDates
                    }"
                    :data-is-between="isBetween(date)"
                    @click="pickDate(date)"
                >
                    <span>{{ date.getDate() }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LocalTranslator from "../../mixins/local-translator"

export default {
    name: "CustomCalendarDays",
    props: {
        rows: {
            type: Array,
            required: true
        },
        todayDate: {
            type: Date,
            required: true
        },
        allowOldDates: {
            type: Boolean,
            required: true
        },
        // Заблокировать старые даты начиная с [blockOldDatesFrom]
        // Работает только вместе с [allowOldDates]
        blockOldDatesFrom: {
            type: Date,
            default: null
        },
        pickedDate: {
            type: Date,
            required: true
        },
        endDate: {
            type: Date,
            required: true
        },
        hoverEndDate: {
            type: Date,
            required: true
        },
        currYear: {
            type: Number,
            required: true
        },
        currMonth: {
            type: Number,
            required: true
        },
        disableMonthChange: {
            type: Boolean,
            default: false
        },
        allowDoubleClick: {
            type: Boolean,
            default: true
        },
        endDateSelected: {
            type: Boolean,
            default: false
        }
    },
    mixins: [LocalTranslator],
    data() {
        return {
            localKeys: "course",
            weeks: [
                "course.weeks_short.0",
                "course.weeks_short.1",
                "course.weeks_short.2",
                "course.weeks_short.3",
                "course.weeks_short.4",
                "course.weeks_short.5",
                "course.weeks_short.6"
            ]
        }
    },

    computed: {
        samePeriodDates() {
            return this.endDateSelected && this.pickedDate && this.endDate && this.pickedDate === this.endDate
        }
    },
    methods: {
        disabledDay(date) {
            if (!this.allowOldDates) {
                const dateFrom = this.blockOldDatesFrom || this.todayDate
                if (dateFrom === date) console.log(dateFrom, date)
                return dateFrom > date
            }
            return false
        },
        // Установликаем клик
        pickDate(date) {
            this.$emit("pickDate", date)
        },
        // Дни между первым и последним
        isBetween(date) {
            return (
                this.pickedDate.valueOf() !== this.endDate.valueOf() && // Если первый выбранный дата не равно второму выбранному
                date.valueOf() >= this.pickedDate.valueOf() &&
                date.valueOf() <= this.endDate.valueOf()
            )
        },
        // Первый клик
        isFirstSelect(date) {
            return date.valueOf() >= this.pickedDate.valueOf() && date.valueOf() <= this.hoverEndDate.valueOf()
        },
        // Последний клик
        isEndSelect(date) {
            return this.pickedDate.valueOf() !== this.endDate.valueOf() && date.valueOf() === this.endDate.valueOf()
        }
    }
}
</script>

<style scoped lang="sass">
.custom-calendar
    &__days
        &_row
            @media screen and (max-width: 480px)
                padding-left: 0
                padding-right: 0
        &_day
            display: flex
            align-items: center
            justify-content: center
            color: #52565C
            font-weight: 500
            font-size: 14px
            span
                width: 24px
                height: 24px
                display: flex
                align-items: center
                justify-content: center
                transition: 200ms
                border-radius: 50%
            &:hover
                color: #fff
                span
                    background-color: #3965FF
        &_first-double-click span
            border-radius: 50% 0 0 50%
        &_end-double-click span
            border-radius: 0 50% 50% 0
        &_blank
            color: #DDDDDD !important
            span
                background-color: transparent !important
        &_between:not(&_first):not(&_end)
            background-color: #ebeffe
        &_first, &_end
            color: #fff
            position: relative
            z-index: 0
            &[data-is-between='true']:before
                content: ""
                position: absolute
                top: 0
                bottom: 0
                background-color: #ebeffe
                width: 50%
                z-index: -1
            span
                background-color: #3965FF !important
        &_first:before
            right: 0
        &_end:before
            left: 0

    &__week
        @media screen and (max-width: 480px)
            padding-left: 0
            padding-right: 0
        &_day
            background-color: transparent !important
            font-weight: 500
            font-size: 12px
            line-height: 12px
            letter-spacing: 0.03em
            text-transform: uppercase
            span
                color: #151515 !important
                background-color: transparent !important
</style>
