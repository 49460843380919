<template>
    <button @click="$emit('update:isOpen', !isOpen)" class="collapse-handler">
        {{ title }}
        <svg
            :class="{ 'rotate-180': !isOpen }"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            stroke="#52565C"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.83337 8.3335L10 12.5002L14.1667 8.3335"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </button>
</template>

<script>
export default {
    props: {
        isOpen: {
            required: true,
            type: [Boolean, Number]
        },
        title: {
            type: String,
            default: ""
        }
    },
    name: "CollapseHandler"
}
</script>

<style scoped lang="sass">
.collapse-handler
    padding: 5px 10px
    display: flex
    align-items: center
    border-radius: 4px
    background-color: hsla(217, 100%, 97%, 1)
    color: hsla(216, 6%, 34%, 1)
    font-size: 14px
    font-weight: 400
    line-height: 160%
    svg
        margin-left: 8px
        transition: .2s
    &:hover
        svg
            stroke: hsla(227, 100%, 61%, 1)
</style>
