<template>
    <div class="lesson-get-access course__content_actions">
        <template v-if="lessonDto.course_access.can_pre_subscribe || lessonDto.course_access.is_course_pre_subscribed">
            <template v-if="!auth">
                <button
                    data-default-auth-handler
                    class="course__content_actions__item course__content_actions__item_green"
                >
                    {{ t("course.login_to_your_account") }}
                </button>

                <default-title font-family="Inter, sans-serif" :size="20" :weight="400">
                    {{ t("contacts.or") }}
                </default-title>
            </template>
            <a
                v-if="lessonDto.course_access.is_pre_subscription_enabled"
                data-prerecording
                :data-product-id="course.product_id"
                data-prerecording-handler
                data-default-auth-handler
                :class="{
                    disabled: lessonDto.course_access.is_course_pre_subscribed
                }"
                class="course__content_actions__item course__content_actions__item_green"
                href="javascript:void(0)"
                onclick="fbqTrack('clickGetAccess')"
            >
                {{
                    t(
                        lessonDto.course_access.is_course_pre_subscribed
                            ? "course.pre_entry_success"
                            : "course.pre_entry"
                    )
                }}
            </a>
        </template>

        <flex-container class="gap-25 buttons-container" align="center" v-else>
            <template v-if="!auth">
                <button
                    data-default-auth-handler
                    class="course__content_actions__item course__content_actions__item_green"
                >
                    {{ t("course.login_to_your_account") }}
                </button>

                <default-title font-family="Inter, sans-serif" :size="20" :weight="400">
                    {{ t("contacts.or") }}
                </default-title>
            </template>
            <a
                :href="lessonDto.link_get_access"
                onclick="fbqTrack('clickGetAccess')"
                class="course__content_actions__item course__content_actions__item_green"
            >
                {{
                    lessonDto.title_get_access ? lessonDto.title_get_access : t("create-course.get_access_to_the_course")
                }}
            </a>
        </flex-container>
    </div>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import { COURSE, LESSON_DTO } from "@lesson/shared/state/lesson-getters"

export default {
    name: "LessonGetAccess",
    components: { DefaultTitle, FlexContainer },
    methods: {
        getAttribute() {
            if (window.user_id) {
                return "data-default-auth-handler"
            } else {
                return "data-prerecording-handler"
            }
        }
    },
    computed: {
        auth() {
            return !!window.user
        },
        course() {
            return this.$store.getters[COURSE]
        },
        lessonDto() {
            return this.$store.getters[LESSON_DTO]
        }
    }
}
</script>

<style scoped lang="sass">
.lesson-get-access
    margin-bottom: 100px
    margin-top: 30px
    .buttons-container
        @media (max-width: 990px)
            flex-direction: column !important
            align-self: center
            .course__content_actions__item
                margin-bottom: 0px
</style>
