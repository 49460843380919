<template>
    <modal-container block centred :top="120" :padding="[20]" :min-width="440" :max-width="440">
        <default-title
            v-html="t('expert.something_went_wrong')"
            text-color="#323030"
            :size="16"
            :weight="600"
            :line-height="25"
            font-family="Inter, sans-serif"
        >
        </default-title>

        <default-title
            v-html="t('quiz.camera_is_busy')"
            class="mb-10 mt-10"
            :size="16"
            :weight="400"
            :text-color="'#52565C'"
            :line-height="25"
            font-family="Inter, sans-serif"
        >
        </default-title>

        <flex-container class="mt-30">
            <DefaultButton
                @click="$emit('cancel')"
                background="cancel"
                class="mr-10 inter"
                full-width
                :height="45"
                :text="t('expert.cancel')"
            />
            <DefaultButton
                class="inter"
                @click="
                    $emit('handle', {
                        video: true,
                        audio: true
                    })
                "
                background="green"
                full-width
                :height="45"
                :text="t('quiz.try_again')"
            />
        </flex-container>
    </modal-container>
</template>

<script>
import DefaultButton from "@components/Buttons/DefaultButton.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import ModalContainer from "@expert-components/Modals/ModalContainer.vue"

export default {
    name: "RecordingVideoIsUseModal",
    components: { FlexContainer, DefaultButton, DefaultTitle, ModalContainer }
}
</script>

<style scoped lang="sass">

</style>
