<template>
    <div class="blue-alert">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "BlueAlert"
}
</script>

<style scoped lang="sass">
.blue-alert
    padding: 10px
    border-radius: 7px
    border: 1px solid #B3DBF1
    background: #F1F8FC
    color: #52565C
    font-size: 14px
    font-weight: 400
    line-height: 150%
    &.inter
        font-family: Inter, sans-serif
        font-size: 13px
    strong
        font-weight: 600
        color: #3965FF
    a, ::v-deep a
        color: #3451BE
        text-decoration: underline
        text-underline-offset: 2px
</style>
