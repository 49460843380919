<template>
    <div
        :class="[
            {
                'success-msg': status == 'success',
                error: status == 'error'
            },
            additionalClass
        ]"
        v-html="msgText"
    ></div>
</template>

<script>
export default {
    name: "FormMsg",
    data() {
        return {}
    },
    props: {
        msgText: [String, Error],
        status: String,
        additionalClass: String
    }
}
</script>
