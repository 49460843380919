
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#3E4755" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.58579 14.4142L8.11612 14.9445L7.58579 14.4142ZM10.4142 14.4142L9.88388 14.9445L10.4142 14.4142ZM10.5858 14.5858L11.1161 14.0555L10.5858 14.5858ZM13.4142 14.5858L12.8839 14.0555H12.8839L13.4142 14.5858ZM15.5858 12.4142L15.0555 11.8839L15.5858 12.4142ZM18.4142 12.4142L18.9445 11.8839L18.4142 12.4142ZM5 4.75H19V3.25H5V4.75ZM19.25 5V19H20.75V5H19.25ZM19 19.25H5V20.75H19V19.25ZM4.75 19V5H3.25V19H4.75ZM5 19.25C4.86193 19.25 4.75 19.1381 4.75 19H3.25C3.25 19.9665 4.0335 20.75 5 20.75V19.25ZM19.25 19C19.25 19.1381 19.1381 19.25 19 19.25V20.75C19.9665 20.75 20.75 19.9665 20.75 19H19.25ZM19 4.75C19.1381 4.75 19.25 4.86193 19.25 5H20.75C20.75 4.0335 19.9665 3.25 19 3.25V4.75ZM5 3.25C4.0335 3.25 3.25 4.0335 3.25 5H4.75C4.75 4.86193 4.86193 4.75 5 4.75V3.25ZM4.53033 18.5303L8.11612 14.9445L7.05546 13.8839L3.46967 17.4697L4.53033 18.5303ZM9.88388 14.9445L10.0555 15.1161L11.1161 14.0555L10.9445 13.8839L9.88388 14.9445ZM13.9445 15.1161L16.1161 12.9445L15.0555 11.8839L12.8839 14.0555L13.9445 15.1161ZM17.8839 12.9445L19.4697 14.5303L20.5303 13.4697L18.9445 11.8839L17.8839 12.9445ZM16.1161 12.9445C16.6043 12.4564 17.3957 12.4564 17.8839 12.9445L18.9445 11.8839C17.8706 10.8099 16.1294 10.8099 15.0555 11.8839L16.1161 12.9445ZM10.0555 15.1161C11.1294 16.1901 12.8706 16.1901 13.9445 15.1161L12.8839 14.0555C12.3957 14.5436 11.6043 14.5436 11.1161 14.0555L10.0555 15.1161ZM8.11612 14.9445C8.60427 14.4564 9.39573 14.4564 9.88388 14.9445L10.9445 13.8839C9.8706 12.8099 8.1294 12.8099 7.05546 13.8839L8.11612 14.9445ZM9.941 8.846C9.941 9.45079 9.44979 9.942 8.845 9.942V11.442C10.2782 11.442 11.441 10.2792 11.441 8.846H9.941ZM8.845 9.942C8.24148 9.942 7.75 9.45105 7.75 8.846H6.25C6.25 10.2789 7.41252 11.442 8.845 11.442V9.942ZM7.75 8.846C7.75 8.24095 8.24148 7.75 8.845 7.75V6.25C7.41252 6.25 6.25 7.41305 6.25 8.846H7.75ZM8.845 7.75C9.44979 7.75 9.941 8.24121 9.941 8.846H11.441C11.441 7.41279 10.2782 6.25 8.845 6.25V7.75Z"
        />
    </svg>
</template>
<script>
export default {
    name: "ImageIcon"
}
</script>
