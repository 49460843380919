<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#98A2B0" xmlns="http://www.w3.org/2000/svg">
        <rect x="5" y="9" width="14" height="1.5" rx="0.75" />
        <rect x="5" y="13.498" width="14" height="1.5" rx="0.75" />
    </svg>
</template>

<script>
export default {
    name: "MoveHandlerIcon"
}
</script>


