import axios from "~axios"
import { EventBus } from "~events"

export default class LessonRepository {
    static async index({ course, lesson }) {
        const { data } = await axios.get(`/courses/${course}/lessons-new/${lesson}`)

        return data
    }

    static async update({ course, lesson, payload }) {
        const { data } = await axios.put(`/courses/${course}/lessons-new/${lesson}`, payload)

        return data
    }

    static async copy({ lesson, from }) {
        const { data } = await axios.put(`/lessons/${lesson}/copy/${from}`)

        return data
    }

    static async program({ course }) {
        const { data } = await axios.get(`/courses/${course}/program`)

        return data
    }

    static async create({ course, title }) {
        const { data } = await axios.post(`/courses/${course}/lessons-new`, {
            title
        })

        return data
    }

    static async search(q) {
        const { data } = await axios.get(`/courses/list`, {
            params: {
                type_id: 1,
                lessons_search: q
            }
        })

        return data
    }

    static async courses() {
        const { data } = await axios.get(`/courses-cards?per_page=100`)

        return data
    }

    static async sectionPart({ section }) {
        const { data } = await axios.get(`/public/sections/${section}/part`)

        return data
    }

    static async replaceContents({ section }) {
        const result = await LessonRepository.sectionPart({ section })

        if (result && result.data) {
            const html = result.data.aside
            const doc = new DOMParser().parseFromString(html, "text/html")

            const progress = document.querySelector(".course-progress")

            let aside = document.querySelector(
                ".course-program-container__scroll .simplebar-content > .collapse-wrapper"
            )

            if (!aside) {
                return
            }

            const scroll = document.querySelector(".course-program-container__scroll")

            let scrollTop = 0

            if (scroll) {
                scrollTop = scroll.scrollTop
            }

            const newAside = document.createElement("div")
            newAside.innerHTML = doc.body.querySelector(
                ".course-program-container__scroll > .collapse-wrapper"
            ).outerHTML

            const newProgress = document.createElement("div")

            if (!doc.body.querySelector(".course-progress")) {
                return
            }

            newProgress.innerHTML = doc.body.querySelector(".course-progress").outerHTML

            aside.parentNode.replaceChild(newAside.firstChild, aside)
            progress.parentNode.replaceChild(newProgress.firstChild, progress)

            aside = document.querySelector(".course-program-container__scroll")

            if (scrollTop) {
                aside.scrollTop = scrollTop
            }

            setTimeout(() => {
                if (window.initToggle) {
                    //window.initToggle()
                }

                EventBus.$emit("set:paginate", result.data.paginate_info)
            })
        }
    }
}
