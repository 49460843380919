<template>
    <div class="errors-container">
        <slot></slot>
        <div class="errors-list" :class="{ absolute: areAbsolute }">
            <div
                v-html="strip(error)"
                :style="{ color }"
                class="error"
                :key="index"
                v-for="(error, index) in errors"
            ></div>
            <slot name="customErrors"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#DD4141"
        },
        errors: {
            type: Array,
            default: () => []
        },
        areAbsolute: {
            type: Boolean,
            default: false
        }
    },
    name: "ErrorsContainer",
    methods: {
        strip(val, fillEmpty = false) {
            let str = val.replace(/<[^>]*>?/gm, fillEmpty ? " " : "")

            if (fillEmpty) {
                str = str.replace(/  +/g, " ")
            }

            return str
        }
    }
}
</script>

<style scoped lang="sass">
.error
    font-size: 11px
    line-height: 14px
    font-weight: 400
.errors-list
    width: max-content
    max-width: 100%
</style>
