import ClickOutside from "vue-click-outside"

export default {
    directives: {
        ClickOutside
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        toggle() {
            if (!this.isOpen) {
                this.open()
            } else {
                this.hide()
            }
        },
        hide(event) {
            if (event && event.target && this.allowedTargets) {
                for (const allowedTarget of this.allowedTargets) {
                    if (event.target.classList.contains(allowedTarget)) {
                        return
                    }
                }
            }

            this.isOpen = false
            if (this.onHide) {
                this.onHide()
            }
        },
        async open() {
            this.isOpen = true

            if (this.onOpen) {
                await this.onOpen()
            }

            this.$forceUpdate()
        }
    }
}
