<template>
    <practice-blue-alert v-if="quiz.estimate && quiz.estimate.is_on && quiz.estimate.is_show" class="mb-10">
        {{ t("course.detail.quiz_scores") }}:
        <strong
            ><template v-if="quiz.common_scores_min">{{ quiz.common_scores_min }} -</template>
            {{ quiz.common_scores_max }}</strong
        >
    </practice-blue-alert>
</template>

<script>
import PracticeBlueAlert from "@components/Practice/common/alerts/PracticeBlueAlert.vue"

export default {
    props: {
        quiz: {
            type: Object,
            default: () => {}
        }
    },
    name: "PracticeQuizEstimate",
    components: { PracticeBlueAlert }
}
</script>

<style scoped lang="sass">

</style>
