export default {
    methods: {
        isNotStarted() {
            if (this.interactive) {
                return false
            }

            return this.question.is_not_started
        },
        isSkipped() {
            if (this.interactive) {
                return false
            }

            return this.question.is_skipped
        },
        isCorrect() {
            if (this.interactive || !(this.typeShow === "only_correct_or_not")) {
                return false
            }

            return this.question.attempt_question_result && this.question.attempt_question_result.is_correct
        },
        isIncorrect() {
            if (this.interactive || !(this.typeShow === "only_correct_or_not")) {
                return false
            }

            const isCorrect = this.question.attempt_question_result && this.question.attempt_question_result.is_correct

            return !isCorrect
        }
    }
}
