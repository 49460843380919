<template>
    <div class="custom-calendar">
        <!-- Month       -->
        <CustomCalendarHead
            :currYear="currYear"
            :currMonth="currMonth"
            :firstDate="pickedDate"
            :allow-year-select="allowYearSelect"
            :endDate="endDate"
            :disableMonthChange="disableMonthChange"
            @select="parsePropsDate($event)"
            @prev="changeCurMonth(-1)"
            @next="changeCurMonth(1)"
        />
        <!-- Separator       -->
        <span v-if="displaySeparator" class="custom-calendar__line"></span>
        <!-- Days       -->
        <CustomCalendarDays
            :rows="rows"
            :currMonth="currMonth"
            :currYear="currYear"
            :pickedDate="pickedDate"
            :endDate="endDate"
            :todayDate="todayDate"
            :allowOldDates="allowOldDates"
            :blockOldDatesFrom="blockOldDatesFrom"
            :hoverEndDate="hoverEndDate"
            :disableMonthChange="disableMonthChange"
            :allowDoubleClick="allowDoubleClick"
            :endDateSelected="!selectEndDate"
            @pickDate="handlePick"
        />
    </div>
</template>

<script>
import CustomCalendarHead from "../../components/CustomCalendar/CustomCalendarHead"
import CustomCalendarDays from "../../components/CustomCalendar/CustomCalendarDays"
import LocalTranslator from "../../mixins/local-translator"
import moment from "moment"

let NOW = new Date()
export default {
    name: "CustomCalendar",
    props: {
        // Выбранные даты
        // String => Date
        // Object => { start: Date, end: Date }
        selectDate: {
            type: [String, Object, Date]
        },
        // Выбор даты `От - До`
        allowDoubleClick: {
            type: Boolean,
            default: true
        },
        // Разрешить выбирать старые даты
        allowOldDates: {
            type: Boolean,
            default: true
        },
        // Разрешить выбирать старые даты
        allowYearSelect: {
            type: Boolean,
            default: false
        },
        // Заблокировать старые даты начиная с [blockOldDatesFrom]
        // Работает только вместе с [allowOldDates]
        blockOldDatesFrom: {
            type: Date,
            default: null
        },
        // Формат даты для Сервера
        serverDateFormat: {
            type: String,
            default: "YYYY-MM-DD"
        },
        // Формат даты для Фронта
        frontDateFormat: {
            type: String,
            default: "DD MMMM, YYYY"
        },
        // Разделитель
        displaySeparator: {
            type: Boolean,
            default: false
        },
        needTime: {
            type: Boolean,
            default: true
        },
        timeWithUtc: {
            type: Boolean,
            default: false
        },
        defaultEmit: {
            type: Boolean,
            default: false
        },
        disableMonthChange: {
            type: Boolean,
            default: false
        }
    },
    components: {
        CustomCalendarDays,
        CustomCalendarHead
    },
    data() {
        return {
            localKeys: "course",
            utcOffset: null,
            pickedDate: NOW,
            selectEndDate: false,
            endDate: NOW,
            hoverEndDate: NOW,
            currYear: NOW.getFullYear(),
            currMonth: NOW.getMonth(),
            curDay: NOW.getDate(),
            todayDate:
                !this.allowOldDates && this.blockOldDatesFrom
                    ? this.blockOldDatesFrom
                    : new Date(NOW.getFullYear(), NOW.getMonth(), NOW.getDate())
        }
    },
    mixins: [LocalTranslator],
    created() {
        if (this.selectDate && typeof this.selectDate === "string") {
            const date = moment(this.selectDate)
            if (!date.isValid()) {
                this.selectDate = ""
            }
        }

        this.parsePropsDate()
    },
    computed: {
        // А вдруг поднадобиться когда нибудь :D
        // високосный год
        // leapYear() {
        //     if (this.currYear % 100 === 0) {
        //         return this.currYear % 400 === 0
        //     } else {
        //         return this.currYear % 4 === 0
        //     }
        // },
        // Определяем последний день из пред. месяца
        _lastDateOfPrevMonth() {
            return new Date(this.currYear, this.currMonth, 0).getDate()
        },
        // Определяем сколько дней будем показывать из пред. месяца
        _qtyDaysPrevMonth() {
            return new Date(this.currYear, this.currMonth, 0).getDay()
        },
        // Генерируем дней из пред. месяца
        endOfDaysFromPreviousMonth() {
            return Array.from(
                { length: this._qtyDaysPrevMonth },
                (v, k) =>
                    new Date(
                        this.currYear,
                        this.currMonth - 1,
                        this._lastDateOfPrevMonth - this._qtyDaysPrevMonth + (k + 1)
                    )
            )
        },
        // Генерируем дней из след. месяца
        startOfDaysFromNextMonth() {
            const res = this.daysInMonthCount + this._qtyDaysPrevMonth,
                forFiveWeek = 35 - res,
                forSixWeek = 42 - res,
                isFiveWeekCurrMonth = Math.sign(forFiveWeek) === 1 || Math.sign(forFiveWeek) === 0
            let total = isFiveWeekCurrMonth ? forFiveWeek : forSixWeek
            total = total >= 7 ? 0 : total
            return Array.from({ length: total }, (v, k) => new Date(this.currYear, this.currMonth + 1, k + 1))
        },
        // Количество дней в текущем месяце
        daysInMonthCount() {
            return new Date(this.currYear, this.currMonth + 1, 0).getDate()
        },
        // Генерируем дней из текущего месяца
        daysInMonth() {
            return Array.from(
                { length: this.daysInMonthCount },
                (v, k) => new Date(this.currYear, this.currMonth, k + 1)
            )
        },
        // Собираем дней для показа
        dates() {
            // Первый это дни из пред. месяца
            // Второй это дни из текущего месяца
            // Третий это дни из след. месяца
            return [...this.endOfDaysFromPreviousMonth, ...this.daysInMonth, ...this.startOfDaysFromNextMonth]
        },
        // Сортируем дней для показать как в дизайне
        rows() {
            return this.dates.reduce(function (res, date, idx) {
                if (idx % 7 === 0) {
                    res[res.length] = []
                }
                res[res.length - 1].push(date)
                return res
            }, [])
        }
    },
    methods: {
        handlePick($event) {
            this.pickDate($event)
            this.$emit("pick")
        },
        parsePropsDate(val) {
            if (val) {
                const date = new Date(val)

                //this.$emit("get", moment(val).format(this.serverDateFormat))
                this.pickDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()), false)
                return
            }

            if (this.selectDate) {
                if (this.allowDoubleClick) {
                    if (this.selectDate.start) {
                        const date = new Date(this.selectDate.start)
                        this.pickDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()), true)
                    }
                    if (this.selectDate.end) {
                        const date = new Date(this.selectDate.end)
                        this.pickDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()), true)
                    }
                    if (!this.selectDate.start && !this.selectDate.end) {
                        const date = new Date(this.selectDate)
                        this.pickDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()), true)
                    }
                } else {
                    const date = new Date(this.selectDate)
                    this.pickDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()), true)
                }
            }
            // else {
            //
            // }
        },
        /*
         * Меняем месяца
         * n => Number
         * След. месяц n => 1
         * Пред. месяц n => -1
         * След. год n => 12
         * Пред. год n => -12
         * */
        changeCurMonth(n) {
            let temp = this.currMonth + n
            if (temp < 0) {
                this.currYear -= 1
            } else if (temp >= 12) {
                this.currYear += 1
            }
            this.currMonth = (temp + 12) % 12
        },
        /*
         * Собираем даты после клика
         * d => Date
         * */
        pickDate(d, isIgnoreSend = false) {
            // Когда кликаем первый раз
            if (!this.selectEndDate || d.valueOf() < this.pickedDate.valueOf()) {
                this.endDate = d
                this.pickedDate = d
                this.currMonth = d.getMonth()
                this.currYear = d.getFullYear()
                this.hoverEndDate = d

                if (!this.allowDoubleClick) {
                    if (!isIgnoreSend) {
                        this.sendDate(this.pickedDate, true)
                    }
                } else this.selectEndDate = true
            }
            // Когда кликаем второй раз
            else if (this.allowDoubleClick && this.selectEndDate && d.valueOf() >= this.pickedDate.valueOf()) {
                this.endDate = d
                this.currMonth = d.getMonth()
                this.currYear = d.getFullYear()
                this.selectEndDate = false

                if (!isIgnoreSend)
                    this.sendDate(
                        {
                            start: this.pickedDate,
                            end: this.endDate
                        },
                        false
                    )
            }
        },
        // parametrs:
        //      value: String || Object
        //      single: Boolean
        // example:
        //      value: Date || { start: Date, end: Date }
        // return: Object
        sendDate(value, single = true) {
            let obj = {}
            if (single) {
                const momentDate = moment(value)
                if (this.defaultEmit) {
                    this.$emit("get", momentDate.format(this.serverDateFormat))
                    return
                }
                obj.date = momentDate.format(this.serverDateFormat)
                obj.format = momentDate.format(this.frontDateFormat)
                if (this.needTime) {
                    obj.date += "T00:00:00"
                }
                if (this.timeWithUtc) {
                    obj.dateUtc = this.setTimeShift(obj.date)
                }
            } else {
                for (const valueKey in value) {
                    const momentDate = moment(value[valueKey])
                    if (this.defaultEmit) {
                        obj[valueKey] = momentDate.format(this.serverDateFormat)
                    } else {
                        obj[valueKey] = {
                            date: momentDate.format(this.serverDateFormat),
                            format: momentDate.format(this.frontDateFormat)
                        }
                        if (this.needTime) {
                            obj[valueKey].date += "T00:00:00"
                        }
                        if (this.timeWithUtc) {
                            obj[valueKey].dateUtc = this.setTimeShift(obj[valueKey].date)
                        }
                    }
                }
            }

            this.$emit("get", obj)
        },
        setTimeShift(date) {
            if (!date) return

            if (!this.utcOffset) {
                this.utcOffset = -moment().utcOffset()
            }

            return moment.utc(date).startOf("day").utcOffset(this.utcOffset).format("YYYY-MM-DDTHH:mm:ss")
        }
    }
}
</script>

<style scoped lang="sass">
.custom-calendar
    min-width: 330px
    width: 330px
    @media screen and (max-width: 480px)
        min-width: 100%
        width: 100%
    &__line
        width: 100%
        height: 1px
        background-color: #DDDDDD
        margin: 8px 0
        display: block
</style>
