
<template>
    <svg
        class="dots-menu-icon"
        :class="$hasAttr('hovered')"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 12V14C13.1046 14 14 13.1046 14 12H12ZM12 12H10C10 13.1046 10.8954 14 12 14V12ZM12 12V10C10.8954 10 10 10.8954 10 12H12ZM12 12H14C14 10.8954 13.1046 10 12 10V12ZM12 5V7C13.1046 7 14 6.10457 14 5H12ZM12 5H10C10 6.10457 10.8954 7 12 7V5ZM12 5V3C10.8954 3 10 3.89543 10 5H12ZM12 5H14C14 3.89543 13.1046 3 12 3V5ZM12 19V21C13.1046 21 14 20.1046 14 19H12ZM12 19H10C10 20.1046 10.8954 21 12 21V19ZM12 19V17C10.8954 17 10 17.8954 10 19H12ZM12 19H14C14 17.8954 13.1046 17 12 17V19Z"
        />
    </svg>
</template>
<script>
export default {
    name: "DotsMenuIcon"
}
</script>
<style lang="sass" scoped>
.hovered
    cursor: pointer
    transition: .2s
    &:hover
        fill: #3965FF !important
</style>
