<template>
    <div
        :style="{ backgroundColor: color, height: `${height}px` }"
        :class="{ 'default-divider_vertical': vertical }"
        class="default-divider"
    ></div>
</template>

<script>
export default {
    name: "DefaultDivider",
    props: {
        height: {
            type: [Number, String],
            default: 1,
            required: false
        },
        vertical: {
            type: Boolean,
            default: false,
            required: false
        },
        color: {
            type: String,
            default: "#6082FF",
            required: false
        }
    }
}
</script>

<style scoped lang="sass">
.default-divider
    width: 100%
    border-radius: 100px
    &_vertical
        width: 1px
</style>
