export default class ExternalLoader {
    static SCRIPT(links) {
        return new Promise(resolve => {
            links.forEach(async link => {
                const tag = document.createElement("script")
                const head = document.head || document.getElementsByTagName("head")[0]

                if (link === "/build/js/quill.js") {
                    link += `?v=5`
                }

                tag.src = link
                head.insertBefore(tag, head.firstChild)
                await new Promise(resolve => {
                    tag.addEventListener("load", () => {
                        resolve()
                    })
                })
                resolve()
            })
        })
    }
    static STYLE(links, rel = "stylesheet") {
        return new Promise(resolve => {
            links.forEach(async link => {
                const tag = document.createElement("link")
                const head = document.head || document.getElementsByTagName("head")[0]

                if (link === "/build/css/quill.core.css" || link === "/build/css/quill.snow.css") {
                    link += `?v=6`
                }

                tag.href = link
                tag.rel = rel
                head.insertBefore(tag, head.firstChild)
                await new Promise(resolve => {
                    tag.addEventListener("load", () => {
                        resolve()
                    })
                })
                resolve()
            })
        })
    }
}
