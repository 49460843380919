<template>
    <practice-red-alert class="mt-10 timer">
        <strong class="inline">{{ t("course.practice.question_4") }}</strong>
        {{ t("course.practice.result_saved") }}
    </practice-red-alert>
</template>

<script>
import PracticeRedAlert from "@components/Practice/common/alerts/PracticeRedAlert.vue"

export default {
    name: "PracticeQuizTimeLeft",
    components: { PracticeRedAlert }
}
</script>

<style scoped lang="sass">

</style>
