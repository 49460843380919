<template>
    <div class="practice-check">
        <div :class="{ 'practice-check-input_active': active }" class="practice-check-input">
            <CheckIcon fill="#fff" />
        </div>
    </div>
</template>

<script>
import CheckIcon from "@icons/CheckIcon"

export default {
    components: { CheckIcon },
    props: {
        active: {
            type: [Boolean, Number],
            default: false
        }
    },
    name: "PracticeCheck"
}
</script>

<style scoped lang="sass">
.practice-check
    width: 20px
    height: 20px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    margin-right: 14px
    &.with-red
        .practice-check-input
            border-color: #DD4141
            background: #DD4141
    &.with-black
        .practice-check-input_active
            background: #3E4755 !important
            border-color: #3E4755 !important
            &::after
                background: #3E4755 !important
.practice-check-input
    width: 16px
    height: 16px
    position: relative
    border-radius: 4px
    border: 1px solid #DDDDDD
    display: flex
    align-items: center
    justify-content: center
    &_active
        background: #15A06E !important
        border-color:  #15A06E !important
    svg
        width: 14px
</style>
