const arrowRight = (fill = "#fff") => `
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" stroke="${fill}" stroke-width="1" fill="none" viewBox="0 0 10 11">
        <g clip-path="url(#clip0_8128_117533)"><path d="M9 1V4M9 4H6M9 4C7.9173 3.23345 6.92231 2.23101 5.55763 2.03902C4.6988 1.91819 3.82396 2.07974 3.06493 2.4993C2.3059 2.91887 1.7038 3.57374 1.34934 4.36523C0.994886 5.15672 0.907281 6.04196 1.09972 6.88757C1.29217 7.73317 1.75424 8.49333 2.41631 9.05352C3.07838 9.6137 3.90458 9.94356 4.77044 9.99339C5.6363 10.0432 6.4949 9.81032 7.21687 9.32979C7.93884 8.84926 8.48508 8.14713 8.77327 7.32918" stroke-linecap="round" stroke-linejoin="round"/></g>
        <defs><clipPath id="clip0_8128_117533"><rect width="10" height="11" fill="white"/></clipPath></defs>
    </svg>
`
const arrowLeft = (fill = "#fff") => `
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" stroke="${fill}" stroke-width="1" viewBox="0 0 10 11" fill="none">
        <g clip-path="url(#clip0_8128_117531)"><path d="M1 1V4M1 4H4M1 4C2.0827 3.23345 3.07769 2.23101 4.44237 2.03902C5.3012 1.91819 6.17604 2.07974 6.93507 2.4993C7.6941 2.91887 8.2962 3.57374 8.65066 4.36523C9.00511 5.15672 9.09272 6.04196 8.90028 6.88757C8.70783 7.73317 8.24576 8.49333 7.58369 9.05352C6.92162 9.6137 6.09542 9.94356 5.22956 9.99339C4.3637 10.0432 3.50511 9.81032 2.78313 9.32979C2.06116 8.84926 1.51492 8.14713 1.22673 7.32918" stroke-linecap="round" stroke-linejoin="round"/></g>
        <defs><clipPath id="clip0_8128_117531"><rect width="10" height="11" fill="white"/></clipPath></defs>
    </svg>
`
let timerInfoBlock = null
const defaultOptions = {
    initHotkey: true,
    seek: 10, // in second
    delayInfoBlock: 1500, // 1.5 sec
    infoBlockSize: "250%",
    svgFill: "#fff"
}
class VjsSeeking {
    constructor(player, options = {}) {
        if (!player) {
            throw new Error("Player undefined")
        }
        this.$infoBlock = null
        this.player = player
        this.controlBar = player.controlBar
        this.options = {
            ...defaultOptions,
            ...options
        }
    }
    createTag(tag, className) {
        const $tag = document.createElement(tag)
        $tag.classList = className
        return $tag
    }
    hotKeyInit() {
        this.player.on("keydown", event => {
            switch (event.which) {
                case 37:
                    this.seekToLeft()
                    break
                case 39:
                    this.seekToRight()
                    break
                default:
                    return
            }

            event.preventDefault()
        })
    }
    seekTo(seek = 0) {
        this.player.currentTime(seek)
    }
    seekToRight() {
        this.hideInfoBlock()
        this.toggleDisplayInfoBlock("+")
        this.seekTo(this.player.currentTime() + this.options.seek)
    }
    seekToLeft() {
        this.hideInfoBlock()
        this.toggleDisplayInfoBlock("-")
        this.seekTo(this.player.currentTime() - this.options.seek)
    }
    hideInfoBlock() {
        this.$infoBlock.style.opacity = ""
        this.$infoBlock.innerHTML = ""
        this.$infoBlock.style.fontSize = ""
        if (timerInfoBlock) {
            clearTimeout(timerInfoBlock)
        }
    }
    toggleDisplayInfoBlock(text) {
        this.hideInfoBlock()
        setTimeout(() => {
            this.$infoBlock.innerHTML = text + this.options.seek
            this.$infoBlock.style.opacity = 1
            this.$infoBlock.style.fontSize = this.options.infoBlockSize
            timerInfoBlock = setTimeout(() => {
                this.hideInfoBlock()
            }, this.options.delayInfoBlock)
        }, 100)
    }
    createButtonLeft() {
        const $buttonLeft = this.createTag("button", "vjs-custom-seeking__btn")
        $buttonLeft.onclick = this.seekToLeft.bind(this)
        $buttonLeft.innerHTML = `
            <span style="margin-right: 3px">-${this.options.seek}</span>
            ${arrowLeft(this.options.svgFill)}
        `
        return $buttonLeft
    }
    createButtonRight() {
        // button right
        const $buttonRight = this.createTag("button", "vjs-custom-seeking__btn")
        $buttonRight.onclick = this.seekToRight.bind(this)
        $buttonRight.innerHTML = `
            ${arrowRight(this.options.svgFill)}
            <span style="margin-left: 3px">+${this.options.seek}</span>
        `
        return $buttonRight
    }
    createInfoBlock() {
        this.$infoBlock = this.createTag("div", "vjs-custom-seeking__info")
        this.player.el_.appendChild(this.$infoBlock)
    }
    render() {
        // Wrapper
        const $root = this.createTag("div", "vjs-custom-seeking vjs-control")
        // Append Button Left
        $root.appendChild(this.createButtonLeft())
        // Append Button Right
        $root.appendChild(this.createButtonRight())
        // Append root
        this.controlBar.el_.appendChild($root)
        // Create info Block
        this.createInfoBlock()
    }
    init() {
        this.render()
        if (this.options.initHotkey) this.hotKeyInit()
    }
}
export default VjsSeeking
