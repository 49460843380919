<template>
    <div class="course__lesson_files">
        <h4 v-if="item.title" class="course__content_summary__title bold">
            {{ item.title }}
        </h4>
        <files-new-view v-if="item.files && item.files.length" :files="item.files"></files-new-view>
    </div>
</template>

<script>
export default {
    name: "CoursePublicUnitsFiles",
    components: {
        FilesNewView: () => import("@components/Files/FilesNewView")
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>


