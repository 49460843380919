<template>
    <div :style="{ background: bg_color }" v-if="author" class="comment-author">
        <img
            v-if="author.avatar_url || (author.avatar && author.avatar.url)"
            :src="author.avatar_url || author.avatar.url"
            :alt="author.name"
        />
        <span v-else class="comment-author_no-photo">
            {{ author.name.slice(0, 1) }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        author: {
            type: Object,
            default: null
        }
    },
    name: "CommentAuthor",
    computed: {
        bg_color() {
            return this.author?.bg_color || "#ffffff"
        }
    }
}
</script>

<style scoped lang="sass">
.comment-author
    min-width: 35px
    min-height: 35px
    width: 35px
    height: 35px
    border-radius: 50%
    overflow: hidden
    margin-right: 8px
    text-transform: uppercase
    background-image: url('@images/portal/fingerprint.svg')
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    display: flex
    align-items: center
    justify-content: center
    img
        width: 100%
        height: 100%
        object-fit: cover
    &_no-photo
        font-size: 15px
    @media screen and (max-width: 480px)
        margin-right: 10px
</style>
