<template>
    <div class="practice-comment">
        <default-title class="mb-10 black" large> {{ t("quiz.comment") }}: </default-title>
        <div class="comment-view">
            <PracticeQuestionDescription :description="question.comment" />
        </div>
        <FilesNewView
            class="mt-10"
            v-if="question.comment_files && question.comment_files.length"
            :files="question.comment_files"
        />
    </div>
</template>

<script>
import PracticeQuestionDescription from "@components/Practice/PracticeQuestions/components/PracticeQuestionDescription.vue"
import FilesNewView from "@components/Files/FilesNewView.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import ImageEnlargable from "@mixins/image-enlargable"

export default {
    components: { DefaultTitle, PracticeQuestionDescription, FilesNewView },
    mixins: [ImageEnlargable],
    props: {
        question: {
            type: Object,
            default: () => {}
        }
    },
    name: "PracticeQuestionComment",
    mounted() {
        this.initImages()
    }
}
</script>

<style scoped lang="sass">
.practice-comment
    border-radius: 10px
    padding: 0 13px
    margin-top: 20px
    position: relative
    &:before
        content: ''
        width: 3px
        height: 100%
        display: block
        border-radius: 100px
        position: absolute
        left: 0
        background-color: #39C4FF
    .ql-container
        height: max-content
    .ql-editor
        padding: 0
        line-height: 130%
</style>
