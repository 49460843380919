export default function (items, key = "percentage") {
    const equalPercentage = 100 / items.length

    // Detect equal part is not integer
    let remainder = 0
    if (Math.floor(equalPercentage) !== equalPercentage) {
        remainder = 100 - Math.floor(equalPercentage) * items.length
    }

    return items.map(el => {
        if (remainder >= 1 && items.length > 2) {
            el[key] = Math.floor(equalPercentage) + 1
            remainder--
        } else el[key] = Math.floor(equalPercentage)
        return el
    })
}
