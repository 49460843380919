<template>
    <div class="practice-red-alert">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "PracticeRedAlert"
}
</script>

<style scoped lang="sass">
.practice-red-alert
    padding: 10px
    border-radius: 7px
    border: 1px solid #F9F1D0
    background: #FFFDF5
    color: #52565C
    font-size: 13px
    font-weight: 400
    line-height: 17px
    max-width: 700px
    &.big
        font-size: 16px
        strong
            display: inline !important
    ul
        list-style: disc
        padding-left: 20px
    u
        margin-left: 5px
        color: #3451BE
        cursor: pointer
    li
        margin-top: 10px
    span
        display: block
        margin-top: 10px
    strong
        font-weight: 700
        width: 100%
        display: block
        margin-bottom: 2px
        color: #DD4141
        &.inline
            display: inline
            margin-bottom: 0
    &.timer
        font-size: 14px
</style>
