
<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30.8332 37.5H9.1665C7.78567 37.5 6.6665 36.3808 6.6665 35V5C6.6665 3.61917 7.78567 2.5 9.1665 2.5H24.9998L33.3332 10.8333V35C33.3332 36.3808 32.214 37.5 30.8332 37.5Z"
            fill="#43A047"
        />
        <path d="M33.3333 10.8333H25V2.5L33.3333 10.8333Z" fill="#C8E6C9" />
        <path d="M25 10.8333L33.3333 19.1667V10.8333H25Z" fill="#2E7D32" />
        <path
            d="M25.8333 19.1667H14.1667H12.5V20.8333V22.5V24.1667V25.8333V27.5V29.1667V30.8333H27.5V29.1667V27.5V25.8333V24.1667V22.5V20.8333V19.1667H25.8333ZM14.1667 20.8333H17.5V22.5H14.1667V20.8333ZM14.1667 24.1667H17.5V25.8333H14.1667V24.1667ZM14.1667 27.5H17.5V29.1667H14.1667V27.5ZM25.8333 29.1667H19.1667V27.5H25.8333V29.1667ZM25.8333 25.8333H19.1667V24.1667H25.8333V25.8333ZM25.8333 22.5H19.1667V20.8333H25.8333V22.5Z"
            fill="#E8F5E9"
        />
    </svg>
</template>
<script>
export default {
    name: "FileExcelIcon"
}
</script>