<template>
    <div class="quiz-name">
        <flex-container class="mb-10" align="center">
            <PracticeTimerIcon
                class="mr-7"
                v-if="(quiz.options && quiz.options.timer_duration) || quizIncludesQuestionWithTimer()"
            />
            <practice-title>
                {{ quiz.name }}
            </practice-title>
        </flex-container>
    </div>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer.vue"
import PracticeTimerIcon from "@components/Practice/common/timer/PracticeTimerIcon.vue"
import PracticeTitle from "@components/Practice/common/typography/PracticeTitle.vue"

export default {
    props: {
        quiz: {
            type: Object,
            default: () => {}
        }
    },
    name: "PracticeQuizName",
    components: { PracticeTitle, PracticeTimerIcon, FlexContainer },
    methods: {
        quizIncludesQuestionWithTimer() {
            if (!this.quiz) {
                return false
            }
            return !!this.quiz.questions.find(q => q.timer)
        }
    }
}
</script>

<style scoped lang="sass">

</style>
