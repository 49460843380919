export default {
    BLUR_MAIN_CONTENT: "BLUR_MAIN_CONTENT",
    DARK_MAIN_CONTENT: "DARK_MAIN_CONTENT",
    BLUR_FULL_CONTENT: "BLUR_FULL_CONTENT",
    BLURED_CONTENT_CLICK: "BLURED_CONTENT_CLICK",
    RENDER_BREADCRUMBS: "RENDER_BREADCRUMBS",
    HIDE_BREADCRUMBS: "HIDE_BREADCRUMBS",
    SHOW_EVENT_ERROR: "showEventError",
    SHOW_SUCCESSFUL_SAVING: "showSuccessfulSaving",
    CHANGE_ROUTE_ITEMS: "changeRouteItems",
    HEADER_VISIBILIRY: "HEADER_VISIBILIRY",
    RENDER_BACK_BUTTON: "renderBackButton",
    CONNECTING_SOCKET_TO: "connect_socket_to_",
    GET_YOUTUBE_PREVIEWS: "get_youtb_previews",
    FILE_UPLOAD_STATE: "file_upload_state",
    VIDEO_SET_SEEK: "video_set_seek"
}
