<template>
    <div class="player" :style="playerStyles">
        <template v-if="!loading">
            <slot name="remove-btn" :methods="{ remove: removeItem }">
                <button v-if="cancelled" class="player__remove-btn" @click="removeItem">
                    <CloseIcon />
                </button>
            </slot>
            <slot
                name="play-btn"
                :methods="{
                    toggle: togglePlay,
                    play: handleStartAudio,
                    stop: handleStopAudio,
                    finish: handleFinishAudio
                }"
            >
                <button class="player__reproduce-button" @click="togglePlay">
                    <PlayIcon v-if="!play" />
                    <PauseIcon v-else />
                </button>
            </slot>
            <div class="progress-container">
                <slot name="progress-bar" :data="{ analize: analizeTrack }">
                    <div class="player__progress__wrapper">
                        <div
                            ref="bar"
                            class="player__progress full-size"
                            :style="{ gridTemplateColumns: `repeat(${num}, 5px)` }"
                            @click="rewindAudio"
                        >
                            <div
                                v-for="i of num"
                                :key="i"
                                class="player__progress__item"
                                :style="{ height: analizeTrack[i] ? `${analizeTrack[i].height}px` : '' }"
                            />
                        </div>

                        <div
                            class="player__progress progress"
                            :style="{ gridTemplateColumns: `repeat(${num}, 5px)`, ...progressTimer }"
                        >
                            <div
                                v-for="i of num"
                                :key="i"
                                class="player__progress__item"
                                :style="{ height: analizeTrack[i] ? `${analizeTrack[i].height}px` : '' }"
                            />
                        </div>
                    </div>
                </slot>

                <slot name="timer" :timer="{ timer, duration, currentTime }">
                    <span v-if="audio" class="player__timer"
                        >{{ timer.elapsedStartTime }}
                        <template v-if="audio.duration">/ {{ getTimeCodeFromNum(audio.duration) }}</template></span
                    >
                </slot>
            </div>

            <button class="player__playback-rate" @click="toggleRate">{{ playbackRate }}x</button>
        </template>
        <LoaderBlock style="margin-top: -10px" v-else />
    </div>
</template>

<script>
import PlayIcon from "@icons/PlayIcon.vue"
import PauseIcon from "@icons/PauseIcon.vue"
import CloseIcon from "@icons/CloseIcon.vue"
import LoaderBlock from "@components/Loaders/LoaderBlock.vue"

const SAMPLES = 21

export default {
    name: "RecordingTrack",
    components: { PlayIcon, PauseIcon, CloseIcon, LoaderBlock },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        cancelled: {
            type: Boolean,
            default: false
        },
        settings: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            play: false,
            num: SAMPLES,
            elapsedTimeTimer: null,
            timer: {
                elapsedEndTime: "0:00",
                elapsedStartTime: "0:00"
            },
            currentTime: 0,
            duration: 0,
            audio: null,
            frequency: new Uint8Array(SAMPLES),
            requestAnimation: null,
            loading: false,
            analizeTrack: [],
            playbackRate: 1
        }
    },
    computed: {
        playerStyles() {
            if (!this.settings?.style) return null
            return {
                ...(this.settings?.style?.width && { maxWidth: this.settings.style.width }),
                ...this.settings.style.width
            }
        },
        progressTimer() {
            if (!this.audio) {
                return { width: "0" }
            }

            const width = (this.currentTime * 0.1) / this.audio.duration
            return { width: `${width}%` }
        }
    },
    mounted() {
        this.createAudio()
    },
    watch: {
        value: {
            deep: true,
            handler() {
                this.createAudio()
            }
        }
    },
    methods: {
        getTimeCodeFromNum(num) {
            let seconds = parseInt(num)
            let minutes = parseInt(seconds / 60)
            seconds -= minutes * 60
            const hours = parseInt(minutes / 60)
            minutes -= hours * 60

            if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`
            return `${String(hours).padStart(2, 0)}:${String(minutes).padStart(2, 0)}:${String(seconds % 60).padStart(
                2,
                0
            )}`
        },
        toggleRate() {
            if (this.playbackRate === 1) {
                this.playbackRate = 1.5
            } else if (this.playbackRate === 1.5) {
                this.playbackRate = 2
            } else {
                this.playbackRate = 1
            }

            this.audio.playbackRate = this.playbackRate
        },
        createAudio() {
            this.audio = new Audio()
            this.audio.preload = "auto"
            this.audio.src = this.value.url || this.value.audioUrl
            this.audio.addEventListener("play", () => {
                if (this.audio.duration == Infinity) {
                    this.audio.currentTime = 1e101
                    const timeout = setTimeout(() => {
                        this.setDuration()
                        this.audio.currentTime = 0
                        clearTimeout(timeout)
                    }, 1000)
                } else {
                    const timeout = setTimeout(() => {
                        this.setDuration()
                        this.audio.currentTime = 0
                        clearTimeout(timeout)
                    }, 1000)
                }
            })

            this.audio.addEventListener("play", () => {
                if (this.audio.duration < 300) {
                    this.analyserTrack().draw(this.audio.src)
                }
            })
        },
        //methods for timer
        setDuration() {
            this.duration = Math.round(this.audio.duration) * 1000
            this.setTimer()
        },
        setTimer() {
            this.timer.elapsedEndTime = this.computeElapsedTime(this.duration)
            this.timer.elapsedStartTime = this.computeElapsedTime(this.currentTime)
        },
        startTimer() {
            this.currentTime += 100
            this.duration -= 100
        },
        //methods for plaing audio
        togglePlay() {
            if (!this.play) {
                this.handleStartAudio()
            } else {
                this.handleStopAudio()
            }
        },
        handleStartAudio() {
            this.play = true
            this.audio.play()
            this.handleElapsedRecordingTime()
        },
        handleStopAudio() {
            this.play = false
            this.audio.pause()
            clearInterval(this.elapsedTimeTimer)
        },
        handleFinishAudio() {
            this.handleStopAudio()
            this.audio.currentTime = 0
            this.currentTime = 0
            this.setDuration()
        },
        //helper methods
        rewindAudio(event) {
            const itemSettings = this.$refs.bar.getBoundingClientRect()
            const pos = event.pageX - itemSettings.left
            const percent = pos / itemSettings.width
            this.audio.currentTime = percent * this.audio.duration
            this.currentTime = this.audio.currentTime * 1000
            this.duration = this.audio.duration * 1000 - this.currentTime
            this.setTimer()
        },
        handleElapsedRecordingTime() {
            this.elapsedTimeTimer = setInterval(() => {
                this.startTimer()
                this.setTimer()
                if (this.duration < 0) {
                    this.handleFinishAudio()
                }
            }, 100)
        },
        computeElapsedTime(time) {
            let timeDiff = time
            timeDiff = timeDiff / 1000
            let seconds = Math.floor(timeDiff % 60)
            seconds = seconds < 10 ? "0" + seconds : seconds
            timeDiff = Math.floor(timeDiff / 60)
            let minutes = timeDiff % 60
            minutes = minutes < 10 ? "" + minutes : minutes
            timeDiff = Math.floor(timeDiff / 60)
            let hours = timeDiff % 24
            timeDiff = Math.floor(timeDiff / 24)
            let days = timeDiff

            let totalHours = hours + days * 24
            totalHours = totalHours < 10 ? "0" + totalHours : totalHours

            if (totalHours === "00") {
                return minutes + ":" + seconds
            } else {
                return totalHours + ":" + minutes + ":" + seconds
            }
        },

        removeItem() {
            this.handleFinishAudio()
            this.$emit("delete", this.value)
        },
        analyserTrack() {
            window.AudioContext = window.AudioContext || window.webkitAudioContext
            const audioContext = new AudioContext()
            const filterData = audioBuffer => {
                const rawData = audioBuffer.getChannelData(0)
                const samples = SAMPLES
                const blockSize = Math.floor(rawData.length / samples)
                const filteredData = []
                for (let i = 0; i < samples; i++) {
                    let blockStart = blockSize * i
                    let sum = 0
                    for (let j = 0; j < blockSize; j++) {
                        sum = sum + Math.abs(rawData[blockStart + j])
                    }
                    filteredData.push(sum / blockSize)
                }
                return filteredData
            }
            const normalizeData = filteredData => {
                const multiplier = Math.pow(Math.max(...filteredData), -1)
                return filteredData.map(n => n * multiplier)
            }
            const visualizeAudio = url => {
                fetch(url)
                    .then(response => response.arrayBuffer())
                    .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
                    .then(audioBuffer => this.animateAnalyser(normalizeData(filterData(audioBuffer))))
            }
            return {
                draw: visualizeAudio
            }
        },
        animateAnalyser(ctx) {
            for (let i = 0; i < SAMPLES; i++) {
                const height = +ctx[i] * SAMPLES
                this.analizeTrack.push({ height })
            }
        }
    },
    beforeDestroy() {
        this.handleStopAudio()
    }
}
</script>


<style lang="sass">
.player
    &__reproduce-button, &__remove-btn
        svg
            stroke: white
            path
                fill: white
</style>
<style lang="sass" scoped>
.progress-container
    display: flex
    flex-direction: column
    margin-top: 3px
    margin-left: 3px
.player
    width: 100%
    max-width: 210px
    height: 65px
    background: #FFFFFF
    border: 1px solid #E0E0EC
    border-radius: 8px
    padding: 10px
    display: flex
    align-items: flex-start
    justify-content: flex-start
    column-gap: 10px
    position: relative
    box-shadow: 0px 2px 5px rgba(128, 158, 191, 0.25)
    z-index: 1
    &__playback-rate
        width: 24px
        min-width: 24px
        color: #8a96a7
        font-weight: 500
        height: 24px
        display: flex
        align-items: center
        justify-content: center
        border-radius: 4px
        background:  #f4f8fb
        transition: .2s
        font-size: 12px
        &:focus
            background: #eef9ff
    &__timer
        font-size: 12px
        color: #B5C1D2
        margin-top: 7px
        font-weight: 600

    &__remove-btn
        position: absolute
        width: 20px
        height: 20px
        background-color: #B5C1D2
        border-radius: 50%
        top: -10px
        right: -10px
        transition: background-color .2s ease

        &:hover
            background-color: #3E4755

    &__reproduce-button
        width: 30px
        height: 30px
        border-radius: 50%
        display: flex
        align-items: center
        justify-content: center
        background-color: #B5C1D2
        transition: background-color .2s ease

        &:hover
            background-color: #3E4755

    &__progress
        height: 21px
        display: grid
        align-items: center
        column-gap: 0px
        transition: width 0.2s ease
        &__wrapper
            display: block
            position: relative
        &__item
            width: 2px
            min-height: 10px
            max-height: 24px
            border-radius: 8px
            flex: 1 0 auto
            transform: scaleY(.8)
        &.progress
            position: absolute
            width: 100%
            top: 0
            left: 0
            width: 0
            overflow: hidden
            pointer-events: none
            height: 100%
            background: transparent
            .player__progress__item
                background-color: #3965FF
        &.full-size
            cursor: pointer
            .player__progress__item
                    background-color: #B5C1D2
</style>
