export default {
    data() {
        return {
            wasAppend: false
        }
    },
    methods: {
        // [options] => {
        //      center: Boolean
        //      className: String
        //      toRaisePadding: Number
        // }

        /*
         * [center] - centered
         * [className] - class name for `Node` element
         * [toRaisePadding] - raise up | add padding up
         */

        isHover(e, display, options = {}) {
            let className = "image-info__tooltip"

            if (!options.toRaisePadding || typeof options.toRaisePadding !== "number") options.toRaisePadding = 0

            if (options.width) {
                if (options.width === "auto") {
                    className += ` ${className}_w-auto`
                }
            }

            if (options.withoutTriangle) {
                className += ` image-info__tooltip_no-triangle`
            }

            if (display) {
                const pos = e.target.getBoundingClientRect()
                const content = document.createElement("div")
                content.innerHTML = e.target.dataset.tooltip || e.target.closest("[data-tooltip]").dataset.tooltip
                content.classList = `${className} ${options.className || ""}`

                if (options.pushed_left) {
                    content.classList.add("pushed_left")
                }

                // The child element will always be 1, and logically we don't need them.
                // Child element can be either text or icons
                const childrenNodes = e.target.children
                for (let i = 0; childrenNodes.length > i; i++) {
                    if (!childrenNodes[i].hasAttribute("tooltip-child")) {
                        childrenNodes[i].setAttribute("tooltip-child", "true")
                        childrenNodes[i].style["pointer-events"] = "none"
                    }
                }

                if (options.bind_to_cursor && this.wasAppend) {
                    const el = document.querySelector(`.image-info__tooltip`)
                    el.style.top = e.pageY - 30
                    el.style.left = e.pageX + 10
                    return
                }

                if (this.wasAppend) return
                document.body.appendChild(content)
                this.wasAppend = true

                this.$nextTick(() => {
                    if (options.center) {
                        content.style.left = pos.left + pos.width / 2 + "px"
                    } else if (options.pushed_left) {
                        content.style.left = pos.left + pos.width + "px"
                    } else {
                        content.style.left = pos.left + pos.width / 2 + 28 + "px"
                    }

                    if (options.width && typeof options.width === "number") {
                        content.style.width = `${options.width}px`
                    }

                    if (options.whiteSpace) {
                        content.style.whiteSpace = `${options.whiteSpace}`
                    }

                    let top = e.pageY - e.layerY - pos.height / 2

                    if (options.move_up) top -= options.move_up

                    if (options.toRaisePadding) top = top - options.toRaisePadding

                    content.style.top = top + "px"
                    content.style.opacity = "1"
                    content.style.pointerEvents = "none"
                })
            } else if (e !== null) {
                const nodeClassNames = options.className ? options.className : className

                ;[...document.querySelectorAll(`.${nodeClassNames}`)].forEach(t => t.remove())
                this.wasAppend = false
                const childrenNodes = e.target.children
                for (let i = 0; childrenNodes.length > i; i++) {
                    if (!childrenNodes[i].hasAttribute("tooltip-child")) {
                        childrenNodes[i].setAttribute("tooltip-child", "false")
                        childrenNodes[i].style["pointer-events"] = "all"
                    }
                }
            }
        }
    }
}
