
<template>
    <svg width="18" height="19" viewBox="0 0 18 19" fill="#3E4755" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.75 12C17.75 11.5858 17.4142 11.25 17 11.25C16.5858 11.25 16.25 11.5858 16.25 12H17.75ZM1.75 12C1.75 11.5858 1.41421 11.25 1 11.25C0.585786 11.25 0.25 11.5858 0.25 12H1.75ZM9 13L8.46967 13.5303C8.76256 13.8232 9.23744 13.8232 9.53033 13.5303L9 13ZM9.75 1C9.75 0.585787 9.41422 0.25 9 0.25C8.58579 0.25 8.25 0.585786 8.25 1L9.75 1ZM5.53033 8.46967C5.23744 8.17678 4.76256 8.17678 4.46967 8.46967C4.17678 8.76256 4.17678 9.23744 4.46967 9.53033L5.53033 8.46967ZM13.5303 9.53033C13.8232 9.23744 13.8232 8.76256 13.5303 8.46967C13.2374 8.17678 12.7626 8.17678 12.4697 8.46967L13.5303 9.53033ZM16.25 12V16H17.75V12H16.25ZM15 17.25H3V18.75H15V17.25ZM1.75 16V12H0.25V16H1.75ZM3 17.25C2.30964 17.25 1.75 16.6904 1.75 16H0.25C0.25 17.5188 1.48122 18.75 3 18.75V17.25ZM16.25 16C16.25 16.6904 15.6904 17.25 15 17.25V18.75C16.5188 18.75 17.75 17.5188 17.75 16H16.25ZM9.75 13L9.75 1L8.25 1L8.25 13H9.75ZM4.46967 9.53033L8.46967 13.5303L9.53033 12.4697L5.53033 8.46967L4.46967 9.53033ZM9.53033 13.5303L13.5303 9.53033L12.4697 8.46967L8.46967 12.4697L9.53033 13.5303Z"
        />
    </svg>
</template>
<script>
export default {
    name: "DownloadIcon"
}
</script>