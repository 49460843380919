
<template>
    <svg
        @click="$emit('click')"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        :fill="noColor ? null : fill || '#C2C2C2'"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.85714 14L7.29769 14.4995C7.43998 14.6589 7.64349 14.75 7.85714 14.75C8.07079 14.75 8.2743 14.6589 8.41659 14.4995L7.85714 14ZM15.5595 6.49951C15.8353 6.19053 15.8085 5.71642 15.4995 5.44055C15.1905 5.16467 14.7164 5.19151 14.4405 5.50049L15.5595 6.49951ZM5.55945 10.3005C5.28358 9.99151 4.80947 9.96467 4.50049 10.2405C4.19151 10.5164 4.16467 10.9905 4.44055 11.2995L5.55945 10.3005ZM8.41659 14.4995L15.5595 6.49951L14.4405 5.50049L7.29769 13.5005L8.41659 14.4995ZM8.41659 13.5005L5.55945 10.3005L4.44055 11.2995L7.29769 14.4995L8.41659 13.5005Z"
        />
    </svg>
</template>
<script>
export default {
    name: "CheckIcon",
    props: {
        noColor: {
            type: Boolean,
            default: false
        },
        fill: {
            type: String,
            default: ""
        }
    }
}
</script>
