<template>
    <div :class="{ inter }" class="red-alert">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        inter: {
            type: Boolean,
            default: false
        }
    },
    name: "RedAlert"
}
</script>

<style scoped lang="sass">
.red-alert
    padding: 10px
    border-radius: 7px
    border: 1px solid #F9F1D0
    background: #FFFDF5
    color: #52565C
    font-size: 13px
    font-weight: 400
    line-height: 17px
    &.inter
        font-family: Inter, sans-serif
        line-height: 160%
        &::v-deep
            b, strong
                font-weight: 500
    &.big
        font-size: 16px
        strong
            display: inline !important
    ul
        list-style: disc
        padding-left: 20px
    a, &::v-deep a
        color: #3965FF
        cursor: pointer
    u
        margin-left: 5px
        color: #3451BE
        cursor: pointer
    li
        margin-top: 10px
    span
        display: block
        margin-top: 10px
    &::v-deep
        strong
            font-weight: 600
            width: 100%
            display: block
            margin-bottom: 2px
            color: #DD4141
            &.inline
                display: inline
                margin-bottom: 0
                margin-right: 5px
</style>
