<template>
    <div class="loader-icon">
        <LoaderGreenIcon :fill="fill" />
    </div>
</template>

<script>
import LoaderGreenIcon from "@icons/LoaderGreenIcon.vue"

export default {
    props: {
        fill: {
            type: String,
            default: "#30C58F"
        }
    },
    name: "LoaderIcon",
    components: { LoaderGreenIcon }
}
</script>
