import Vue from "vue"
import Vuex from "vuex"
import langs from "@common-store/langs"
import user from "@common-store/user"

import lesson from "./lesson-state"

Vue.use(Vuex)

export const lessonModules = {
    langs,
    user,
    lesson
}
