<template>
    <p class="practice-title">
        <slot></slot>
    </p>
</template>

<script>
export default {
    name: "PracticeTitle"
}
</script>

<style scoped lang="sass">
.practice-title
    font-family: 'Ubuntu', serif
    font-size: 16px
    font-weight: 500
    line-height: 21px
</style>
