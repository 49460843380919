import Vue from "vue"

import PracticeFillGapsInput from "@components/Practice/PracticeQuestions/components/PracticeFillGapsInput.vue"
import PracticeFillGapsDropdown from "@components/Practice/PracticeQuestions/components/PracticeFillGapsDropdown.vue"
import AttemptResultExpertWidget from "@components/Practice/common/result/AttemptResultExpertWidget.vue"
import AttemptResultStudentWidget from "@components/Practice/common/result/AttemptResultStudentWidget.vue"

if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (str, newStr) {
        if (Object.prototype.toString.call(str).toLowerCase() === "[object regexp]") {
            return this.replace(str, newStr)
        }
        return this.replace(new RegExp(str, "g"), newStr)
    }
}

Vue.component("PracticeFillGapsInput", PracticeFillGapsInput)
Vue.component("PracticeFillGapsDropdown", PracticeFillGapsDropdown)
Vue.component("AttemptResultExpertWidget", AttemptResultExpertWidget)
Vue.component("AttemptResultStudentWidget", AttemptResultStudentWidget)
