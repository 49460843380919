<template>
    <transition name="translate-up">
        <a
            v-if="scrollY > scrollTop || !isSticky"
            ref="handler"
            :href="`${getLink()}/program`"
            class="lesson-program-handler"
            :class="{ 'lesson-program-handler_sticky': isSticky }"
        >
            <svg
                stroke="#323030"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20 10V4.00004L14 4.00004M4 14L4 20H10M10 4L4.00004 4L4.00004 10M14 20H20V14"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <line x1="7.75" y1="7.98242" x2="16.25" y2="7.98242" stroke-width="1.5" stroke-linecap="round" />
                <line x1="7.75" y1="11.9824" x2="16.25" y2="11.9824" stroke-width="1.5" stroke-linecap="round" />
                <line x1="7.75" y1="15.9824" x2="16.25" y2="15.9824" stroke-width="1.5" stroke-linecap="round" />
            </svg>
            {{ translate("create-course.course_program") }}
        </a>
    </transition>
</template>

<script>
import { LESSON } from "@lesson/shared/state/lesson-getters"
import localTranslator from "@mixins/local-translator"

export default {
    props: {
        isSticky: {
            type: Boolean,
            default: false
        }
    },
    name: "LessonProgramHandler",
    mixins: [localTranslator],
    data() {
        return {
            scrollTop: 0,
            scrollY: 0,
            localKeys: "create-course"
        }
    },
    computed: {
        lesson() {
            return this.$store && this.$store.getters[LESSON]
        }
    },
    methods: {
        getLink() {
            return this.lesson?.link || location.href
        }
    },
    mounted() {
        window.addEventListener("scroll", () => {
            this.scrollY = window.scrollY
        })

        if (!this.isSticky) {
            return
        }

        const firstHandler = document.querySelector(".lesson-program-handler")

        if (!firstHandler) {
            return
        }

        this.scrollTop = firstHandler.getBoundingClientRect().top + window.scrollY + 60

        /* const stickyElm = this.$refs.handler

        const observer = new IntersectionObserver(
            ([e]) => {
                e.target.classList.toggle("isSticky", e.intersectionRatio < 1)
            },
            { rootMargin: "-1px 0px 0px 0px", threshold: [1] }
        )

        observer.observe(stickyElm)*/
    }
}
</script>
<style lang="sass">
#courseProgramHandler
    @media (min-width: 1300px)
        .lesson-program-handler
            display: none
</style>
<style scoped lang="sass">
.lesson-program-handler
    background-color: #F4F8FD
    height: 60px
    display: flex
    align-items: center
    justify-content: center
    border-bottom: 1px solid #fff
    font-family: Inter, sans-serif
    font-size: 14px
    font-weight: 400
    color: #323030
    line-height: 160%
    transition: .2s
    &:hover
        background-color: #FAFAFF
        color: #3965FF
        svg
            stroke: #3965FF
    svg
        transition: .2s
        margin-right: 4px
    &_sticky
        border-bottom: none
        border-top: 1px solid #fff
        position: sticky
        bottom: -1px
        transition: .2s
        z-index: 5
</style>
