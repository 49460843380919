export default {
    methods: {
        t(key) {
            let value, group, groupKey

            for (group in this.langsKeys) {
                for (groupKey in this.langsKeys[group]) {
                    if (groupKey === key) value = this.langsKeys[group][groupKey]
                }
            }

            return value ? value : key
        }
    }
}
