export default {
    methods: {
        isMobile() {
            return navigator.userAgent.match(/Android/) || this.isIOS() || window.innerWidth < 600
        },
        isIOS() {
            return navigator.userAgent.match(/iPhone/)
        },
        isMac() {
            return navigator.userAgent.includes("Mac") && !this.isIpad()
        },
        isIpad() {
            if (this.isIOS()) {
                return false
            }

            return (
                navigator.userAgent.match(/iPad/) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
            )
        }
    }
}
