
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" stroke="#3965FF" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.5 8.02941H15.5M8.5 11.5588H12.875M5 4.5H19V15.0882H12L7.625 19.5V15.0882H5V4.5Z"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
<script>
export default {
    name: "Message2Icon"
}
</script>
