const closeIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3337 6.66602L6.66699 13.3327M6.66701 6.66602L13.3337 13.3327" stroke="#3E4755" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`

export default {
    data() {
        return {
            images: [],
            root: null
        }
    },
    mounted() {
        let container = document.querySelector(".full-page-root")
        if (container) {
            this.root = container
        } else {
            const remove = ({ target }) => {
                if (target.className === "full-page-image") {
                    return
                }
                document.querySelector(".full-page-root").classList.remove("visible")
            }

            const root = document.createElement("div")
            root.className = "full-page-root"

            root.addEventListener("click", remove)

            const modal = document.createElement("div")
            modal.className = "full-page-modal"
            root.appendChild(modal)

            const close = document.createElement("svg")
            close.innerHTML = closeIcon
            modal.appendChild(close)

            close.addEventListener("click", remove)

            const name = document.createElement("p")
            name.className = "full-page-name"
            modal.appendChild(name)

            const imageContainer = document.createElement("div")
            imageContainer.className = "full-page-image-container"
            modal.appendChild(imageContainer)

            const img = document.createElement("img")
            img.className = "full-page-image"
            imageContainer.appendChild(img)

            this.root = document.body.insertBefore(root, document.body.firstChild)
        }
    },
    methods: {
        initImages(all = true) {
            if (!this.$el.querySelectorAll) {
                return
            }
            const images = (this.images = this.$el.querySelectorAll(all ? "img" : "img[data-enlargable]"))

            const t = () => {
                switch (window.app_current_locale) {
                    case "ru": {
                        return "Нажмите чтобы увеличить"
                    }
                    case "uk": {
                        return "Натисніть щоб збільшити"
                    }
                    default: {
                        return "Click to enlarge"
                    }
                }
            }

            for (const image of images) {
                const picture = document.createElement("picture")

                if (image.getAttribute("justify")) {
                    const justify = image.getAttribute("justify") || "flex-start"

                    picture.style.display = image.getAttribute("display") || "block"
                    picture.style.margin =
                        justify === "center" ? "10px auto" : justify === "flex-end" ? "10px 0 10px auto" : "10px 0"
                } else {
                    picture.style.height = "100%"
                    picture.style.width = "auto"
                }

                if (window.getComputedStyle(image).width && parseInt(window.getComputedStyle(image).width) !== 0) {
                    picture.style.minWidth = window.getComputedStyle(image).width
                }

                if (window.getComputedStyle(image).height && parseInt(window.getComputedStyle(image).height) !== 0) {
                    picture.style.height = window.getComputedStyle(image).height
                }

                //image.removeAttribute("style")
                picture.appendChild(image.cloneNode())
                image.replaceWith(picture)
                picture.setAttribute("data-enlargable", t())
                picture.addEventListener("click", this.listener)
            }
        },
        listener({ target }) {
            if (target && target.src) {
                this.root.classList.add("visible")
                this.root.querySelector(".full-page-image").src = target.src
            }
        }
    },
    destroyed() {
        for (const image of this.images) {
            image.removeEventListener("click", this.listener)
        }
    }
}
