<template>
    <div v-if="!showOnlyFrame" :data-state="playerState" class="youtube-player">
        <div :class="{ fixed: fixed, fullscreened: fullscreened, mobile: mobile }" class="youtube-player-container">
            <PlayerControlsWrapper
                ref="controls"
                @toggle:mute="toggleMute"
                @toggle:fs="toggleFullScreen"
                @toggle:pip="togglePip"
                @change:volume="setVolume($event * 100)"
                @change:quality="setQuality($event)"
                @change:rate="setRate($event)"
                @update:seek="updateSeek($event)"
                @forwards="forwards"
                @backwards="backwards"
                @play="playVideo"
                @pause="pauseVideo"
                :quality="getQuality()"
                :rate="getRate()"
                :quality-levels="getQualityLevels()"
                :rate-levels="geRateLevels()"
                :player-state="paused ? PLAYER_STATES.UNINIT : playerState"
                :preview="
                    playerState === PLAYER_STATES.PAUSED && !isShowPreviewOnPause
                        ? ''
                        : preview || `http://img.youtube.com/vi/${extractYoutubeKey(this.link)}/maxresdefault.jpg`
                "
                :muted="muted"
                :time="time"
                :duration="duration"
                :volume="volume / 100"
                :fullscreened="fullscreened"
            />
            <div class="youtube-player-video-container" :class="{ vertical: isVertical }">
                <div
                    class="frame-container"
                    :style="{ pointerEvents: playerState === PLAYER_STATES.UNSTARTED && false ? 'all' : 'none' }"
                >
                    <div class="player" :id="componentId"></div>
                </div>
            </div>
        </div>
    </div>
    <iframe
        style="aspect-ratio: 16 / 9"
        v-else
        width="100%"
        height="auto"
        :src="`https://www.youtube.com/embed/${extractYoutubeKey(link)}?autoplay=1`"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
    ></iframe>
</template>

<script>
import PlayerControlsWrapper, { PLAYER_STATES } from "@components/Players/components/PlayerControlsWrapper.vue"
import BugIcon from "@icons/BugIcon.vue"
import { EventBus, EventsList } from "~events"

export default {
    components: { PlayerControlsWrapper },
    props: {
        isShowPreviewOnPause: {
            type: Boolean,
            default: false,
            required: false
        },
        infoUnitId: {
            type: Number,
            default: 0
        },
        isVertical: {
            type: Boolean,
            default: false,
            required: false
        },
        link: {
            type: String,
            default: ""
        },
        host: {
            type: String,
            default: ""
        },
        preview: {
            type: String,
            default: ""
        }
    },
    name: "YouTubeVideoPlayerContent",
    data() {
        return {
            fullScreenAvailable: true,
            player: null,
            volume: 100,
            fixed: false,
            muted: false,
            fullscreened: false,
            mobile: false,
            isPromptVisible: true,
            playerState: PLAYER_STATES.UNINIT,
            PLAYER_STATES: PLAYER_STATES,
            duration: 0,
            time: 0,
            rate: 1,
            quality: "unknown",
            didUpdate: false,
            paused: false,
            componentId: null,
            retriesCount: 0,
            showOnlyFrame: false
        }
    },

    mounted() {
        this.mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth < 600

        this.componentId = "ytb-id-" + this._uid

        this.fullScreenAvailable =
            document.fullscreenEnabled ||
            document.mozFullscreenEnabled ||
            document.webkitFullscreenEnabled ||
            document.msFullscreenEnabled

        if (this.fullScreenAvailable && !this.isIOS()) {
            const exitHandler = () => {
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    setTimeout(() => {
                        if (this.fullscreened) {
                            this.fullscreened = false
                        }
                    }, 100)
                }
            }
            if (document.addEventListener) {
                document.addEventListener("fullscreenchange", exitHandler, false)
                document.addEventListener("mozfullscreenchange", exitHandler, false)
                document.addEventListener("MSFullscreenChange", exitHandler, false)
                document.addEventListener("webkitfullscreenchange", exitHandler, false)
            }
        }

        setInterval(() => {
            if (this.player && this.player.getPlayerState && this.player.getPlayerState() === PLAYER_STATES.PLAYING) {
                this.time = this.player.getCurrentTime()
                this.$emit("timeupdate", {
                    seconds: this.player.getCurrentTime(),
                    duration: this.player.getDuration()
                })
            }
        }, 1000)

        this.initScript()
    },
    methods: {
        getQualityLevels() {
            if (this.player && this.player.getAvailableQualityLevels) {
                return this.player.getAvailableQualityLevels()
            }
            return []
        },
        geRateLevels() {
            if (this.player && this.player.getAvailablePlaybackRates) {
                return this.player.getAvailablePlaybackRates()
            }
            return []
        },
        getQuality() {
            return this.quality || "unknown"
        },
        getRate() {
            return this.rate || 1
        },
        pauseVideo() {
            this.player.pauseVideo()
        },
        backwards() {
            this.updateSeek(this.player.getCurrentTime() - 10)
        },
        forwards() {
            this.updateSeek(this.player.getCurrentTime() + 10)
        },
        playVideo() {
            if ([PLAYER_STATES.PLAYING, PLAYER_STATES.BUFFERING].indexOf(this.playerState) === -1) {
                this.player.playVideo()
            }

            setTimeout(() => {
                if ([PLAYER_STATES.UNINIT, PLAYER_STATES.UNSTARTED].indexOf(this.playerState) !== -1) {
                    this.retriesCount++
                    if (this.retriesCount > 5) {
                        //this.showOnlyFrame = true

                        console.error("Error playing custom youtube player", this.playerState, this.player)

                        this.$emit("showDefault")
                        return
                    }
                    this.playVideo()
                } else {
                    this.retriesCount = 0
                }
            }, 1000)
        },
        onPlayerReady() {
            setTimeout(() => {
                this.duration = this.player.getDuration()

                if (localStorage.getItem("yt-volume")) {
                    const value = localStorage.getItem("yt-volume")
                    this.volume = value
                    this.player.setVolume(value)
                }
                this.setPlayerState(PLAYER_STATES.UNSTARTED)

                this.playVideo()

                this.$emit("ready", this.player)
            }, 1000)
        },
        setPlayerState(state) {
            const setLoader = () => {
                this.paused = true
                setTimeout(() => {
                    this.paused = false
                }, 400)
            }

            if (state === PLAYER_STATES.PLAYING) {
                this.time = this.player.getCurrentTime()
            }

            if (this.playerState === PLAYER_STATES.PAUSED) {
                setLoader()
            }

            if (state === PLAYER_STATES.PAUSED) {
                this.$emit("pause")
            }

            if (state === PLAYER_STATES.UNSTARTED) {
                setLoader()
            }

            if (state === PLAYER_STATES.ENDED) {
                this.$emit("ended")
            }

            if (this.playerState === PLAYER_STATES.UNSTARTED) {
                this.$emit("play")
                setLoader()
            }

            /* if (this.playerState === PLAYER_STATES.BUFFERING && this.player) {
                this.player.setPlaybackQuality(this.quality)
            }*/

            this.$set(this, "playerState", state)
            this.update()
        },
        updateSeek(val) {
            this.time = val
            this.player.seekTo(val)
        },
        setQuality(val) {
            if (this.player) {
                this.quality = val
                this.player.setPlaybackQuality(val)
            }
        },
        onPlaybackRateChange(val) {
            this.rate = val.data
        },
        onPlaybackQualityChange(val) {
            this.quality = val.data
        },
        setRate(val) {
            if (this.player) {
                this.player.setPlaybackRate(val)
            }
        },
        setVolume(value) {
            if (this.player.isMuted()) {
                this.player.unMute()
                this.muted = false
            }

            this.player.setVolume(value)
            localStorage.setItem("yt-volume", value)
            this.volume = value
        },
        toggleMute() {
            if (this.player.isMuted()) {
                this.player.unMute()
                this.player.setVolume(50)
                this.volume = 50
                this.muted = false
            } else {
                this.player.mute()
                this.player.setVolume(0)
                this.volume = 0
                this.muted = true
            }
        },
        isInFullScreen() {
            return (
                (document.fullscreenElement && true) ||
                (document.webkitFullscreenElement && true) ||
                (document.mozFullScreenElement && true) ||
                (document.msFullscreenElement && true)
            )
        },
        togglePip() {
            const video = this.$el.querySelector(".youtube-player-container iframe")

            if (document.pictureInPictureElement) {
                document.exitPictureInPicture()
            } else if (document.pictureInPictureEnabled) {
                video.requestPictureInPicture()
            }
        },
        toggleFullScreen() {
            const video = this.$el.querySelector(".youtube-player-container")

            this.fullscreened = !this.fullscreened

            if (this.fullscreened) {
                this.scrollTop = document.body.scrollTop
            } else {
                setTimeout(() => {
                    document.body.scrollTop = this.scrollTop
                }, 200)
            }

            if (!this.fullScreenAvailable) {
                this.fixed = !this.fixed
                return
            }

            if (!this.isInFullScreen()) {
                if (video.requestFullScreen) {
                    video.requestFullScreen()
                } else if (video.mozRequestFullScreen) {
                    video.mozRequestFullScreen()
                } else if (video.webkitRequestFullScreen) {
                    video.webkitRequestFullScreen()
                } else if (video.webkitRequestFullscreen) {
                    video.webkitRequestFullscreen()
                } else if (video.webkitEnterFullscreen) {
                    video.webkitEnterFullScreen()
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen()
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen()
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen()
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen()
                }
            }
        },
        initScript() {
            if (!window.YT) {
                const tag = document.createElement("script")

                tag.src = "https://www.youtube.com/iframe_api"
                const firstScriptTag = document.getElementsByTagName("script")[0]
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
            }
            setTimeout(() => {
                this.initPlayer()
            }, 500)
        },
        seek() {
            return 0
        },
        isIOS() {
            return (
                ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
                    navigator.platform
                ) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes("Mac") && "ontouchend" in document)
            )
        },
        initPlayer() {
            if (!window.YT || (window.YT && !window.YT.Player)) {
                this.$nextTick(() => {
                    this.initScript()
                })
                return
            }
            const playerVars = {
                rel: 0,
                showinfo: 0,
                modestbranding: 1,
                iv_load_policy: 3,
                muted: 0,
                enablejsapi: 1,
                origin: window.location.origin,
                playsinline: 1,
                autoplay: 0,
                disablekb: 0,
                controls: 0,
                fs: 1,
                start: this.extractStart(this.link)
            }

            this.player = new window.YT.Player(this.componentId, {
                height: window.innerHeight,
                width: window.innerWidth,
                videoId: this.extractYoutubeKey(this.link),
                playerVars: playerVars,
                host: this.host ? this.host : "https://www.youtube-nocookie.com",
                events: {
                    onPlaybackQualityChange: this.onPlaybackQualityChange.bind(this),
                    onPlaybackRateChange: this.onPlaybackRateChange.bind(this),
                    onReady: this.onPlayerReady.bind(this),
                    onError: this.onPlayerError.bind(this),
                    onStateChange: this.onPlayerStateChange.bind(this)
                }
            })

            EventBus.$on(EventsList.VIDEO_SET_SEEK, ({ unitId, seekTo }) => {
                if (this.infoUnitId === unitId) {
                    this.updateSeek(seekTo)
                }
            })

            /*document.addEventListener("visibilitychange", () => {
                if (!document.hidden) {
                    if (this.volume === 0 || this.muted) {
                        if (this.player.seekTo) {
                            this.player.seekTo(this.seek())
                        }
                    }
                }
            })*/
        },
        onPlayerError(event) {
            const noti = text => {
                if (this.$notify) {
                    this.$notify({
                        position: "top-right",
                        color: "danger",
                        time: 5000,
                        title: this.t("expert.something_went_wrong"),
                        text: text,
                        icon: BugIcon
                    })
                }
            }
            switch (event.data) {
                case 2: {
                    noti("The request contains an invalid parameter value")
                    break
                }
                case 5: {
                    noti(
                        "The requested content cannot be played in an HTML5 player or another error related to the player has occurred."
                    )
                    fetch(
                        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
                        // "https://www3.doubleclick.net",
                        // "https://static.ads-twitter.com/uwt.js"
                        { method: "HEAD", mode: "no-cors", cache: "no-store" }
                    )
                        .then(() => {})
                        .catch(() => {
                            noti("Please disable Adblock")
                        })
                    break
                }
                case 100: {
                    noti("The video requested was not found.")
                    break
                }
                case 101:
                case 150: {
                    noti("The owner of the requested video does not allow it to be played in embedded players")
                    break
                }
                default: {
                    console.error(event)
                }
            }
        },
        onPlayerStateChange(event) {
            this.setPlayerState(event.data)
        },
        update() {
            this.didUpdate = true
            this.$nextTick(() => {
                this.didUpdate = false
            })
        },
        extractYoutubeKey(url) {
            const regExp =
                /^.*(?:(?:youtu\.be\/|v\/|vi\/|live\/|u\/\w\/|embed|shorts|\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
            let match = url.match(regExp)
            let videoId = null
            if (match) {
                if (match[7] && match[7].length === 11) {
                    videoId = match[7]
                } else if (match[1] && match[1].length === 11) {
                    videoId = match[1]
                }
            }
            return videoId || false
        },
        extractStart(url_string) {
            let url = new URL(url_string)
            return parseInt(url.searchParams.get("t"))
        }
    },
    watch: {
        fixed(val) {
            if (val) {
                document.body.classList.add("body-black")
            } else {
                document.body.classList.remove("body-black")
            }
        }
    }
}
</script>



<style scoped lang="sass">
.youtube-player
    border-radius: 10px
    position: relative
    padding-bottom: 50%
    height: 0 !important
    .frame-container
        height: 100%
        width: 100%
        position: relative
        .black-loader
            position: absolute
            top: 0px
            bottom: 0
            left: 0
            right: 0
            width: 100%
            height: 100%
            z-index: 555
            background: #000000
            border-radius: 10px
    video
        width: 100%
        border-radius: 10px
    .youtube-player-video-container
        position: relative
        top: 0
        right: 0
        bottom: 0
        left: 0
        height: 100%
    .youtube-player-container
        position: absolute
        max-height: 100%
        height: 100%
        width: 100%
        border-radius: 10px
        overflow: hidden
        &.fullscreened
            border-radius: 0
            &::v-deep
                .controls-loader, .controls-play
                    border-radius: 0
        &.fixed
            position: fixed
            top: 0
            right: 0
            left: 0
            bottom: 0
            z-index: 9999999999
            background-color: #000
            display: flex
            align-items: center
            justify-content: center
            @media screen and (orientation: portrait)
                .frame-container
                    height: 100% !important
                    position: absolute
            @media screen and (orientation: landscape)
                .frame-container
                    height: auto
            .youtube-player-video-container
                height: max-content
                display: flex
                width: 100%
                &.vertical
                    height: 100% !important
                &::before
                    float: left
                    padding-top: 56.25%
                    content: ""
                &::after
                    display: block
                    content: ""
                    clear: both
            .actions
                border-radius: 0 !important

        &:fullscreen
            width: 100vw
            position: fixed
            .youtube-player-video-container
                @supports not (aspect-ratio: 16 / 9)
                    height: 100% !important
                @supports (aspect-ratio: 16 / 9)
                    transform: translateY(-50%) !important
                    top: 50% !important
                    aspect-ratio: 16 / 9 !important
                    width: 100% !important
                    height: auto !important
                    &.vertical
                        height: 100% !important
            video
                height: 100%
                width: 100%
                border-radius: 0
                object-fit: contain
                @media (orientation: landscape)
                    object-fit: cover
            .actions
                border-radius: 0 !important

    &::v-deep
        video::-webkit-media-controls, video::-webkit-media-controls-enclosure
            display: none
            -webkit-appearance: none
    &::v-deep
        .player
            width: 300%
            height: 100%
            margin-left: -100%
            position: relative
            padding-top: 0
</style>
