<template >
    <div class="quiz-unavailable">
        <img src="@images/portal/safe.svg" width="200" height="200" alt="safe" class="quiz-unavailable-image" />
        <div class="quiz-unavailable-text">
            <p class="quiz-unavailable-title">
                {{ quiz.name }}
            </p>
            <p class="quiz-unavailable-description">
                <DefaultButton
                    @click="reload"
                    v-if="isNewContentAvailable"
                    reverse-icon
                    size="large"
                    background="white"
                    :text="t('create-course.quiz_available_button')"
                >
                    <template #icon>
                        <UpdateIcon />
                    </template>
                </DefaultButton>
                <template v-else>
                    <span v-html="format(blocked.tooltip_message)" v-if="isBlocked()"></span>
                    <span v-html="format(closed.tooltip_message)" v-else-if="isClosed()"></span>
                </template>
            </p>
        </div>
    </div>
</template>

<script>
import DefaultButton from "@components/Buttons/DefaultButton.vue"
import UpdateIcon from "@icons/UpdateIcon.vue"
import { DateTime } from "luxon"

export default {
    components: { UpdateIcon, DefaultButton },
    props: {
        quiz: {
            type: Object,
            default: () => {}
        }
    },
    name: "QuizUnavailable",
    data() {
        return {
            interval: null,
            isNewContentAvailable: false
        }
    },
    created() {
        if (!this.blocked.is_accessible && this.blocked.access_date) {
            this.clearContentInterval()
            this.checkNewContent()
        }
    },
    methods: {
        clearContentInterval() {
            if (this.interval) {
                clearInterval(this.interval)
            }
        },
        checkNewContent() {
            this.interval = setInterval(() => {
                const seconds = DateTime.fromISO(this.blocked.access_date)
                    .setZone(window.app_timezone)
                    .diffNow("seconds").seconds

                if (seconds < 3) {
                    this.isNewContentAvailable = true
                    this.clearContentInterval()
                }
            }, 1000)
        },
        reload() {
            location.reload()
        },
        format(message) {
            const toSpan = (date, modifier) => {
                return `<span class="${modifier}">${DateTime.fromISO(date)
                    .setLocale(window.app_current_locale)
                    .setZone(window.app_timezone)
                    .toFormat("dd MMMM, yyyy HH:mm")}</span> <span class="gray-light">(${window.app_timezone})</span>`
            }

            return message.replaceAll(
                ":date",
                this.isBlocked() ? toSpan(this.blocked.access_date, "blue") : toSpan(this.closed.access_date, "red")
            )
        },
        isBlocked() {
            this.$nextTick(() => {
                if (!this.blocked.is_accessible && this.blocked.access_date) {
                    this.clearContentInterval()
                    this.checkNewContent()
                }
            })
            return !this.blocked.is_accessible
        },
        isClosed() {
            return !this.closed.is_accessible
        }
    },
    beforeDestroy() {
        this.clearContentInterval()
    },
    computed: {
        blocked() {
            return this.quiz.blocked_info
        },
        closed() {
            return this.quiz.closed_info
        }
    }
}
</script>

<style scoped lang="sass">
.quiz-unavailable
    padding: 20px
    color: #F2F2F2
    display: flex
    background: #F2F2F2
    align-items: center
    border-radius: 10px
    @media (max-width: 580px)
        flex-direction: column
        align-items: flex-start
    @media (max-width: 400px)
        padding: 20px 10px 28px 10px
    .quiz-unavailable-description
        font-size: 16px
        line-height: 28px
        font-weight: 500
        color: #52565C
        @media (max-width: 480px)
            font-size: 16px
            line-height: 25px
        @media (max-width: 400px)
            font-size: 14px
            line-height: 22px
        &::v-deep
            span
                &.red
                    color: #DD4141
                &.blue
                    color: #3965FF
                &.gray-light
                    color: #98A2B0
            .color-grey
                color: #98A2B0
    .quiz-unavailable-title
        font-size: 18px
        line-height: 23px
        font-weight: 500
        font-family: 'Ubuntu', serif
        color: #151515
        margin-bottom: 20px
        @media (max-width: 480px)
            font-size: 16px
            line-height: 21px
    .quiz-unavailable-image
        margin-right: 40px
        @media (max-width: 580px)
            margin: 0 auto  30px
</style>
