const state = {
    el: null,
    fn: null
}
function setState(key, val) {
    state[key] = val
}
function setEvent(el, fn) {
    setState("el", el)
    setState("fn", fn)
    document.body.addEventListener("click", clickOutSide)
}
function removeEvent() {
    setState("el", null)
    setState("fn", null)
    document.body.removeEventListener("click", clickOutSide)
}
function clickOutSide(event) {
    if (!(state.el === event.target || state.el.contains(event.target))) {
        state.fn()
        removeEvent()
    }
}

export default function clickToOutside($el, val, callback) {
    if (val) setEvent($el, callback)
    else removeEvent()
}
