
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M37.5 75C58.2107 75 75 58.2107 75 37.5C75 16.7893 58.2107 0 37.5 0C16.7893 0 0 16.7893 0 37.5C0 58.2107 16.7893 75 37.5 75ZM37.5 68.75C54.7589 68.75 68.75 54.7589 68.75 37.5C68.75 20.2411 54.7589 6.25 37.5 6.25C20.2411 6.25 6.25 20.2411 6.25 37.5C6.25 54.7589 20.2411 68.75 37.5 68.75Z"
            fill="url(#paint0_angular_9566_200926)"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M71.1923 30.0021C72.904 29.7813 74.4706 30.9899 74.6915 32.7016C74.8967 34.2928 74.9997 35.8955 74.9997 37.4999C74.9997 39.2258 73.6006 40.6249 71.8747 40.6249C70.1488 40.6249 68.7497 39.2258 68.7497 37.4999C68.7497 36.1629 68.6639 34.8273 68.4928 33.5013C68.272 31.7896 69.4806 30.2229 71.1923 30.0021Z"
            fill="#15A06E"
        />
        <defs>
            <radialGradient
                id="paint0_angular_9566_200926"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(37.5 37.5) scale(37.5)"
            >
                <stop stop-color="#15A06E" stop-opacity="0" />
                <stop offset="0.0001" stop-color="#3AE180" stop-opacity="0" />
                <stop offset="1" stop-color="#15A06E" />
            </radialGradient>
        </defs>
    </svg>
</template>
<script>
export default {
    name: "LoaderV2Icon"
}
</script>