<template>
    <div class="code-component code-component_student" :class="theme">
        <SpinLoader width="35" style="margin-top: 5px" v-if="loading" :color="`${theme === 'vs' ? '#000' : '#fff'}`" />
        <div :data-uuid="uuid" class="code-component-wrapper"></div>
    </div>
</template>

<script>
import SpinLoader from "@components/Loaders/SpinLoader.vue"
import { ExternalLoader } from "@helpers"
import { v4 } from "uuid"

export default {
    components: { SpinLoader },
    props: {
        theme: {
            default: "vs-dark"
        },
        language: {
            default: "html"
        },
        value: {
            default: ""
        }
    },
    name: "CodeComponent",
    data() {
        return {
            uuid: v4(),
            loading: true
        }
    },
    mounted() {
        if (!window.monaco_loader && !window.monaco_promise) {
            window.monaco_promise = ExternalLoader.SCRIPT(["/build/js/monaco-loader.min.js"])
        }

        window.monaco_promise.then(() => {
            this.loading = false
            this.initEditor()
        })
    },
    methods: {
        initEditor() {
            const container = document.querySelector(`[data-uuid='${this.uuid}']`)
            const width = this.$el.getBoundingClientRect().width

            if (container) {
                container.innerHTML = ""
            } else {
                return
            }
            let val = this.value

            if (val) {
                val = val.replaceAll("&nbsp;", " ")
            }

            window.monaco_loader.init().then(monaco => {
                this.monaco = monaco.editor.create(container, {
                    value: val,
                    padding: {
                        top: 15,
                        bottom: 15
                    },
                    language: this.language,
                    theme: this.theme || "vs-dark",
                    scrollBeyondLastLine: false,
                    wordWrap: "on",
                    automaticLayout: true,
                    wrappingStrategy: "advanced",
                    minimap: {
                        enabled: false
                    },
                    overviewRulerLanes: 0
                })

                monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
                    noSemanticValidation: true,
                    noSyntaxValidation: true // This line disables errors in jsx tags like <div>, etc.
                })

                const editor = this.monaco

                // eslint-disable-next-line no-unused-vars
                let ignoreEvent = false
                const updateHeight = () => {
                    const contentHeight = Math.min(1000, editor.getContentHeight())
                    container.style.width = `${width}px`
                    container.style.height = `${contentHeight}px`
                    try {
                        ignoreEvent = true
                        editor.layout({ width, height: contentHeight })
                    } finally {
                        ignoreEvent = false
                    }
                }
                editor.onDidContentSizeChange(updateHeight)
                updateHeight()
            })
        }
    }
}
</script>

<style lang="sass" >
.code-component
    border-radius: 6px
    position: relative
    z-index: 2
    margin: 20px 0
    &_student
        min-height: 50px !important
        .bracket-match
            border-color: transparent !important
        .spin-loader
            display: block !important
            position: relative
            top: 5px
    &.vs-dark
        background-color: #1e1e1e
    &.vs
        border: 1px solid #dedede
    .dropdown-scroll
        max-height: 320px !important
    .lang-dropdown
        position: absolute
        z-index: 1
        top: 7px
        left: 25px
    .monaco-editor
        border-radius: 6px
        .overflow-guard
            border-radius: 6px
</style>
