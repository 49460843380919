<template>
    <div
        @mouseenter="handleEnter"
        @mouseleave="handleLeave"
        v-click-outside="hide"
        class="alert-with-question"
        :class="{ 'mr-8': !hasSlot('description'), 'alert-with-question_hoverable': hoverable }"
    >
        <div
            v-if="!noHanlder"
            @click.stop="handleClick"
            class="alert-with-question__handler"
            :class="{
                'mr-8': hasSlot('description') || slotKey,
                'alert-with-question__handler_custom': customHandler,
                'alert-with-question__handler_hoverable': hoverable
            }"
        >
            <svg
                v-if="!hasSlot('icon')"
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="11"
                viewBox="0 0 6 11"
                :fill="questionMarkColor"
            >
                <path
                    d="M2.60932 1.51601C2.28266 1.51601 1.96999 1.55801 1.67132 1.64201C1.37266 1.71667 1.05999 1.84267 0.733324 2.02001L0.257324 0.718005C0.602658 0.522005 0.994658 0.368006 1.43332 0.256005C1.88132 0.134672 2.33866 0.0740051 2.80532 0.0740051C3.36532 0.0740051 3.82732 0.153338 4.19132 0.312005C4.55532 0.461338 4.84466 0.652672 5.05932 0.886005C5.27399 1.11934 5.42332 1.37601 5.50732 1.65601C5.59132 1.93601 5.63332 2.20667 5.63332 2.46801C5.63332 2.78534 5.57266 3.07001 5.45132 3.32201C5.33932 3.57401 5.19466 3.80734 5.01732 4.02201C4.83999 4.23667 4.64866 4.44201 4.44332 4.63801C4.23799 4.82467 4.04666 5.02067 3.86932 5.22601C3.69199 5.42201 3.54266 5.63201 3.42132 5.85601C3.30932 6.08001 3.25332 6.33201 3.25332 6.61201C3.25332 6.65867 3.25332 6.71467 3.25332 6.78001C3.25332 6.83601 3.25799 6.89201 3.26732 6.94801H1.81132C1.79266 6.85467 1.77866 6.75667 1.76932 6.65401C1.75999 6.54201 1.75532 6.43934 1.75532 6.34601C1.75532 6.03801 1.80666 5.76267 1.90932 5.52001C2.01199 5.27734 2.14266 5.05334 2.30132 4.84801C2.45999 4.64267 2.62799 4.45134 2.80532 4.27401C2.99199 4.09667 3.16466 3.91934 3.32332 3.74201C3.48199 3.56467 3.61266 3.38267 3.71532 3.19601C3.81799 3.00934 3.86932 2.80401 3.86932 2.58001C3.86932 2.27201 3.76199 2.02001 3.54732 1.82401C3.34199 1.61867 3.02932 1.51601 2.60932 1.51601ZM3.70132 9.07601C3.70132 9.40267 3.59399 9.66867 3.37932 9.87401C3.16466 10.0793 2.89866 10.182 2.58132 10.182C2.27332 10.182 2.00732 10.0793 1.78332 9.87401C1.56866 9.66867 1.46132 9.40267 1.46132 9.07601C1.46132 8.74934 1.56866 8.48334 1.78332 8.27801C2.00732 8.06334 2.27332 7.95601 2.58132 7.95601C2.89866 7.95601 3.16466 8.06334 3.37932 8.27801C3.59399 8.48334 3.70132 8.74934 3.70132 9.07601Z"
                />
            </svg>
            <template v-else>
                <slot name="icon"></slot>
            </template>
        </div>
        <transition name="fade-up">
            <wrapper-alert
                class="wrapper-alert-question"
                :class="{ 'wrapper-alert_hoverable': hoverable, 'wrapper-alert_fixed': fixed }"
                :without-close="withoutClose"
                :style="alertStyle()"
                @close="isOpen = false"
                v-if="isOpen"
            >
                <slot name="default"></slot>

                <p
                    v-if="hasSlot('description') && !hasSlot('default') && !hasSlot('modal-title')"
                    class="alert-with-question-modal-title"
                >
                    <slot name="description"></slot>
                </p>

                <p v-if="hasSlot('modal-title') || slotKey" class="alert-with-question-modal-title">
                    <slot name="modal-title"></slot>
                    <span v-if="slotKey" v-html="getSlotTitle()"></span>
                </p>

                <p
                    v-if="hasSlot('modal-description') || slotKey"
                    class="alert-with-question-modal-description custom-scroll"
                >
                    <slot name="modal-description"></slot>
                    <span v-if="slotKey" v-html="getSlotDescription()"></span>
                    <red-alert class="mt-10 mb-10" v-if="hasSlot('modal-alert')" inter>
                        <strong v-if="attention"> {{ attention }} </strong>
                        <slot name="modal-alert"></slot>
                    </red-alert>
                    <slot name="modal-description2"></slot>
                </p>
            </wrapper-alert>
        </transition>

        <p
            class="alert-with-question-description"
            :class="{ 'full-width': fullWidth }"
            v-if="hasSlot('description') || slotKey"
        >
            <slot name="description"></slot>
            <span v-if="slotKey" v-html="getSlotTitle()"></span>
        </p>
    </div>
</template>

<script>
import RedAlert from "../Alerts/RedAlert.vue"
import { EventBus } from "../../../common/utils/Events/index"
import WrapperAlert from "../Alerts/WrapperAlert"
import ClickOutside from "vue-click-outside"

const resolveIdent = (ident = [0, 0, 0, 0]) => {
    return ident.map(n => `${n}px`).join(" ")
}

export default {
    props: {
        top: {
            type: Number,
            default: 0
        },
        isRight: {
            type: Boolean,
            default: false
        },
        withoutClose: {
            type: Boolean,
            default: false
        },
        fixed: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        customHandler: {
            type: Boolean,
            default: false
        },
        noHanlder: {
            type: Boolean,
            default: false
        },
        alertWidth: {
            type: Number,
            default: 0
        },
        alertMargin: {
            type: Array,
            default: () => []
        },
        alertPadding: {
            type: Array,
            default: () => []
        },
        styleAlert: {
            type: Object,
            default: () => {}
        },
        hoverable: {
            type: Boolean,
            default: false
        },
        isOrange: {
            type: Boolean,
            default: false
        },
        attention: {
            type: String,
            default: ""
        },
        slotKey: {
            type: String,
            default: ""
        }
    },
    name: "AlertWithQuestion",
    directives: {
        ClickOutside
    },
    components: { RedAlert, WrapperAlert },
    data() {
        return {
            isOpen: false,
            uuid: ""
        }
    },
    mounted() {
        this.uuid = this._uid
        EventBus.$on("hide-menu", uuid => {
            if (uuid !== this.uuid) {
                this.isOpen = false
            }
        })
        if (this.fixed) {
            document.body.addEventListener("wheel", () => {
                this.$forceUpdate()
            })
        }
    },
    methods: {
        getSlotTitle() {
            if (this.slotKey && this.t) {
                return this.t(this.slotKey)
            }
        },
        getSlotDescription() {
            if (this.slotKey && this.t) {
                return this.t(`${this.slotKey}_descr`)
            }
        },
        hasSlot(name = "default") {
            return !!this.$slots[name]
        },
        hide() {
            this.isOpen = false
        },
        open() {
            this.isOpen = true
        },
        handleEnter() {
            if (this.hoverable) {
                this.isOpen = true
            }
        },
        handleClick(event) {
            if (!this.isOpen) {
                EventBus.$emit("hide-menu", this.uuid)
                event.preventDefault()
                event.stopPropagation()
            }

            this.isOpen = !this.isOpen
        },
        handleLeave() {
            if (this.hoverable) {
                this.isOpen = false
            }
        },
        alertStyle() {
            const style = { top: `${this.top}px` }
            if (this.alertWidth) {
                style.width = `${this.alertWidth}px`
            }
            if (this.alertMargin.length) {
                style.margin = `${resolveIdent(this.alertMargin)} !important`
            }

            if (this.alertPadding.length) {
                style.padding = `${resolveIdent(this.alertPadding)} !important`
            }

            if (this.fixed && this.$el) {
                const { top, left } = this.$el.getBoundingClientRect()
                style.position = `fixed`
                style.transform = "translateY(-100%)"
                style.top = `${top + 15}px !important`
                style.left = `${left}px !important`
                style.bottom = "auto"

                if (this.isRight) {
                    style.transform = "translate(-100%, -100%)"
                }
            }

            return {
                ...style,
                ...this.styleAlert
            }
        }
    },
    computed: {
        questionMarkColor() {
            if (this.isOrange) {
                return "#FF7439"
            }
            return "#3965FF"
        }
    }
}
</script>

<style scoped lang="sass">

.alert-with-question-description, .alert-with-question-modal-title, .alert-with-question-modal-description
    font-size: 14px
    font-weight: 400
    line-height: 22px
    color: hsla(0, 0%, 8%, 1)
    font-family: Inter, sans-serif

.alert-with-question-modal-title
    font-weight: 500
    &::v-deep
        & > span
            color: hsla(0, 0%, 8%, 1) !important

.alert-with-question-modal-description
    color: hsla(216, 6%, 34%, 1)
    margin-top: 4px
    font-size: 13px
    max-height: 310px
    overflow: auto
    margin-right: -10px
    padding-right: 10px

.alert-with-question
    position: relative
    display: flex
    align-items: center
    &.inline-flex
        display: inline-flex
    &_hoverable
        .alert-with-question-description
            cursor: pointer
    &::v-deep
        .default-description, .default-title
            a
                color: #3965FF
    &__handler
        width: 20px
        min-width: 20px
        height: 20px
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        border-radius: 4px
        &:hover
            background: #fff
            box-shadow: 0 1px 4px rgba(56, 79, 104, 0.15)
        &_hoverable
            &:hover
                background: initial
                box-shadow: none
</style>
