<template>
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="11.5" y="16.25" width="10" height="1.5" rx="0.75" fill="white" />
    </svg>
</template>

<script>
export default {
    name: "MinusIcon"
}
</script>

<style lang="sass" scoped>

</style>
