import LessonMutations from "./lesson-mutations"
import LessonActions from "./lesson-actions"
import LessonGetters from "./lesson-getters"

const LessonInitialState = {
    lesson: null,
    lessonDto: null,
    section: null,
    entry: "course",
    course: null,
    error: null,
    loaders: {
        all: false,
        program: false,
        create: false,
        save: false,
        courses: false,
        settings: false,
        section: false
    },
    programs: {},
    courses: [],
    activeSectionIndex: 0
}

export default {
    state: LessonInitialState,
    mutations: LessonMutations,
    actions: LessonActions,
    getters: LessonGetters
}
