
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#3E4755" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.25 10C19.25 10.4142 19.5858 10.75 20 10.75C20.4142 10.75 20.75 10.4142 20.75 10H19.25ZM20 4.00004H20.75C20.75 3.58582 20.4142 3.25004 20 3.25004V4.00004ZM14 3.25004C13.5858 3.25004 13.25 3.58582 13.25 4.00004C13.25 4.41425 13.5858 4.75004 14 4.75004V3.25004ZM4.75 14C4.75 13.5858 4.41421 13.25 4 13.25C3.58579 13.25 3.25 13.5858 3.25 14H4.75ZM4 20H3.25C3.25 20.1989 3.32902 20.3897 3.46967 20.5303C3.61032 20.671 3.80109 20.75 4 20.75V20ZM10 20.75C10.4142 20.75 10.75 20.4142 10.75 20C10.75 19.5858 10.4142 19.25 10 19.25V20.75ZM10 4.75C10.4142 4.75 10.75 4.41421 10.75 4C10.75 3.58579 10.4142 3.25 10 3.25V4.75ZM4.00004 4L4.00004 3.25C3.58582 3.25 3.25004 3.58579 3.25004 4L4.00004 4ZM3.25004 10C3.25004 10.4142 3.58582 10.75 4.00004 10.75C4.41425 10.75 4.75004 10.4142 4.75004 10H3.25004ZM14 19.25C13.5858 19.25 13.25 19.5858 13.25 20C13.25 20.4142 13.5858 20.75 14 20.75V19.25ZM20 20V20.75C20.4142 20.75 20.75 20.4142 20.75 20H20ZM20.75 14C20.75 13.5858 20.4142 13.25 20 13.25C19.5858 13.25 19.25 13.5858 19.25 14H20.75ZM20.75 10V4.00004H19.25V10H20.75ZM20 3.25004L14 3.25004V4.75004L20 4.75004V3.25004ZM3.25 14L3.25 20H4.75L4.75 14H3.25ZM4 20.75H10V19.25H4V20.75ZM10 3.25L4.00004 3.25L4.00004 4.75L10 4.75V3.25ZM3.25004 4L3.25004 10H4.75004L4.75004 4L3.25004 4ZM14 20.75H20V19.25H14V20.75ZM20.75 20V14H19.25V20H20.75Z"
        />
    </svg>
</template>
<script>
export default {
    name: "FullScreenIcon"
}
</script>
