<template>
    <div class="progress-steps">
        <div
            class="progress-step"
            :key="step"
            :class="{ 'progress-step_active': step <= current }"
            v-for="step in steps"
        >
            {{ step }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        steps: {
            type: Number,
            default: 0
        },
        current: {
            type: Number,
            default: 0
        }
    },
    name: "ProgressSteps"
}
</script>

<style scoped lang="sass">
.progress-steps
    display: flex
    align-items: center
    justify-content: center
.progress-step
    width: 30px
    height: 30px
    border-radius: 50%
    font-size: 16px
    font-family: Inter, sans-serif
    display: flex
    align-items: center
    justify-content: center
    color: #fff
    background-color: #D9D9D9
    position: relative
    &:not(&:first-child)
        margin-left: 40px
        &::before
            content: ''
            position: absolute
            width: 20px
            height: 2px
            background-color: #D9D9D9
            left: -30px
    &_active
        background-color: #3965FF
        &::before
            background-color: #3965FF !important
            border-radius: 4px
</style>
