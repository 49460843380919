import PracticePasteWordAnswer from "@components/Practice/PracticeQuestions/PracticePasteWordAnswer.vue"
import PracticeWordOrderAnswer from "@components/Practice/PracticeQuestions/PracticeWordOrderAnswer.vue"
import { QUESTION_TYPES } from "./_types"
import PracticeWordFromCharsAnswer from "@components/Practice/PracticeQuestions/PracticeWordFromCharsAnswer.vue"
import PracticeTextMediaAnswer from "@components/Practice/PracticeQuestions/PracticeTextMediaAnswer.vue"
import PracticeFillGapsAnswer from "@components/Practice/PracticeQuestions/PracticeFillGapsAnswer.vue"
import PracticeLikertScaleAnswer from "@components/Practice/PracticeQuestions/PracticeLikertScaleAnswer.vue"
import PracticeInterviewAnswer from "@components/Practice/PracticeQuestions/PracticeInterviewAnswer.vue"
import PracticeSortingAnswer from "@components/Practice/PracticeQuestions/PracticeSortingAnswer.vue"
import PracticeConformityAnswer from "@components/Practice/PracticeQuestions/PracticeConformityAnswer.vue"
import PracticeTextGraphicAnswer from "@components/Practice/PracticeQuestions/PracticeTextGraphicAnswer.vue"
import PracticeMultipleAnswer from "@components/Practice/PracticeQuestions/PracticeMultipleAnswer.vue"
import PracticeSingleAnswer from "@components/Practice/PracticeQuestions/PracticeSingleAnswer.vue"
import PracticeScaleAnswer from "@components/Practice/PracticeQuestions/PracticeScaleAnswer.vue"
import PracticeNetPromoteAnswer from "@components/Practice/PracticeQuestions/PracticeNetPromoteAnswer.vue"

export default {
    [QUESTION_TYPES.SINGLE_ANSWER]: PracticeSingleAnswer,
    [QUESTION_TYPES.MULTIPLE_ANSWER]: PracticeMultipleAnswer,
    [QUESTION_TYPES.TEXT_GRAPHIC_ANSWER]: PracticeTextGraphicAnswer,
    [QUESTION_TYPES.CONFORMITY_ANSWER]: PracticeConformityAnswer,
    [QUESTION_TYPES.SORTING]: PracticeSortingAnswer,
    [QUESTION_TYPES.INTERVIEW]: PracticeInterviewAnswer,
    [QUESTION_TYPES.LIKERT_SCALE]: PracticeLikertScaleAnswer,
    [QUESTION_TYPES.FILL_GAPS]: PracticeFillGapsAnswer,
    [QUESTION_TYPES.WORD_FROM_CHARS]: PracticeWordFromCharsAnswer,
    [QUESTION_TYPES.TEXT_MEDIA_ANSWER]: PracticeTextMediaAnswer,
    [QUESTION_TYPES.RATING]: PracticeScaleAnswer,
    [QUESTION_TYPES.REACTION]: PracticeScaleAnswer,
    [QUESTION_TYPES.YES_NO]: PracticeScaleAnswer,
    [QUESTION_TYPES.WORD_ORDER]: PracticeWordOrderAnswer,
    [QUESTION_TYPES.NET_PROMOTE]: PracticeNetPromoteAnswer,
    [QUESTION_TYPES.PASTE_WORD]: PracticePasteWordAnswer,
    [QUESTION_TYPES.CHOOSE_FROM_LIST]: PracticePasteWordAnswer,
    [QUESTION_TYPES.MULTI_CHOOSE_FROM_LIST]: PracticePasteWordAnswer
}
