<template>
    <div :style="questionStyle()" :class="{ 'practice-question_with_code': hasCode() }" class="practice-question">
        <PracticeQuestionInfo
            v-bind="widget()"
            :expert-view="expertView"
            :number="questionNumber"
            :name="question.name"
            :question="question"
            :quiz="quiz"
        />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />
        <div v-else class="mb-10"></div>

        <FilesNewView v-if="question.files && question.files.length" :files="question.files" />

        <PracticeTextMediaInput
            @update="update"
            @input="handleInput"
            v-if="interactive"
            :value="question.text_answer"
            class="mt-10"
            :errors="errors"
            :readonly="false"
            :question="question"
            :user="user"
        />

        <div v-else-if="getResult() && isShowAnswers()">
            <default-description class="black mt-15 mb-10" :line-height="24" :size="16">
                {{ getResult().text_answer }}
            </default-description>

            <div v-if="getResult().files && getResult().files.length" class="mt-10 mb-10">
                <FilesUploader
                    parent="student"
                    short-file
                    :isCustomLabelEnable="true"
                    :filesList="getResult().files"
                    :userComments="true"
                    :user="user"
                    appModel="comment"
                />
            </div>
        </div>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />

        <PracticeQuestionComment v-if="!interactive && question.comment" :question="question" />

        <PracticeActionsContainer
            :question="question"
            :quiz="quiz"
            v-if="interactive && !didUpdate"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import PracticeMixin from "@components/Practice/PracticeQuestions/_mixin"
import PracticeCorrectStatusAlert from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import PracticeQuestionInfo from "@components/Practice/PracticeQuestions/components/PracticeQuestionInfo.vue"
import FilesNewView from "@components/Files/FilesNewView.vue"
import PracticeQuestionDefaultOptions from "@components/Practice/PracticeQuestions/components/PracticeQuestionDefaultOptions.vue"
import PracticeActionsContainer from "@components/Practice/PracticeQuestions/components/PracticeActionsContainer.vue"
import PracticeTextMediaInput from "@components/Practice/common/inputs/PracticeTextMediaInput.vue"
import DefaultDescription from "@components/Typography/DefaultDescription.vue"
import FilesUploader from "@components/FilesUploader.vue"
import { TEXT_ANSWER_TYPES } from "@components/Practice/PracticeQuestions/_types"
import { Debouncer } from "@helpers"
import moment from "moment"

export default {
    name: "PracticeTextMediaAnswer",
    components: {
        PracticeCorrectStatusAlert,
        FilesUploader,
        DefaultDescription,
        PracticeTextMediaInput,
        PracticeActionsContainer,
        PracticeQuestionDefaultOptions,
        FilesNewView,
        PracticeQuestionInfo
    },
    mixins: [PracticeMixin],
    data() {
        return {
            didUpdate: false,
            debouncer: new Debouncer()
        }
    },
    created() {
        if (this.question.text_answer_type_id === TEXT_ANSWER_TYPES.DATE) {
            if (this.question.text_answer) {
                const date = moment(this.question.text_answer)
                if (!date.isValid()) {
                    this.question.text_answer = ""
                }
            }
        }
    },
    computed: {
        user() {
            return this.$store.getters["user/currentUser"]
        }
    },
    methods: {
        handleInput(event) {
            this.question.text_answer = event
            this.update()
            this.$forceUpdate()
        },
        update() {
            this.didUpdate = true
            this.$nextTick(() => {
                this.didUpdate = false
            })

            this.$emit("updateActions")

            this.$emit("callout")
        },
        getResult() {
            return this.results.find(r => r.quiz_question_id === this.question.id)
        }
    }
}
</script>


