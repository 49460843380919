<template>
    <div :style="questionStyle()" :class="{ 'practice-question_with_code': hasCode() }" class="practice-question">
        <PracticeQuestionInfo
            v-bind="widget()"
            :expert-view="expertView"
            :number="questionNumber"
            :name="question.name"
            :question="question"
            :quiz="quiz"
        />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />
        <div v-else class="mb-10"></div>

        <FilesNewView v-if="question.files && question.files.length" :files="question.files" />

        <div
            v-if="isShowAnswers()"
            class="practice-question__answers mt-10"
            :class="{ 'practice-question__answers_disabled': disabledByTimer() }"
        >
            <flex-container align="center" class="mt-10 mb-10">
                <img :style="getImageStyle(question)" v-if="question.image" :src="question.image.url" alt="image" />

                <PracticeQuestionDescription class="mb-10 ml-20" v-if="question.text" :description="question.text" />
            </flex-container>

            <flex-container
                :direction="!isHorizontal(question.likert_scale_type) ? 'column' : 'row'"
                class="flex-wrap mb-20 ml-20"
            >
                <div
                    @click="setCorrect(answer)"
                    :class="{
                        'pointer-events-none': readonly,
                        'practice-question__answer_max': isHorizontal(question.likert_scale_type),
                        'practice-question__answer_hovered_active': getIsActive(answer)
                    }"
                    class="practice-question__answer practice-question__answer_hovered cursor-pointer pr-10"
                    v-for="(answer, index) in question.answers"
                    :style="{
                        marginRight: isHorizontal(question.likert_scale_type) ? '25px' : 0
                    }"
                    :key="index"
                >
                    <flex-container
                        align="center"
                        :style="{
                            opacity: isAnswered(answer) || checkable ? 1 : 0.4
                        }"
                    >
                        <template v-if="!isHorizontal(question.likert_scale_type)">
                            <PracticeRadio
                                :class="{
                                    'with-black':
                                        !interactive &&
                                        (typeShow() === 'simple_answer' ||
                                            typeShow() === 'only_correct_or_not_question')
                                }"
                                :active="getIsActive(answer)"
                            />
                            <img
                                :style="getImageStyle(answer)"
                                v-if="answer.image"
                                :src="answer.image.url"
                                alt="image"
                                class="mr-15"
                            />

                            <PracticeQuestionDescription class="m-5" :description="answer.text" />
                        </template>
                        <template v-else>
                            <div>
                                <img
                                    :style="getImageStyle(answer)"
                                    v-if="answer.image"
                                    :src="answer.image.url"
                                    alt="image"
                                    class="mb-10"
                                />
                                <flex-container align="center">
                                    <PracticeRadio
                                        :class="{
                                            'with-black':
                                                !interactive &&
                                                (typeShow() === 'simple_answer' ||
                                                    typeShow() === 'only_correct_or_not_question')
                                        }"
                                        :active="getIsActive(answer)"
                                    />

                                    <PracticeQuestionDescription class="ml-5" :description="answer.text" />
                                </flex-container>
                            </div>
                        </template>
                    </flex-container>
                </div>
            </flex-container>
        </div>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />

        <PracticeQuestionComment v-if="isDisplayComment" :question="question" />

        <PracticeActionsContainer
            class="mt-25"
            v-if="interactive"
            :question="question"
            :quiz="quiz"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import PracticeCorrectStatusAlert from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import FlexContainer from "../../Containers/FlexContainer"
import PracticeRadio from "../common/inputs/PracticeRadio"
import PracticeMixin from "./_mixin"
import PracticeActionsContainer from "./components/PracticeActionsContainer.vue"
import PracticeQuestionDefaultOptions from "./components/PracticeQuestionDefaultOptions"
import FilesNewView from "../../Files/FilesNewView"
import PracticeQuestionInfo from "./components/PracticeQuestionInfo"
//
export default {
    props: {
        question: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        PracticeCorrectStatusAlert,
        PracticeQuestionInfo,
        PracticeActionsContainer,
        FilesNewView,
        PracticeQuestionDefaultOptions,
        PracticeRadio,
        FlexContainer
    },
    name: "PracticeLikertScaleAnswer",
    mixins: [PracticeMixin],
    methods: {
        getIsActive(answer) {
            if (!this.interactive) {
                return !!this.isAnswered(answer)
            }
            return !!answer.is_correct
        },
        setCorrect(answer) {
            if (this.disabledByTimer() || !this.interactive) {
                if (!this.checkable) {
                    return
                } else {
                    this.$emit("callout")
                }
            }

            this.$nextTick(() => {
                for (const answer of this.question.answers) {
                    answer.is_correct = false
                }

                const issuedAnswer = this.question.answers.find(a => a.id === answer.id)
                issuedAnswer.is_correct = true

                this.$forceUpdate()
                this.$emit("callout")
            })
        }
    }
}
</script>
