<template>
    <div class="recording-script">
        <flex-container align="center">
            <alert-with-question>
                <default-title class="mb-5"> Показать скрипт </default-title>
                <default-description :line-height="17">
                    Со стороны участника появится возможность посмотреть скрипт. По умолчанию скрипт свернут.
                </default-description>
            </alert-with-question>
            <default-description class="black" :weight="500"> Показать скрипт </default-description>
            <CustomSwitcher class="recording-script-switcher" v-model="displayScript" />
        </flex-container>
        <textarea v-if="displayScript" placeholder="Введите скрипт" class="recording-script__text-area"></textarea>
    </div>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer.vue"
import AlertWithQuestion from "@components/Alerts/AlertWithQuestion.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import DefaultDescription from "@components/Typography/DefaultDescription.vue"
import CustomSwitcher from "@components/Forms/CustomSwitcher.vue"

export default {
    props: {
        parent: {
            type: String,
            default: "student"
        }
    },
    name: "RecordingScript",
    components: { CustomSwitcher, DefaultDescription, DefaultTitle, AlertWithQuestion, FlexContainer },
    data() {
        return {
            displayScript: false
        }
    }
}
</script>

<style scoped lang="sass">
.recording-script
    margin-bottom: 10px
    .alert-with-question
        margin-right: 10px
    .recording-script-switcher
        margin-left: 50px
    &__text-area
        margin-top: 17px
        font-family: 'Lato', sans-serif
        border: 1px solid #E0E0EC
        border-radius: 8px
        padding-top: 14px
        padding-left: 32px
        width: 100%
        min-height: 150px
</style>
