<template>
    <div class="single-switcher">
        <div
            class="single-switcher__wrapper"
            :class="{ active: active }"
            :style="wrapperStyles"
            @click="$emit('switch')"
        >
            <div class="single-switcher__dot" :style="{ marginRight: marginRight && `${marginRight}px` }">
                <div class="single-switcher__dot-inner"></div>
            </div>

            <p v-if="text" class="single-switcher__text" :style="textStyles" v-html="text"></p>

            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "SingleSwitcher",
    props: {
        active: {
            type: Boolean,
            default: false
        },
        text: String,
        marginRight: Number,
        ItemMarginBottom: Number,
        ItemMarginRight: Number,
        ItemMarginLeft: Number,
        textColor: String,
        textFontSize: Number
    },
    computed: {
        wrapperStyles() {
            return {
                marginBottom: this.ItemMarginBottom && `${this.ItemMarginBottom}px`,
                marginRight: this.ItemMarginRight && `${this.ItemMarginRight}px`,
                marginLeft: this.ItemMarginLeft && `${this.ItemMarginLeft}px`
            }
        },
        textStyles() {
            return {
                color: this.textColor && this.textColor,
                fontSize: this.textFontSize && `${this.textFontSize}px`
            }
        }
    }
}
</script>

<style lang="sass" scoped>
$activeColor: #15A06E
$passiveColor: #ddd
$transitionTime: 0.2s

.single-switcher
    &__wrapper
        display: flex
        align-items: center
        cursor: pointer
        width: fit-content
    &__dot
        display: flex
        justify-content: center
        align-items: center
        min-width: 16px
        width: 16px
        height: 16px
        border: 1px solid $passiveColor
        border-radius: 100%
        //cursor: pointer
    &__dot-inner
        width: 10px
        height: 10px
        background: $passiveColor
        border-radius: 100%
    &__dot,
    &__dot-inner
        transition: $transitionTime
    &__text
        font-weight: 400
        font-size: 13px
        line-height: 130%
        color: #151515

.active
    .single-switcher
        &__dot
            border: 1px solid $activeColor
        &__dot-inner
            background: $activeColor
</style>
