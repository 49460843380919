export default function (body) {
    Array.prototype.forEach.call(body.querySelectorAll(".ql-toolbar, .ql-tooltip, .ql-clipboard "), node => {
        node.parentNode.removeChild(node)
    })

    if (body.querySelector("tr > td")) {
        body.querySelector("tr > td").padding = 0
    }

    Array.prototype.forEach.call(body.querySelectorAll(".rows-container"), node => {
        node.parentNode.removeChild(node)
    })

    const step = el => {
        if (el.classList && el.classList.length && !el.classList.contains("ql-editor")) {
            el.classList.forEach(className => {
                switch (className) {
                    case "ql-align-center": {
                        el.style.textAlign = "center"
                        break
                    }
                    case "ql-align-left": {
                        el.style.textAlign = "left"
                        break
                    }
                    case "ql-align-right": {
                        el.style.textAlign = "right"
                        break
                    }
                    case "ql-align-justify": {
                        el.style.textAlign = "justify"
                        break
                    }
                }
                switch (className) {
                    case "ql-font-comic": {
                        el.style.fontFamily = "'comic sans ms', 'marker felt-thin', arial, sans-serif"
                        break
                    }
                    case "ql-font-arial": {
                        el.style.fontFamily = "arial, 'helvetica neue', helvetica, sans-serif"
                        break
                    }
                    case "ql-font-courier": {
                        el.style.fontFamily =
                            "'courier new', courier, 'lucida sans typewriter', 'lucida typewriter', monospace"
                        break
                    }
                    case "ql-font-georgia": {
                        el.style.fontFamily = "georgia, times, 'times new roman', serif"
                        break
                    }
                    case "ql-font-impact": {
                        el.style.fontFamily = "'impact', 'helvetica neue', helvetica, sans-serif"
                        break
                    }
                    case "ql-font-tahoma": {
                        el.style.fontFamily = "tahoma, verdana, segoe, sans-serif"
                        break
                    }
                    case "ql-font-times": {
                        el.style.fontFamily = "'times new roman',serif"
                        break
                    }
                    case "ql-font-trebuchet": {
                        el.style.fontFamily =
                            "'trebuchet ms', 'lucida grande', 'lucida sans unicode', 'lucida sans', tahoma, sans-serif"
                        break
                    }
                    case "ql-font-verdana": {
                        el.style.fontFamily = "verdana, geneva, sans-serif"
                        break
                    }
                }
                switch (className) {
                    case "ql-indent-1": {
                        el.style.paddingLeft = "3em"
                        break
                    }
                    case "ql-indent-2": {
                        el.style.paddingLeft = "6em"
                        break
                    }
                    case "ql-indent-3": {
                        el.style.paddingLeft = "9em"
                        break
                    }
                    case "ql-indent-4": {
                        el.style.paddingLeft = "12em"
                        break
                    }
                    case "ql-indent-5": {
                        el.style.paddingLeft = "15em"
                        break
                    }
                    case "ql-indent-6": {
                        el.style.paddingLeft = "18em"
                        break
                    }
                    case "ql-indent-7": {
                        el.style.paddingLeft = "21em"
                        break
                    }
                    case "ql-indent-8": {
                        el.style.paddingLeft = "24em"
                        break
                    }
                }
            })
        }

        if (el.classList && el.classList.contains("ql-editor")) {
            el.setAttribute("contenteditable", "false")
            el.style.fontSize = "14px"
        }

        if (el.tagName && el.tagName.toLowerCase() === "h1") {
            el.style.fontSize = "2em"
            el.style.margin = 0
        }
        if (el.tagName && el.tagName.toLowerCase() === "h2") {
            el.style.fontSize = "1.5em"
            el.style.margin = 0
        }
        if (el.tagName && el.tagName.toLowerCase() === "p") {
            el.style.margin = 0
        }

        if (el.childNodes.length) {
            el.childNodes.forEach(step)
        }
    }

    Array.prototype.forEach.call(body.querySelectorAll(".text-container"), text => {
        if (text.childNodes.length) {
            text.childNodes.forEach(step)
        }
    })

    return body.outerHTML.replaceAll("&quot;", "'").replaceAll("https://https://", "https://")
}
