<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#3E4755" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.0304 8.53033C17.3232 8.23744 17.3232 7.76256 17.0304 7.46967C16.7375 7.17678 16.2626 7.17678 15.9697 7.46967L17.0304 8.53033ZM7.96967 15.4697C7.67678 15.7626 7.67678 16.2375 7.96967 16.5304C8.26256 16.8232 8.73744 16.8232 9.03033 16.5304L7.96967 15.4697ZM9.03036 7.46967C8.73746 7.17678 8.26259 7.17678 7.9697 7.46967C7.6768 7.76256 7.6768 8.23744 7.9697 8.53033L9.03036 7.46967ZM15.9697 16.5304C16.2626 16.8232 16.7375 16.8232 17.0304 16.5304C17.3233 16.2375 17.3233 15.7626 17.0304 15.4697L15.9697 16.5304ZM15.9697 7.46967L7.96967 15.4697L9.03033 16.5304L17.0304 8.53033L15.9697 7.46967ZM7.9697 8.53033L15.9697 16.5304L17.0304 15.4697L9.03036 7.46967L7.9697 8.53033Z"
        />
    </svg>
</template>
<script>
export default {
    name: "CrossSmallIcon"
}
</script>
