
<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="#3E4755" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 10L9.99994 20M9.99997 10L20 20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>
<script>
export default {
    name: "BigCloseIcon"
}
</script>
