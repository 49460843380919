<template>
    <div v-if="file" class="image-new-view">
        <div class="image-new-view__wrapper" :class="{ 'flex-center': !(file.url || file.preview_path) }">
            <template v-if="file.url || file.preview_path">
                <img :src="file.url || file.preview_path" :alt="file.original_name" />
                <div class="image-new-view__content" @click="downloadWithChoosingPath">
                    <div class="image-new-view__name">
                        {{ file.original_name }}
                    </div>
                    <div
                        v-if="parent === 'expert' || parent === 'creator'"
                        class="image-new-view__delete"
                        @click.stop="deleteFile"
                    >
                        <DeleteIcon width="22" fill="#000" />
                    </div>
                    <div class="image-new-view__text">
                        {{ zoomTitle() }}
                    </div>
                </div>
            </template>
            <LoaderBlock v-else :green-color="true" />
        </div>
    </div>
</template>

<script>
import DeleteIcon from "@icons/DeleteIcon"
import LoaderBlock from "@components/Loaders/LoaderBlock"
import KeyTranslator from "@mixins/keyTranslator"

export default {
    name: "ImageNewView",
    components: { LoaderBlock, DeleteIcon },
    mixins: [KeyTranslator],
    props: {
        file: {
            type: Object,
            required: true
        },
        parent: {
            type: String,
            default: ""
        }
    },
    methods: {
        zoomTitle() {
            const locale = window.app_current_locale
            switch (locale) {
                case "ru":
                    return "Нажмите чтобы увеличить"
                case "en":
                    return "Click to zoom in"
                case "uk":
                    return "Натисніть, щоб збільшити"
                default:
                    return ""
            }
        },
        deleteFile() {
            this.$emit("file:delete", this.file)
        },
        downloadWithChoosingPath() {
            let endPoint = `/api/v1/files/${this.file.uuid}/download`

            const newWindow = window.open(endPoint, "_blank")
            newWindow.document.title = this.file.original_name
        }
    }
}
</script>

<style scoped lang="sass">
.image-new-view
    width: 100%
    margin-right: 0 !important
    $root: &
    &__wrapper
        width: 450px
        height: 250px
        position: relative
        @media screen and (max-width: 480px)
            width: 100%
    img
        width: 100%
        height: 100%
        object-fit: contain
    &__content
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        font-weight: 600
        font-size: 16px
        line-height: 130%
        color: #fff
        cursor: pointer
        opacity: 1
        transition: 0.4s
    &__name
        position: absolute
        top: 20px
        left: -100%
        opacity: 0
        transition: 0.4s
        cursor: default
        pointer-events: none
    &__delete
        position: absolute
        display: flex
        align-items: center
        justify-content: center
        top: 0px
        right: 0px
        width: 24px
        height: 24px
        opacity: 1
        border-radius: 4px
        transition: 0.4s
        background: #fff

        svg
            fill: #FF3939
        &:hover
           box-shadow: 0 2px 5px rgba(128, 158, 191, 0.25)
    &__text
        position: absolute
        top: 100%
        left: 50%
        transform: translate(-50%, -50%)
        font-weight: 700
        opacity: 0
        transition: 0.4s
        pointer-events: none
    /* &__wrapper:hover
        #{$root}__content
            opacity: 1
        #{$root}__name
            left: 20px
            opacity: 1
        #{$root}__delete
            right: 20px
            opacity: 1
        #{$root}__text
            top: 50%
            opacity: 1 */
</style>
