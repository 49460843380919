<template>
    <div class="tooltip-component">
        <label contenteditable="false"></label>
        <AlertWithQuestion>
            <template #modal-title>{{ title }}</template>
            <template #modal-description>{{ description }}</template>
        </AlertWithQuestion>
        <slot></slot>
    </div>
</template>

<script>
import AlertWithQuestion from "@components/Alerts/AlertWithQuestion.vue"
export default {
    components: {
        AlertWithQuestion
    },
    props: {
        title: {
            default: ""
        },
        description: {
            default: ""
        }
    },
    name: "TooltipComponent"
}
</script>

<style scoped lang="sass">
.tooltip-component
    display: flex
    align-items: center
    margin-top: 5px
    margin-bottom: 5px
    .alert-with-question
        margin-right: 8px
</style>
