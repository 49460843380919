import axios from "~axios"

export default class SectionRepository {
    static async create({ lesson, name }) {
        const { data } = await axios.post(`/lessons/${lesson}/info-section`, {
            name,
            info_units: []
        })

        return data
    }

    static async update({ section, payload }) {
        const { data } = await axios.put(`/info-section/${section}`, payload)

        return data
    }

    static async delete({ section }) {
        const { data } = await axios.delete(`/info-section/${section}`)

        return data
    }
}
