export default function (link, isLink = true) {
    let time = link
    // get query params
    if (isLink) {
        const queryStr = new URL(link).search
        const params = new URLSearchParams(queryStr)
        // check has seek to
        if (!params.has("start")) {
            return undefined
        }
        // get seek time
        time = params.get("start")
    }
    const timeArr = time.split(":")
    if (timeArr.length > 3) {
        return undefined
    }
    for (let i = timeArr.length; i < 3; i++) {
        timeArr.unshift("00")
    }
    // parse seconds
    return +timeArr[0] * 60 * 60 + +timeArr[1] * 60 + +timeArr[2]
}
