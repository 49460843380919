<template>
    <div class="word-from-chars">
        <default-title class="mb-20">
            {{ t("question_types.10") }}
        </default-title>

        <div v-if="!interactive">
            <p class="word-text-answer mb-20">
                <span
                    :class="{
                        colored: typeShow() !== 'simple_answer' && typeShow() !== 'only_correct_or_not_question',
                        red: !isCorrectChar(index),
                        blue: isCorrectChar(index)
                    }"
                    class="ch"
                    :key="index"
                    v-for="(ch, index) in isAnswered(this.answer).text_answer"
                >
                    <template v-if="ch === ' '"> &nbsp;</template>
                    <template v-else>
                        {{ ch }}
                    </template>
                </span>
            </p>

            <p v-if="typeShow() === 'full_information'" class="word-text-answer mb-20">
                <span class="ch colored green" :key="index" v-for="(ch, index) in this.answer.text.split('')">
                    <template v-if="ch === ' '"> &nbsp;</template>
                    <template v-else>
                        {{ ch }}
                    </template>
                </span>
            </p>
        </div>

        <div v-if="interactive">
            <p v-if="!question.text_answer" class="word-text-answer placeholder mb-20">
                <span :key="index" v-for="(ch, index) in noEmptyChars">
                    <template v-if="ch.char === ' '"> &nbsp;</template>
                    <template v-else>
                        {{ ch.char }}
                    </template>
                </span>
            </p>
            <p v-else class="word-text-answer mb-20">
                <span :key="index" v-for="(ch, index) in question.text_answer">
                    <template v-if="ch === ' '"> &nbsp;</template>
                    <template v-else>
                        {{ ch }}
                    </template>
                </span>
                <span @click="removeChar()" class="remove"> ← {{ t("quiz.remove_char") }} </span>
            </p>
            <transition-group name="list-complete" class="chars-flex" tag="div">
                <div
                    @dragover.prevent="entered = char"
                    @dragleave.prevent="entered = null"
                    @click="addChar(char)"
                    v-for="(char, index) in noEmptyChars"
                    class="shadow"
                    :class="{
                        colored:
                            !interactive &&
                            typeShow() !== 'simple_answer' &&
                            typeShow() !== 'only_correct_or_not_question',
                        red: !isCorrectChar(index),
                        hovered: entered && entered.id === char.id && char.id !== dragged
                    }"
                    :data-id="char.id"
                    :data-char="char.char"
                    :data-order="char.order"
                    :key="char.id"
                >
                    {{ char.char }}
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import PracticeMixin from "../_mixin"
import WordFromChars from "./mixins/_word-from-chars"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
export default {
    components: { DefaultTitle },
    mixins: [PracticeMixin, WordFromChars],
    name: "PracticeWordFromCharsMobile",
    data() {
        return {
            chars: [],
            noEmptyChars: [],
            entered: null,
            dragged: null
        }
    },
    created() {
        if (this.interactive) {
            let empty = new Array(this.answer.text.length).fill({ empty: true }).map(this.mapChar)
            const filled = new Array(this.answer.text.length).fill({}).map(this.mapChar)

            if (this.viewable) {
                const result = this.isAnswered(this.answer)

                if (result && result.text_answer) {
                    this.question.text_answer = result.text_answer

                    empty = new Array(result.text_answer.length).fill({}).map((_, index) => {
                        const char = result.text_answer[index]

                        const fill = filled.find(fill => fill.char === char)

                        if (fill) {
                            filled.splice(filled.indexOf(fill), 1)
                        }

                        return {
                            index,
                            order: index,
                            id: this.uuid(),
                            char
                        }
                    })
                }
            }

            this.chars = [...empty, ...filled]

            this.noEmptyChars = [...filled]

            this.$nextTick(() => {
                this.order()
                this.index()
            })
        } else {
            const result = this.isAnswered(this.answer)
            if (result && result.text_answer) {
                this.chars = new Array(result.text_answer.length).fill({}).map((_, index) => ({
                    index,
                    order: index,
                    id: this.uuid(),
                    char: result.text_answer[index]
                }))
            }
        }
    },
    computed: {
        answer() {
            return this.question.answers[0]
        }
    },
    methods: {
        removeChar() {
            const lastChar = this.question.text_answer[this.question.text_answer.length - 1]

            this.question.text_answer = this.question.text_answer.substring(0, this.question.text_answer.length - 1)

            this.$set(this.question, "is_allowed", this.question.text_answer.length)

            this.$emit("callout")

            this.noEmptyChars.push({
                index: this.noEmptyChars.length,
                order: this.noEmptyChars.length,
                id: this.uuid(),
                char: lastChar
            })

            this.$nextTick(() => {
                this.order()
                this.index()
                this.$emit("callout")
            })

            this.$forceUpdate()
        },
        addChar(ch) {
            if (!this.question.text_answer) {
                this.question.text_answer = ""
            }

            this.question.text_answer += ch.char
            this.noEmptyChars.splice(ch.index, 1)

            this.$set(this.question, "is_allowed", true)

            this.$nextTick(() => {
                this.order()
                this.index()
                this.$emit("callout")
            })
        }
    }
}
</script>

<style lang="sass" scoped>
.word-text-answer
    font-size: 16px
    font-weight: 500
    color: #3965FF
    display: flex
    align-items: center
    flex-wrap: wrap

    span
        text-transform: uppercase

    &.placeholder
        color: #B5C1D2

    .remove
        color: #DD4141
        font-size: 11px
        font-weight: 400
        line-height: 14px
        margin-left: 10px
        cursor: pointer
        margin-top: 5px
        margin-bottom: 5px
        text-transform: lowercase

.ch
    &.colored
        &.red
            color: #DD4141

        &.blue
            color: #3965FF

        &.green
            color: #15A06E

.chars-flex
    display: flex
    flex-wrap: wrap
    grid-gap: 10px 15px
</style>
