const state = {
    templateModules: [
        // *** НЕ МЕНЯТЬ ПОРЯДОК!!! ***
        {
            // url: "/build/img/previews/frame-280.jpg",
            url: "https://cdn1-admin.ojowo.com/assets/img/previews/frame-280.jpg",
            extension: "jpg",
            mime_type: "image/jpg"
        },
        {
            // url: "/build/img/previews/frame-290.jpg",
            url: "https://cdn1-admin.ojowo.com/assets/img/previews/frame-290.jpg",
            extension: "jpg",
            mime_type: "image/jpg"
        },
        {
            // url: "/build/img/previews/frame-295.jpg",
            url: "https://cdn1-admin.ojowo.com/assets/img/previews/frame-295.jpg",
            extension: "jpg",
            mime_type: "image/jpg"
        },
        {
            // url: "/build/img/previews/frame-296.jpg",
            url: "https://cdn1-admin.ojowo.com/assets/img/previews/frame-296.jpg",
            extension: "jpg",
            mime_type: "image/jpg"
        },
        {
            // url: "/build/img/previews/frame-297.jpg",
            url: "https://cdn1-admin.ojowo.com/assets/img/previews/frame-297.jpg",
            extension: "jpg",
            mime_type: "image/jpg"
        }
    ]
}

const getters = {
    getTemplateModules: state => {
        return state.templateModules
    }
}

const mutations = {}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
