import axios from "axios"

let localLangs = {}
const useCounter = () => undefined

export default {
    data() {
        return {
            counter: 0
        }
    },
    created() {
        if (!this.t || !this.$store) {
            this.fetchLocalLangs(this.localKeys)
        }
    },
    methods: {
        translate(key) {
            useCounter(this.counter)

            if (this.t && this.$store) {
                return this.t(key)
            }

            if (this.localTranslate) {
                return this.localTranslate(key)
            }

            return key
        },
        localTranslate(key) {
            let value, group, groupKey

            for (group in localLangs) {
                for (groupKey in localLangs[group]) {
                    if (groupKey === key) value = localLangs[group][groupKey]
                }
            }

            return value ? value : key
        },
        async fetchLocalLangs(group = "") {
            const saveLangs = data => {
                localLangs = data
                this.$nextTick(() => {
                    this.$forceUpdate()
                    this.counter++
                })
            }

            if (localLangs[group]) {
                saveLangs({
                    [group]: localLangs[group]
                })
                return
            }

            await axios
                .get(
                    (window.cached_routes && window.cached_routes["translation-groups"]) ||
                        "https://kwiga.com/api/v1/translation-groups?t=1715964660",
                    {
                        params: {
                            groups: group,
                            lang: window.app_current_locale || "en"
                        },
                        headers: { "X-Language": window.app_current_locale || "en" }
                    }
                )
                .then(async response => {
                    const data = response.data

                    saveLangs(data)
                })
        }
    }
}
