<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.1363 17.197C16.4292 17.4899 16.9041 17.4899 17.197 17.197C17.4899 16.9041 17.4899 16.4292 17.197 16.1363L16.1363 17.197ZM14.25 8.75C14.25 11.7876 11.7876 14.25 8.75 14.25V15.75C12.616 15.75 15.75 12.616 15.75 8.75H14.25ZM8.75 14.25C5.71243 14.25 3.25 11.7876 3.25 8.75H1.75C1.75 12.616 4.88401 15.75 8.75 15.75V14.25ZM3.25 8.75C3.25 5.71243 5.71243 3.25 8.75 3.25V1.75C4.88401 1.75 1.75 4.88401 1.75 8.75H3.25ZM8.75 3.25C11.7876 3.25 14.25 5.71243 14.25 8.75H15.75C15.75 4.88401 12.616 1.75 8.75 1.75V3.25ZM17.197 16.1363L13.7073 12.6467L12.6467 13.7073L16.1363 17.197L17.197 16.1363Z"
            fill="#3E4755"
        />
    </svg>
</template>

<script>
export default {
    name: "SearchIcon"
}
</script>

<style lang="scss" scoped>

</style>
