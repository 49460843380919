<template>
    <svg
        class="pracrice-timer-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.33333 1.75C7.91912 1.75 7.58333 2.08579 7.58333 2.5C7.58333 2.91421 7.91912 3.25 8.33333 3.25V1.75ZM11.6667 3.25C12.0809 3.25 12.4167 2.91421 12.4167 2.5C12.4167 2.08579 12.0809 1.75 11.6667 1.75V3.25ZM10 11.25H9.25C9.25 11.4489 9.32902 11.6397 9.46967 11.7803L10 11.25ZM10.75 8.33333C10.75 7.91912 10.4142 7.58333 10 7.58333C9.58579 7.58333 9.25 7.91912 9.25 8.33333H10.75ZM11.1363 13.447C11.4292 13.7399 11.9041 13.7399 12.197 13.447C12.4899 13.1541 12.4899 12.6792 12.197 12.3863L11.1363 13.447ZM15.5 11.25C15.5 14.2876 13.0376 16.75 10 16.75V18.25C13.866 18.25 17 15.116 17 11.25H15.5ZM10 16.75C6.96243 16.75 4.5 14.2876 4.5 11.25H3C3 15.116 6.13401 18.25 10 18.25V16.75ZM4.5 11.25C4.5 8.21243 6.96243 5.75 10 5.75V4.25C6.13401 4.25 3 7.38401 3 11.25H4.5ZM10 5.75C13.0376 5.75 15.5 8.21243 15.5 11.25H17C17 7.38401 13.866 4.25 10 4.25V5.75ZM8.33333 3.25H11.6667V1.75H8.33333V3.25ZM10.75 11.25V8.33333H9.25V11.25H10.75ZM9.46967 11.7803L11.1363 13.447L12.197 12.3863L10.5303 10.7197L9.46967 11.7803Z"
            fill="#DD4141"
        />
    </svg>
</template>

<script>
export default {
    name: "PracticeTimerIcon"
}
</script>


