<template>
    <button
        type="button"
        class="tooltip-close flex"
        :class="[
            {
                'tooltip-close_danger': dangerAccent,
                'tooltip-close_hover-danger': hoverDanger,
                'tooltip-close_danger-bold': dangerBold || dangerBoldHover,
                'tooltip-close_danger-bold-hover': dangerBoldHover
            },
            additionalClass
        ]"
        @click.prevent.stop="$emit('close')"
    >
        <close-icon :bold="dangerBold || dangerBoldHover" :noColor="true" :fill="color" />
    </button>
</template>

<script>
import CloseIcon from "../../icons/CloseIcon"

export default {
    name: "CloseButtonSm",
    components: {
        CloseIcon
    },
    props: {
        additionalClass: [String],
        color: {
            type: String,
            default: "#2B2B2B"
        },
        dangerAccent: {
            type: Boolean,
            default: false
        },
        dangerBold: {
            type: Boolean,
            default: false
        },
        dangerBoldHover: {
            type: Boolean,
            default: false
        },
        hoverDanger: {
            type: Boolean,
            default: false
        }
    }
}
</script>
