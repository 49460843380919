<template>
    <div @click.stop="$emit('click')" class="modal-overlay" :class="{ 'modal-overlay_black': black }">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        black: {
            type: Boolean,
            default: false
        }
    },
    name: "ModalOverlay"
}
</script>

<style scoped lang="sass">
.modal-overlay
    position: fixed
    backdrop-filter: blur(6px)
    top: 0
    bottom: 0
    right: 0
    left: 0
    z-index: 9999999999
    @supports not (backdrop-filter: blur(6px))
        background: rgba(40, 40, 40, 0.53)
    @supports (backdrop-filter: blur(6px))
        backdrop-filter: blur(6px)
    &_black
        backdrop-filter: none
        background: #32303066
</style>
