import { t } from "./index"

function createTitle(title) {
    return title.reverse().join(" - ")
}

export default function getPageTitle(to, langs, options = {}) {
    const path = to.path || to
    const isPartners = options.isPartners || false
    const routes = path.split("/").filter(route => route && route.length)
    const tKey = "app-meta.title"
    let title = Array()

    if (isPartners) {
        routes.unshift("partners")
    }
    const merchants = {
        1: "Fondy",
        2: "PayPal",
        3: "WayForPay"
    }
    const liveSessions = {
        1: "Zoom"
    }
    for (let i = 0; i < routes.length; i++) {
        if (routes.length === 1) {
            // set default dashboard route and return result
            title = [...title, t(`${tKey}.${routes[i]}`, langs) + " - " + t(`${tKey}.app`, langs)]
            break
        } else if (i === 0) {
            // change first string to app name and continue
            title = [...title, t(`${tKey}.app`, langs)]
        } else if (!isNaN(routes[i])) {
            // Delete numbers (ids) from route
            title = [...title]
        } else {
            // get title from route
            switch (routes[i]) {
                // delete all ids after route and return result
                // case "mailing":
                case "viewers":
                case "automation":
                case "storage":
                case "experts":
                case "transaction":
                case "domain":
                case "groups":
                    if (routes.length > i + 1) {
                        title = [...title, t(`${tKey}.${routes[i]}`, langs)]
                        return createTitle(title)
                    }
                    title = [...title, t(`${tKey}.${routes[i]}`, langs)]
                    break
                case "order":
                    if (to.name && to.name === "OfferInformation") {
                        if (to.params.orderId === "new") {
                            title = [...title, t(`${tKey}.new-order`, langs)]
                        } else {
                            title = [...title, t(`${tKey}.order`, langs).replace(":id", to.params.orderId)]
                        }

                        return createTitle(title)
                    }
                    title = [...title, t(`${tKey}.${routes[i]}`, langs)]
                    break
                // Show word 'list' on specific recipients list and return result
                case "recipients":
                    if (routes.length > i + 1) {
                        title = [...title, t(`${tKey}.${routes[i]}`, langs), t(`${tKey}.list`, langs)]
                        return createTitle(title)
                    }
                    title = [...title, t(`${tKey}.${routes[i]}`, langs)]
                    break

                // Show specific payment system depending on id and return result
                case "payment_systems":
                    if (routes.length > i + 1) {
                        title = [...title, t(`${tKey}.${routes[i]}`, langs), merchants[routes[i + 1]]]
                        return createTitle(title)
                    }
                    title = [...title, t(`${tKey}.${routes[i]}`, langs)]
                    break

                // Show specific live-session depending on id and return result
                case "live-sessions":
                    if (routes.length > i + 1) {
                        title = [...title, t(`${tKey}.${routes[i]}`, langs), liveSessions[routes[i + 1]]]
                        return createTitle(title)
                    }
                    title = [...title, t(`${tKey}.${routes[i]}`, langs)]
                    break
                case "referral":
                case "statistic":
                case "methods":
                    title = [...title]
                    break

                // Default title format without any restrictions
                default:
                    title = [...title, t(`${tKey}.${routes[i]}`, langs)]
                    break
            }
        }
    }

    if (to?.meta?.page_title) {
        title = [t(to?.meta?.page_title, langs) + " - " + t(`${tKey}.app`, langs)]
        return createTitle(title)
    }

    return createTitle(title)
}
