
<template>
    <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.33398 1V0C0.7817 0 0.333984 0.447715 0.333984 1L1.33398 1ZM24.6673 31V32C25.2196 32 25.6673 31.5523 25.6673 31H24.6673ZM1.33398 31H0.333984C0.333984 31.5523 0.7817 32 1.33398 32L1.33398 31ZM16.334 1L17.0411 0.292893C16.8536 0.105357 16.5992 0 16.334 0V1ZM24.6673 9.33333H25.6673C25.6673 9.06812 25.562 8.81376 25.3744 8.62623L24.6673 9.33333ZM6.33398 23.3333C5.7817 23.3333 5.33398 23.781 5.33398 24.3333C5.33398 24.8856 5.7817 25.3333 6.33398 25.3333V23.3333ZM19.6673 25.3333C20.2196 25.3333 20.6673 24.8856 20.6673 24.3333C20.6673 23.781 20.2196 23.3333 19.6673 23.3333V25.3333ZM6.33398 16.6667C5.7817 16.6667 5.33398 17.1144 5.33398 17.6667C5.33398 18.219 5.7817 18.6667 6.33398 18.6667V16.6667ZM16.334 18.6667C16.8863 18.6667 17.334 18.219 17.334 17.6667C17.334 17.1144 16.8863 16.6667 16.334 16.6667V18.6667ZM24.6673 30H1.33398V32H24.6673V30ZM2.33398 31V1H0.333984V31H2.33398ZM1.33398 2H16.334V0H1.33398V2ZM23.6673 9.33333V31H25.6673V9.33333H23.6673ZM15.6269 1.70711L23.9602 10.0404L25.3744 8.62623L17.0411 0.292893L15.6269 1.70711ZM13.6673 1V7.66667H15.6673V1H13.6673ZM18.0007 12H24.6673V10H18.0007V12ZM13.6673 7.66667C13.6673 10.0599 15.6074 12 18.0007 12V10C16.712 10 15.6673 8.95533 15.6673 7.66667H13.6673ZM6.33398 25.3333H19.6673V23.3333H6.33398V25.3333ZM6.33398 18.6667H16.334V16.6667H6.33398V18.6667Z"
            fill="#B5C1D2"
        />
    </svg>
</template>
<script>
export default {
    name: "DocFileIcon"
}
</script>