import axios from "~axios"
import { EventBus } from "~events"

export function getPersonalSettings(key) {
    if (window.user?.user_personal_settings) {
        return window.user.user_personal_settings[key]
    }
    return null
}

export function updatePersonalSettings(key, value) {
    if (!window.user) {
        return new Promise(resolve => resolve())
    }

    let settings = []

    if (typeof key === "string") {
        settings.push({
            key: key,
            value: value
        })
    } else if (Array.isArray(key)) {
        settings = key
    } else if (typeof key === "object") {
        settings.push(key)
    }

    const method = "post"
    const url = "/user-personal-settings"

    return new Promise(resolve => {
        axios({ method, url, data: { settings: settings } }).then(result => {
            window.user.user_personal_settings = result.data.data

            for (let setting of settings) {
                EventBus.$emit(`update-settings:${setting.key}`)
            }

            resolve()
        })
    })
}
