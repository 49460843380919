const intervals = {}

const TimerMixin = {
    data() {
        return {
            didUpdate: false
        }
    },
    methods: {
        addQuestionInterval(question) {
            let seconds = question["left_time_in_seconds"]

            if (intervals[question.id]) {
                clearInterval(intervals[question.id])
            }

            intervals[question.id] = setInterval(() => {
                seconds -= 1

                if (seconds >= 0) {
                    question["left_time_in_seconds"] = seconds

                    this.didUpdate = true
                    this.$nextTick(() => {
                        this.didUpdate = false
                    })
                } else {
                    clearInterval(intervals[question.id])
                    delete intervals[question.id]
                }
            }, 1000)
        },
        isTimeLeft(val) {
            if (val === undefined || val === null) {
                return false
            }
            return val <= 0
        },
        isTimeNotLeft(val) {
            if (val === undefined || val === null) {
                return false
            }
            return val > 0
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, "0")
        },
        convertTimeToMs(t) {
            const leftTimeArray = t.split(":")

            const time = {
                h: leftTimeArray[0],
                m: leftTimeArray[1],
                s: leftTimeArray[2]
            }

            return parseInt(time.h) * 3600000 + parseInt(time.m) * 60000 + parseInt(time.s) * 1000
        },
        convertMsToHM(milliseconds) {
            let seconds = Math.floor(milliseconds / 1000)
            let minutes = Math.floor(seconds / 60)
            let hours = Math.floor(minutes / 60)

            seconds = seconds % 60
            minutes = minutes % 60
            return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`
        }
    }
}

export default TimerMixin
