<template>
    <div
        :style="{ justifyContent: justify, alignItems: align, flexDirection: direction }"
        :class="[inline ? 'inline-flex' : 'flex-container', additionalClass]"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        justify: {
            type: String,
            default: "flex-start"
        },
        align: {
            type: String,
            default: "flex-start"
        },
        direction: {
            type: String,
            default: "row"
        },
        additionalClass: [String, Array, Object],
        inline: {
            type: Boolean,
            default: false
        }
    },
    name: "FlexContainer"
}
</script>

<style scoped lang="sass">
.flex-container
    display: flex
</style>
