
<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#151515" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.25 5C8.25 4.58579 7.91421 4.25 7.5 4.25C7.08579 4.25 6.75 4.58579 6.75 5H8.25ZM6.75 15C6.75 15.4142 7.08579 15.75 7.5 15.75C7.91421 15.75 8.25 15.4142 8.25 15H6.75ZM13.25 5C13.25 4.58579 12.9142 4.25 12.5 4.25C12.0858 4.25 11.75 4.58579 11.75 5H13.25ZM11.75 15C11.75 15.4142 12.0858 15.75 12.5 15.75C12.9142 15.75 13.25 15.4142 13.25 15H11.75ZM6.75 5V15H8.25V5H6.75ZM11.75 5V15H13.25V5H11.75Z"
        />
    </svg>
</template>
<script>
export default {
    name: "PauseIcon"
}
</script>
