import { v4 } from "uuid"

export const mapChar = ({ empty }, index) => ({
    index,
    order: index,
    id: this.uuid(),
    char: empty ? "" : this.answer.text[index]
})

export default {
    methods: {
        mapChar({ empty }, index) {
            return {
                index,
                order: index,
                id: this.uuid(),
                char: empty ? "" : this.answer.text[index]
            }
        },
        isCorrectChar(index) {
            const result = this.isAnswered(this.answer)
            if (result) {
                return !result?.additional?.words_diff[index]
            }
            return true
        },
        uuid() {
            return v4()
        },
        index() {
            this.chars = this.chars.map((_, index) => ({ ..._, index }))
            this.noEmptyChars = this.noEmptyChars.map((_, index) => ({ ..._, index }))
        },
        order() {
            this.chars = this.chars.map((_, index) => ({ ..._, order: index }))
            this.noEmptyChars = this.noEmptyChars.map((_, index) => ({ ..._, order: index }))
        }
    }
}
