
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="#3E4755">
        <path
            d="M6.36431 7.80317C6.07142 7.51027 5.59655 7.51027 5.30365 7.80317C5.01076 8.09606 5.01076 8.57093 5.30365 8.86383L6.36431 7.80317ZM10.0007 12.5002L9.47032 13.0305C9.76321 13.3234 10.2381 13.3234 10.531 13.0305L10.0007 12.5002ZM14.6976 8.86383C14.9905 8.57093 14.9905 8.09606 14.6976 7.80317C14.4048 7.51027 13.9299 7.51027 13.637 7.80317L14.6976 8.86383ZM5.30365 8.86383L9.47032 13.0305L10.531 11.9698L6.36431 7.80317L5.30365 8.86383ZM10.531 13.0305L14.6976 8.86383L13.637 7.80317L9.47032 11.9698L10.531 13.0305Z"
        />
    </svg>
</template>
<script>
export default {
    name: "ArrowDownIcon"
}
</script>
<style lang="sass" scoped>
svg
    transition: .2s
</style>
