<template>
    <practice-red-alert v-if="quiz.attempt && quiz.attempt.left_time_in_seconds" class="mb-10 timer">
        {{ t("course.practice.quiz_left_time") }}:
        <PracticeTimer :attempt="quiz.attempt" />
    </practice-red-alert>
</template>

<script>
import PracticeTimer from "@components/Practice/common/timer/PracticeTimer.vue"
import PracticeRedAlert from "@components/Practice/common/alerts/PracticeRedAlert.vue"

export default {
    props: {
        quiz: {
            type: Object,
            default: () => {}
        }
    },
    name: "PracticeQuizTimer",
    components: { PracticeRedAlert, PracticeTimer }
}
</script>

<style scoped lang="sass">

</style>
