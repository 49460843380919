import axios from "~axios"

const base = ({ unit_id, lesson_id }) => (unit_id ? `/info-units/${unit_id}` : `/lessons/${lesson_id}`)

export default class PracticeRepository {
    static async quizzes({ lesson_id, unit_id }) {
        const response = await axios.get(`${base({ lesson_id, unit_id })}/quizzes`)
        return response.data
    }

    static async quiz({ quiz_id, lesson_id, unit_id }) {
        const response = await axios.get(`${base({ lesson_id, unit_id })}/quizzes/${quiz_id}`)
        return response.data
    }

    static async lesson({ lesson_id }) {
        const response = await axios.get(`/public/lessons/${lesson_id}`)
        return response.data
    }
    static async questions({ lesson_id, quiz_id, attempt_id, unit_id }) {
        let query = ""

        if (attempt_id) {
            query += `attempt_id=${attempt_id}`
        }

        const response = await axios.get(`${base({ lesson_id, unit_id })}/quizzes/${quiz_id}/quiz-questions?${query}`)
        return response.data
    }
    static async attempt({ lesson_id, quiz_id, unit_id }) {
        const response = await axios.get(`${base({ lesson_id, unit_id })}/quizzes/${quiz_id}/start-attempt`)
        return response.data
    }

    static async clear({ attempt_id }) {
        const response = await axios.post(`/quiz-attempts/${attempt_id}/clear`)
        return response.data
    }

    static async finish({ attempt_id, payload }) {
        const response = await axios.post(`/quiz-attempts/${attempt_id}/finish`, {
            payload
        })
        return response.data
    }

    static async answers({ lesson_id, attempt_id, payload, unit_id }) {
        const response = await axios.post(`${base({ lesson_id, unit_id })}/quiz-attempts/${attempt_id}`, payload)
        return response.data
    }
}
