<template>
    <div class="word-from-chars">
        <div ref="container" class="chars-container custom-scroll relative">
            <draggable v-model="chars" :move="handleMove" @end="handleDragEnd" @start="handleDragStart">
                <transition-group
                    tag="div"
                    :style="{
                        gridTemplateColumns: `repeat(${
                            this.interactive ? this.chars.length / 2 : this.chars.length
                        }, 50px)`
                    }"
                    class="grid-char"
                    name="grid-char"
                >
                    <div
                        :class="{
                            spacer: char.char && char.char === ' ',
                            colored:
                                !interactive &&
                                typeShow() !== 'simple_answer' &&
                                typeShow() !== 'only_correct_or_not_question',
                            red: !isCorrectChar(index),
                            hovered: entered && entered.id === char.id && char.id !== dragged
                        }"
                        :data-id="char.id"
                        :data-char="char.char"
                        :data-order="char.order"
                        @dragover.prevent="entered = char"
                        @dragleave.prevent="entered = null"
                        @click="handleChar(char)"
                        class="shadow"
                        :key="char.id"
                        v-for="(char, index) in chars"
                    >
                        {{ char.char === " " ? t("quiz.space") : char.char }}
                    </div>
                </transition-group>
            </draggable>
            <div
                :style="{
                    gridTemplateColumns: `repeat(${
                        interactive ? chars.length / 2 : chars.length || answer.text.length
                    }, 50px)`
                }"
                class="grid-char mt-30"
                v-if="!interactive && typeShow() === 'full_information'"
            >
                <div
                    class="shadow colored"
                    :class="{ green: chars.length, spacer: char && char === ' ' }"
                    :key="uuid() + char"
                    :data-index="index"
                    v-for="(char, index) in answer.text.split('')"
                >
                    {{ char === " " ? t("quiz.space") : char }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PracticeMixin from "../_mixin"
import WordFromChars from "./mixins/_word-from-chars"
import draggable from "vuedraggable"

export default {
    components: {
        draggable
    },
    mixins: [PracticeMixin, WordFromChars],
    name: "PracticeWordFromCharsDesktop",
    data() {
        return {
            chars: [],
            noEmptyChars: [],
            entered: null,
            dragged: null
        }
    },
    created() {
        if (this.interactive) {
            let empty = new Array(this.answer.text.length).fill({ empty: true }).map(this.mapChar)
            const filled = new Array(this.answer.text.length).fill({}).map(this.mapChar)

            if (this.viewable) {
                const result = this.isAnswered(this.answer)

                if (result && result.text_answer) {
                    empty = new Array(this.answer.text.length).fill({ empty: true }).map((_, index) => {
                        const char = result.text_answer[index]

                        const fill = filled.find(fill => fill.char === char)

                        if (fill) {
                            filled.splice(filled.indexOf(fill), 1, {
                                index,
                                order: index,
                                id: this.uuid(),
                                char: "",
                                empty: true
                            })
                        }

                        return {
                            index,
                            order: index,
                            id: this.uuid(),
                            char
                        }
                    })
                }
            }

            this.chars = [...empty, ...filled]

            this.noEmptyChars = [...filled]

            this.$nextTick(() => {
                this.order()
                this.index()
            })
        } else {
            const result = this.isAnswered(this.answer)
            if (result) {
                this.chars = new Array(this.answer.text.length).fill({}).map((_, index) => ({
                    index,
                    order: index,
                    id: this.uuid(),
                    char: (result.text_answer && result.text_answer[index]) || ""
                }))
            } else {
                this.chars = new Array(this.answer.text.length).fill({}).map((_, index) => ({
                    index,
                    order: index,
                    id: this.uuid(),
                    char: ""
                }))
            }
        }
    },
    computed: {
        answer() {
            return this.question.answers[0]
        }
    },
    methods: {
        handleChar(ch) {
            const char = this.chars.find(char => char.id === ch.id)

            const isFilled = this.chars.indexOf(char) < this.chars.length / 2

            if (ch.char) {
                const empty = this.chars.find(
                    c => !c.char && (!isFilled ? c.index < this.chars.length / 2 : c.index >= this.chars.length / 2)
                )

                if (empty) {
                    const index = this.chars.indexOf(empty)
                    const _items = Object.assign([], this.chars)
                    _items[index] = char
                    _items[char.index] = empty
                    this.chars = _items

                    this.order()
                    this.index()
                    this.$forceUpdate()
                }
            }

            this.$nextTick(() => {
                this.$emit("callout")
            })
        },
        handleDragStart(item) {
            this.dragged = item.clone.dataset.id
        },
        handleDragEnd() {
            this.futureItem = this.chars[this.futureIndex]
            this.movingItem = this.chars[this.movingIndex]
            const _items = Object.assign([], this.chars)
            _items[this.futureIndex] = this.movingItem
            _items[this.movingIndex] = this.futureItem

            this.chars = _items
            this.dragged = null
            this.entered = null
            this.index()
            this.$emit("callout")
        },
        handleMove(e) {
            const { index, futureIndex } = e.draggedContext
            this.movingIndex = index
            this.futureIndex = futureIndex
            return false
        }
    },
    watch: {
        chars: {
            deep: true,
            handler() {
                if (!this.interactive) {
                    return
                }
                this.$nextTick(() => {
                    this.question.text_answer = ""
                    const chars = this.chars.slice(0, this.chars.length / 2)

                    for (let i = 0; i < chars.length; i++) {
                        this.question.text_answer += chars[i].char || ""
                    }

                    this.$set(this.question, "is_allowed", this.question.text_answer.split("").length)
                })
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.grid-char
    display: grid
    grid-template-columns: repeat(3, 50px)
    grid-gap: 20px 10px

.grid-char-move
    transition: all 0.3s


.chars-container
    padding: 10px 10px 35px
    max-width: calc(100vw - 660px)
    overflow: auto
    @media (max-width: 1640px)
        max-width: calc(100vw - 640px)
    @media (max-width: 1300px)
        max-width: calc(100vw - 530px)
    @media (max-width: 1200px)
        max-width: calc(100vw - 430px)
    @media (max-width: 960px)
        max-width: calc(100vw - 100px)
</style>
