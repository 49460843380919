<template>
    <div class="conformity-container">
        <div class="statements">
            <div :key="index" v-for="(statement, index) in conformityStatements">
                <div style="width: 100%" class="relative statement-drop-container statement shadow">
                    <img v-if="statement.image" :src="statement.image.url" alt="" />
                    <flex-container class="mb-20">
                        <template>
                            <AttemptResultStudentWidget
                                class="mr-10"
                                :attempt-id="attempt && attempt.id"
                                v-if="
                                    getCommentable(statement) && getCommentable(statement).has_comments && !expertView
                                "
                                :commentable-id="getCommentable(statement).id"
                                :comments-count="getCommentable(statement).comments_count"
                                :commentable-type="'statement_id'"
                            />
                            <AttemptResultExpertWidget
                                class="mr-10"
                                :statement="statement"
                                v-bind="widget(statement)"
                                v-else-if="expertView"
                            />
                        </template>
                        <default-description
                            :size="16"
                            :weight="700"
                            :line-height="21"
                            text-color="#151515"
                            style="min-height: 24px"
                        >
                            <PracticeQuestionDescription :description="statement.text" />
                        </default-description>
                    </flex-container>

                    <div
                        class="shadow answer"
                        :class="getAnswerModifier(answer)"
                        :key="answer.id"
                        v-for="answer in statement.answers"
                    >
                        <!-- <img v-if="answer.image" :src="answer.image.url || answer.image" alt="" />-->
                        <PracticeQuestionDescription :description="answer.text" />
                        <CloseButtonSm
                            v-if="interactive"
                            @click.native.stop
                            hover-danger
                            @close="handleAnswerClick(answer, statement)"
                        />
                    </div>

                    <div class="relative">
                        <button
                            v-if="interactive"
                            @click="handleStatementClick(statement)"
                            :class="{ disabled: readonly || !conformityAnswers.length }"
                            class="answers-container"
                        >
                            <svg
                                :class="{ 'rotate-180': statement.is_open }"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.83337 8.3335L10 12.5002L14.1667 8.3335"
                                    stroke="#3E4755"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>

                        <transition name="fade-up">
                            <div class="statements-modal top-0" v-if="statement.is_open">
                                <flex-container class="mt-5 px-5" justify="space-between">
                                    <default-title text-color="#151515" :size="14" :weight="700" :line-height="18">
                                        {{ t("quiz.answers") }}
                                    </default-title>
                                    <CloseButtonSm @close="handleStatementClick(statement)" />
                                </flex-container>
                                <vue-simplebar class="mt-10 conformity-scroll">
                                    <button
                                        :key="answer.id"
                                        v-for="answer in conformityAnswers"
                                        @click="handleAnswerClick(answer, statement, true)"
                                        class="statements-modal-item mr-10 block full-width cursor-pointer"
                                    >
                                        <img
                                            style="max-width: 100%"
                                            v-if="answer.image"
                                            :src="answer.image.url || answer.image"
                                            alt=""
                                        />
                                        <PracticeQuestionDescription :description="answer.text" />
                                    </button>
                                </vue-simplebar>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="!interactive && typeShow === 'full_information' && !expertView">
            <p class="practice-question-title mt-30 mb-15">
                {{ t("quiz.correct_answer") }}
            </p>
            <div class="statements">
                <div :key="index" v-for="(statement, index) in statements">
                    <div style="width: 100%" class="relative statement-drop-container statement shadow">
                        <img v-if="statement.image" :src="statement.image.url" alt="" />
                        <flex-container class="mt-15">
                            <template>
                                <AttemptResultStudentWidget
                                    class="mr-10"
                                    :attempt-id="attempt && attempt.id"
                                    v-if="
                                        getCommentable(statement) &&
                                        getCommentable(statement).has_comments &&
                                        !expertView
                                    "
                                    :commentable-id="getCommentable(statement).id"
                                    :comments-count="getCommentable(statement).comments_count"
                                    :commentable-type="'statement_id'"
                                />
                                <AttemptResultExpertWidget
                                    class="mr-10"
                                    :statement="statement"
                                    v-bind="widget(statement)"
                                    v-else-if="expertView"
                                />
                            </template>
                            <default-description
                                :size="16"
                                :weight="700"
                                :line-height="21"
                                text-color="#151515"
                                style="min-height: 24px"
                            >
                                <PracticeQuestionDescription :description="statement.text" />
                            </default-description>
                        </flex-container>

                        <div class="relative mt-20">
                            <div
                                class="shadow answer green"
                                :key="answer.id"
                                v-for="answer in getOnlyCorrectAnswers(statement)"
                            >
                                <img v-if="answer.image" :src="answer.image.url || answer.image" alt="" />
                                <PracticeQuestionDescription :description="answer.text" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <div v-if="!interactive && typeShow === 'only_correct_or_not'">
            <PracticeCorrectStatusAlert
                :is-correct="isCorrect"
                :is-incorrect="isIncorrect"
                :is-skipped="isSkipped"
                :is-not-started="isNotStarted"
            />
        </div>
    </div>
</template>

<script>
import CloseButtonSm from "@components/Buttons/CloseButtonSm.vue"
import PracticeConformityManyToOne from "@components/Practice/PracticeQuestions/components/PracticeConformityManyToOne.vue"
import PracticeQuestionDescription from "@components/Practice/PracticeQuestions/components/PracticeQuestionDescription.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"

export default {
    name: "PracticeConformityManyToOneCombined",
    components: { DefaultTitle, PracticeQuestionDescription, CloseButtonSm },
    extends: PracticeConformityManyToOne
}
</script>

<style lang="sass" scoped>
.conformity-scroll
    max-height: 190px
.answers
    margin-top: 10px
    display: flex
    flex-wrap: wrap
    grid-gap: 15px 15px

.statements
    margin-top: 15px
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 20px 20px
    @media (max-width: 640px)
        display: block
        .statement
            margin-bottom: 15px
            padding: 10px

.answer
    min-height: 37px
    padding: 0px 15px
    width: 100%
    margin-bottom: 10px
    font-size: 13px
    font-weight: 400
    color: #52565C
    border: 1px solid transparent
    cursor: default
    display: flex
    align-items: center
    justify-content: space-between
    box-shadow: 0px 2px 5px 0px #809EBF40 !important
    img
        max-height: 250px

.shadow
    box-shadow: 0 0 15px rgba(128, 158, 191, 0.15)
    border-radius: 7px
    font-size: 13px
    cursor: pointer
    line-height: 17px
    color: #151515
    border: 1px solid transparent
    background: #fff

    &.red
        background: #FFF6F6
        border-color: #DD4141

    &.green
        background: #F9FEFC
        border-color: #15A06E

.statement
    border-radius: 7px
    padding: 20px 10px
    cursor: default

    img
        height: auto
        max-width: 300px
        display: block
        margin: 0 auto 10px

.answers-container
    min-height: 30px
    background: #FBFCFF
    width: 100%
    border: 1px dashed #98A2B0 !important
    border-radius: 7px
    position: relative
    display: flex
    justify-content: center
    align-items: center
    transition: .2s
    path
        transition: .2s
    &:hover
        border-color: #3965FF !important
        path
            stroke: #3965FF !important

.empty
    position: absolute
    font-weight: 500
    color: #B5C1D2
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    pointer-events: none
    z-index: 5
    text-align: center
</style>
