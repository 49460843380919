<template>
    <div v-if="tasks.length" class="file-tasks">
        <button class="full-width" @click="toggle">
            <flex-container align="center">
                <default-title color="#fff" large :font-size="14">
                    {{ t("expert.uploading_files") }}
                </default-title>
                <span v-if="hasProgressTasks && !isOpen" class="ml-10">
                    <SpinLoader :width="20" />
                </span>

                <ArrowDownIcon fill="#fff" v-else class="ml-5" :class="{ 'rotate-180': isOpen }" />
            </flex-container>
        </button>
        <custom-collapse :is-open="isOpen">
            <div class="custom-scroll mt-10">
                <div class="file-task" :key="index" v-for="(task, index) in tasks">
                    <flex-container align="center">
                        <SpinLoader color="#15A06E" v-if="task.status === 'checking'" :width="22" />

                        <CrossSmallIcon
                            fill="#DD4141"
                            v-else-if="task.status === 'aborted' || task.status === 'failed'"
                            class="mr-5 mt-3"
                            width="22"
                        />

                        <CloseButtonSm
                            @close="task.abort()"
                            v-else-if="task.status === 'progress'"
                            :title="t('expert.cancel')"
                            class="mr-5"
                            danger-bold-hover
                        />
                        <CheckIcon
                            v-else-if="task.status === 'completed'"
                            fill="#15A06E"
                            width="22"
                            height="22"
                            class="mr-5"
                        />

                        <flex-container
                            style="flex: 1; max-width: calc(100% - 29px)"
                            :title="task.fileName"
                            justify="space-between"
                        >
                            <default-title class="task-title" small :font-size="12"> {{ task.fileName }} </default-title
                            ><default-title v-if="task.status === 'progress'" class="ml-2" small :font-size="12"
                                ><b>{{ task.progress }}%</b></default-title
                            >
                        </flex-container>
                    </flex-container>

                    <div class="pl-27">
                        <FlatLoader v-if="task.status === 'progress'" class="mt-5" :percent="task.progress" />
                        <default-title v-else-if="task.status === 'checking'" class="task-title" small :font-size="10">
                            {{ t("expert.file_checking") }}
                        </default-title>
                        <default-title v-else-if="task.status === 'aborted'" class="task-title" small :font-size="10">
                            {{ t("expert.file_aborted") }}
                        </default-title>
                        <default-title v-else-if="task.status === 'failed'" class="task-title" small :font-size="10">
                            {{ task.message || t("expert.file_error_expected") }}
                        </default-title>
                    </div>
                </div>
            </div>
            <!--  <div class="pl-27">
                <button class="mt-15 clear-button">
                    <flex-container align="center">
                        <default-title class="task-title" small :font-size="11">
                            {{ t("expert.clear_list") }}
                        </default-title>
                        <DeleteIcon class="ml-5" width="16" fill="#000" />
                    </flex-container>
                </button>
            </div>-->
        </custom-collapse>
    </div>
</template>

<script>
import CloseButtonSm from "@components/Buttons/CloseButtonSm.vue"
import CustomCollapse from "@components/Collapse/CustomCollapse.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import { FileTask } from "@components/Files/FileTask"
import FlatLoader from "@components/Loaders/FlatLoader.vue"
import SpinLoader from "@components/Loaders/SpinLoader.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import ArrowDownIcon from "@icons/ArrowDownIcon.vue"
import CheckIcon from "@icons/CheckIcon.vue"
import CrossSmallIcon from "@icons/CrossSmallIcon.vue"
import IsOpen from "@mixins/is-open"

import { set } from "idb-keyval"
import { v4 } from "uuid"

export let createFileTask = function (files) {
    return this.handleFiles(files)
}

export default {
    name: "FileTasks",
    components: {
        CustomCollapse,
        ArrowDownIcon,
        CrossSmallIcon,
        SpinLoader,
        CheckIcon,
        CloseButtonSm,
        FlexContainer,
        FlatLoader,
        DefaultTitle
    },
    mixins: [IsOpen],
    data() {
        return {
            tasks: [],
            title: ""
        }
    },
    async created() {
        this.title = document.title
        createFileTask = createFileTask.bind(this)

        this.isOpen = true

        await set("file_storage_url", window.file_storage_url)
        await set("file_model", "comment")
        await set("user_id", window.user_id)
        await set("user_id_hash", window.id_hash)
        await set("cabinet_id", window.current_cabinet_id)
        await set("jwt", window.token)
    },
    methods: {
        clearTasks() {
            this.tasks = []
        },
        createTask(file) {
            const task = new FileTask(file)
            this.tasks = [task, ...this.tasks]

            this.$forceUpdate()

            return new Promise((resolve, reject) => {
                task.onComplete = resolve
                task.onError = reject
            })
        },
        handleFiles(files) {
            const taskId = v4()

            return Promise.allSettled(
                Array.from(files)
                    .map(f => {
                        f.taskId = taskId
                        return f
                    })
                    .map(this.createTask)
            )
        }
    },
    computed: {
        hasProgressTasks() {
            return this.tasks.find(task => task.status === "checking" || task.status === "progress")
        }
    }
}
</script>

<style lang="sass" scoped>
.clear-button
    background-color: #fff
    border-radius: 5px
    .default-title
        color: #000 !important
.custom-scroll::-webkit-scrollbar-track
    background-color: #505050
    width: 8px
    height: 8px

.custom-scroll::-webkit-scrollbar-thumb
    -webkit-border-radius: 0
    border-radius: 0
    background-color: rgba(141, 141, 141, 0.7)
.spin-loader
    margin: 0
    margin-right: 5px
.tooltip-close
    margin-left: 0
    width: 20px
    height: 20px
.file-task
    max-width: 270px
    overflow: hidden
    &:not(&:last-child)
        margin-bottom: 18px
.task-title
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
.file-tasks
    position: fixed
    bottom: 0
    width: 300px
    right: 0
    z-index: 999999999999999999
    background: #505050
    box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
    border-radius: 10px 0 0 0
    padding: 15px 10px
    padding-right: 5px
.default-title
    color: #fff !important
.custom-scroll
    max-height: 200px
    overflow: auto
    padding-right: 10px
</style>
