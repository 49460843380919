<template>
    <button
        :id="id"
        @click="$emit('click', $event)"
        class="floating-button"
        :class="[{ 'floating-button_shadow': needBoxShadow }, hoverClass]"
        :style="buttonStyle"
    >
        <slot></slot>
        <component v-if="buttonInlineStyle" is="style">
            {{ buttonInlineStyle }}
        </component>
    </button>
</template>

<script>
const props = {
    /**
     * @values 45, 33
     * */
    size: {
        type: Number,
        default: 25,
        required: false
    },
    /**
     * @values 45, 33
     * */
    width: {
        type: Number,
        default: 0,
        required: false
    },
    /**
     * @values 45, 33
     * */
    radius: {
        type: Number,
        default: -1,
        required: false
    },
    /**
     * @values "#fff", "transparent"
     * */
    background: {
        type: String,
        default: "transparent",
        required: false
    },

    /**
     * @values "#fff", "transparent"
     * */
    backgroundHover: {
        type: String,
        default: "",
        required: false
    },
    /**
     * @values "#fff", "transparent"
     * */
    needBoxShadow: {
        type: Boolean,
        default: false,
        required: false
    },
    needsBorderHover: {
        type: Boolean,
        default: false
    },
    /**
     * @values "blue, orange, red"
     * */
    borderHoverColor: {
        type: String,
        default: ""
    }
}

export default {
    name: "FloatingButton",
    props,
    data() {
        return {
            id: 0
        }
    },
    created() {
        this.id = `button-${this._uid}`
    },
    computed: {
        buttonInlineStyle() {
            let style = ``

            if (this.backgroundHover) {
                style += `
                    #${this.id}:hover {
                        background: ${this.backgroundHover} !important;
                    }
                `
            }

            return style
        },
        buttonStyle() {
            return {
                width: `${this.width || this.size}px`,
                minWidth: `${this.size}px`,
                height: `${this.size}px`,
                background: this.background,
                borderRadius: this.radius !== -1 ? `${this.radius}px` : "50%"
            }
        },
        hoverClass() {
            return this.borderHoverColor ? `floating-button_hover-${this.borderHoverColor}` : ""
        }
    }
}
</script>

<style scoped lang="sass">
@import 'resources/sass/vars'

.floating-button
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    transition: $transitionTime
    &_shadow
        box-shadow: 0px 2px 5px 0px #809EBF40
    &_hover-blue,
    &_hover-orange,
    &_hover-red
        transition: $transitionTime
        border: 1px solid transparent
    &_hover-blue
        &::v-deep
            .plus-icon
                stroke: #3965FF
            &:hover
                .plus-icon
                    stroke: #3965FF
        &:hover
            border-color: $accent-color5
    &_hover-blue-fill
        &:hover
            svg
                fill: #3965FF
    &_hover-blue-background
        &:hover
            background-color: #2E54DC !important
    &_hover-orange
        &:hover
            border-color: $accent-color
    &_hover-red
        &:hover
            border-color: $danger-color
    &_hover-red
        &:hover
            border-color: $danger-color
    &_hover-green
        background-color: #F5FFF2
        &:hover
            background-color: #E4FDDB !important
    &_fill-blue
        box-shadow: 0px 2px 5px 0px #809EBF40
        &:hover
            background-color:  #2E54DC !important
        &::v-deep
            .plus-icon
                stroke: #fff
</style>
