import { reflectKeys } from "@helpers"
import { LESSON_PREFIX } from "./lesson-prefix"

export const {
    COURSES,
    LESSON_SETTINGS,
    COURSE_PROGRAM,
    LESSON,
    LESSON_ENTRY,
    LESSON_LOADER,
    LESSON_ERROR,
    SECTION,
    COURSE,
    LESSON_SECTIONS,
    ACTIVE_SECTION_INDEX,
    LESSON_DTO
} = reflectKeys(
    [
        "COURSES",
        "LESSON_SETTINGS",
        "COURSE_PROGRAM",
        "LESSON",
        "LESSON_ENTRY",
        "LESSON_LOADER",
        "LESSON_ERROR",
        "SECTION",
        "COURSE",
        "LESSON_SECTIONS",
        "ACTIVE_SECTION_INDEX",
        "LESSON_DTO"
    ],
    LESSON_PREFIX
)

export default {
    [LESSON]: state => state.lesson,
    /**
     * @description Keys are used when copying a lesson
     * @see ./lesson-actions.js COPY_LESSON_CONTENT
     */
    [LESSON_SETTINGS]: state => ({
        status_id: state?.lesson?.status_id || 1,
        type_id: state?.lesson?.type_id || 1,
        image: state?.lesson?.image,
        is_public: state?.lesson?.is_public || false,
        is_skip_checkpoint: state?.lesson?.is_skip_checkpoint || false,
        is_open: state?.lesson?.is_open || false,
        offers: state?.lesson?.offers || [],
        is_preview_added: state?.lesson?.is_preview_added || false,
        lesson_previews: state?.lesson?.lesson_previews || [],
        video_previews: state?.lesson?.video_previews || [],
        preview: state?.lesson?.preview || null,
        accessible_after_days: state?.lesson?.accessible_after_days || 0,
        accessible_start_hour: state?.lesson?.accessible_start_hour || "00:00",
        accessible_timezone: state?.lesson?.accessible_timezone || null,
        accessible_timezone_id: state?.lesson?.accessible_timezone_id || null
    }),
    [LESSON_LOADER]: state => loader => state.loaders[loader],
    [LESSON_ERROR]: state => state.error,
    [LESSON_ENTRY]: state => state.entry,
    [COURSE_PROGRAM]: state => id => state.programs[id],
    [COURSES]: state => state.courses,
    [LESSON_DTO]: state => state.lessonDto,
    [COURSE]: state => state.course,
    [SECTION]: state => state.section || state?.lesson?.sections[state.activeSectionIndex] || null,
    [LESSON_SECTIONS]: state => state?.lesson?.sections || [],
    [ACTIVE_SECTION_INDEX]: state => state.activeSectionIndex
}
